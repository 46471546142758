export const localStorageFields = {
    rememberMe: 'rememberMe'
}

export const apiResponseMessages = {
    VERIFY_CODE_EXISTS: "Верификационный код уже был отправлен вам на почту!",
    VALIDATION_ERROR: "Пользователь с текущим адресом эл.почты уже существует",
    WRONG_VERIFY_CODE: "Код верификации неверный",
    USER_NOT_FOUND: "Неверный адрес эл.почты или пароль",
    FORGOT_PASSWORD_EMAIL_NOT_FOUND: "Адрес эл.почты не найден",
    FORGOT_PASSWORD_VERIFY_CODE_EXISTS: "Верификационный код уже был отправлен вам на почту!"
}
