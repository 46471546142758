import { createSlice } from '@reduxjs/toolkit';

const initialState: { list: any[] } = {
    list: [],
};

const appEventsSlice = createSlice({
    name: 'appEvents',
    initialState,
    reducers: {
        addAppEvents: (state, action) => {
            state.list = [...state.list, ...action.payload];
        },
        setAppEvents: (state, action) => {
            state.list = action.payload;
        },
    },
});

export const appEventsActions = appEventsSlice.actions;
export const appEventsReducer = appEventsSlice.reducer;

export const { addAppEvents } = appEventsSlice.actions;
export default appEventsSlice.reducer;
