import { IconContext } from 'react-icons';
import { MdPerson } from 'react-icons/md';

import { appRoutes } from '@core/navigation';
import AlertNode from '@entities/.app/alert/hooks/alert.useAlert';

const Modals = () => {
    return (
        <>
            <AlertNode delay={1500} />
            {/* Offcanvas */}
            <div className="offcanvas offcanvas-top" tabIndex={-1} id="warning">
                <div className="d-flex align-items-center justify-content-center">
                    <IconContext.Provider value={{ className: 'icon-20' }}>
                        <MdPerson />
                    </IconContext.Provider>
                    <div className="fs-12 fw-7 title-font ms-4">
                        Пожалуйста,{' '}
                        <a href={appRoutes.ROOT} className="bb-1">
                            войдите
                        </a>{' '}
                        в аккаунт или{' '}
                        <a href={appRoutes.ROOT} className="bb-1">
                            зарегистрируйтесь
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Modals;
