import axios from 'axios';

import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

export const askQuestion = async (payloads) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.QUESTION_ASK), payloads);
        return response;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const getDataForHomePage = async (payload) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.APP_HOME), payload);
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};
