import { Navigate, Route, Routes } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import RoleFilter from '@core/navigation/Router.RoleFilter';
import { useAppSelector } from '@core/store/hooks';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import OfficeLayout from '@features/office/Office.Layout';
import OfficeMenuWidget from '@features/office/Office.Menu.Widget';
import AppNotFoundPage from '@processes/.app/App.NotFound.Page';
import OfficeAdTemplatesPage from '@processes/office/Office.AdTemplates.Page';
import OfficeCargosPage from '@processes/office/Office.Cargos.Page';
import OfficeCarsPage from '@processes/office/Office.Cars.Page';
import OfficeDocumentsPage from '@processes/office/Office.DealDocuments.Page';
import OfficeMessagerPage from '@processes/office/Office.Messager.Page';
import OfficeDealsPage from '@processes/office/Office.Deals.Page';
import OfficeProfileEditPage from '@processes/office/Office.Profile.Edit.Page';
import OfficeProfileViewPage from '@processes/office/Office.Profile.View.Page';
import OfficeRoutesPage from '@processes/office/Office.Routes.Page';
import OfficeTariffsPage from '@processes/office/Office.Tariffs.Page';
import { useWidth } from '@shared/hooks/useWidth';

const DealsDocsRoute = () => {
    const currentDeal = useAppSelector(selectCurrentDeal);
    return currentDeal ? <OfficeDocumentsPage /> : <Navigate to={appRoutes.ROOT} />;
};

const OfficeIndexRoute = () => {
    const isMobile = useWidth(991);
    return isMobile ? <OfficeMenuWidget /> : <Navigate to={appRoutes.PROFILE} replace={true} />;
};

const OfficeRouter = () => {
    return (
        <Routes>
            <Route path={appRoutes.ROOT} element={<OfficeLayout />}>
                <Route index element={<OfficeIndexRoute />} />

                <Route path={appRoutes.PROFILE} element={<OfficeProfileViewPage />} />
                <Route path={appRoutes.PROFILE_EDIT} element={<OfficeProfileEditPage />} />

                <Route element={<RoleFilter />}>
                    <Route path={appRoutes.CARS} element={<OfficeCarsPage />} />
                    <Route path={appRoutes.CARGOS} element={<OfficeCargosPage />} />
                    <Route path={appRoutes.ROUTES} element={<OfficeRoutesPage />} />
                </Route>

                <Route path={appRoutes.DEALS} element={<OfficeDealsPage />} />
                <Route path={appRoutes.DEAL_DOCS} element={<DealsDocsRoute />} />
                <Route path={appRoutes.MESSAGER} element={<OfficeMessagerPage />} />

                <Route path={appRoutes.TARIFFS} element={<OfficeTariffsPage />} />
                <Route path={appRoutes.TEMPLATES} element={<OfficeAdTemplatesPage />} />
            </Route>

            <Route path={appRoutes.ALL} element={<AppNotFoundPage />} />
        </Routes>
    );
};
export default OfficeRouter;
