import {appRoutes} from '@core/navigation';
import { getDateUI } from '@shared/lib/dateTIme';
import { getURL } from '@shared/lib/getURL';
import CustomSelect from '@shared/ui/CustomSelect';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';
import { IconContext } from 'react-icons';
import { IoFolderOpen } from 'react-icons/io5';
import { NavLink, useOutletContext } from 'react-router-dom';

const ForumTopic = (props) => {
    return (
        <div className="forum-section">
            <div className="icon">
                <IconContext.Provider
                    value={{
                        className: 'green'
                    }}
                >
                    <IoFolderOpen />
                </IconContext.Provider>
            </div>
            <NavLink to={props.id ? getURL(appRoutes.FORUM_TOPIC, props.id) : getURL()} className="text-topic">
                <div className="fs-11 fw-5 title-font text-truncate d-block">{props.title || 'без названия'}</div>
                {props.info && <div className="info mt-1">{props.info}</div>}
            </NavLink>
            <div className="messages">{props.messages || ''}</div>
            <div className="latest">
                {props.latest && <div className="mb-1">{props.latest}</div>}
                <NavLink
                    to={props.userId ? getURL(appRoutes.MARKET_PROFILE, props.userId) : getURL()}
                    className="blue text-decoration-underline"
                >
                    {props.userName || 'Имя пользователя'}
                </NavLink>
            </div>
        </div>
    );
};

const ForumTopics = () => {
    const [topics, topicsPagination, isAuth] = useOutletContext<any>();

    return !isAuth ? (
        <div className="col-lg-9">
            <div className="d-flex justify-content-end mb-3">
                <Pagination
                    pageLimit={topicsPagination.pageLimit}
                    currentPage={topicsPagination.currentPage}
                    setCurrentPage={topicsPagination.setCurrentPage}
                    pagesDisplayedLimit={3}
                    itemsAmount={topics?.meta?.total || 0}
                    startingPage={topicsPagination.startingPage}
                    setStartingPage={topicsPagination.setStartingPage}/>
            </div>
            <div className="forum-header">
                <div className="icon" />
                <div className="text">Название темы</div>
                <div className="messages">Сообщений</div>
                <div className="latest">Последнее сообщение</div>
            </div>
            {topics.isLoading ? (
                topics?.items?.length ? (
                    topics.items.map((item) => (
                        <ForumTopic
                            key={item?.id}
                            id={item?.id}
                            title={item?.title}
                            info={item?.description}
                            topics={item?.topics}
                            messages={item?.messagesCount}
                            latest={item?.lastMessage ? getDateUI(item?.lastMessage?.updatedAt) : null}
                            userName={item?.user?.firstName}
                            userId={item?.user?.id}
                        />
                    ))
                ) : (
                    <h6 className="text-center w-100 p-5">Пока нет ни одной темы</h6>
                )
            ) : (
                <div className="w-100 d-flex justify-content-center p-5">
                    <Loader color="#545454" />
                </div>
            )}
            <div className="d-flex align-items-center justify-content-between mt-4">
                <Pagination
                    pageLimit={topicsPagination.pageLimit}
                    currentPage={topicsPagination.currentPage}
                    setCurrentPage={topicsPagination.setCurrentPage}
                    pagesDisplayedLimit={3}
                    itemsAmount={topics?.meta?.total || 0}
                    startingPage={topicsPagination.startingPage}
                    setStartingPage={topicsPagination.setStartingPage}
                />
                <div className="d-flex align-items-center">
                    <span className="d-none d-sm-block me-2">показать</span>
                    <CustomSelect
                        className="inp"
                        name="items-count"
                        options={['10', '15', '20']}
                        checkedOptions={[topicsPagination.pageLimit]}
                        callback={({ title }) => {
                            topicsPagination.setCurrentPage(1);
                            topicsPagination.setPageLimit(+title);
                        }}
                        align="right"
                    />
                    <span className="ms-2 d-none d-md-block">тем на странице</span>
                </div>
            </div>
        </div>
    ) : (
        <p className="p-5 text-center col-lg-9 fs-5">
            Невозможно загрузить список тем.{' '}
            <NavLink to={getURL(appRoutes.LOGIN)} className="green fw-bold">
                Авторизуйтесь
            </NavLink>
            , чтобы посмотреть их
        </p>
    );
};

export default ForumTopics;
