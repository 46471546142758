const AppInformationAboutPayPage = () => {
    return (
        <div className="box px-0 p-sm-4 p-xl-5 bg-transparent" style={{ border: 'none' }}>
            <h1 className="dark-blue text-center d-lg-none">Информация об оплате</h1>

            <div className="pay-information">
                <strong>Оплата банковскими картами осуществляется через АО «Тинькофф Банк»</strong>
                <p>К оплате принимаются карты VISA, MasterCard, Платежная система «Мир».</p>
                <p>
                    Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных
                    систем Visa, MasterCard и Платежная система «Мир» на принципах соблюдения конфиденциальности и
                    безопасности совершения платежа, для чего используются самые современные методы проверки, шифрования
                    и передачи данных по закрытым каналам связи. Ввод данных банковской карты осуществляется на
                    защищенной платежной странице АО «Тинькофф Банк»
                    <br />
                    На странице для ввода данных банковской карты потребуется ввести данные банковской карты: номер
                    карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA или
                    CVC2 для MasterCard). Все необходимые данные пропечатаны на самой карте. Трёхзначный код
                    безопасности — это три цифры, находящиеся на обратной стороне карты. Далее вы будете перенаправлены
                    на страницу Вашего банка для ввода 3DSecure кода, который придет к Вам в СМС. Если 3DSecure код к
                    Вам не пришел, то следует обратится в банк выдавший Вам карту.
                </p>
                <p>
                    Случаи отказа в совершении платежа:
                    <br />- банковская карта не предназначена для совершения платежей через интернет, о чем можно
                    узнать, обратившись в Ваш Банк;
                    <br />- недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на
                    банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;
                    <br />- данные банковской карты введены неверно;
                    <br />- истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой
                    стороне карты (это месяц и год, до которого действительна карта). Подробнее - сроке действия карты
                    Вы можете узнать, обратившись в банк, выпустивший банковскую карту.
                    <br />
                    По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сайта, Вы можете
                    обращаться по следующим телефонам:
                    <a href="tel:+79639025009">+7 963 902-50-09</a> и <a href="tel:+79279330770">+7 927 933-07-70</a>
                </p>
                <p>
                    <strong>Возврат средств</strong>
                    <br />
                    При оплате картами возврат наличными денежными средствами не допускается. Порядок возврата
                    регулируется правилами международных платежных систем. Процедура возврата товара регламентируется
                    статьей 26.1 федерального закона «О защите прав потребителей».
                    <br />
                    -Потребитель вправе отказаться от покупки в любое время до его передачи, а после передачи услуги - в
                    течение семи дней;
                    <br />
                    -Потребитель не вправе отказаться от услуги надлежащего качества, имеющего
                    индивидуально-определенные свойства, если указанный товар может быть использован исключительно
                    приобретающим его человеком;
                    <br />
                    -При отказе потребителя от услуги продавец должен возвратить ему денежную сумму, уплаченную
                    потребителем по договору.
                    <br />
                    Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате
                    денежных средств», которое высылается по требованию компанией на электронный адрес и оправить его
                    вместе с приложением копии паспорта по адресу <a href="mailto:erigold@mail.ru">erigold@mail.ru</a>.
                    <br />
                    Возврат денежных средств будет осуществлен на банковскую карту в течение 21 (двадцати одного)
                    рабочего дня со дня получения «Заявление о возврате денежных средств» Компанией.
                    <br />
                    Для возврата денежных средств по операциям проведенными с ошибками необходимо обратиться с
                    письменным заявлением и приложением копии паспорта и чеков/квитанций, подтверждающих ошибочное
                    списание. Данное заявление необходимо направить по адресу 
                    <a href="mailto:erigold@mail.ru">erigold@mail.ru</a>.
                    <br />
                    Сумма возврата будет равняться сумме покупки. Срок рассмотрения Заявления и возврата денежных
                    средств начинает исчисляться с момента получения Компанией Заявления и рассчитывается в рабочих днях
                    без учета праздников/выходных дней.
                </p>
                <br />
            </div>
        </div>
    );
};
export default AppInformationAboutPayPage;
