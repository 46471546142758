interface IProps {
    children: any;
    error: any;
    className?: string;
}

const ValidateWrapper = ({ children, error, className }: IProps) => (
    <div className={`validate-wrapper ${error ? 'validate-wrapper_error' : ''}`}>
        {children}
        {error && <div className={`validate-error ${className ?? ''}`}>{error?.message}</div>}
    </div>
);
export default ValidateWrapper;
