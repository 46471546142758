import { bindActionCreators } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { IAppDispatch, IAppState } from '@core/store/types';
import { citiesActions } from '@entities/.app/cities/cities.slice';
import { accountActions } from '@entities/.app/account/account.slice';
import { dealActions } from '@entities/office/deal/deal.slice';
import { documentActions } from '@entities/office/dealDocument/dealDocument.slice';
import { appEventsActions } from '@entities/.app/appEvents/appEvents.slice';
import { alertActions } from '@entities/.app/alert/alert.slice';

export const useAppSelector: TypedUseSelectorHook<IAppState> = useSelector;
export const useAppDispatch: () => IAppDispatch = useDispatch;

const actions = {
    ...alertActions,
    ...appEventsActions,
    ...citiesActions,
    ...accountActions,
    ...dealActions,
    ...documentActions,
};

export const useActions = () => {
    const dispatch = useAppDispatch();
    return bindActionCreators(actions, dispatch);
};
