import { onInputHandler } from '@shared/lib/forms';

export const AdContactsForm = ({ data, setData, setContactsInfo, resetFieldVal, valid }) => {
    return (
        <div className="box">
            {/*<div className="row gx-2 gx-sm-4 mb-4 mb-md-0">
            <div className="d-flex justify-content-center">
                <button
                    type="button"
                    onClick={() => addContacts()}
                    className="green fs-11 fw-5 text-start"
                >
                    <IconContext.Provider
                        value={{className: "green icon-15"}}
                    >
                        <IoAddCircle/>
                    </IconContext.Provider>
                    <span className="ms-2">Добавить контакт</span>
                </button>
            </div>
        </div>*/}
            <div className="row mt-3">
                <div className="col-md-9">
                    <div className="row align-items-center gy-2 gy-md-3">
                        <div className="col-md-4">
                            <div data-label={'contactPhone'} data-warning="false" className="title-font fs-12 fw-5">
                                Телефон*
                            </div>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="tel"
                                name="phone"
                                //@ts-ignore
                                value={data?.contacts?.map((i) => i.phone) || ''}
                                onChange={(e) => {
                                    setContactsInfo((prevState) => ({
                                        ...prevState,
                                        phone: e.target.value,
                                    }));
                                    resetFieldVal(e, 'isInValidPhone');
                                }}
                                style={{ borderColor: valid.isInValidPhone && 'red' }}
                                placeholder="+ 7 (962) 458 65 79"
                                className="w-100 fs-12"
                            />
                            {valid.isInValidPhone && (
                                <span className="position-absolute" style={{ color: valid.isInValidPhone && 'red' }}>
                                    Поле пустое или введены неверные данные
                                </span>
                            )}
                        </div>
                        <div className="col-md-4">
                            <div data-label={`contactName`} data-warning="false" className="title-font fs-12 fw-5">
                                Имя*
                            </div>
                        </div>
                        <div className="col-md-8">
                            <input
                                type="text"
                                name="firstName"
                                //@ts-ignore
                                value={data?.contacts?.map((i) => i.firstName) || ''}
                                onChange={(e) => {
                                    setContactsInfo((prevState) => ({
                                        ...prevState,
                                        firstName: e.target.value,
                                    }));
                                    resetFieldVal(e, 'isInValidFirstName');
                                }}
                                style={{ borderColor: valid.isInValidFirstName && 'red' }}
                                placeholder="Имя"
                                className="w-100 fs-12"
                            />
                            {valid.isInValidFirstName && (
                                <span
                                    className="position-absolute"
                                    style={{ color: valid.isInValidFirstName && 'red' }}
                                >
                                    Поле пустое или введены неверные данные
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {/*{contactsArray.map((obj, idx) => (
        <div className="row mt-3">
            <div className="col-md-9">
                <div className="row align-items-center gy-2 gy-md-3">
                    <div className="col-md-4">
                        <div
                            data-label={"contactPhone"}
                            data-warning="false"
                            className="title-font fs-12 fw-5"
                        >
                            Телефон
                        </div>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="tel"
                            name='phone'
                            onChange={e => setContactsInfo(prevState => ({...prevState, phone: e.target.value}))}
                            placeholder="+ 7 (962) 458 65 79"
                            className="w-100 fs-12"
                        />
                    </div>
                    <div className="col-md-4">
                        <div
                            data-label={`contactName`}
                            data-warning="false"
                            className="title-font fs-12 fw-5"
                        >
                            Имя
                        </div>
                    </div>
                    <div className="col-md-8">
                        <input
                            type="text"
                            name='firstName'
                            onChange={e => setContactsInfo(prevState => ({...prevState, firstName: e.target.value}))}
                            placeholder="Имя"
                            className="w-100 fs-12"
                        />
                    </div>
                </div>
            </div>
            {contactsArray?.length > 1 &&
                    <div className="col-md-3 mt-2 mt-md-0">
                        <button
                            type="button"
                            onClick={() => deleteContacts(obj)}
                            className="red fs-11 fw-5"
                        >
                            <IconContext.Provider
                                value={{className: "red icon-15"}}
                            >
                                <IoCloseCircle/>
                            </IconContext.Provider>
                            <span className="ms-2">Удалить</span>
                        </button>
                    </div>
                }
        </div>
        )).reverse()}*/}
            <div className="row mt-3">
                <div className="col-md-3 mb-2 mb-md-0">
                    <div data-label="note" data-warning="false" className="title-font fs-12 fw-5">
                        Примечание
                    </div>
                </div>
                <div className="col-md-9">
                    <textarea
                        rows={3}
                        name="note"
                        //@ts-ignore
                        value={data?.note || ''}
                        onChange={(e) => onInputHandler(e, setData)}
                        placeholder="Укажите здесь дополнительную информацию или дополнительные контакты"
                    />
                </div>
            </div>
        </div>
    );
};
