
import { Outlet } from 'react-router-dom';

import Modals from '@features/.app/App.Modals';
import { AppFooter } from '@features/.app/App.Footer';
import AppHeader from '@features/.app/App.Header';

const AppLayout = () => {
    return (
        <div className="root-wrapper">
            <AppHeader />
            <div className="content-wrapper">
                <Outlet />
            </div>
            <AppFooter />
            <Modals />
        </div>
    );
};
export default AppLayout;
