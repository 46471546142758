import { Link, useLocation } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import OfficeRouter from '@core/navigation/Router.Office';
import { useWidth } from '@shared/hooks/useWidth';
import { getURL } from '@shared/lib/getURL';

export default function OfficeMainPage() {
    const location = useLocation();
    const isMobile = useWidth();

    return (
        <main className="account bg-gray py-sm-3 py-md-4 py-lg-5 bg-transparent">
            <section
                id="sec-12"
                className={location?.pathname.slice(1) === appRoutes.OFFICE_MESSAGER && isMobile ? '' : 'container'}
            >
                <Link to={getURL()} className="fs-12 fw-5 d-none d-lg-block d-lg-none mb-5">
                    <span className="green fs-15 me-2">⟵</span>
                    На главную
                </Link>
                <OfficeRouter />
            </section>
        </main>
    );
}
