import { useState, useEffect } from 'react';

const useMatchMedia = ({ initialState = undefined, query, event }) => {
    const [state, setState] = useState(window.matchMedia(initialState).matches);
    const changeState = () => {
        if (window.matchMedia(query).matches) {
            setState(true);
        } else {
            setState(false);
        }
    };

    useEffect(() => {
        changeState();

        window.addEventListener(event, changeState);
        return () => window.removeEventListener(event, changeState);
    }, []);

    return { state, setState };
};
export default useMatchMedia;
