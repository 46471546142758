import axios from 'axios';

import { apiRoutes, getAxiosPrivate } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

export const getLogout = async () => {
    try {
        await (await (await getAxiosPrivate())).get(getURL(apiRoutes.APP_API_URL, apiRoutes.LOGOUT));
    } catch (error) {
        Logger.error(error);
    }
};

//PAYMENTS

export const buyTariff = async (userId, tariffType) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.PAYMENTS_BUY_TARIFF, userId), {
            tariffType,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const getTariffs = async () => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.TARIFFS_GET));
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

//PROFILE

export const getAccountType = async () => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.ACCOUNT_TYPES));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const updateUserInfo = async (axiosPrivate, id, formData) => {
    try {
        const response = await axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.ACTIONS_USER, id), formData);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteUserAvatar = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.DELETE_AVATAR, id));
        return response;
    } catch (error) {
        Logger.error(error);
    }
};

export const getUserInfo = async (userId) => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.ACTIONS_USER, userId));
        return response;
    } catch (error) {
        Logger.error(error);
    }
};

export const reportUser = async (axiosPrivate, ids) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.REPORT_USER), ids);
        return response;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};
