
import CargoPageContent from '@features/market/cargo/Cargo.FormPageContent';
import { useParams } from 'react-router-dom';


export default function CargoEditPage() {
    const {id} = useParams();

  return <CargoPageContent cargoId={id}/>
}
