
import { Tooltip } from 'bootstrap';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { VscChromeClose } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { setAlert, showNoAuthAlert } from '@entities/.app/alert/alert.actions';
import { selectUserId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { createCar, getCar, getCarTypes, updateCar } from '@entities/market/car/car.api';
import { getURL } from '@shared/lib/getURL';
import { CarForm } from '@features/market/car/Car.Form';

const MobileButtons = ({ resetForm }) => {
    return (
        <div className="mobile-btns d-block d-lg-none" style={{ zIndex: 1 }}>
            <div className="container d-flex align-items-center justify-content-center">
                <div className="d-flex align-items-center justify-content-between blue title-font fw-5 fs-11">
                    <button type="reset" onClick={() => resetForm()}>
                        <IconContext.Provider value={{ className: 'icon-15' }}>
                            <VscChromeClose />
                        </IconContext.Provider>
                        <span className="ms-1">Очистить форму</span>
                    </button>
                </div>
                <button type="submit" className="btn btn-1 text-uppercase fs-15 mx-auto mt-2">
                    добавить машину
                </button>
            </div>
        </div>
    );
};

export default function CarPageContent({ carId = null }) {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useAppDispatch();

    const currentUserId = useAppSelector(selectUserId);

    const [carTypes, setCarTypes] = useState([]);
    const [selectValue, setSelectValue] = useState(null);
    // const [radioBtnState, setRadioBtnState] = useState(null);
    const [responseErrors, setResponseErrors] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
        reset,
        getValues,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        carId &&
            getCar(axiosPrivate, carId).then((result) => {
                reset({
                    id: result?.id,
                    name: result?.name,
                    additionalConfiguration: result?.additionalConfiguration,
                    carrying: result?.carrying,
                    capacity: result?.capacity,
                    width: result?.width,
                    height: result?.height,
                    length: result?.length,
                    sts: result?.sts,
                    vin: result?.vin,
                    pts: result?.pts,
                    carBodyTypeId: result?.carBodyTypeId,
                });
                // @ts-ignore
                setSelectValue({
                    value: result?.bodyType?.id,
                    label: result?.bodyType?.name,
                });
                // setRadioBtnState(result?.additionalConfiguration);
            });
    }, [carId, reset]);

    useEffect(() => {
        getCarTypes(axiosPrivate)
            .then((items) => items && setCarTypes(items.map((item) => ({ value: item.id, label: item.name }))))
            .catch(() => setCarTypes([]));
    }, []);

    useEffect(() => {
        if (formData && currentUserId) {
            setResponseErrors(null);

            const handleFormSubmit = async () => {
                try {
                    await createCar(axiosPrivate, formData, currentUserId);
                    // @ts-ignore
                    dispatch(setAlert('success', 'Машина успешно добавлена'));
                    setTimeout(() => {
                        resetForm();
                        navigate(getURL(appRoutes.OFFICE_CARS));
                    }, 2500);
                } catch (error) {
                    // @ts-ignore
                    dispatch(setAlert('danger', 'Не удалось добавить машину'));
                    if (error?.response?.data?.body?.errors) {
                        const errors = error.response.data.body.errors;

                        errors.forEach((error) =>
                            setResponseErrors((prev) => ({
                                ...prev,
                                [error.field]: {
                                    message: error.message,
                                },
                            })),
                        );
                    }
                }
            };
            if (carId) {
                updateCar(axiosPrivate, formData, currentUserId)
                    .then(() => {
                        // @ts-ignore
                        dispatch(setAlert('success', 'Данные машины были обновлены'));
                        setTimeout(() => {
                            resetForm();
                            navigate(-1);
                        }, 2500);
                    })
                    .catch((error) => {
                        // @ts-ignore
                        dispatch(setAlert('danger', 'Не удалось обновить данные машины'));

                        if (error?.response?.data?.body?.errors) {
                            const errors = error.response.data.body.errors;

                            errors.forEach((error) =>
                                setResponseErrors((prev) => ({
                                    ...prev,
                                    [error.field]: {
                                        message: error.message,
                                    },
                                })),
                            );
                        }
                    });
            } else {
                handleFormSubmit();
            }
        } else if (formData && !currentUserId) {
            // @ts-ignore
            dispatch(showNoAuthAlert());
        }
    }, [formData, currentUserId]);

    const onSubmit = (data) => setFormData((prev) => ({ ...prev, ...data }));

    const resetForm = () => {
        reset({
            name: '',
            additionalConfiguration: '',
            carrying: '',
            capacity: '',
            width: '',
            height: '',
            length: '',
            sts: '',
            vin: '',
            pts: '',
            carBodyTypeId: '',
        });
        setFormData(null);
        setResponseErrors(null);
        setSelectValue(null);
    };

    const loadOptions = async (searchKey) => {
        const defaultValue = getValues('carBodyTypeId');
        setSelectValue(carTypes.find((item) => item.value === defaultValue));

        if (!searchKey) {
            return await carTypes;
        } else {
            return await carTypes.filter((item) => item.label.includes(searchKey));
        }
    };

    useEffect(() => {
        //init tooltip
        Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
            (tooltipNode) => new Tooltip(tooltipNode),
        );
    });

    return (
        <main className="bg-gray bg-transparent">
            <section
                id="sec-9"
                className="container pt-4 pt-sm-5 py-lg-5 box shadow"
                style={{ marginTop: '3rem', marginBottom: '3rem' }}
            >
                <button
                    onClick={() => navigate(getURL(appRoutes.OFFICE_CARS))}
                    className="fs-12 fw-5 d-block mb-3 mb-sm-5"
                >
                    <span className="green fs-15 me-2">⟵</span> Назад
                </button>
                <h1 className="dark-blue text-center text-uppercase">{`${carId ? 'Редактирование' : 'Добавление'} Машины`}</h1>
                <form className="row" noValidate onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-lg-8">
                        <fieldset name="aboutCar" className="mt-lg-5">
                            <div className="d-flex align-items-center justify-content-center justify-content-lg-between mb-4 mb-lg-3">
                                <h4 className="text-center text-lg-start mb-0">Транспорт</h4>
                                <div className="d-none d-lg-flex align-items-center fs-09">
                                    <button
                                        type="reset"
                                        className="btn btn-4 p-2 ms-3"
                                        onClick={() => {
                                            resetForm();
                                            setSelectValue(null);
                                        }}
                                    >
                                        <IconContext.Provider value={{ className: 'icon-15' }}>
                                            <VscChromeClose />
                                        </IconContext.Provider>
                                        <span className="ms-2">Очистить форму</span>
                                    </button>
                                </div>
                            </div>
                            <CarForm
                                control={control}
                                register={register}
                                loadOptions={loadOptions}
                                carTypes={carTypes}
                                selectValue={selectValue}
                                setSelectValue={setSelectValue}
                                errors={errors}
                                responseErrors={responseErrors}
                            />
                            <MobileButtons resetForm={resetForm} />
                        </fieldset>

                        <div className="d-none d-lg-block title-font fs-09 fw-5 mt-3">
                            * Поля обязательные к заполнению
                        </div>
                    </div>
                    <div className="col-lg-4 pt-lg-5 position-relative d-none d-lg-block">
                        <aside className="box">
                            <button type="submit" className="btn btn-1 text-uppercase fs-15 mx-auto">
                            {carId ? 'сохранить изменения' : 'добавить машину'}
                            </button>
                        </aside>
                    </div>
                </form>
            </section>
        </main>
    );
}
