import { appRoutes } from '@core/navigation';
import { useActions, useAppDispatch, useAppSelector } from '@core/store/hooks';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import {
    deleteDataSet,
    getDataSets,
    getDocModel,
    getDocStructure,
    saveDataSet,
} from '@entities/office/dealDocument/dealDocument.api';
// import { doc0, doc1, doc2, doc3, doc4, doc5, doc6, doc7 } from '@entities/office/dealDocument/dealDocument.data';
import Loader from '@shared/ui/Loader';
import { memo, useCallback, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoCloseOutline } from 'react-icons/io5';
import { MdBorderColor, MdChat, MdFileDownload, MdHelp, MdLocalPrintshop } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import store from '@core/store';
import { selectCurrentUser, selectUserId } from '@entities/.app/account/account.selectors';
import { selectAppEventsList } from '@entities/.app/appEvents/appEvents.selectors';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import {
    actionSaveDocModel,
    downloadDocFile,
    setCellEditedState,
} from '@entities/office/dealDocument/dealDocument.actions';
import {
    selectDataSets,
    selectDocFillFields,
    selectDocIsFullFilled,
    selectDocIsModifyed,
    selectDocModel,
    selectDocStructure,
    selectPickType,
} from '@entities/office/dealDocument/dealDocument.selectors';
import { EInsertTypes, EPickTypes } from '@entities/office/dealDocument/dealDocument.types';
import { useEditor } from '@entities/office/dealDocument/hooks/document.useEditor';
import DealDocumentRender from '@features/office/dealDocument/DealDocument.Render';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';
import { deepCopy } from '@shared/lib/objects';
// import CustomModal from '@shared/ui/CustomModal';
import { setAlert } from '@entities/.app/alert/alert.actions';

const CloseDocModal = ({ setIsShowModalToChat }) => {
    const axiosPrivate = useAxiosPrivate();

    const currentDeal = useAppSelector(selectCurrentDeal);
    const currentUser = useAppSelector(selectCurrentUser);

    const docModel = useAppSelector(selectDocModel);
    // const docIsFullFilled = useAppSelector(selectDocIsFullFilled);

    const { docIsDeclareClosed } = useEditor();

    return (
        <div className="modal fade" id="close-doc" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close" data-bs-dismiss="modal">
                            <IoCloseOutline />
                        </button>
                        <div style={{ textAlign: 'center' }}>
                            {docIsDeclareClosed ? (
                                <>
                                    <h4>
                                        Закрыв документ, его нельзя будет редактировать - он будет доступен в режиме
                                        чтения и для экспорта.
                                    </h4>
                                    <h3 style={{ marginTop: ' 1em' }}>Подтвердить закрытие?</h3>
                                </>
                            ) : (
                                <>
                                    <h4>
                                        Как только партнер подтвердит документ закрытым, его нельзя будет редактировать
                                        - он будет доступен в режиме чтения и для экспорта.
                                    </h4>
                                    <h3 style={{ marginTop: ' 1em' }}>Объявить закрытым?</h3>
                                </>
                            )}
                        </div>
                        <div className="row row-cols-sm-2 fs-12">
                            <div className="mb-3 mb-sm-0">
                                <button type="button" data-bs-dismiss="modal" className="btn btn-1 w-100">
                                    Нет
                                </button>
                            </div>
                            <div>
                                <button
                                    data-bs-dismiss="modal"
                                    type="button"
                                    className="btn btn-2 w-100"
                                    onClick={() =>
                                        actionSaveDocModel({
                                            axiosPrivate,
                                            currentDeal,
                                            currentUser,
                                            docModel,
                                            docIsFullFilled: true, // docIsFullFilled,
                                            docIsDeclareClosed,
                                            docDeltaState: store.getState().document.edit.deltaState,
                                            setIsShowModalToChat,
                                        })
                                    }
                                >
                                    Да
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const HelpModal = () => {
    return (
        <div className="modal fade" id="help-edit" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <h3>Элементы редактора документов</h3>
                        <div
                            style={{
                                padding: 10,
                                display: 'flex',
                                rowGap: 10,
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div style={{ padding: 10, display: 'flex', rowGap: 10, flexDirection: 'column' }}>
                                <p>Динамические поля</p>

                                <div>
                                    <input
                                        className="form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Пусто"
                                        onChange={() => {}}
                                    ></input>
                                    <input
                                        className="close form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Закрыто"
                                        disabled
                                    ></input>
                                </div>
                                <div>
                                    <input
                                        className="filled form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Согласовано"
                                        onChange={() => {}}
                                    ></input>
                                    <input
                                        className="close filled form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Согласовано"
                                        disabled
                                    ></input>
                                </div>

                                <div>
                                    <input
                                        className="filled-new form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Изменения партнера"
                                        onChange={() => {}}
                                    ></input>
                                    <input
                                        className="close filled-new form-control input-block"
                                        style={{ width: '200px' }}
                                        value="Изменения партнера"
                                        disabled
                                    ></input>
                                </div>

                                <input
                                    className="filled-current form-control input-block"
                                    style={{ width: '200px' }}
                                    value="Ваши новые правки"
                                    onChange={() => {}}
                                ></input>
                            </div>
                            <div>
                                <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                    <MdBorderColor />
                                </IconContext.Provider>
                                -{' '}
                                <p style={{ textDecoration: 'underline', display: 'inline-block' }}>
                                    пикер шаблонных данных
                                </p>{' '}
                                для автозаполнения блоков полей. Мы можете сохранять свои заполненные структуры и затем
                                быстро подставлять их в новых документах. Например, реквизиты компании или персональные
                                данных водителей.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const schemaREQ = {
    [EInsertTypes.REQ_NAME]: 'Наименование организации',
    [EInsertTypes.REQ_ADDRESS]: 'Юридический адрес',
    [EInsertTypes.REQ_ADDRESS2]: 'Адрес для корреспонденции',
    [EInsertTypes.REQ_OGRN]: 'ОГРН',
    [EInsertTypes.REQ_INN]: 'ИНН',
    [EInsertTypes.REQ_BANK_RS]: 'Расчетный счет',
    [EInsertTypes.REQ_BANK_NAME]: 'Банк',
    [EInsertTypes.REQ_BANK_INN]: 'ИНН банка',
    [EInsertTypes.REQ_BANK_KPP]: 'КПП банка',
    [EInsertTypes.REQ_BANK_BIK]: 'БИК',
    [EInsertTypes.REQ_BANK_KS]: 'Корреспонденский счет',
    [EInsertTypes.REQ_CONTACT_NAME]: 'Контактное лицо',
    [EInsertTypes.REQ_CONTACT_PHONE]: 'Телефон/Факс',
    [EInsertTypes.REQ_CONTACT_EMAIL]: 'Электронная почта',
};
const schemaDRIVER = {
    [EInsertTypes.DRIVER_NAME]: 'ФИО',
    [EInsertTypes.DRIVER_PASPORT_FULL]: 'Паспорт',
    // [EInsertTypes.DRIVER_PASPORT_PART]: 'Паспорт: серия',
    // [EInsertTypes.DRIVER_PASPORT_NUMBER]: 'Паспорт: номер',
    // [EInsertTypes.DRIVER_PASSPORT_LOC]: 'Паспорт: кем выдан',
    // [EInsertTypes.DRIVER_PASSPORT_DATE]: 'Паспорт: дата выдачи',
    // [EInsertTypes.DRIVER_PASPORT_CODE]: 'Паспорт: код подразделения',
    [EInsertTypes.DRIVER_LICENSE_FULL]: 'Водительское удостоверение',
    // [EInsertTypes.DRIVER_LICENSE_NUMBER]: 'Водительское удостоверение: номер',
    // [EInsertTypes.DRIVER_LICENSE_DATE]: 'Водительское удостоверение: дата выдачи',
    [EInsertTypes.DRIVER_PHONE_NUMBER]: 'Номер телефона',
};

const pickSchemas = {
    [EPickTypes.REQ]: schemaREQ,
    [EPickTypes.DRIVER]: schemaDRIVER,
};

const InsertDataModal = () => {
    const axiosPrivate = useAxiosPrivate();

    const currentUser = useAppSelector(selectCurrentUser);

    const fillFields = useAppSelector(selectDocFillFields);
    const pickType = useAppSelector(selectPickType);
    const schema = pickSchemas[pickType];
    const [listDataSets, setListDataSets] = useState([]);
    const [currentDataSetID, setCurrentDataSetID] = useState(1);

    const { setDataSets } = useActions();
    const [isLoaded, setIsLoaded] = useState(false);
    const init = useCallback(async () => {
        setIsLoaded(false);
        const loaded = await getDataSets(axiosPrivate, currentUser?.id, 1, 100);
        setDataSets(loaded.data.map((ds) => ({ ...ds, data: JSON.parse(ds.data) })));
        setIsLoaded(true);
    }, []);
    useEffect(() => {
        init();
    }, []);

    const newDataSet = async () => {
        const defaultSet = {};
        Object.keys(schema).forEach((schemaKey) => {
            defaultSet[schemaKey] = '';
        });

        const loaded = await saveDataSet(axiosPrivate, currentUser?.id, { data: defaultSet, pickType });
        init();
        setCurrentDataSetID(loaded?.id);
    };

    const dataSets = useAppSelector(selectDataSets);
    useEffect(() => {
        const setup = async () => {
            Logger.log({ dataSets });
            const pickSets = Array.isArray(dataSets) ? dataSets?.filter((set) => set.pickType === pickType) : [];
            if (!pickSets.length) {
                newDataSet();
            } else {
                setListDataSets(pickSets);
                setCurrentDataSetID(pickSets[0].id);
            }
        };
        isLoaded && setup();
    }, [pickType, isLoaded]);

    useEffect(() => {
        Logger.log(`useEffect InsertDataModal`, { listDataSets });
    }, [listDataSets]);

    return (
        <div className="modal fade" id="req-picker" tabIndex={-1} aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close" data-bs-dismiss="modal">
                            <IoCloseOutline />
                        </button>
                        <h3>Выбрать данные для автозаполнения</h3>
                        <form
                            className="fs-12"
                            action=""
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                            }}
                        >
                            {/* DATASET BUTTONS */}
                            <div
                                style={{
                                    display: 'flex',
                                    gap: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <select
                                    name="example"
                                    defaultValue={listDataSets?.[0]?.id}
                                    value={currentDataSetID}
                                    onChange={(e) => setCurrentDataSetID(Number(e.target.value))}
                                >
                                    {listDataSets.map((r, idx) => (
                                        <option key={r?.id} value={r?.id}>
                                            {r?.data[Object.keys(r?.data)[0]] || idx}
                                        </option>
                                    ))}
                                </select>
                                <div className="dataset-buttons">
                                    <div className="mb-3 mb-sm-0">
                                        <button
                                            type="button"
                                            className="btn btn-1 w-100"
                                            onClick={() => {
                                                newDataSet();
                                            }}
                                        >
                                            Новый
                                        </button>
                                    </div>
                                    <div className="mb-3 mb-sm-0">
                                        <button
                                            type="button"
                                            className="btn btn-1 w-100"
                                            onClick={async () => {
                                                await saveDataSet(
                                                    axiosPrivate,
                                                    currentUser?.id,
                                                    listDataSets.find((dataSet) => dataSet?.id === currentDataSetID),
                                                );
                                                await init();
                                            }}
                                        >
                                            Сохранить
                                        </button>
                                    </div>
                                    <div className="mb-3 mb-sm-0">
                                        <button
                                            type="button"
                                            className="btn btn-1 w-100"
                                            onClick={async () => {
                                                await deleteDataSet(axiosPrivate, currentDataSetID);
                                                await init();
                                            }}
                                            disabled={listDataSets.length < 2}
                                        >
                                            Удалить
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {/* FIELDS */}
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    rowGap: '10px',
                                    marginTop: '10px',
                                    alignItems: 'center',
                                }}
                            >
                                {Object.keys(schema).map((key, pIDX) => {
                                    return (
                                        <div
                                            className="fields-groups-container"
                                            style={{ lineHeight: '30px', display: 'flex' }}
                                            key={pIDX}
                                        >
                                            <div style={{ width: '250px' }}> {schema[key]} </div>
                                            <input
                                                type={
                                                    key === EInsertTypes.DRIVER_LICENSE_DATE ||
                                                    key === EInsertTypes.DRIVER_PASSPORT_DATE
                                                        ? 'date'
                                                        : 'text'
                                                }
                                                value={
                                                    listDataSets.find((dataSet) => dataSet?.id === currentDataSetID)
                                                        ?.data?.[key]
                                                }
                                                className="form-control input-block"
                                                onInput={(e: any) => {
                                                    setListDataSets((prev) => {
                                                        const changedDataSet = deepCopy(
                                                            prev.find((dataSet) => dataSet?.id === currentDataSetID),
                                                        );
                                                        changedDataSet.data[key] = e.target.value;
                                                        return [
                                                            ...prev.filter(
                                                                (dataSet) => dataSet.id !== currentDataSetID,
                                                            ),
                                                            changedDataSet,
                                                        ];
                                                    });
                                                }}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                            {/* FILL BUTTONS */}
                            <div className="row row-cols-sm-2 mt-4">
                                <div className="mb-3 mb-sm-0">
                                    <button type="button" className="btn btn-1 w-100" data-bs-dismiss="modal">
                                        Отмена
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        className="btn btn-2 w-100"
                                        data-bs-dismiss="modal"
                                        onClick={() =>
                                            Object.keys(fillFields).forEach((key) => {
                                                setCellEditedState(
                                                    key,
                                                    listDataSets.find((dataSet) => dataSet?.id === currentDataSetID)
                                                        ?.data?.[fillFields[key]],
                                                );
                                            })
                                        }
                                    >
                                        Заполнить
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

const IconButton = ({ icon, title, format }) => {
    const axiosPrivate = useAxiosPrivate();

    const currentDeal = useAppSelector(selectCurrentDeal);
    const docModel = useAppSelector(selectDocModel);

    const [isLoaded, setIsLoaded] = useState(true);

    const isClosed = format === 'pdf' && [3, 4, 7].includes(docModel?.type);
    // || !docModel?.isClosed

    return (
        <button
            type="button"
            className="d-flex align-items-center"
            onClick={async () => {
                setIsLoaded(false);
                await downloadDocFile(axiosPrivate, currentDeal?.id, docModel?.type, format);
                setIsLoaded(true);
            }}
            disabled={isClosed}
        >
            {isLoaded ? (
                <IconContext.Provider value={{ className: `icon-15 ${!isClosed ? 'white' : 'gray'}` }}>
                    {icon}
                </IconContext.Provider>
            ) : (
                <div className="w-100 d-flex justify-content-center">
                    <Loader color="#545454" />
                </div>
            )}
            <span className="d-none d-md-blockms-1">{title}</span>
        </button>
    );
};

const ActionButton = ({ setIsShowModalToChat }: any) => {
    const axiosPrivate = useAxiosPrivate();

    //USER-DEAL
    const currentUser = useAppSelector(selectCurrentUser);
    const currentDeal = useAppSelector(selectCurrentDeal);

    //DOC
    const { setIsModifyed } = useActions();
    const docModel = useAppSelector(selectDocModel);
    const { lastChangesEditor, isLastDeclorator, docIsDeclareClosed } = useEditor();
    const docIsFullFilled = useAppSelector(selectDocIsFullFilled);
    const isModifyed = useAppSelector(selectDocIsModifyed);

    useEffect(() => {
        setIsModifyed(false);
    }, []);

    //CHANGE_BUTTON
    const actionChangeButton = async () => {
        if (isModifyed) {
            actionSaveDocModel({
                axiosPrivate,
                currentDeal,
                currentUser,
                docModel,
                docIsFullFilled,
                docIsDeclareClosed,
                isModifyed,
                docDeltaState: store.getState().document.edit.deltaState,
                setIsShowModalToChat,
                setIsModifyed,
            });
        }
    };
    const isDisableChangeButton = docModel?.isClosed || (!isModifyed && Boolean(lastChangesEditor.length));
    const labelChangeButton = docModel?.isClosed
        ? 'Документ закрыт'
        : isModifyed
        ? 'Отправить на согласование'
        : lastChangesEditor?.length
        ? 'Ожидайте согласования'
        : docIsDeclareClosed
        ? isLastDeclorator
            ? 'Ожидайте подтверждения закрытия'
            : 'Подтвердить закрытие документа'
        : 'Объявить документ закрытым';

    Logger.log('ACTION_BUTTON');

    return (
        <button
            type="button"
            data-bs-toggle={!isModifyed ? 'modal' : ''}
            data-bs-target={!isModifyed ? '#close-doc' : ''}
            className="btn btn-1 text-uppercase px-3 px-sm-4 shadow"
            onClick={actionChangeButton}
            disabled={isDisableChangeButton}
        >
            {labelChangeButton}
        </button>
    );
};

const exportButtons = [
    {
        title: 'Скачать',
        icon: <MdFileDownload />,
        format: 'docx',
    },
    {
        title: 'Печать',
        icon: <MdLocalPrintshop />,
        format: 'pdf',
    },
];
export default function OfficeDealDocumentEditorPage() {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const location = useLocation();

    //USER-DEAL
    const currentDeal = useAppSelector(selectCurrentDeal);
    const currentUserId = useAppSelector(selectUserId);

    //DOC
    const {
        setDocModel,
        setDocStructure,
        setDocStartedState,
        setDocEditedState,
        setDocDeltaState,
        setDocIsFullFilled,
    } = useActions();
    const docStructure = useAppSelector(selectDocStructure);
    const docModel = useAppSelector(selectDocModel);

    const setDoc = useCallback((docObject, loadedState) => {
        setDocModel(docObject);
        setDocStartedState(loadedState);
        setDocEditedState(loadedState);
        setDocDeltaState({});
        setDocIsFullFilled(true);
    }, []);

    //INIT
    useEffect(() => {
        const load = async (isFirst = true) => {
            //GET DOC DATA
            const docObject =
                location?.state?.doc || (await getDocModel(axiosPrivate, currentDeal?.id, location?.state?.docType));
            const loadedState = JSON.parse(docObject?.state || '{}');

            //STATE
            setDoc(docObject, loadedState);

            ////STRUCTURE
            //DOWNLOAD
            // if (isFirst) {
            const loadedDocStructure = await getDocStructure(axiosPrivate, docObject?.type);
            setDocStructure(loadedDocStructure);
            // }
            //dynamic from ts
            // const docs = [
            //     doc0,
            //     doc1,
            //     doc2,
            //     doc3,
            //     doc4,
            //     doc5,
            //     doc6,
            //     doc7,
            // ];

            //PROCESSING CONDITIONS

            //SET
            // setDocStructure(docs[docObject?.type]);
        };
        load();
        Logger.log('useEffect main');
    }, []);

    //MODAL TO CHAT
    const dispatch = useAppDispatch();
    const setIsShowModalToChat = (is, docModel) => {
        if (is) {
            dispatch(setAlert('success', 'Изменения отправлены'));
            docModel && setDocModel(docModel);
            // load(false);
        }
    };
    // const [isShowModalToChat, setIsShowModalToChat] = useState(false);
    // useEffect(() => {
    //     if (isShowModalToChat) {
    //         setTimeout(() => {
    //             // setIsShowModalToChat(false);
    //         }, 1000);
    //         setTimeout(() => {
    //             // setIsShowModalToChat(false);
    //             // navigate(getURL(appRoutes.OFFICE_MESSAGER), {
    //             //     state: { dealId: currentDeal?.id },
    //             // });
    //         }, 1500);
    //     }

    //     Logger.log('useEffect modalToChat');
    // }, [currentDeal?.id, isShowModalToChat]);

    Logger.log('DOC_EDITOR');

    //REAL-TIME UPDATE
    const { setAppEvents } = useActions();
    const newAppEvents = useAppSelector(selectAppEventsList);
    useEffect(() => {
        (async () => {
            if (newAppEvents.length) {
                const currentFeedId = currentDeal.feedId;
                const currentEvents = newAppEvents.filter(
                    (event) =>
                        event.feedId === currentFeedId &&
                        event.founderId !== currentUserId &&
                        event.content.docType === docModel.type,
                );
                if (currentEvents.length) {
                    setDocModel({ ...docModel, isClosed: true });

                    //Установка изменений от партнера
                    currentEvents.forEach((event) => {
                        if (event.content.changes) {
                            console.log('CHANGE!');
                            Object.keys(event.content.changes).forEach((changeCellVector) => {
                                setCellEditedState(changeCellVector, event.content.changes[changeCellVector], false);
                            });
                        }
                    });

                    const docObject = await getDocModel(axiosPrivate, currentDeal?.id, docModel.type);
                    const loadedState = JSON.parse(docObject?.state || '{}');

                    // // const changesVectorList = JSON.parse(
                    // //     docObject.lastChangesCustomer.length > 2
                    // //         ? docObject.lastChangesCustomer
                    // //         : docObject.lastChangesContractor,
                    // // );
                    // // changesVectorList.forEach((changeCellVector) => {
                    // //     setCellEditedState(changeCellVector, getCell(loadedState, changeCellVector), false);
                    // // });

                    //Установка нового стартового состояния
                    setDocStartedState(loadedState);
                    setDocModel(docObject);
                }
                Logger.log({ newAppEvents, currentFeedId, currentEvents });
                setAppEvents([]);
            }
        })();
    }, [newAppEvents]);

    return (
        <>
            <main className="py-4 py-md-5 container">
                {/* HEADER */}
                <div className="d-lg-flex justify-content-between align-items-center mb-3">
                    {/* PREV PAGE */}
                    <Link
                        to={getURL(appRoutes.OFFICE, appRoutes.DEAL_DOCS)}
                        className="d-block mb-3 mb-lg-0 fs-12 fw-5 title-font"
                    >
                        <span className="green fs-15 me-2">⟵</span> Назад к документам
                    </Link>
                    {/* TOOLS PANEL */}
                    <div
                        className="d-flex align-items-center fs-11"
                        style={{ columnGap: '1em', justifyContent: 'space-around' }}
                    >
                        {exportButtons.map((b, bIDX) => {
                            return <IconButton key={bIDX} title={b.title} icon={b.icon} format={b.format} />;
                        })}
                        <button
                            type="button"
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                navigate(getURL(appRoutes.OFFICE_MESSAGER), {
                                    state: { dealId: currentDeal?.id },
                                })
                            }
                        >
                            <IconContext.Provider value={{ className: 'icon-20 white' }}>
                                <MdChat />
                            </IconContext.Provider>
                        </button>
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#help-edit"
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <IconContext.Provider value={{ className: 'icon-20 white' }}>
                                <MdHelp />
                            </IconContext.Provider>
                        </button>
                        <ActionButton setIsShowModalToChat={setIsShowModalToChat} />
                    </div>
                </div>
                {/* CONTENT */}
                <div className="row justify-content-center">
                    <div className="col-xxl-10 ">
                        {/* VIEWPORT */}
                        <div
                            className="box p-xl-5 shadow"
                            style={{ display: 'flex', flexDirection: 'column', rowGap: '2em' }}
                        >
                            {docStructure.length ? (
                                <DealDocumentRender nodes={docStructure} />
                            ) : (
                                <div className="w-100 d-flex justify-content-center">
                                    <Loader color="#545454" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* FOOTER */}
                <div className="d-lg-flex justify-content-between align-items-center mt-3">
                    {/* PREV PAGE */}
                    <Link
                        to={getURL(appRoutes.OFFICE, appRoutes.DEAL_DOCS)}
                        className="d-block mb-3 mb-lg-0 fs-12 fw-5 title-font"
                    >
                        <span className="green fs-15 me-2">⟵</span> Назад к документам
                    </Link>
                    {/* TOOLS PANEL */}
                    <div
                        className="d-flex align-items-center fs-11"
                        style={{ columnGap: '1em', justifyContent: 'space-around' }}
                    >
                        {exportButtons.map((b, bIDX) => {
                            return <IconButton key={bIDX} title={b.title} icon={b.icon} format={b.format} />;
                        })}
                        <button
                            type="button"
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                navigate(getURL(appRoutes.OFFICE_MESSAGER), {
                                    state: { dealId: currentDeal?.id },
                                })
                            }
                        >
                            <IconContext.Provider value={{ className: 'icon-20 white' }}>
                                <MdChat />
                            </IconContext.Provider>
                        </button>
                        <button
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#help-edit"
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                        >
                            <IconContext.Provider value={{ className: 'icon-20 white' }}>
                                <MdHelp />
                            </IconContext.Provider>
                        </button>
                        <ActionButton setIsShowModalToChat={setIsShowModalToChat} />
                    </div>
                </div>
            </main>
            {/* MODALS */}
            <InsertDataModal />
            <HelpModal />
            <CloseDocModal setIsShowModalToChat={setIsShowModalToChat} />
            {/* <CustomModal
                className="modal__inUserProfile"
                isShow={isShowModalToChat}
                setIsShow={setIsShowModalToChat}
                closeButton={false}
                size={'sm'}
                scrollable={true}
            >
                <div className="text-center">
                    <span className="fs-12">Изменения отправлены</span>
                    <span className="fs-12">Изменения отправлены переход в чат сделки</span>
                </div>
            </CustomModal> */}
        </>
    );
}
