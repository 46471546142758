import CustomModal from "@shared/ui/CustomModal";
import { Alert } from 'react-bootstrap';

export const RouteTemplateSaveModal = ({
    showModalSave,
    setShowModalSave,
    alertForSavePattern,
    isShowAlert,
    setDataTemplate,
    resetFieldVal,
    saveTemplate,
    valid,
}) => {
    return (
        <CustomModal
            className="modal__savePattern"
            isShow={showModalSave}
            setIsShow={setShowModalSave}
            centered={false}
            size={'lg'}
            closeButton={true}
        >
            <div>
                {alertForSavePattern && (
                    <div className="d-flex justify-content-center">
                        <Alert show={isShowAlert} className="end-0 m-0 p-2" variant="success">
                            <span>Сохранено</span>
                        </Alert>
                    </div>
                )}
                {alertForSavePattern === false && (
                    <div className="d-flex justify-content-center">
                        <Alert show={isShowAlert} className="end-0 m-0 p-2" variant="danger">
                            <span>Ошибка</span>
                        </Alert>
                    </div>
                )}
                <h3>Сохранить шаблон маршрута</h3>
                <form className="fs-12">
                    <label className="mb-2">Название шаблона</label>
                    <input
                        type="text"
                        className="mb-3"
                        placeholder="Название"
                        style={{ borderColor: valid.isInValidNameTemplate && 'red' }}
                        onChange={(e) => {
                            setDataTemplate((prevState) => ({
                                ...prevState,
                                templateName: e.target.value,
                            }));
                            resetFieldVal(e, 'isInValidNameTemplate');
                        }}
                    />
                    {valid.isInValidNameTemplate && (
                        <span style={{ color: valid.isInValidNameTemplate && 'red' }}>Введите название шаблона</span>
                    )}
                    <label className="mb-2">Примечание</label>
                    <input
                        type="text"
                        className="mb-3"
                        placeholder="Примечание"
                        onChange={(e) =>
                            setDataTemplate((prevState) => ({
                                ...prevState,
                                templateNote: e.target.value,
                            }))
                        }
                    />
                    <div className="row row-cols-sm-2 mt-4">
                        <div className="mb-3 mb-sm-0">
                            <button type="button" className="btn btn-1 w-100" onClick={() => setShowModalSave(false)}>
                                Отмена
                            </button>
                        </div>
                        <div>
                            <button
                                type="button"
                                className="btn btn-2 w-100"
                                onClick={(e) => {
                                    saveTemplate();
                                }}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </CustomModal>
    );
};
