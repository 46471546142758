import { NavLink } from 'react-router-dom';

import { getURL } from '@shared/lib/getURL';


const AppNotFoundPage = () => {
    return (
        <div className="not-found">
            <h1 className="not-found__title">К сожалению, такой страницы не существует</h1>
            <NavLink className="not-found__link" to={getURL()}>
                Перейти на главную
            </NavLink>
        </div>
    );
};
export default AppNotFoundPage;
