import { getURL } from '@shared/lib/getURL';

const paths = {
    //UTILS
    PARAMS: {
        id: ':id',
        slug: ':slug',
        all: '*',
    },
    METHODS: {
        add: 'add',
        edit: 'edit',
    },

    //DOMAINS
    APP: {
        infoPay: 'information-pay',
        contacts: 'contacts',
        login: 'login',
        reg: 'registration',
        resetPwd: 'reset-password',
        resetPwd2: 'reset-password-2',
    },
    NEWS: {
        index: 'news',
    },
    FORUM: {
        index: 'forum',
        topic: 'topic',
        myTopics: 'my-topics',
    },
    MARKET: {
        index: 'market',
        profile: 'profile',
        search: 'search',
        car: 'car',
        cars: 'cars',
        cargo: 'cargo',
        cargos: 'cargos',
        route: 'route',
        routes: 'routes',
    },
    OFFICE: {
        index: 'office',
        messager: 'messager',
        deal: 'deal',
        deals: 'deals',
        document: 'document',
        documents: 'documents',
        editor: 'editor',
        tariffs: 'tariffs',
        templates: 'templates',
    },
};

export const appRoutes = {
    ROOT: '/',

    //UTILS
    BY_ID: paths.PARAMS.id,
    ALL: paths.PARAMS.all,
    EDIT: paths.METHODS.edit,

    //APP (SHARED)
    INFOPAY: paths.APP.infoPay,
    CONTACTS: paths.APP.contacts,
    LOGIN: paths.APP.login,
    REG: paths.APP.reg,
    RESET_PWD: paths.APP.resetPwd,
    RESET_PWD2: paths.APP.resetPwd2,

    //NEWS
    NEWS_ALL: paths.NEWS.index,
    NEWS_BY_SLUG: getURL('', paths.NEWS.index, paths.PARAMS.slug),

    //FORUM
    FORUM: paths.FORUM.index,
    FORUM_TOPIC: getURL('', paths.FORUM.index, paths.FORUM.topic),
    FORUM_MY_TOPICS: paths.FORUM.myTopics,
    FORUM_TOPIC_BY_ID: getURL('', paths.FORUM.index, paths.FORUM.topic, paths.PARAMS.id),

    //MARKET
    SEARCH: getURL('', paths.MARKET.index, paths.MARKET.search),

    PROFILE: paths.MARKET.profile,
    MARKET_PROFILE: getURL('', paths.MARKET.index, paths.MARKET.profile),
    MARKET_PROFILE_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.profile, paths.PARAMS.id),
    PROFILE_EDIT: getURL('', paths.MARKET.profile, paths.METHODS.edit),

    CARS: paths.MARKET.cars,
    MARKET_CAR_ADD: getURL('', paths.MARKET.index, paths.MARKET.car, paths.METHODS.add),
    MARKET_CAR_EDIT_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.car, paths.METHODS.edit, paths.PARAMS.id),
    MARKET_CAR_EDIT: getURL('', paths.MARKET.index, paths.MARKET.car, paths.METHODS.edit),

    CARGOS: paths.MARKET.cargos,
    MARKET_CARGO: getURL('', paths.MARKET.index, paths.MARKET.cargo),
    MARKET_CARGO_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.cargo, paths.PARAMS.id),
    MARKET_CARGO_ADD: getURL('', paths.MARKET.index, paths.MARKET.cargo, paths.METHODS.add),
    MARKET_CARGO_EDIT_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.cargo, paths.METHODS.edit, paths.PARAMS.id),
    MARKET_CARGO_EDIT: getURL('', paths.MARKET.index, paths.MARKET.cargo, paths.METHODS.edit),

    ROUTES: paths.MARKET.routes,
    MARKET_ROUTE: getURL('', paths.MARKET.index, paths.MARKET.route),
    MARKET_ROUTE_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.route, paths.PARAMS.id),
    MARKET_ROUTE_ADD: getURL('', paths.MARKET.index, paths.MARKET.route, paths.METHODS.add),
    MARKET_ROUTE_EDIT_BY_ID: getURL('', paths.MARKET.index, paths.MARKET.route, paths.METHODS.edit, paths.PARAMS.id),
    MARKET_ROUTE_EDIT: getURL('', paths.MARKET.index, paths.MARKET.route, paths.METHODS.edit),

    //OFFICE
    OFFICE: paths.OFFICE.index,
    OFFICE_ALL: getURL('', paths.OFFICE.index, paths.PARAMS.all),

    OFFICE_PROFILE: getURL('', paths.OFFICE.index, paths.MARKET.profile),

    OFFICE_CARS: getURL('', paths.OFFICE.index, paths.MARKET.cars),
    OFFICE_CARGOS: getURL('', paths.OFFICE.index, paths.MARKET.cargos),
    OFFICE_ROUTES: getURL('', paths.OFFICE.index, paths.MARKET.routes),

    MESSAGER: getURL('', paths.OFFICE.messager),
    OFFICE_MESSAGER: getURL('', paths.OFFICE.index, paths.OFFICE.messager),

    DEALS: paths.OFFICE.deals,
    DEAL_DOCS: getURL('', paths.OFFICE.deal, paths.OFFICE.documents),
    ROOT_OFFICE_DEAL_DOCS: getURL(paths.OFFICE.index, paths.OFFICE.deal, paths.OFFICE.documents),

    DOCS: paths.OFFICE.documents,
    OFFICE_DEAL_DOC_EDITOR: getURL(
        '',
        paths.OFFICE.index,
        paths.OFFICE.deal,
        paths.OFFICE.document,
        paths.OFFICE.editor,
    ),

    TEMPLATES: paths.OFFICE.templates,
    TARIFFS: paths.OFFICE.tariffs,
};
