import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { setAlert } from '@entities/.app/alert/alert.actions';
import { buyTariff, getTariffs } from '@entities/.app/account/account.api';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import CustomModal from '@shared/ui/CustomModal';
import Loader from '@shared/ui/Loader';

const titles = ['14 дней', '1 месяц', '3 месяца', 'пол года', 'год'];

const TariffsTiles = ({ setIsShowModal, setTariffType }) => {
    const [tariffs, setTariffs] = useState<any>({
        isLoaded: false,
        error: null,
        items: [],
    });

    useEffect(() => {
        getTariffs()
            .then((res) => setTariffs({ isLoaded: true, items: res }))
            .catch((error) => setTariffs({ isLoaded: true, error }));
    }, []);

    return tariffs.isLoaded ? (
        tariffs?.items?.length ? (
            tariffs.items.map((item, index) => (
                <div key={index}>
                    <div
                        className="tariff"
                        onClick={() => {
                            setIsShowModal(true);
                            setTariffType(index);
                        }}
                    >
                        <div className="title-font fw-7 mb-2">{index < titles?.length ? titles[index] : null}</div>
                        <div>
                            <span className="fw-5 title-font">{item?.price}</span>&nbsp;₽
                        </div>
                    </div>
                </div>
            ))
        ) : null
    ) : (
        <div className="d-flex justify-content-center">
            <Loader color="#545454" />
        </div>
    );
};

const AccessToService = ({ isShowModal, setTariffType }) => {
    const currentUser = useAppSelector(selectCurrentUser);
    const [tariffExpiredAt, setTariffExpiredAt] = useState(null);

    useEffect(() => {
        !isShowModal && setTariffType(null);
    }, [isShowModal]);

    useEffect(() => {
        //@ts-ignore
        if (currentUser && currentUser?.tariffExpiredAt) {
            //@ts-ignore
            const date = new Date(currentUser?.tariffExpiredAt);
            const options = {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            };
            //@ts-ignore
            date && setTariffExpiredAt(date.toLocaleDateString('ru', options));
        }
    }, [currentUser]);

    return tariffExpiredAt ? (
        <>
            Доступ к сервису закончится:&nbsp;
            <span>{tariffExpiredAt}</span>
        </>
    ) : (
        <>{'Тариф не оплачен'}</>
    );
};

const BuyModal = ({ isShowModal, setIsShowModal, tariffType }) => {
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector(selectCurrentUser);

    const onBuyTariff = (userId, tariffType) => {
        buyTariff(userId, tariffType)
            .then(async (res) => {
                if (res) {
                    await window.open(res, '_blank');
                    setIsShowModal(false);
                } else {
                    //@ts-ignore
                    dispatch(setAlert('danger', 'Что-то пошло не так, повторите попытку позже'));
                    setIsShowModal(false);
                }
            })
            .catch((error) => {
                //@ts-ignore
                dispatch(setAlert('danger', 'Что-то пошло не так, повторите попытку позже'));
                setIsShowModal(false);
            });
    };

    return (
        <CustomModal
            isShow={isShowModal}
            setIsShow={setIsShowModal}
            titleHead="Перейти к оплате?"
            titleBody="Если у вас уже оплачен доступ, он увеличиться на выбранный вами срок."
            classNameHeader="tariff-modal-header"
            closeButton
            size="lg"
        >
            <div className="tariff-modal-body">
                <button className="btn btn-1" type="button" onClick={() => onBuyTariff(currentUser?.id, tariffType)}>
                    Да
                </button>
                <button className="btn btn-2" type="button" onClick={() => setIsShowModal(false)}>
                    Нет
                </button>
            </div>
        </CustomModal>
    );
};

export default function OfficeTariffsPage() {
    const [isShowModal, setIsShowModal] = useState(false);

    const [tariffType, setTariffType] = useState(null);

    return (
        <div className="box px-0 p-sm-4 p-xl-5">
            <Link to={appRoutes.ROOT + appRoutes.OFFICE} className="fs-12 fw-5 d-block d-lg-none mb-3 mb-sm-5">
                <span className="green fs-15 me-2">⟵</span> Назад
            </Link>

            <h1 className="dark-blue text-center d-lg-none">Тарифы</h1>
            <div className="fs-12 fw-5 black mb-3">После выбора тарифа Вы получаете:</div>
            <ul className="fw-5">
                <li className="mb-1">возможность публиковать грузы</li>
                <li className="mb-1">возможность откликаться на объявления</li>
                <li>автоматическое формирование документов (договора, счёт, акт)</li>
            </ul>
            <h4 className="mt-5 black mb-4">Получить доступ к платформе на:</h4>
            <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-xxl-5 g-3 g-xl-4 tariffs">
                <TariffsTiles setTariffType={setTariffType} setIsShowModal={setIsShowModal} />
            </div>
            <div className="fs-12 fw-5 black mt-5 tariff__date">
                <AccessToService isShowModal={isShowModal} setTariffType={setTariffType} />
            </div>
            <BuyModal isShowModal={isShowModal} setIsShowModal={setIsShowModal} tariffType={tariffType} />
        </div>
    );
}
