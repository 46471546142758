import { createSlice } from '@reduxjs/toolkit';

import { IAccount } from '@entities/.app/account/account.types';

const initialState: IAccount = {
    token: '',
    user: {
        roleId: null,
        id: null,
        avatar: null,
        fullName: null,
        taxIdentificationNumber: null,
        roleForUser: null,
        subject: null,
        companyName: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        city: null,
    },
};

export const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        resetCurrentUser: (state, action) => {
            state.token = initialState.token;
            state.user = initialState.user;
        },
    },
});

export const accountActions = accountSlice.actions;
export const accountReducer = accountSlice.reducer;

export const { setCurrentUser, resetCurrentUser } = accountSlice.actions;
export default accountSlice.reducer;
