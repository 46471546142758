import { Link } from 'react-router-dom';

export default function ArticleCard({ title, img, date = null, text, url }) {
    return date ? (
        <article className="mini" style={{ border: '5px solid #FFF', borderRadius: 8, background: '#FFF', padding: 5 }}>
            <figure>
                <img src={img} alt={title} style={{ borderRadius: 8 }} />
                {/*  need suggestion hover effect*/}
                <figcaption className="article__title">{title}</figcaption>
            </figure>
            {/* pass value to dateTime attribute if needed */}
            <time className="gray-3 fs-11 d-block mb-2 mb-xl-3" dateTime="2021-12-13 19:00">
                {`${new Date(date).toLocaleDateString()}`}
            </time>
            <div className="text">{text}</div>
            <Link to={url} className="stretched-link fs-12 blue d-block mt-1 mt-xl-2">
                Читать далее...
            </Link>
        </article>
    ) : (
        <article>
            <figure style={{ borderRadius: 8 }}>
                <img src={img} alt={title} style={{ border: '5px solid #FFF', borderRadius: 8 }} />
                <figcaption>
                    <Link to={url} className="title stretched-link">
                        {title}
                    </Link>
                    <div className="hidden-div">
                        <div className="text">{text}</div>
                    </div>
                </figcaption>
            </figure>
        </article>
    );
}
