import { IAppState } from '@core/store/types';
import { getCell } from './dealDocument.actions';
import store from '@core/store';

//MODEL
export const selectDocModel = (state: IAppState) => state.document.model;
export const selectDocIsClosed = (state: IAppState) => state.document.model.isClosed;

//STRUCTURE
export const selectDocStructure = (state: IAppState) => state.document.structure;

//EDIT
export const selectDocStartedState = (state: IAppState) => state.document.edit.startedState;
export const selectDocEditedState = (state: IAppState) => state.document.edit.editedState;
export const selectDocLinkedState = (state: IAppState) => state.document.edit.linkedState;
export const selectDocDeltaState = (state: IAppState) => state.document.edit.deltaState;

export const selectStartedCell = (cellVector) => (state: IAppState) =>
    getCell(state.document.edit.startedState, cellVector);
export const selectEditedCell = (cellVector) => (state: IAppState) =>
    getCell(state.document.edit.editedState, cellVector);

export const manualSelectEditedCell = (cellVector) => getCell(store.getState().document.edit.editedState, cellVector);

export const selectDocFillFields = (state: IAppState) => state.document.dataSets.fillFields;
export const selectPickType = (state: IAppState) => state.document.dataSets.pickType;
export const selectDataSets = (state: IAppState) => state.document.dataSets.list;

export const selectDocIsFullFilled = (state: IAppState) => state.document.edit.isFullFilled;
export const selectDocIsModifyed = (state: IAppState) => state.document.edit.isModifyed;
