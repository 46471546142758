import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import { localStorageFields } from './account.data';
import { getLogout } from './account.api';
import { resetCurrentUser } from './account.slice';
import Logger from '@shared/lib/logger';
import { appRoutes } from '@core/navigation';

//UTILS
export const redirectInProfile = (userId, otherUserId) => {
    if (userId === otherUserId) {
        return getURL(appRoutes.OFFICE_PROFILE);
    } else {
        return getURL(appRoutes.MARKET_PROFILE, otherUserId);
    }
};

export const getPhotoPath = (path = '') => {
    const pathParts = apiRoutes.APP_API_URL.split('//')
    const appAPIUrl = pathParts[0] + '//' + pathParts[1].split('/')[0]
    const resPath = path?.length
        ? path.includes('http')
            ? path
            : getURL('',appAPIUrl, 'uploads', path)
        : '/img/users/no-photo.png';

     return resPath;
};

export const handleRemeberMe = (rememberMe) => {
    localStorage.setItem(localStorageFields.rememberMe, rememberMe);
};

export const logout = async (dispatch) => {
    try {
        await getLogout();
        localStorage.setItem(localStorageFields.rememberMe, null);
        dispatch(resetCurrentUser(null));
    } catch (error) {
        Logger.error(error);
    }
};
