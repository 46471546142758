import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import { IDocExportFormat } from './dealDocument.types';
import Logger from '@shared/lib/logger';

//Управление сделкой
export const getAllDocModelsFromDealId = async (axiosPrivate, dealId) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC, dealId));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const getDocModel = async (axiosPrivate, dealId, docType) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC), {
            doc: {
                dealId,
                type: docType,
            },
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

//EXIST
export const openDoc = async (axiosPrivate, dealId, docType) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC_EXIST_OPEN), {
            doc: {
                dealId,
                type: docType,
            },
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const closeDoc = async (axiosPrivate, dealId, docType) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC_EXIST_CLOSE), {
            doc: {
                dealId,
                type: docType,
            },
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const getDocStructure = async (axiosPrivate, typeDoc) => {
    try {
        const response = await axiosPrivate.get(
            getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC, 'structure', typeDoc),
        );
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const saveDocModel = async (axiosPrivate, dealId, userId, docType, editedState) => {
    try {
        const response = await axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC), {
            doc: {
                dealId,
                type: docType,
                state: JSON.stringify(editedState),
            },
            userId,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const getDocFile = async (axiosPrivate, dealId: number, docType: number, format: IDocExportFormat) => {
    try {
        Logger.log(`getDocFile`);
        const response = await axiosPrivate.post(
            getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC, format),
            {
                doc: {
                    dealId,
                    type: docType,
                },
            },
            {
                responseType: 'blob',
            },
        );
        Logger.log({ response });
        return response.data;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

export const saveDataSet = async (axiosPrivate, userId, dataSet) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC_DATASET), {
            id: dataSet.id,
            pickType: dataSet.pickType,
            data: JSON.stringify(dataSet.data),
            userId,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};
export const getDataSets = async (axiosPrivate, userId, page, limit, pickType = null) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC_DATASET, userId), {
            page,
            limit,
            orderBy: 'desc',
            pickType,
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const deleteDataSet = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.DEAL_DOC_DATASET, id));
        return response.data;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};
