import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoAddCircleSharp, IoShieldCheckmarkSharp } from 'react-icons/io5';
import { useAppDispatch } from '@core/store/hooks';
import { Link, useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { setAlert } from '@entities/.app/alert/alert.actions';
import { selectUserId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { deleteCar, getCars } from '@entities/market/car/car.api';
import usePagination from '@shared/hooks/usePagination';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';

const CarCard = (props) => {
    const navigate = useNavigate();

    return (
        <div
            className={'card-mini ' + props.className}
            style={props.isDark ? { background: '#545454', color: 'white' } : {}}
        >
            <div>
                <div className="title mb-2 mb-sm-3">{props.route}</div>
                {props.name && (
                    <div className="fs-12 mt-1 mt-sm-2">
                        <span className="fw-7 me-2">Марка машины: {props.name}</span>
                        <span className="green">{props.verified && <IoShieldCheckmarkSharp />}</span>
                    </div>
                )}
                <div className="fs-11 mt-1 mt-sm-2">
                    <span className="fw-5">Тип машины: {props.carTypeForUser}</span>
                </div>
            </div>
            <div className="car-card__actions">
                <button
                    type="button"
                    className="btn btn-1 w-100"
                    onClick={() => navigate(getURL(appRoutes.MARKET_CAR_EDIT, props.id))}
                >
                    Редактировать
                </button>
                <button
                    type="button"
                    className="btn btn-2 w-100"
                    onClick={() => props.callback && props.callback(props.id)}
                >
                    Удалить
                </button>
            </div>
        </div>
    );
};

const DeleteModal = ({ isShowCardModal, setIsShowCardModal, onDelete }) => {
    return (
        <CustomModal
            isShow={isShowCardModal}
            setIsShow={setIsShowCardModal}
            closeButton={true}
            centered={true}
            size={'lg'}
        >
            <div className="dark-blue fs-12 fw-7 title-font text-center">
                Вы действительно хотите удалить автомобиль?
            </div>
            <div className="row row-cols-sm-2 gx-2 gx-lg-4 mt-4 fs-12">
                <div>
                    <button
                        type="button"
                        className="btn btn-1 w-100 px-4 mb-3 mb-sm-0"
                        onClick={() => {
                            onDelete();
                            setIsShowCardModal(false);
                        }}
                    >
                        Удалить
                    </button>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-2 w-100 px-4 point"
                        onClick={() => setIsShowCardModal(false)}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};
const initialPageLimit = 9;

export default function OfficeCarsPage() {
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useAppDispatch();

    const currentUserId = useAppSelector(selectUserId);

    const carsPagination = usePagination(initialPageLimit);

    const [cars, setCars] = useState({
        isLoading: false,
        error: null,
        meta: null,
        items: [],
    });
    const [carId, setCarId] = useState(null);

    const getCarsRequest = (page = undefined, limit = undefined) => {
        getCars(axiosPrivate, currentUserId, page, limit)
            .then((result) =>
                setCars((prev) => ({ ...prev, isLoading: true, meta: result?.meta, items: result?.data })),
            )
            .catch((error) => setCars((prev) => ({ ...prev, isLoading: true, error })));
    };

    const onDelete = () => {
        deleteCar(axiosPrivate, carId)
            .then(() => dispatch(setAlert('success', 'Машина удалена')))
            .catch(() => dispatch(setAlert('danger', 'Не удалось удалить машину')));
        getCarsRequest();
    };

    useEffect(() => {
        getCarsRequest(carsPagination.currentPage, carsPagination.pageLimit);
    }, [carsPagination.currentPage, carsPagination.pageLimit]);

    const [isShowCardModal, setIsShowCardModal] = useState(false);
    useEffect(() => {
        !isShowCardModal && setCarId(null);
    }, [isShowCardModal]);

    return (
        <div className="box px-0 p-sm-4 p-xl-5">
            <Link to={getURL(appRoutes.OFFICE)} className="fs-12 fw-5 d-block d-lg-none mb-3 mb-sm-5">
                <span className="green fs-15 me-2">⟵</span> Назад
            </Link>
            <h1 className="dark-blue text-center d-lg-none">Мои машины</h1>
            <div className="d-md-flex flex-row-reverse justify-content-between align-items-center mb-4 mb-xl-5">
                <Link to={getURL(appRoutes.MARKET_CAR_ADD)} className="btn btn-2 fs-12 px-4 mb-4 mb-md-0">
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 white',
                        }}
                    >
                        <IoAddCircleSharp />
                    </IconContext.Provider>
                    <span className="ms-2">Добавить машину</span>
                </Link>
                <div className="d-flex align-items-center fs-12 fw-5 title-font">
                    <button type="button" className="active tab-btn">
                        {`Мои машины (${cars?.meta?.total || 0})`}
                    </button>
                </div>
            </div>
            <div className="row row-cols-2 row-cols-xxl-3 g-1 g-sm-3 g-md-4">
                {cars.isLoading ? (
                    cars?.items?.length ? (
                        cars.items.map((item) => (
                            <div key={item.id}>
                                <CarCard
                                    isDark={true}
                                    id={item.id}
                                    name={item.name}
                                    carTypeForUser={item?.bodyType?.name}
                                    profileView={true}
                                    callback={(id) => {
                                        setIsShowCardModal(true);
                                        setCarId(id);
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <h6 className="text-center w-100 p-5">У вас пока не машин</h6>
                    )
                ) : (
                    <div className="w-100 d-flex justify-content-center">
                        <Loader color="#545454" />
                    </div>
                )}
            </div>
            <div className="mt-4">
                {cars?.items?.length > 0 && (
                    <Pagination
                        pageLimit={carsPagination.pageLimit}
                        currentPage={carsPagination.currentPage}
                        setCurrentPage={carsPagination.setCurrentPage}
                        pagesDisplayedLimit={3}
                        itemsAmount={cars?.meta?.total || 0}
                        startingPage={carsPagination.startingPage}
                        setStartingPage={carsPagination.setStartingPage}
                    />
                )}
            </div>

            <DeleteModal
                isShowCardModal={isShowCardModal}
                setIsShowCardModal={setIsShowCardModal}
                onDelete={onDelete}
            />
        </div>
    );
}
