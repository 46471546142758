import { useEffect, useState } from "react";

import { useAppSelector } from '@core/store/hooks';
import { defaultTownsOptions } from "@entities/.app/cities/cities.data";
import { selectCities } from '@entities/.app/cities/cities.selectors';
import Logger from "@shared/lib/logger";

export const useCities = () => {
    const [optionsTowns, setOptionsTowns] = useState(defaultTownsOptions);
    const citiesList = useAppSelector(selectCities);
    const [cities, setCities] = useState<any>([]);

    Logger.log(`useCities`,{cities});

    useEffect(() => {
        if (citiesList) {
            Logger.log({citiesList});
            const citiesOptions = citiesList.map((item, idx) => {
                return {
                    value: idx.toString(),
                    label: item,
                };
            });
            Logger.log({citiesOptions});
            setCities(citiesOptions);

            const newDefaultOptions = optionsTowns.map((item) => {
                const newTown = {
                    label: item.label,
                    value: citiesOptions?.find((i) => i.label === item.label)?.value,
                };
                return newTown;
            });
            setOptionsTowns(newDefaultOptions);
        }
        // setCities(defaultTownsOptions);
    }, [citiesList]);

    return [cities, optionsTowns]
}
