import axios from 'axios';

import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

//CARGO
export const createCargo = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ACTIONS), payloads);
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const getCargo = async (id, userId) => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ACTIONS, id, userId ?? ''));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const updateCargo = async (axiosPrivate, id, payloads) => {
    try {
        const response = await axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ACTIONS, id), payloads);
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const deleteCargo = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ACTIONS, id));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const unArchiveCargo = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_UNARCHIVE, id));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const reportCargo = async (axiosPrivate, payloads) => {
    try {
        return axiosPrivate.post(getURL(apiRoutes.REPORT_CARGO), payloads);
    } catch (error) {
        Logger.error(error);
    }
};

//CARGO::PARTS
export const getItemTypes = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ITEM_TYPES));
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const getPackageTypes = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_PACKAGE_TYPES));
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

//CARGOS
export const getCargoCount = async () => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_COUNT));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const searchCargo = async (page, limit, payloads = {}) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_SEARCH), {
            page,
            limit,
            ...payloads,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const paginateCargo = async (city, page, limit) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_PAGINATE, city), {
            page,
            limit,
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const getNotArchivedCargo = async (axiosPrivate, userId, page, limit) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_NOT_ARCHIVE, userId), {
            page,
            limit,
            orderBy: 'desc',
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const getArchivedCargo = async (axiosPrivate, userId, page, limit) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_ARCHIVE, userId), {
            page,
            limit,
            orderBy: 'desc',
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

//CARGO:TEMPLATE
export const deleteCargoTemplate = async (axiosPrivate, id, currentState, errorCallback) => {
    //TODO: Handle the failure case properly, display an error notification
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.CARGO_TEMPLATE_ACTIONS, id));
        if (response.data.status !== 200) {
            errorCallback(currentState);
        }
    } catch (error) {
        errorCallback(currentState);
        Logger.error(error);
    }
};

export const getCargoTemplates = async (axiosPrivate, userId, page, limit=6) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARGO_TEMPLATE, userId), {
            page,
            limit,
            orderBy: 'desc',
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
