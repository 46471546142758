
import Joi from 'joi';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { apiRoutes } from '@core/config/api';
import { appRoutes } from '@core/navigation';
import { handleRemeberMe } from '@entities/.app/account/account.actions';
import { getAxiosPrivate } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import FormErrorMessage from '@shared/ui/FormErrorMessage';
import InputPassword from '@shared/ui/InputPassword';

const formValueDefault = {
    password: '',
};

const formErrorDefault = {
    password: '',
};

const schema = Joi.object({
    password: Joi.string()
        .pattern(/.*[A-Z].*/)
        .pattern(/.*[0-9].*/)
        .min(8)
        .required()
        .messages({
            'string.empty': 'Пароль не может быть пустым',
            'string.pattern.base': 'Пароль должен содержать одну заглавную букву и одну цифру',
            'string.min': `Пароль не может быть короче 8 символов`,
            'string.max': `Пароль не может быть длиннее 20 символов`,
        }),
});

const ResetPassword2Form = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [formValue, setFormValue] = useState(formValueDefault);
    const [formErrors, setFormErrors] = useState(formErrorDefault);
    const [rememberMe, setRememberMe] = useState(false);

    useEffect(() => {
        if (!location.state) return navigate(getURL());

        const { email, verifyCode } = location?.state;
        if (email && verifyCode) {
            setFormValue((prev) => {
                return { ...prev, email, verifyCode };
            });
        }
    }, [location]);

    const handleFormChange = (e) => {
        setFormValue((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
        setFormErrors((prev) => {
            return { ...prev, [e.target.name]: '' };
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const result = schema.validate({ password: formValue.password }, { abortEarly: false });
        if (result.error) {
            handleFormErrors(result.error.details);
            return;
        }

        try {
            await (await getAxiosPrivate()).post(`${apiRoutes.FORGOT_PASSWORD}`, formValue);
            handleRemeberMe(rememberMe);

            return navigate(getURL());
        } catch (error) {}

        setFormErrors(formErrorDefault);
        setFormValue(formValueDefault);
    };

    const handleFormErrors = (errors) => {
        errors.forEach((formField) => {
            setFormErrors((prev) => {
                return { ...prev, [formField.path[0]]: formField.message };
            });
        });
    };

    return (
        <>
            <h1 className="text-center dark-blue mt-3 mt-sm-4 mt-lg-5">Восстановление пароля</h1>
            <form className="fs-12">
                <label className="mt-3">Новый пароль</label>
                <InputPassword
                    name="password"
                    className="mt-2"
                    value={formValue.password}
                    onChange={handleFormChange}
                />
                <FormErrorMessage>{formErrors.password}</FormErrorMessage>
                <label className="mt-3">
                    <input
                        type="checkbox"
                        name="remember"
                        className="me-2"
                        defaultChecked={rememberMe}
                        checked={rememberMe}
                        onChange={() => setRememberMe((prev) => !prev)}
                    />
                    <span className="blue">Запомнить меня</span>
                </label>
                <button type="submit" className="btn btn-2 fs-12 text-uppercase w-100 mt-4" onClick={handleFormSubmit}>
                    Восстановить пароль
                </button>
            </form>
        </>
    );
};

export default function AppResetPassword2Page() {
    return (
        <main className="bg-white position-relative">
            <section id="sec-10" className="container py-3 py-sm-4 py-lg-5">
                <Link to={getURL(appRoutes.RESET_PWD)} className="fs-12">
                    <span className="green fs-15 me-2">⟵</span> Назад
                </Link>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-5">
                        <ResetPassword2Form />
                    </div>
                </div>
            </section>
        </main>
    );
}
