import { createSlice } from '@reduxjs/toolkit';

import Logger from '@shared/lib/logger';
import { EPickTypes, IDocumentEntity } from './dealDocument.types';
import { deepCopy } from '@shared/lib/objects';
// import { getLVL } from './document.actions';

const setBoolean = (state, payload) => {
    if (payload !== state[0][state[1]]) {
        state[0][state[1]] = !state[0][state[1]];
    }
};

const initialState: IDocumentEntity = {
    model: {
        id: null,
        deal_id: null,
        type: null,
        isClosed: false,
        state: null,
        lastChangesCustomer: null,
        lastChangesContractor: null,
        createdAt: null,
        updatedAt: null,
    },
    structure: [],
    conditions:[],
    edit: {
        startedState: {},
        editedState: {},
        deltaState: {},
        linkedState: {
            2: {
                '1.2': ['qwerty'],
            },
        },
        isFullFilled: true,
        isModifyed: false,
    },
    dataSets: {
        list: [],
        fillFields: {},
        pickType: EPickTypes.REQ,
    },
};

//Change the logic in the future. Make API calls to store templates on the server
const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setDocModel: (state, action) => {
            state.model = action.payload;
        },
        setDocStructure: (state, action) => {
            state.structure = action.payload;
        },
        //EDIT
        setDocStartedState: (state, action) => {
            // let cell = state.edit.startedState;
            // Logger.log(`setDocStartedState`, action.payload);
            // if (Array.isArray(action.payload)) {
            //     const path = action.payload[0];
            //     Logger.log(`setCurrentCourseState cell`, cell);
            //     Logger.log(`setCurrentCourseState path`, path);
            //     if (path.length > 1) {
            //         path.slice(0, path.length - 1).forEach((field) => {
            //             !cell?.[field] && (cell[field] = {});
            //             cell = cell?.[field];
            //             Logger.log({ cell });
            //         });
            //         //@ts-ignore
            //         cell[path.at(-1)] = action.payload[1];
            //     } else {
            //         //@ts-ignore
            //         cell[path[0]] = action.payload[1];
            //     }
            // } else {
            //     state.edit.startedState = action.payload;
            // }
            state.edit.startedState = action.payload;
        },
        setDocEditedState: (state, action) => {
            let cellStartedState = deepCopy(state.edit.startedState);
            let cellEditedState = state.edit.editedState;
            let cellDeltaState = state.edit.deltaState;

            Logger.log(`setEditedState`, action.payload);
            if (Array.isArray(action.payload)) {
                const vectorString = action.payload[0];
                const value = action.payload[1];
                const isCurrentUserChanges = action.payload[2];

                const rawVectorArray = vectorString.split('.');
                const vectorArray = rawVectorArray?.filter((e) => e);

                const isEmptyObj = (obj) => typeof obj === 'object' && obj !== null && Object.keys(obj).length === 0;
                const isObjAndNotNull = (obj) => typeof obj === 'object' && obj !== null;
                const isNullOrPrimitiveOrUndefined = (obj) => !isObjAndNotNull(obj);
                const deepCopy = (object) => JSON.parse(JSON.stringify(object) || null);

                const getLVL = (state, vector) => {
                    return Object.keys(state).find((e) => e === vector);
                };

                const updateState = (value, vectorString, isFinal = false) => {
                    // if (isNullOrPrimitiveOrUndefined(cellEditedState[vectorString]) && cellEditedState[vectorString] !== value) cellEditedState[vectorString] = value;  //Значение или {}
                    // if (!isFinal) cellEditedState = cellEditedState[vectorString]; //Углубление
                    //Присвоение
                    if (
                        isNullOrPrimitiveOrUndefined(cellEditedState[vectorString]) &&
                        cellEditedState[vectorString] !== value
                    ) {
                        cellEditedState[vectorString] = deepCopy(value);
                    }
                    if (isNullOrPrimitiveOrUndefined(cellStartedState[vectorString]) && isEmptyObj(value) && !isFinal) {
                        cellStartedState[vectorString] = deepCopy(value);
                    }

                    //Дельта: добавление и удаление
                    if (
                        isCurrentUserChanges &&
                        isFinal &&
                        cellStartedState[getLVL(cellStartedState, vectorString)] !==
                            cellEditedState[getLVL(cellEditedState, vectorString)] &&
                        cellDeltaState[getLVL(cellDeltaState, vectorString)] !==
                            cellEditedState[getLVL(cellEditedState, vectorString)] &&
                        // cellEditedState[getLVL(cellEditedState, vectorString)] !== '' &&
                        typeof value !== 'undefined' &&
                        isNullOrPrimitiveOrUndefined(value)
                    ) {
                        cellDeltaState[getLVL(cellEditedState, vectorString)] = value;
                        // setBoolean([state.edit, 'isModifyed'], Boolean(Object.keys(cellDeltaState).length));
                        setBoolean([state.edit, 'isModifyed'], true);
                    } else if (
                        isCurrentUserChanges &&
                        isFinal &&
                        (cellStartedState[getLVL(cellStartedState, vectorString)] ===
                            cellEditedState[getLVL(cellEditedState, vectorString)] ||
                            cellEditedState[getLVL(cellEditedState, vectorString)] === '')
                    ) {
                        delete cellDeltaState[getLVL(cellEditedState, vectorString)];
                        setBoolean([state.edit, 'isModifyed'], Boolean(Object.keys(cellDeltaState).length));
                    }

                    //Углубляемся для следующей итерации
                    if (!isFinal) {
                        cellEditedState = cellEditedState[getLVL(cellEditedState, vectorString)];
                        cellStartedState = cellStartedState[getLVL(cellStartedState, vectorString)];
                    }
                };

                if (rawVectorArray[0] === '') {
                    // cellEditedState[vectorString.slice(1)] = value;
                    updateState(value, vectorString.slice(1));
                } else {
                    (() => {
                        let minIDX = 0;
                        for (let idx = vectorArray.length; idx > minIDX; idx--) {
                            const cellOrder = vectorArray.slice(0, idx).join('.');
                            if (
                                isObjAndNotNull(cellEditedState) &&
                                Object.keys(cellEditedState)
                                    .map((e) => e.split('#')[0])
                                    .includes(cellOrder)
                            ) {
                                if (idx === vectorArray.length) {
                                    return updateState(value, cellOrder, true);
                                    // return (cellEditedState[cellOrder] = value); //Замена
                                } else {
                                    updateState({}, cellOrder);
                                    // if(isNullOrPrimitive(cellEditedState[cellOrder])) cellEditedState[cellOrder] = {}       //Порождение уровня
                                    // cellEditedState = cellEditedState[cellOrder];                                           //Углубление
                                    minIDX = idx; //Повышение предела по возвышению
                                    idx = vectorArray.length + 1; //сброс цикла
                                }
                                //Предел по понижению
                            } else if (idx === minIDX + 1) {
                                //Предел по возвышению
                                if (minIDX + 1 === vectorArray.length) {
                                    return updateState(value, cellOrder, true);
                                    // return (cellEditedState[cellOrder] = value); //Вставка
                                } else {
                                    updateState({}, cellOrder);
                                    // cellEditedState = cellEditedState[cellOrder] = {}                                       //Порождение уровня и Углубление
                                    minIDX = idx; //Повышение предела по возвышению
                                    idx = vectorArray.length + 1; //сброс цикла
                                }
                            }
                        }
                    })();
                }
            } else {
                state.edit.editedState = action.payload;
            }
        },
        setDocDeltaState: (state, action) => {
            state.edit.deltaState = action.payload;
        },
        setDocIsFullFilled: (state, action) => {
            state.edit.isFullFilled = action.payload;
        },
        reduceDocIsFullFilled: (state, action) => {
            // if ((state.edit.isFullFilled && action.payload) !== state.edit.isFullFilled) {
            //     state.edit.isFullFilled = !state.edit.isFullFilled;
            // }
            setBoolean([state.edit, 'isFullFilled'], state.edit.isFullFilled && action.payload);
        },
        // setDocIsModifyed: (state, action) => {
        //     setBoolean([state.edit, 'isModifyed'], action.payload);
        // },
        setIsModifyed: (state, action) => {
            state.edit.isModifyed = action.payload;
        },

        //CONDITIONS
        setConditions: (state, action) => {
            state.conditions = action.payload;
        },

        //DATASETS
        setFillFields: (state, action) => {
            state.dataSets.fillFields = action.payload;
        },
        setPickType: (state, action) => {
            state.dataSets.pickType = action.payload;
        },
        setDataSets: (state, action) => {
            state.dataSets.list = action.payload;
        },
    },
});

export const documentActions = documentSlice.actions;
export const documentReducer = documentSlice.reducer;
