import { useEffect, useState } from 'react';

import { useAppSelector } from '@core/store/hooks';
import { selectToken } from '@entities/.app/account/account.selectors';
import useRefreshToken from '@entities/.app/account/hooks/account.useRefreshToken';
import { getAxiosPrivate } from '@core/config/api';
import Logger from '@shared/lib/logger';

const useAxiosPrivate = () => {
    const refreshToken = useRefreshToken();
    const currentUserToken = useAppSelector(selectToken);

    const [axiosPrivate, setAxiosPrivate] = useState(() => getAxiosPrivate());

    // useEffect(() => {
    //     const reloadFinger = async () => {
    //         const axi = await;
    //         setAxiosPrivate(() => axi);
    //     };

    //     !axiosPrivate && reloadFinger();

    //     const requestInterceptor = axiosPrivate?.interceptors?.request?.use(
    //         (request) => {
    //             if (!request.headers['Authorization']) {
    //                 request.headers['Authorization'] = `Bearer ${currentUserToken}`;
    //             }
    //             return request;
    //         },
    //         (error) => {
    //             return Promise.reject(error);
    //         },
    //     );

    //     const responseInterceptor = axiosPrivate?.interceptors?.response?.use(
    //         (response) => response,
    //         async (error) => {
    //             const prevRequest = error?.config;
    //             if (error?.response?.status === 403 && !prevRequest.isSent) {
    //                 Logger.log('interceptor is working');
    //                 prevRequest.isSent = true;
    //                 const newAccessToken = await refreshToken();
    //                 prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
    //                 return axiosPrivate(prevRequest);
    //             }
    //             return Promise.reject(error);
    //         },
    //     );

    //     return () => {
    //         axiosPrivate?.interceptors?.request?.eject(requestInterceptor);
    //         axiosPrivate?.interceptors?.response?.eject(responseInterceptor);
    //     };
    // }, [currentUserToken, refreshToken, axiosPrivate]);

    return axiosPrivate;
};
export default useAxiosPrivate;
