import axios from 'axios';

import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

const getAllNews = async (page = 1, limit = undefined, orderBy = 'asc') => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.NEWS), { page, limit, orderBy });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

const getRandomNews = async (limit = 4) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.RANDOM_NEWS), { limit });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

const getOneNews = async (slug) => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.NEWS, slug));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export { getAllNews, getRandomNews, getOneNews };
