import { NavLink } from 'react-router-dom';

import {appRoutes} from '@core/navigation';
import { resetAlertAction, setAlertAction, showNoAuthAlertAction } from '@entities/.app/alert/alert.slice';
import { getURL } from '@shared/lib/getURL';

export const setAlert = (variant, message) => {
    return (dispatch) => {
        dispatch(setAlertAction({ variant, message, isShow: true }));
    };
};

export const showNoAuthAlert = () => {
    return (dispatch) => {
        dispatch(
            showNoAuthAlertAction({
                variant: 'danger',
                message: (
                    <span>
                        Эта функция доступна только для авторизованных пользователей.{' '}
                        <NavLink to={getURL(appRoutes.LOGIN)} className="green fw-bold">
                            Войдите
                        </NavLink>
                        , чтобы она была доступна для вас
                    </span>
                ),
                isShow: true,
            }),
        );
    };
};

export const resetAlert = () => {
    return (dispatch) => {
        dispatch(resetAlertAction());
    };
};
