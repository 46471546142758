import AsyncSelect from "react-select/async";

export const AdCarSelectForm = ({ setData, valid, resetFieldVal, loadOptions, cars, selectCar, setSelectCar }) => {
    return (
        <div className="box">
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <div
                        data-label="carName"
                        data-warning="false"
                        className="title-font fs-12 fw-5"
                        style={{ color: valid.isInValidCar && 'red' }}
                    >
                        Выбор машины*
                    </div>
                </div>
                <div className="col-md-9">
                    <AsyncSelect
                        className="fs-12 w-100"
                        classNamePrefix="react-select"
                        placeholder={'Выберите...'}
                        loadOptions={loadOptions}
                        defaultOptions={cars}
                        value={selectCar && cars?.find((item) => item.value === selectCar.value)}
                        onChange={(val, e) => {
                            setSelectCar({ value: val.value, label: val.label });
                            setData((prevState) => ({ ...prevState, carId: val.value }));
                            resetFieldVal(e, 'isInValidCar');
                        }}
                    />
                    {valid.isInValidCar && (
                        <span className="position-absolute" style={{ color: valid.isInValidCar && 'red' }}>
                            Выберете машину
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};
