import { useEffect, useState } from 'react';

import { useAppSelector } from '@core/store/hooks';
import { citiesLSKeys } from '@entities/.app/cities/cities.data';
import { selectCities } from '@entities/.app/cities/cities.selectors';
import useSelectedCity from '@entities/.app/cities/hooks/cities.useSelectedCity';
import CustomSelect from '@shared/ui/CustomSelect';
import SearchDropdown from '@shared/ui/SearchDropdown';

const CityPopup = ({ city, isDefinedCity, setIsShowCities }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (isDefinedCity) {
            handleShow();
        }
    }, [isDefinedCity]);

    const onApplyCity = () => {
        localStorage.setItem(citiesLSKeys.USER_CITY, city);
        handleClose();
    };

    const onChangeCity = () => {
        setIsShowCities(true);
        handleClose();
    };

    return (
        <div className={`city-popup ${show ? 'show' : ''}`}>
            <div className="city-popup__header">
                <h4 className="city-popup__title">
                    Ваш город <strong>{city}?</strong>
                </h4>
            </div>
            <div className="city-popup__footer">
                <button type="button" className="btn btn-1 btn-sm" onClick={onApplyCity}>
                    Всё верно
                </button>
                <button type="button" className="btn btn-2 btn-sm" onClick={onChangeCity}>
                    Сменить город
                </button>
            </div>
        </div>
    );
};

const CityContainer = () => {
    const cities = useAppSelector(selectCities);

    const { city, setCity, isDefinedCity } = useSelectedCity();
    const [isShowCities, setIsShowCities] = useState(false);

    const changeCity = (title) => {
        const localStorageUserCity = localStorage.getItem(citiesLSKeys.USER_CITY);

        if (title !== localStorageUserCity) {
            localStorage.setItem(citiesLSKeys.USER_CITY, title);
            setCity(title);
        } else {
            setCity(localStorageUserCity);
        }

        setIsShowCities(false);
    };

    return (
        <div className="city-container right-alignment select order-1 order-lg-2 ms-lg-5">
            <CustomSelect
                btnClass="color-2 text-uppercase"
                modificator="city"
                isShow={isShowCities}
                checkedOptions={[city]}
                options={cities}
                callback={({ title }) => changeCity(title)}
                child={SearchDropdown}
                placeholder="Введите свой город"
                align="right"
                initialCount="100"
            />
            <CityPopup city={city} isDefinedCity={isDefinedCity} setIsShowCities={setIsShowCities} />
        </div>
    );
};
export default CityContainer;
