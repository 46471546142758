import Backdrop from '@mui/material/Backdrop';
import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { resetAlert } from '@entities/.app/alert/alert.actions';
import { selectAlert } from '@entities/.app/alert/alert.selectors';

const useAlert = ({ delay }) => {
    const submitAlert = useAppSelector(selectAlert);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (submitAlert?.isShow) {
            const timeoutId = setTimeout(() => dispatch(resetAlert()), delay);

            return () => clearTimeout(timeoutId);
        }
    }, [delay, dispatch, submitAlert]);

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 342344 }}
            open={submitAlert?.isShow}
            onClick={() => {
                dispatch(resetAlert());
            }}
        >
            <Alert className="submit-alert" variant={submitAlert?.variant} show={submitAlert?.isShow}>
                {submitAlert?.message}
            </Alert>
        </Backdrop>
    );
};
export default useAlert;
