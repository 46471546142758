import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@core/store/hooks';
import { getPhotoPath, redirectInProfile } from '@entities/.app/account/account.actions';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';

function AdContactsWidget(props) {
    const [visible, setVisibility] = useState(false);
    const currentUser = useAppSelector(selectCurrentUser);

    return (
        <div className={'row g-0 user-contacts ' + props.className}>
            <div className="col-4 col-sm-5 col-md-12">
                {visible && <img src={getPhotoPath(props?.image)} alt={props.title} className="logo" />}
            </div>
            <div className="col-8 col-sm-7 col-md-12 ps-3 ps-md-0">
                <h4 className="text-left text-md-center mt-md-4 mb-2 mb-sm-3">
                    <NavLink to={redirectInProfile(currentUser?.id, props?.id)}>
                        {props?.subject ? props?.company : props.title}
                    </NavLink>
                </h4>
                {visible && <div className="text-center">{props?.phone ? props.phone : 'Тариф не оплачен'}</div>}
                {!visible && (
                    <button
                        type="button"
                        onClick={() => setVisibility(true)}
                        className="btn btn-2 fs-11 mt-4 mx-md-auto px-3"
                    >
                        Показать контакты
                    </button>
                )}
            </div>
        </div>
    );
}
export default AdContactsWidget;
