import { useAppSelector } from '@core/store/hooks';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { selectRoleId } from '@entities/.app/account/account.selectors';
import { getURL } from '@shared/lib/getURL';

export default function RoleFilter() {
    const currentUserRoleId = useAppSelector(selectRoleId);
    const location = useLocation();
    const { id } = useParams();

    const isPrivacy =
        Number.isFinite(currentUserRoleId) ||
        (currentUserRoleId === 2 &&
            [
                getURL(appRoutes.OFFICE_ROUTES),
                getURL(appRoutes.OFFICE_CARGOS),
                getURL(appRoutes.MARKET_CARGO_ADD),
                getURL(appRoutes.MARKET_CARGO_EDIT, id),
            ].includes(location.pathname)) ||
        (currentUserRoleId === 3 &&
            [
                getURL(appRoutes.OFFICE_ROUTES),
                getURL(appRoutes.OFFICE_CARS),
                getURL(appRoutes.MARKET_ROUTE_ADD),
                getURL(appRoutes.MARKET_ROUTE_EDIT, id),
            ].includes(location.pathname));

    return isPrivacy ? <Outlet /> : <Navigate to={appRoutes.ROOT} />;
}
