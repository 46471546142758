import { notVatPriceWithPrepayment, vatPrice } from "@entities/market/market.actions";
import { onInputHandler, onRadioHandler } from "@shared/lib/forms";

export const AdPaymentForm = ({
    data,
    setData,
    valid,
    resetFieldVal,
    btnRadioBargain,
    setBtnRadioBargain,
    btnRadioCalculate,
    setBtnRadioCalculate
}) => {
    return (
        <div className="box">
            <div data-label="bargain" data-warning="false" className="row row-cols-sm-2 row-cols-xxl-3 mb-3">
                <div className="mb-2 mb-sm-0">
                    <label>
                        <input
                            type="radio"
                            name="bargainType"
                            checked={btnRadioBargain === 0}
                            //@ts-ignore
                            onClick={(e) => setBtnRadioBargain(Number(e.target.value))}
                            value={0}
                            onChange={(e) => onRadioHandler(e, setData, true)}
                        />
                        <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Возможен торг</span>
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="bargainType"
                            checked={btnRadioBargain === 1}
                            //@ts-ignore
                            onClick={(e) => setBtnRadioBargain(Number(e.target.value))}
                            onChange={(e) => onRadioHandler(e, setData, true)}
                            value={1}
                        />
                        <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Без торга</span>
                    </label>
                </div>
            </div>
            <div data-label="paymentType" data-warning="false" className="row row-cols-sm-2 row-cols-xxl-3 mb-4">
                <div className="mb-2 mb-sm-0">
                    <label>
                        <input
                            type="radio"
                            checked={btnRadioCalculate === 0}
                            //@ts-ignore
                            onClick={(e) => setBtnRadioCalculate(Number(e.target.value))}
                            name="calculateType"
                            onChange={(e) => onRadioHandler(e, setData, true)}
                            value={0}
                        />
                        <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Наличный расчет</span>
                    </label>
                </div>
                <div>
                    <label>
                        <input
                            type="radio"
                            name="calculateType"
                            checked={btnRadioCalculate === 1}
                            //@ts-ignore
                            onClick={(e) => setBtnRadioCalculate(Number(e.target.value))}
                            onChange={(e) => onRadioHandler(e, setData, true)}
                            value={1}
                        />
                        <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Перевод по карте</span>
                    </label>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div data-label="priceNovat" data-warning="false" className="title-font fs-12 fw-5">
                        Цена без НДС
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-8 col-sm-5 col-xl-4">
                            <input
                                type="number"
                                min="1"
                                //@ts-ignore
                                value={data?.noVatPrice || ''}
                                name="noVatPrice"
                                placeholder="0"
                                onChange={(e) => onInputHandler(e, setData)}
                                className="price w-100 fs-12"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div className="title-font fs-12 fw-5">Цена с НДС</div>
                </div>
                <div className="col-sm-9">
                    <div className="row gx-2 gx-sm-4">
                        <div className="col-8 col-sm-5 col-xl-4">
                            <input
                                type="number"
                                disabled
                                //@ts-ignore
                                value={data?.vatPrice || ''}
                                name="vatPrice"
                                placeholder="0"
                                className="price w-100 fs-12"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div data-label="prepay" data-warning="false" className="title-font fs-12 fw-5">
                        Предоплата*
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-8 col-sm-5 col-xl-4">
                            <input
                                type="number"
                                min="0"
                                max="100"
                                //@ts-ignore
                                value={data?.prepayment || ''}
                                style={{ borderColor: valid.isInValidPrepayment && 'red' }}
                                name="prepayment"
                                placeholder="0"
                                onChange={(e) => {
                                    onInputHandler(e, setData);
                                    resetFieldVal(e, 'isInValidPrepayment');
                                }}
                                className="percent w-100 fs-12"
                            />
                            {valid.isInValidPrepayment && (
                                <span
                                    className="position-absolute"
                                    style={{ color: valid.isInValidPrepayment && 'red' }}
                                >
                                    Поле обязательно для заполнения
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div data-label="priceVat" data-warning="false" className="title-font fs-12 fw-5">
                        Предоплата без НДС
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row gx-2 gx-sm-4">
                        <div className="col-8 col-sm-5 col-xl-4">
                            <input
                                type="number"
                                disabled
                                value={notVatPriceWithPrepayment(data?.noVatPrice, data?.prepayment) || ''}
                                name="vatPrice"
                                placeholder="0"
                                className="price w-100 fs-12"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div data-label="priceVat" data-warning="false" className="title-font fs-12 fw-5">
                        Предоплата с НДС
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row gx-2 gx-sm-4">
                        <div className="col-8 col-sm-5 col-xl-4">
                            <input
                                type="number"
                                disabled
                                //@ts-ignore
                                value={notVatPriceWithPrepayment(vatPrice(data?.noVatPrice), data?.prepayment) || ''}
                                name="vatPrice"
                                placeholder="0"
                                className="price w-100 fs-12"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
