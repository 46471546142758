import useMatchMedia from './useMatchMedia';

export const useWidth = (width = 990) => {
    const { state: isWidth } = useMatchMedia({
        initialState: `(max-width: ${width}px)`,
        query: `(max-width: ${width}px)`,
        event: 'resize',
    });
    return isWidth;
};
