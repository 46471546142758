// options = {day: '2-digit',month: '2-digit',year: 'numeric',}
export const getDate = (date, options = {}) => new Date(date).toLocaleDateString([], options);
// options = { hour: "2-digit", minute: "2-digit" }
export const getTime = (date, options = {}) => new Date(date).toLocaleTimeString([], options);

export const getDateTimeUI = (date) => `${getDate(date)} | ${getTime(date)}`;

export const getDateUI = (initialDate, periodType = false) => (periodType ? periodType : getDate(initialDate));

export const getTimeUI = (initialTime, serverFormat = false) =>
    serverFormat
        ? getTime(initialTime, { hour: '2-digit', minute: '2-digit' })
        : initialTime.split(':').slice(0, 2).join(':');;
