import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { alertReducer } from '@entities/.app/alert/alert.slice';
import { citiesReducer } from '@entities/.app/cities/cities.slice';
import { appEventsReducer } from '@entities/.app/appEvents/appEvents.slice';
import { accountReducer } from '@entities/.app/account/account.slice';
import { dealReducer } from '@entities/office/deal/deal.slice';
import { documentReducer } from '@entities/office/dealDocument/dealDocument.slice';

const reducers = combineReducers({
    cities: citiesReducer,
    alert: alertReducer,
    appEvents:appEventsReducer,
    account: accountReducer,
    document: documentReducer,
    deal: dealReducer,
});

const store = configureStore({
    reducer: reducers,
});

export default store;
