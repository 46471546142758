import { useParams } from 'react-router-dom';

import CarPageContent from '@features/market/car/Car.PageContent';


export default function CarEditPage() {
    const { id } = useParams();

    return <CarPageContent carId={id} />;
}
