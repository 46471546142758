import { optionsLoadingDays, optionsLoadingPeriodType } from "@entities/market/market.data";
import { currentDate } from "@entities/market/route/route.actions";
import AsyncSelect from "react-select/async";

export const AdDateForm = ({
    data,
    setData,
    valid,
    resetFieldVal,
    onRadioHandler,
    btnRadioDate,
    setBtnRadioDate,
    getAndSetDate,
    loadOptions3,
    loadOptions2,
    selectDays,
    setSelectDays,
    selectPeriodType,
    setSelectPeriodType,
}) => {
    return (
        <div className="box">
            <div className="row">
                <div className="col-md-2 mb-3 mb-md-0">
                    <div
                        data-label="frequency"
                        data-warning="false"
                        className="title-font fs-12 fw-5"
                        style={{ color: valid.isInValidDateType && 'red' }}
                    >
                        Дата*
                    </div>
                </div>
                <div className="col-md-10">
                    <div className="row">
                        <div className="col-xl-7 mb-4 mb-lg-2 mb-xl-0">
                            <div className="box p-lg-3">
                                <label className="mb-2 mb-xl-3">
                                    <input
                                        type="radio"
                                        name="dateType"
                                        checked={btnRadioDate === 0}
                                        value={0}
                                        onChange={(e) => {
                                            onRadioHandler(e, setData, true);
                                            resetFieldVal(e, 'isInValidDateType');
                                        }}
                                        //@ts-ignore
                                        onClick={(e) => setBtnRadioDate(Number(e.target.value))}
                                    />
                                    <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Единожды</span>
                                </label>
                                <div
                                    className={`d-flex align-items-center
                                                            ${
                                                                //@ts-ignore
                                                                data.dateType !== 1 ? '' : 'disabled'
                                                            }`}
                                >
                                    <label data-label="date" data-warning="false" className="flex-1">
                                        <input
                                            type="date"
                                            name="date"
                                            min={currentDate()}
                                            //@ts-ignore
                                            defaultValue={data?.dateForInput}
                                            onChange={(e) => getAndSetDate(e.target.value)}
                                        />
                                    </label>
                                    <span className="mx-2 mx-xxl-3">+</span>
                                    <label style={{ maxWidth: '100px' }} data-label="dateDays" data-warning="false">
                                        <AsyncSelect
                                            className="fs-12 w-100"
                                            classNamePrefix="react-select"
                                            placeholder={'Выберите...'}
                                            loadOptions={loadOptions3}
                                            defaultOptions={optionsLoadingDays}
                                            value={
                                                selectDays &&
                                                optionsLoadingDays?.find((item) => item.value === selectDays.value)
                                            }
                                            onChange={(val) => {
                                                setSelectDays({
                                                    value: val.value,
                                                    label: val.label,
                                                });
                                                setData((prevState) => ({
                                                    ...prevState,
                                                    dateDays: val.value,
                                                }));
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>
                            <span className="fs-08">
                                *Нужно выбрать коректную дату. Внимание! Используя шаблон дата может устареть.
                            </span>
                        </div>
                        <div className="col-xl-5">
                            <div className="box p-lg-3">
                                <label className="mb-2 mb-xl-3">
                                    <input
                                        type="radio"
                                        name="dateType"
                                        checked={btnRadioDate === 1}
                                        value={1}
                                        onChange={(e) => {
                                            onRadioHandler(e, setData, true);
                                            resetFieldVal(e, 'isInValidDateType');
                                        }}
                                        //@ts-ignore
                                        onClick={(e) => setBtnRadioDate(Number(e.target.value))}
                                    />
                                    <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Постоянно</span>
                                </label>
                                <div
                                    data-label="loadingPeriodType"
                                    data-warning="false"
                                    //@ts-ignore
                                    className={`${data.dateType !== 0 ? '' : 'disabled'} `}
                                >
                                    <AsyncSelect
                                        className="fs-12 w-100"
                                        classNamePrefix="react-select"
                                        placeholder={'Выберите...'}
                                        loadOptions={loadOptions2}
                                        defaultOptions={optionsLoadingPeriodType}
                                        value={
                                            selectPeriodType &&
                                            optionsLoadingPeriodType?.find(
                                                (item) => item.value === selectPeriodType.value,
                                            )
                                        }
                                        onChange={(val) => {
                                            setSelectPeriodType({
                                                value: val.value,
                                                label: val.label,
                                            });
                                            setData((prevState) => ({
                                                ...prevState,
                                                datePeriodType: val.value,
                                            }));
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
