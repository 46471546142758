import { IconContext } from 'react-icons';
import { IoChevronBackOutline, IoChevronForwardOutline, IoNewspaperOutline } from 'react-icons/io5';
import { VscChromeClose } from 'react-icons/vsc';

export const MobileButtons = ({
    setShowUseTemplate,
    setShowModalSave,
    onReset,
    setActiveField,
    activeField,
    allSteps = 5,
}) => {
    return (
        <div className="mobile-btns d-block d-lg-none" style={{zIndex:1}}>
            <div className="container">
                <div className="d-flex align-items-center justify-content-between blue title-font fw-5 fs-11">
                    <button type="button" onClick={() => setShowUseTemplate(true)}>
                        <IconContext.Provider value={{ className: 'icon-15' }}>
                            <IoNewspaperOutline />
                        </IconContext.Provider>
                        <span className="ms-1">Использовать шаблон</span>
                    </button>
                    {activeField < allSteps ? (
                        <button type="reset" onClick={() => onReset()}>
                            <IconContext.Provider value={{ className: 'icon-15' }}>
                                <VscChromeClose />
                            </IconContext.Provider>
                            <span className="ms-1">Очистить форму</span>
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="fs-11 mx-auto mt-2 mt-xl-3 blue"
                            onClick={() => setShowModalSave(true)}
                        >
                            Сохранить шаблон
                        </button>
                    )}
                </div>
                <div className="row gx-2 gx-sm-4 title-font">
                    {activeField > 1 && (
                        <div className={`col-${activeField < allSteps ? 6 : 5} col-sm-6`}>
                            <button
                                type="button"
                                onClick={() => setActiveField(activeField - 1)}
                                className="btn btn-1 px-3 w-100 fs-11"
                            >
                                <IconContext.Provider value={{ className: 'icon-15' }}>
                                    <IoChevronBackOutline />
                                </IconContext.Provider>
                                <span className="ms-1 ms-sm-3 text-uppercase">Назад</span>
                            </button>
                        </div>
                    )}
                    {activeField < allSteps ? (
                        <div className={`col-${activeField > 1 ? 6 : 12} col-sm-6`}>
                            <button
                                type="button"
                                /*disabled={checkFieldset("payment") ? false : true}*/
                                onClick={() => setActiveField(activeField + 1)}
                                className="btn btn-1 w-100 fs-11"
                            >
                                <span className="me-1 me-sm-3 text-uppercase">Далее</span>
                                <IconContext.Provider value={{ className: 'icon-15' }}>
                                    <IoChevronForwardOutline />
                                </IconContext.Provider>
                            </button>
                        </div>
                    ) : (
                        <div className="col-7 col-sm-6">
                            <button type="submit" className="btn btn-2 w-100 h-100 fs-11 text-uppercase px-3">
                                Сохранить
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
