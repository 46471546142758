import { getCityFromIP } from '@entities/.app/cities/cities.api';
import { setDefineCity, setDefineCityFailure, setDefineCitySuccess } from '@entities/.app/cities/cities.slice';
import Logger from '@shared/lib/logger';
import { defineIP } from '@shared/lib/net';

export const setSelectedCity = (city) => {
    return (dispatch) => {
        dispatch(setDefineCity());
        dispatch(setDefineCitySuccess(city));
    };
};

export const setSelectedCityFailure = (error) => {
    return (dispatch) => {
        dispatch(setDefineCity());
        dispatch(setDefineCityFailure(error));
    };
};

export const defineCity = async () => {
    try {
        const ipAddress = await defineIP();
        const city = await getCityFromIP(ipAddress);
        return city;
    } catch (error) {
        Logger.error(error);
        return 'Москва';
    }
};
