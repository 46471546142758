import { IconContext } from 'react-icons';
import { IoTrash } from 'react-icons/io5';

import { useAppSelector } from "@core/store/hooks";
import { selectCurrentUser } from "@entities/.app/account/account.selectors";
import CustomModal from "@shared/ui/CustomModal";

export const RouteTemplateSelectModal = ({
    isShow,
    setIsShow,

    templates,
    setData,
    setBtnRadioDate,
    setBtnRadioCalculate,
    setBtnRadioBargain,

    setSelectCar,
    setSelectPeriodType,
    setSelectDays,
    onDeleteTemplate,
}) => {
    const currentUser = useAppSelector(selectCurrentUser);

    return (
        <CustomModal
            className="modal__patternRoute"
            isShow={isShow}
            setIsShow={setIsShow}
            centered={false}
            closeButton={true}
            size={'lg'}
        >
            <div>
                <div className="d-flex align-items-center">
                    <div className="flex-1">
                        {templates?.length > 0 && <h2>Выберите шаблон</h2>}
                        {templates.map((item, index) => (
                            <div key={index} className="box patterns p-2 p-sm-4">
                                <div className="d-flex align-items-center">
                                    <div className="flex-1">
                                        <div className="title-font fs-12 fw-7">{item.name}</div>
                                        {item.note && <div className="fs-11 mt-1">{item.note}</div>}
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-1 fs-09 px-2 px-sm-4 ms-2"
                                        onClick={() => {
                                            setData((prevState) => ({
                                                ...prevState,
                                                userId: currentUser.id,
                                                fromRoute: item?.route?.fromRoute,
                                                loadingRadius: item?.route?.loadingRadius,
                                                toRoute: item?.route?.toRoute,
                                                unloadingRadius: item?.route?.unloadingRadius,
                                                dateForInput: item?.route?.date,
                                                dateDays: item?.route?.dateDays,
                                                carId: item?.route?.carId,
                                                carName: item?.route?.car?.name,
                                                dateType: Number(item?.route?.dateType),
                                                datePeriodType: item?.route?.datePeriodType,
                                                bargainType: item?.route?.bargainType,
                                                calculateType: item?.route?.calculateType,
                                                noVatPrice: item?.route?.noVatPrice,
                                                prepayment: item?.route?.prepayment,
                                                contacts: item?.route?.contacts,
                                                note: item?.route?.note,
                                            }));
                                            setBtnRadioDate(Number(item?.route?.dateType));
                                            setBtnRadioCalculate(Number(item?.route?.calculateType));
                                            setBtnRadioBargain(Number(item?.route?.bargainType));
                                            setSelectCar({
                                                value: item?.route?.carId,
                                                label: item?.route?.car?.carName,
                                            });
                                            setSelectPeriodType({
                                                value: item?.route?.datePeriodType,
                                                label: item?.route?.datePeriodTypeForUser,
                                            });
                                            setSelectDays({
                                                value: item?.route?.dateDays,
                                                label: `${item?.route?.dateDays} дн.`,
                                            });
                                        }}
                                    >
                                        Выбрать
                                    </button>
                                    <button
                                        type="button"
                                        className="ms-2 ms-sm-3"
                                        onClick={() => onDeleteTemplate(item.id)}
                                    >
                                        <IconContext.Provider value={{ className: 'gray-4 icon-15' }}>
                                            <IoTrash />
                                        </IconContext.Provider>
                                    </button>
                                </div>
                            </div>
                        ))}
                        {!templates.length && <h5 className="text-center">У Вас нет сохраненных шаблонов</h5>}
                        <p className="text-center fs-11">
                            Сохраняйте однотипные объявления в шаблоны <br /> для удобства и экономии времени
                        </p>
                        <button
                            type="button"
                            onClick={() => setIsShow(false)}
                            className="btn btn-1 fs-12 mx-auto mt-4"
                        >
                            Закрыть
                        </button>
                    </div>
                </div>
            </div>
        </CustomModal>
    );
};
