import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru.json';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import {appRoutes} from '@core/navigation';
import { paginateLastMessages } from '@entities/forum/forum.api';
import { getURL } from '@shared/lib/getURL';
import Loader from '@shared/ui/Loader';


export default function ForumLastMsgFeedWidget(props) {
    TimeAgo.addLocale(ru);
    const [messages, setMessages] = useState({
        isLoading: false,
        error: null,
        items: [],
    });

    useEffect(() => {
        paginateLastMessages(1, 7, 'desc')
            .then((result) => {
            setMessages((prev) => ({ ...prev, isLoading: true, items: result?.data }))
        }
            )
            .catch((error) => setMessages((prev) => ({ ...prev, isLoading: true, error })));
    }, []);

    return messages.isLoading ? (
        messages?.items?.length ? (
            <aside className={props.className}>
                <h5 className="mb-3 text-center">Сообщения на форуме</h5>
                <div className="forum-widget" style={{ borderRadius: 8 }}>
                    {messages.items.map((item) => (
                        <div className="message" key={item.id}>
                            <ReactTimeAgo
                                className="forum-widget__date"
                                date={Date.parse(item.createdAt)}
                                locale="ru-RU"
                            />
                            <NavLink to={item?.topic?.id ? getURL(appRoutes.FORUM_TOPIC, item?.topic?.id) : getURL()}>
                                <h5>{item?.topic?.title || 'Без темы'}</h5>
                            </NavLink>

                            <NavLink className="text" to={item?.user?.id ? getURL(appRoutes.MARKET_PROFILE, item?.user?.id) : getURL()}>
                                <span className="author">{item?.user?.firstName || 'Имя не указано'}&nbsp;</span>
                                {item.description || ''}
                            </NavLink>
                        </div>
                    ))}
                </div>
            </aside>
        ) : (
            <div className="p-5" />
        )
    ) : (
        <div className="w-100 d-flex justify-content-center p-5">
            <Loader color="#545454" />
        </div>
    );
}
