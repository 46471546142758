import { useAppSelector } from "@core/store/hooks";
import { selectCities } from "@entities/.app/cities/cities.selectors";
import SearchInput from "@shared/ui/SearchInput";

export const AdRouteForm = ({ data, setData, valid, resetFieldVal, onInputHandler }) => {
    const cities = useAppSelector(selectCities);

    return (
        <div className="box">
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <label
                        data-label="loading"
                        data-warning="false"
                        className="title-font fs-12 fw-5"
                        style={{ color: valid.isInValidFromRoute && 'red' }}
                    >
                        Откуда*
                    </label>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-xl-6">
                            <SearchInput
                                //@ts-ignore
                                data={cities}
                                placeHolder={'Населенный пункт'}
                                //@ts-ignore
                                value={data?.fromRoute}
                                callback={(value, e) => {
                                    setData((prevState) => ({ ...prevState, fromRoute: value }));
                                    resetFieldVal(e, 'isInValidFromRoute');
                                }}
                            />
                            {valid.isInValidFromRoute && (
                                <span className="position-absolute" style={{ color: 'red' }}>
                                    Поле обязательно для заполнения
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <label data-label="loadingRadius" data-warning="false" className="title-font fs-12 fw-5">
                        Радиус загрузки
                    </label>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3">
                            <input
                                type="number"
                                min="1"
                                //@ts-ignore
                                value={data?.loadingRadius || ''}
                                onChange={(e) => onInputHandler(e, setData)}
                                name="loadingRadius"
                                placeholder="1"
                                className="w-100 fs-12 distance"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <label
                        data-label="unloading"
                        data-warning="false"
                        className="title-font fs-12 fw-5"
                        style={{ borderColor: valid.isInValidToRoute && 'red' }}
                    >
                        Куда*
                    </label>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-xl-6">
                            <SearchInput
                                //@ts-ignore
                                data={cities}
                                placeHolder={'Населенный пункт'}
                                //@ts-ignore
                                value={data?.toRoute}
                                callback={(value, e) => {
                                    setData((prevState) => ({ ...prevState, toRoute: value }));
                                    resetFieldVal(e, 'isInValidToRoute');
                                }}
                            />
                            {valid.isInValidToRoute && (
                                <span className="position-absolute" style={{ color: 'red' }}>
                                    Поле обязательно для заполнения
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <label data-label="unloadingRadius" data-warning="false" className="title-font fs-12 fw-5">
                        Радиус разгрузки
                    </label>
                </div>
                <div className="col-md-9">
                    <div className="row">
                        <div className="col-sm-6 col-xl-3">
                            <input
                                type="number"
                                name="unloadingRadius"
                                min="1"
                                //@ts-ignore
                                value={data?.unloadingRadius || ''}
                                onChange={(e) => onInputHandler(e, setData)}
                                placeholder="1"
                                className="w-100 fs-12 distance"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
