import { useEffect, useLayoutEffect, useState } from 'react';
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import RoleFilter from '@core/navigation/Router.RoleFilter';
import { useActions, useAppSelector } from '@core/store/hooks';
import { getCities } from '@entities/.app/cities/cities.api';
import { selectToken, selectUserId } from '@entities/.app/account/account.selectors';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import { EDocModelTypesLabels } from '@entities/office/dealDocument/dealDocument.types';
import AppLayout from '@features/.app/App.Layout';
import ForumTopicList from '@features/forum/Forum.TopicList.Table';
import AppContactsPage from '@processes/.app/App.Contacts.Page';
import AppHomePage from '@processes/.app/App.Home.Page';
import AppInformationAboutPayPage from '@processes/.app/App.InformationAboutPay.Page';
import AppLoginPage from '@processes/.app/App.Login.Page';
import AppNotFoundPage from '@processes/.app/App.NotFound.Page';
import AppRegistrationPage from '@processes/.app/App.Registration.Page';
import AppResetPasswordPage from '@processes/.app/App.ResetPassword.Page';
import AppResetPassword2Page from '@processes/.app/App.ResetPassword2.Page';
import ForumMainPage from '@processes/forum/Forum.Main.Page';
import ForumTopicChatPage from '@processes/forum/Forum.TopicChat.Page';
import AdProfile from '@processes/market/Ad.Profile.Page';
import AdSearchPage from '@processes/market/Ad.Search.Page';
import CarAddPage from '@processes/market/Car.Add.Page';
import CarEdit from '@processes/market/Car.Edit.Page';
import CargoAddpAGE from '@processes/market/Cargo.Add.Page';
import CargoEditPage from '@processes/market/Cargo.Edit.Page';
import CargoViewPage from '@processes/market/Cargo.View.Page.';
import RouteAdd from '@processes/market/Route.Add.Page';
import RouteEdit from '@processes/market/Route.Edit.Page';
import RouteView from '@processes/market/Route.View.Page';
import NewsArticle from '@processes/news/News.Article.Page';
import NewsList from '@processes/news/News.Main.Page';
import OfficeDealDocumentEditorPage from '@processes/office/Office.DealDocument.Editor.Page';
import OfficeMainPage from '@processes/office/Office.Main.Page';
import Logger from '@shared/lib/logger';

import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import { io } from 'socket.io-client';

const wsClient = io(apiRoutes.APP_BACKEND_URL);

const DealDocumentEditorRoute = () => {
    const currentDeal = useAppSelector(selectCurrentDeal);
    const currentUserId = useAppSelector(selectUserId);
    const location = useLocation();

    const isData = location?.state?.doc || EDocModelTypesLabels[location?.state?.docType];

    const { setDocStructure } = useActions();
    setDocStructure([]);

    Logger.log(`DocEditorRoute`, {
        currentDeal,
        currentUserId,
        doc: location?.state?.doc,
        docType: location?.state?.docType,
    });

    return currentDeal && currentUserId && isData ? <OfficeDealDocumentEditorPage /> : <Navigate to={appRoutes.ROOT} />;
};

function AuthRoutes() {
    const isAuthenticated = useAppSelector(selectToken);
    return !isAuthenticated ? <Outlet /> : <Navigate to={getURL()} />;
}

function ProtectedRoutes() {
    const isAuthenticated = useAppSelector(selectToken);
    return isAuthenticated ? <Outlet /> : <Navigate to={getURL(appRoutes.LOGIN)} />;
}

const AppRouter = () => {
    const Wrapper = ({ children }) => {
        const { pathname } = useLocation();
        useLayoutEffect(() => document.documentElement.scrollTo(0, 0), [pathname]);
        return children;
    };

    const [isConnected, setIsConnected] = useState(wsClient.connected);
    const { addAppEvents } = useActions();

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onAppEvent(event) {
            Logger.log('onAppEvent', event);
            event && addAppEvents([{ ...event, content: JSON.parse(event.content) }]);
        }

        // socket.on('connect', onConnect);
        // socket.on('disconnect', onDisconnect);
        wsClient.on('appEvents', onAppEvent);

        // socket.on('news', (data) => {
        //     Logger.log(data);
        //     socket.emit('my other event', { my: 'data' });
        // });

        return () => {
            // socket.off('connect', onConnect);
            // socket.off('disconnect', onDisconnect);
            wsClient.off('appEvents', onAppEvent);
        };
    }, []);

    //LOADING citiesList
    const { setCities } = useActions();
    useEffect(() => {
        const load = async () => {
            const res = await getCities();
            setCities(res.body);
        };
        load();
    }, []);

    return (
        <Wrapper>
            <Routes>
                <Route path={appRoutes.ROOT} element={<AppLayout />}>
                    {/* Роуты доступные не зареганным пользователям */}
                    <Route element={<AuthRoutes />}>
                        <Route path={appRoutes.LOGIN} element={<AppLoginPage />} />
                        <Route path={appRoutes.REG} element={<AppRegistrationPage />} />
                        <Route path={appRoutes.RESET_PWD} element={<AppResetPasswordPage />} />
                        <Route path={appRoutes.RESET_PWD2} element={<AppResetPassword2Page />} />
                    </Route>

                    {/* Роуты доступные только зареганным пользователям */}
                    <Route element={<ProtectedRoutes />}>
                        <Route element={<RoleFilter />}>
                            <Route path={appRoutes.MARKET_CARGO_ADD} element={<CargoAddpAGE />} />
                            <Route path={appRoutes.MARKET_CARGO_EDIT_BY_ID} element={<CargoEditPage />} />
                            <Route path={appRoutes.MARKET_CAR_ADD} element={<CarAddPage />} />
                            <Route path={appRoutes.MARKET_CAR_EDIT_BY_ID} element={<CarEdit />} />
                            <Route path={appRoutes.MARKET_ROUTE_ADD} element={<RouteAdd />} />
                            <Route path={appRoutes.MARKET_ROUTE_EDIT_BY_ID} element={<RouteEdit />} />
                        </Route>
                        <Route path={appRoutes.OFFICE_DEAL_DOC_EDITOR} element={<DealDocumentEditorRoute />} />
                        <Route path={appRoutes.OFFICE_ALL} element={<OfficeMainPage />} />
                        <Route path={appRoutes.MARKET_PROFILE_BY_ID} element={<AdProfile />} />
                    </Route>

                    {/* Роуты доступные всем пользователям */}

                    {/* NEWS */}
                    <Route path={appRoutes.NEWS_ALL} element={<NewsList />} />
                    <Route path={appRoutes.NEWS_BY_SLUG} element={<NewsArticle />} />

                    {/* MARKET */}
                    <Route path={appRoutes.SEARCH} element={<AdSearchPage />} />
                    <Route path={appRoutes.MARKET_CARGO_BY_ID} element={<CargoViewPage />} />
                    <Route path={appRoutes.MARKET_ROUTE_BY_ID} element={<RouteView />} />

                    {/* FORUM */}
                    <Route path={appRoutes.FORUM} element={<ForumMainPage />}>
                        <Route index element={<ForumTopicList />} />
                        <Route path={appRoutes.FORUM_MY_TOPICS} element={<ForumTopicList />} />
                    </Route>
                    <Route path={appRoutes.FORUM_TOPIC_BY_ID} element={<ForumTopicChatPage />} />

                    {/* APP */}
                    <Route index element={<AppHomePage />} />
                    <Route path={appRoutes.INFOPAY} element={<AppInformationAboutPayPage />} />
                    <Route path={appRoutes.CONTACTS} element={<AppContactsPage />} />
                    <Route path={appRoutes.ALL} element={<AppNotFoundPage />} />
                </Route>
            </Routes>
        </Wrapper>
    );
};
export default AppRouter;
