//Libs
import CustomModal from '@shared/ui/CustomModal';
import { IconContext } from 'react-icons';
import { IoTrash } from 'react-icons/io5';

function CargoTemplateSelectModal({ name, remark, id, setCurrentTemplate, setIsShow, onDelete }) {
    const handleSetCurrentTemplate = () => {
        setCurrentTemplate(id);
        setIsShow(false);
    };

    return (
        <div className="box patterns p-2 p-sm-4">
            <div className="d-flex align-items-center">
                <div className="flex-1">
                    <div className="title-font fs-12 fw-7">{name}</div>
                    {remark && <div className="fs-11 mt-1">{remark}</div>}
                </div>
                <button
                    type="button"
                    className="btn btn-1 fs-09 px-2 px-sm-4 ms-2"
                    onClick={handleSetCurrentTemplate}
                    data-bs-dismiss="modal"
                >
                    Выбрать
                </button>
                <button type="button" className="ms-2 ms-sm-3" onClick={() => onDelete(id)}>
                    <IconContext.Provider value={{ className: 'gray-4 icon-15' }}>
                        <IoTrash />
                    </IconContext.Provider>
                </button>
            </div>
        </div>
    );
}

function CargoTemplatSelectModal({
    isShow,
    setIsShow,
    templates = [],
    setCurrentTemplate,
    handleDeleteTemplate,
}) {
    const handleSetCurrentTemplate = (id) => {
        const template = templates.find((item) => item.id === id);
        setCurrentTemplate(template);
    };

    return (
        <CustomModal isShow={isShow} setIsShow={setIsShow} size="lg" closeButton={true}>
            <div>
                {templates.length > 0 && <h2>Выберите шаблон</h2>}
                <div style={{ maxHeight: '350px', overflow: 'auto' }}>
                    {templates?.map((item) => (
                        <CargoTemplateSelectModal
                            name={item.name}
                            remark={item.note}
                            key={item.id}
                            id={item.id}
                            setCurrentTemplate={handleSetCurrentTemplate}
                            setIsShow={setIsShow}
                            onDelete={handleDeleteTemplate}
                        />
                    ))}
                </div>
                {!templates.length && <h5 className="text-center">У Вас нет сохраненных шаблонов</h5>}
                <p className="text-center fs-11 mt-2">
                    Сохраняйте однотипные объявления в шаблоны <br /> для удобства и экономии времени
                </p>
                <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-1 fs-12 mx-auto mt-3"
                    onClick={() => setIsShow(false)}
                >
                    Закрыть
                </button>
            </div>
        </CustomModal>
    );
}
export default CargoTemplatSelectModal;
