import { memo, ReactElement } from 'react';

import { ETypeDocNode, IDocNode } from '@entities/office/dealDocument/dealDocument.types';
import DealDocumentDataSetPickerElement from '@features/office/dealDocument/DealDocument.DataSetPicker.Element';
import Logger from '@shared/lib/logger';
import React from 'react';
import DealDocumentFormElement from './DealDocument.Form.Element';
import { DealDocumentInputElement, DealDocumentOutputElement } from './DealDocument.Input.Element';

const NewLineElement = () => <br />;
const TextElement = ({ node }) =>
    node?.config?.editable ? (
        <div
            key={node.order}
            style={{ width: '100%' }}
            className="editable-text"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onInput={(e: any) => {
                if (e.target.innerText !== node.content) e.target.classList.add('edit');
                else e.target.classList.remove('edit');
            }}
        >
            {node.content as string}
        </div>
    ) : (
        <span>{node.content as string}</span>
    );
const BlockElement = ({ node }) => (
    <div>
        <DealDocumentRender nodes={node.content as IDocNode[]} />
    </div>
);
const TitleElement = ({ node }) => {
    const data = [];
    data[0] = Array.isArray(node.content) ? node.content : null;
    return node.config?.style === 'plaintext' ? (
        <p className="text-center title-font mb-4" key={node.order}>
            {node.content as string}
        </p>
    ) : node.config?.isMain ? (
        <p className="text-center title-font fw-7 fs-15 title-main" key={node.order}>
            <DealDocumentRender nodes={data[0]} />
        </p>
    ) : node?.config?.editable ? (
        <div
            style={{ width: '100%' }}
            key={node.order}
            className="editable-text text-center m-3 h5"
            contentEditable="true"
            suppressContentEditableWarning={true}
            onInput={(e: any) => {
                if (e.target.innerText !== node.content) e.target.classList.add('edit');
                else e.target.classList.remove('edit');
            }}
        >
            {node.content as string}
        </div>
    ) : (
        <div
            className="title-main h5 text-center m-0"
            key={node.order}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', columnGap: 20 }}
        >
            {Array.isArray(node.content) ? <DealDocumentRender nodes={node.content} /> : node.content}
        </div>
    );
};
const ULElement = ({ node }) => {
    const data = [];
    data[0] = Array.isArray(node.content) ? node.content : null;
    return data[0] ? (
        <ul className={node.config?.isBulleted ? '' : 'doc-ul'} key={node.order}>
            {data[0].map((innerNode, idx) => (
                <li key={idx}>
                    <DealDocumentRender nodes={[innerNode]} />
                </li>
            ))}
        </ul>
    ) : null;
};

const docRenderElements = {
    //PRIMITIVE
    [ETypeDocNode.NEWLINE]: NewLineElement,
    [ETypeDocNode.TEXT]: TextElement,
    //CONTAINERS
    [ETypeDocNode.BLOCK]: BlockElement,
    [ETypeDocNode.TITLE]: TitleElement,
    [ETypeDocNode.UL_LIST]: ULElement,
    //INPUTS
    [ETypeDocNode.INPUT]: DealDocumentInputElement,
    [ETypeDocNode.OUTPUT]: DealDocumentOutputElement,
    //DOMAIN
    [ETypeDocNode.DATA_PICKER]: DealDocumentDataSetPickerElement,
    [ETypeDocNode.FORM]: DealDocumentFormElement,
};

const DealDocumentRender = ({ nodes }: { nodes: IDocNode[] }) => {
    const elements = nodes?.map((node) => {
        try {
            if (node) {
                Logger.log('DOC_RENDER', { order: node.order, type: node.type, content: node.content });
                return docRenderElements[node.type] &&
                    ('isVisible' in node && typeof node.isVisible === 'boolean' ? node.isVisible : true)
                    ? React.createElement(docRenderElements[node.type], { node, key: node.order })
                    : null;
            } else return null;
        } catch (error) {
            Logger.error(error);
            return null;
        }
    }) as ReactElement<any, any>[];
    return <>{elements}</>;
};
export default memo(DealDocumentRender);
