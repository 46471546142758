import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    hasErrors: false,
    selectedCity: null,
    list: [],
};

const citiesSlice = createSlice({
    name: 'cities',
    initialState,
    reducers: {
        setDefineCity: (state, action: {payload:null}) => {
            state.loading = true;
        },
        setDefineCitySuccess: (state, action) => {
            state.loading = false;
            state.selectedCity = action.payload;
        },
        setDefineCityFailure: (state, action) => {
            state.loading = false;
            state.hasErrors = true;
        },
        setCities: (state, action) => {
            state.list = action.payload;
        },
    },
});

export const citiesActions = citiesSlice.actions;
export const citiesReducer = citiesSlice.reducer;

export const { setDefineCity, setDefineCitySuccess, setDefineCityFailure } = citiesSlice.actions;
export default citiesSlice.reducer;
