export enum EAppEventActions {
    //DEAL_DOC
    DEAL_DOC_EDIT,
    DEAL_DOC_DECLARE_CLOSED,
    DEAL_DOC_CLOSE,
    //DEAL
    DEAL_NEW,
    DEAL_OPEN,

    DEAL_DECLARE_START,
    DEAL_START,

    DEAL_DECLARE_FINISH,
    DEAL_FINISH,

    DEAL_DECLARE_CANCEL,
    DEAL_CANCEL,

    DEAL_DECLARE_ARCHIVE,
    DEAL_ARCHIVE,
  }
