
export const currentDate = () => new Date(+new Date() + 86400000).toISOString().slice(0, 10);

export const dayPeriodForUser = (data) => {
    // @ts-ignore
    if (data?.datePeriodType === undefined && data?.datePeriodType === null) {
        return '';
    }
    // @ts-ignore
    if (data?.datePeriodType === 0) {
        return 'по рабочим дням';
    }
    // @ts-ignore
    if (data?.datePeriodType === 1) {
        return 'по выходным';
    }
    // @ts-ignore
    if (data?.datePeriodType === 2) {
        return 'ежедневно';
    }
    // @ts-ignore
    if (data?.datePeriodType === 3) {
        return 'через день';
    }
};
