
import { useEffect, useState } from 'react';
import { useAppDispatch} from '@core/store/hooks';

import { logout } from '@entities/.app/account/account.actions';
import { localStorageFields } from '@entities/.app/account/account.data';
import useRefreshToken from '@entities/.app/account/hooks/account.useRefreshToken';
import Logger from '@shared/lib/logger';

const useInitialAuth = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const dispatch = useAppDispatch();
    const rememberMe = JSON.parse(localStorage.getItem(localStorageFields.rememberMe));

    useEffect(() => {
        const getToken = async () => {
            if (rememberMe === false) {
                setIsLoading(false);
                return logout(dispatch);
            }

            try {
                await refresh();
            } catch (error) {
                Logger.error(error);
            } finally {
                setIsLoading(false);
            }
        };

        getToken();
    }, []);

    return isLoading;
};
export default useInitialAuth;
