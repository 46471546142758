import {apiRoutes} from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

//Управление сделкой
// const getDeal = async (axiosPrivate, id) => {
//     try {
//         const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL,apiRoutes.DEALS, id));
//         return response.data;
//     } catch (error) {
//         Logger.error(error);
//     }
// };
const createDeal = async (axiosPrivate, payload) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL,apiRoutes.DEAL), payload);
        return response.data;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

const updateDeal = async (axiosPrivate, payload, id) => {
    try {
        const response = await axiosPrivate.patch(getURL(apiRoutes.APP_API_URL,apiRoutes.DEAL, id), payload);
        return response.data.body;
    } catch (error) {
        Logger.error(error);
        throw error;
    }
};

const rejectDeal = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL,apiRoutes.DEAL, id));
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

//Списки сделок
const getDeals = async (axiosPrivate, userId, page, limit, status) => {
    try {
        const response = await axiosPrivate.put(getURL(apiRoutes.APP_API_URL,apiRoutes.DEAL, userId), {
            page,
            limit,
            orderBy: 'desc',
            status,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

//Проверка есть ли отлик
const getIsTestDealRoute = async (axiosPrivate, userId, routeId) => {
    try {
        const response = await axiosPrivate.put(
            getURL(apiRoutes.APP_API_URL,apiRoutes.DEALS_ROUTE, userId),
            {
                id: routeId,
            },
        );
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};
const getIsTestDealCargo = async (axiosPrivate, userId, cargoId) => {
    try {
        const response = await axiosPrivate.put(
            getURL(apiRoutes.APP_API_URL,apiRoutes.DEALS_CARGO, userId),
            {
                id: cargoId,
            },
        );
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

export { createDeal, updateDeal, rejectDeal, getDeals, getIsTestDealRoute, getIsTestDealCargo };
