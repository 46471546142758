
import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoNewspaperOutline } from 'react-icons/io5';
import { VscChromeClose } from 'react-icons/vsc';
import { NavLink, useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';

import { appRoutes } from '@core/navigation';
import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { setAlert } from '@entities/.app/alert/alert.actions';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { getCars } from '@entities/market/car/car.api';
import { vatPrice } from '@entities/market/market.actions';
import { deleteTemplate } from '@entities/market/market.api';
import { optionsLoadingDays, optionsLoadingPeriodType } from '@entities/market/market.data';
import { dayPeriodForUser } from '@entities/market/route/route.actions';
import {
    createRoute,
    getRoutePage,
    getRouteTemplates,
    saveRouteTemplate,
    updateRoute,
} from '@entities/market/route/route.api';
import { fields } from '@entities/market/route/route.data';
import { AdCarSelectForm } from '@features/market/ad/Ad.CarSelect.Form';
import { AdContactsForm } from '@features/market/ad/Ad.Contacts.Form';
import { AdDateForm } from '@features/market/ad/Ad.Date.Form';
import { AdPaymentForm } from '@features/market/ad/Ad.Payment.Form';
import { AdRequiredFieldsModal } from '@features/market/ad/Ad.RequiredFields.Modal';
import { AdRouteForm } from '@features/market/ad/Ad.Route.Form';
import { RouteTemplateSaveModal } from '@features/market/route/Route.Template.Save.Modal';
import { RouteTemplateSelectModal } from '@features/market/route/Route.Template.Select.Modal';
import { getDate } from '@shared/lib/dateTIme';
import { onInputHandler, onRadioHandler } from '@shared/lib/forms';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';
import { MobileButtons } from '../ad/Ad.MobileButtons.Widget';

const RightBlock = ({ adRouteId, data, findCar, setShowModalSave, setShowUseTemplate, onReset }) => {
    return (
        <aside className="box">
            <div className="d-none d-lg-flex align-items-center fs-09 mb-lg-5" style={{ marginBottom: 10 }}>
                <button type="button" onClick={() => setShowUseTemplate(true)} className="btn btn-4 p-2">
                    <IconContext.Provider value={{ className: 'icon-15' }}>
                        <IoNewspaperOutline />
                    </IconContext.Provider>
                    <span className="ms-2">Использовать шаблон</span>
                </button>
                <button type="reset" className="btn btn-4 p-2 ms-3" onClick={() => onReset()}>
                    <IconContext.Provider value={{ className: 'icon-15' }}>
                        <VscChromeClose />
                    </IconContext.Provider>
                    <span className="ms-2">Очистить форму</span>
                </button>
            </div>
            <nav className="contents">
                <ol>
                    <li>
                        <Link
                            activeClass="active"
                            to="route"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={300}
                            isDynamic={true}
                            className={data?.fromRoute && data?.toRoute ? 'filled' : ''}
                        >
                            Маршрут
                        </Link>
                        <div className="fs-09">
                            {/* @ts-ignore */}
                            <span className="me-1">{data?.fromRoute}</span>
                            {/* @ts-ignore */}
                            {data?.loadingRadius && <span className="me-1">+{data?.loadingRadius}км</span>}
                            {/* @ts-ignore */}
                            {data?.toRoute && <span className="me-1">— {data?.toRoute}</span>}
                            {/* @ts-ignore */}
                            {data?.unloadingRadius && <span>+{data?.unloadingRadius}км</span>}
                        </div>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="date"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={300}
                            isDynamic={true}
                            className={
                                data?.dateType !== undefined &&
                                (data?.date || data?.datePeriodType || data?.datePeriodType === 0)
                                    ? 'filled'
                                    : ''
                            }
                        >
                            Дата
                        </Link>
                        <div className="fs-09">
                            {/* @ts-ignore */}
                            {data?.dateType ? (
                                <>
                                    <span>Постоянно:{dayPeriodForUser(data)}</span>
                                </>
                            ) : (
                                <>
                                    <span className="me-1">Единожды:</span>
                                    <span className="me-1">{data?.date === 'Invalid Date' ? '' : data?.date}</span>
                                    {/* @ts-ignore */}
                                    <span>{data?.days ? `+ ${data?.days} дней` : ''}</span>
                                </>
                            )}
                        </div>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="aboutCar"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={300}
                            isDynamic={true}
                            className={data?.carId ? 'filled' : ''}
                        >
                            Информация о машине
                        </Link>
                        <div className="fs-09">
                            {/* @ts-ignore */}
                            {findCar(data?.carId)}
                        </div>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="payment"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={300}
                            isDynamic={true}
                            className={data?.prepayment ? 'filled' : ''}
                        >
                            Оплата
                        </Link>
                        <div className="fs-09">
                            {/* @ts-ignore */}
                            {data?.bargainType === 0 ? (
                                <span className="me-1">Возможен торг</span>
                            ) : (
                                <span className="me-1">Без торга</span>
                            )}
                            {/* @ts-ignore */}
                            {data?.calculateType === 0 ? (
                                <span className="me-1">, наличный расчет</span>
                            ) : (
                                <span className="me-1">, перевод по карте</span>
                            )}
                            {/* @ts-ignore */}
                            {data?.priceVat && <span className="me-1">, с&nbsp;НДС {data?.priceVat} ₽/км</span>}
                            {/* @ts-ignore */}
                            {data?.priceNovat && <span className="me-1">, без&nbsp;НДС {data?.priceNovat} ₽/км</span>}
                            {/* @ts-ignore */}
                            {data?.prepay && <span>, предоплата {data?.prepay} %</span>}
                        </div>
                    </li>
                    <li>
                        <Link
                            activeClass="active"
                            to="contacts"
                            spy={true}
                            smooth={true}
                            hashSpy={true}
                            offset={-80}
                            duration={300}
                            isDynamic={true}
                            className={data?.contacts?.find((i) => i.phone && i.firstName) ? 'filled' : ''}
                        >
                            Контакты
                        </Link>
                        <div className="fs-09">
                            {data?.contacts?.map((i, index) => (
                                <div key={index}>
                                    <span className="me-1">{i.firstName}</span>
                                    {i.phone && <span className="me-1">,{i.phone}</span>}
                                </div>
                            ))}
                        </div>
                    </li>
                </ol>
            </nav>
            <button type="submit" className="btn btn-1 text-uppercase fs-15 mx-auto mt-4 mt-xl-5">
                {adRouteId ? 'Редактировать' : 'Добавить маршрут'}
            </button>
            <div className="fs-09 text-center mt-2 mt-xl-3">
                Объявление будет опубликованно до 1 января включительно, после чего удалится в архив
            </div>
            <button type="button" className="fs-11 mx-auto mt-2 mt-xl-3 blue" onClick={() => setShowModalSave(true)}>
                Сохранить шаблон
            </button>
        </aside>
    );
};

export const AdRoutePageContent = ({ adRouteId = null }) => {
    const navigate = useNavigate();
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useAppDispatch();

    const currentUser = useAppSelector(selectCurrentUser);

    //MODALS
    const [isShowAlert, setIsShowAlert] = useState(false);
    useEffect(() => {
        if (isShowAlert) {
            setTimeout(() => setIsShowAlert(false), 1500);
        }
    }, [isShowAlert]);
    const [alertForSavePattern, setAlertForSavePattern] = useState(false);
    const [showModalSave, setShowModalSave] = useState(false);
    const [showModalValidation, setShowModalValidation] = useState(false);
    const [showUseTemplate, setShowUseTemplate] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [dataTemplate, setDataTemplate] = useState({
        templateName: '',
        templateNote: null,
    });
    useEffect(() => {
        if (dataTemplate?.templateNote?.length === 0) {
            setDataTemplate((prevState) => ({ ...prevState, templateNote: null }));
        }
    }, [dataTemplate?.templateNote?.length]);
    useEffect(() => {
        getRouteTemplates(axiosPrivate, currentUser?.id, 1)
            .then((r) => setTemplates(r.data?.body?.data))
            .catch((error) => Logger.error(error));
    }, [currentUser]);

    const [activeField, setActiveField] = useState(1); //для мобильных устройств

    const [btnRadioDate, setBtnRadioDate] = useState(0);
    const [btnRadioBargain, setBtnRadioBargain] = useState(0);
    const [btnRadioCalculate, setBtnRadioCalculate] = useState(0);
    const [contactsInfo, setContactsInfo] = useState({
        id: '',
        phone: '',
        firstName: '',
    });
    // const [contactsArray, setContactsArray] = useState([]);
    let [data, setData] = useState<any>({
        userId: currentUser.id,
        bargainType: 0,
        calculateType: 0,
        contacts: [contactsInfo],
        dateType: 0,
        prepayment: 0,
    });

    const [selectDays, setSelectDays] = useState(null);
    const [selectPeriodType, setSelectPeriodType] = useState(null);
    const [selectCar, setSelectCar] = useState(null);

    const [valid, setValid] = useState(fields);

    const [cars, setCars] = useState([]);
    useEffect(() => {
        getCars(axiosPrivate, currentUser?.id, 1)
            .then((res) => setCars(res?.data?.map((i) => ({ value: i.id, label: i.name }))))
            .catch((error) => Logger.error(error));
    }, [currentUser]);

    //UPLOAD FOR EDIT.PAGE
    const [curRoute, setCurRoute] = useState<any>();
    useEffect(() => {
        adRouteId &&
            getRoutePage(adRouteId, axiosPrivate)
                .then((res) => setCurRoute(res?.data?.body))
                .catch((error) => Logger.error(error));
    }, [adRouteId]);
    useEffect(() => {
        if (curRoute) {
            setData((prevState) => ({
                ...prevState,
                userId: currentUser.id,
                //@ts-ignore
                fromRoute: curRoute?.fromRoute,
                //@ts-ignore
                loadingRadius: curRoute?.loadingRadius,
                //@ts-ignore
                toRoute: curRoute?.toRoute,
                //@ts-ignore
                unloadingRadius: curRoute?.unloadingRadius,
                //@ts-ignore
                dateForInput: curRoute?.date,
                //@ts-ignore
                dateDays: curRoute?.dateDays,
                //@ts-ignore
                dateType: Number(curRoute?.dateType),
                //@ts-ignore
                datePeriodType: curRoute?.datePeriodType,
                //@ts-ignore
                bargainType: curRoute?.bargainType,
                //@ts-ignore
                calculateType: curRoute?.calculateType,
                //@ts-ignore
                noVatPrice: curRoute?.noVatPrice,
                //@ts-ignore
                prepayment: curRoute?.prepayment,
                //@ts-ignore
                contacts: curRoute?.contacts,
                //@ts-ignore
                note: curRoute?.note,
                //@ts-ignore
                carId: curRoute?.carId,
            }));

            setBtnRadioDate(Number(curRoute?.dateType));

            setBtnRadioCalculate(Number(curRoute?.calculateType));

            setBtnRadioBargain(Number(curRoute?.bargainType));

            setSelectCar({ value: curRoute?.carId, label: curRoute?.carName });

            setSelectPeriodType({ value: curRoute?.datePeriodType, label: curRoute?.datePeriodTypeForUser });

            setSelectDays({ value: curRoute?.dateDays, label: `${curRoute?.dateDays} дн.` });
        }
    }, [curRoute, currentUser]);

    //MODIFY
    useEffect(() => {
        setData((prevState) => ({ ...prevState, contacts: [contactsInfo] }));
    }, [contactsInfo]);

    useEffect(() => {
        getAndSetDate(data?.dateForInput);
    }, [data?.dateForInput]);

    useEffect(() => {
        //@ts-ignore
        setData((prevState) => ({ ...prevState, vatPrice: vatPrice(data?.noVatPrice) }));
        //@ts-ignore
    }, [data?.noVatPrice]);
    useEffect(() => {
        data?.date === 'Invalid Date' && delete data?.date;
    }, [data?.date]);

    useEffect(() => {
        if (data.dateType === 1) {
            delete data.date;

            delete data.dateDays;
        } else {
            delete data.datePeriodType;
        }
    }, [data]);

    useEffect(() => {
        setSelectCar({ value: data?.carId, label: data?.carName });
        setSelectDays({
            value: data?.dateDays,
            label: `${data?.dateDays} дн.`,
        });
    }, [data]);

    useEffect(() => {
        setSelectPeriodType({
            value: data?.datePeriodType,

            label: data?.datePeriodTypeForUser,
        });
    }, [data?.datePeriodType, data?.datePeriodTypeForUser]);

    const isInValidFromRoute =
        data?.fromRoute === undefined || data?.fromRoute?.length < 2 || data?.fromRoute?.length > 50;

    const isInValidToRoute = data?.toRoute === undefined || data?.toRoute?.length < 2 || data?.toRoute?.length > 50;

    const isInValidDateType = data?.dateType === undefined;

    const isInValidCar = data?.carId === undefined;

    const isInValidPrepayment = data?.prepayment === undefined || data?.prepayment > 100 || data?.prepayment < 0;

    const isInValidPhone =
        data?.contacts?.map((i) => i.phone)[0].replace(/\s/g, '').length > 12 ||
        !data?.contacts
            ?.map((i) => i.phone)[0]
            .replace(/\s/g, '')
            .includes('+7') ||
        data?.contacts?.map((i) => i.phone)[0].replace(/\s/g, '').length <= 11;

    const isInValidFirstName =
        data?.contacts?.map((i) => i.firstName)[0].replace(/\s/g, '').length > 50 ||
        data?.contacts?.map((i) => i.firstName)[0].replace(/\s/g, '').length < 2;

    const onSubmit = (e) => {
        e.preventDefault();

        if (isInValidFromRoute) {
            setValid({ ...valid, isInValidFromRoute: true });
        } else if (isInValidToRoute) {
            setValid({ ...valid, isInValidToRoute: true });
        } else if (isInValidDateType) {
            setValid({ ...valid, isInValidDateType: true });
        } else if (isInValidCar) {
            setValid({ ...valid, isInValidCar: true });
        } else if (isInValidPrepayment) {
            setValid({ ...valid, isInValidPrepayment: true });
        } else if (isInValidPhone) {
            setValid({ ...valid, isInValidPhone: true });
        } else if (isInValidFirstName) {
            setValid({ ...valid, isInValidFirstName: true });
        } else {
            try {
                //@ts-ignore
                if (data?.dateType === 1) {
                    //@ts-ignore
                    delete data?.date;
                    //@ts-ignore
                    delete data?.dateDays;
                } else {
                    //@ts-ignore
                    delete data?.datePeriodType;
                }
                if (adRouteId) {
                    updateRoute(adRouteId, data, axiosPrivate)
                        .then(() => {
                            dispatch(setAlert('success', 'Маршрут успешно отредактирован'));
                            setTimeout(() => {
                                navigate(getURL(appRoutes.OFFICE_ROUTES));
                            }, 500);
                        })
                        .catch(() => {
                            setShowModalValidation(true);

                            dispatch(setAlert('danger', 'Произошла ошибка'));
                        });
                } else {
                    createRoute(data, axiosPrivate)
                        .then(() => {
                            dispatch(setAlert('success', 'Маршрут успешно добавлен'));
                            navigate(getURL(appRoutes.OFFICE_ROUTES));
                        })
                        .catch((error) => {
                            if (error && error?.response?.data?.code === 'TARIFF_EXPIRED') {
                                dispatch(setAlert('danger', 'Тариф не оплачен, либо истек срок его действия'));
                            } else {
                                setShowModalValidation(true);
                                setIsShowAlert(true);

                                dispatch(setAlert('danger', 'Что-то пошло не так'));
                            }
                        });
                }
            } catch (error) {
                Logger.error(error);
            }
        }
    };

    const onReset = () => {
        setData({
            userId: currentUser?.id,

            dateType: 0,
            bargainType: 0,
            calculateType: 0,
            prepayment: 0,
        });
        // setContactsArray([]);
    };

    const getAndSetDate = (dateMe) => {
        const re = getDate(dateMe);
        setData((prevState) => ({
            ...prevState,
            date: re,
        }));
        return re;
    };

    const findCar = (carId) => {
        const find = cars?.find((i) => i.value === carId);
        return <span>{find?.label}</span>;
    };

    const resetFieldVal = (newState, field) => {
        setValid({ ...valid, [field]: false });
    };

    const saveTemplate = () => {
        const isInValidNameTemplate =
            dataTemplate?.templateName === undefined ||
            dataTemplate?.templateName?.length < 2 ||
            dataTemplate?.templateName?.length > 50;

        if (isInValidNameTemplate) {
            setValid({ ...valid, isInValidNameTemplate: true });
        } else if (isInValidFromRoute) {
            setValid({ ...valid, isInValidFromRoute: true });
            setShowModalSave(false);
        } else if (isInValidToRoute) {
            setValid({ ...valid, isInValidToRoute: true });
            setShowModalSave(false);
        } else if (isInValidDateType) {
            setValid({ ...valid, isInValidDateType: true });
            setShowModalSave(false);
        } else if (isInValidCar) {
            setValid({ ...valid, isInValidCar: true });
            setShowModalSave(false);
        } else if (isInValidPrepayment) {
            setValid({ ...valid, isInValidPrepayment: true });
            setShowModalSave(false);
        } else if (isInValidPhone) {
            setValid({ ...valid, isInValidPhone: true });
            setShowModalSave(false);
        } else if (isInValidFirstName) {
            setValid({ ...valid, isInValidFirstName: true });
            setShowModalSave(false);
        } else {
            try {
                Logger.log(`saveTemplate1`);

                if (data?.dateType === 1) {
                    delete data?.date;

                    delete data?.dateDays;
                } else {
                    delete data?.datePeriodType;
                }
                saveRouteTemplate(axiosPrivate, data, dataTemplate, )
                    .then(() => {
                        setIsShowAlert(true);
                        setAlertForSavePattern(true);
                        getRouteTemplates(axiosPrivate, currentUser?.id, 1)
                            .then((r) => setTemplates(r.data?.body?.data))
                            .catch((error) => Logger.error(error));
                    })
                    .catch(() => {
                        setAlertForSavePattern(false);
                        setIsShowAlert(true);
                    });
            } catch (error) {
                Logger.error(error);
            }
        }
    };

    const onDeleteTemplate = async (id) => {
        await deleteTemplate(id, axiosPrivate);

        await getRouteTemplates(axiosPrivate, currentUser?.id, 1)
            .then((r) => setTemplates(r.data?.body?.data))
            .catch((error) => Logger.error(error));
    };

    const loadOptions = async (searchKey) => {
        //@ts-ignore
        const defaultValue = data?.carId;
        setSelectCar(cars?.find((item) => item.value === defaultValue));

        if (!searchKey) {
            return await cars;
        } else {
            return await cars?.filter((item) => item.label.includes(searchKey));
        }
    };

    const loadOptions2 = async (searchKey) => {
        const defaultValue = data?.datePeriodType;
        setSelectPeriodType(optionsLoadingPeriodType?.find((item) => item.value === defaultValue));

        if (!searchKey) {
            return await optionsLoadingPeriodType;
        } else {
            return await optionsLoadingPeriodType?.filter((item) => item.label.includes(searchKey));
        }
    };

    const loadOptions3 = async (searchKey) => {
        const defaultValue = data?.dateDays;
        setSelectDays(optionsLoadingDays?.find((item) => item.value === defaultValue));

        if (!searchKey) {
            return await optionsLoadingDays;
        } else {
            return await optionsLoadingDays?.filter((item) => item.label.includes(searchKey));
        }
    };

    const formFields = [
        {
            title: 'Маршрут',
            name: 'route',
            content: (
                <AdRouteForm
                    data={data}
                    setData={setData}
                    valid={valid}
                    resetFieldVal={resetFieldVal}
                    onInputHandler={onInputHandler}
                />
            ),
        },
        {
            title: 'Дата',
            name: 'route',
            content: (
                <AdDateForm
                    data={data}
                    setData={setData}
                    valid={valid}
                    resetFieldVal={resetFieldVal}
                    loadOptions2={loadOptions2}
                    loadOptions3={loadOptions3}
                    onRadioHandler={onRadioHandler}
                    btnRadioDate={btnRadioDate}
                    setBtnRadioDate={setBtnRadioDate}
                    getAndSetDate={getAndSetDate}
                    selectDays={selectDays}
                    setSelectDays={setSelectDays}
                    selectPeriodType={selectPeriodType}
                    setSelectPeriodType={setSelectPeriodType}
                />
            ),
        },
        {
            title: 'Машина',
            name: 'route',
            content: (
                <AdCarSelectForm
                    setData={setData}
                    valid={valid}
                    resetFieldVal={resetFieldVal}
                    loadOptions={loadOptions}
                    cars={cars}
                    selectCar={selectCar}
                    setSelectCar={setSelectCar}
                />
            ),
        },
        {
            title: 'Оплата',
            name: 'route',
            content: (
                <AdPaymentForm
                    data={data}
                    setData={setData}
                    resetFieldVal={resetFieldVal}
                    valid={valid}
                    btnRadioBargain={btnRadioBargain}
                    setBtnRadioBargain={setBtnRadioBargain}
                    btnRadioCalculate={btnRadioCalculate}
                    setBtnRadioCalculate={setBtnRadioCalculate}
                />
            ),
        },
        {
            title: 'Контакты',
            name: 'route',
            content: (
                <AdContactsForm
                    data={data}
                    setData={setData}
                    resetFieldVal={resetFieldVal}
                    valid={valid}
                    setContactsInfo={setContactsInfo}
                />
            ),
        },
    ];

    return (
        <main className="bg-gray bg-transparent">
            <section
                id="sec-9"
                className="container pt-4 pt-sm-5 py-lg-5 box shadow"
                style={{ marginTop: '3rem', marginBottom: '3rem' }}
            >
                <NavLink to={getURL(appRoutes.OFFICE_ROUTES)} className="fs-12 fw-5 d-block mb-3 mb-sm-5">
                    <span className="green fs-15 me-2">⟵</span> Назад
                </NavLink>
                <h1 className="dark-blue text-center text-uppercase">{`${
                    adRouteId ? 'Редактирование' : 'Добавление'
                } Маршрута`}</h1>
                <form
                    className="row"
                    onSubmit={onSubmit}
                    //@ts-ignore
                    onReset={(e) => onReset(e)}
                    noValidate
                >
                    <div className="col-lg-8">
                        <div className="mobile-indicators d-flex d-lg-none">
                            <button
                                type="button"
                                //@ts-ignore
                                style={{ background: data?.toRoute && data?.fromRoute && '#01BFC4' }}
                                onClick={() => setActiveField(1)}
                            >
                                1
                            </button>
                            <button
                                type="button"
                                //@ts-ignore
                                style={{ background: (data?.date || data?.datePeriodType) && '#01BFC4' }}
                                onClick={() => setActiveField(2)}
                            >
                                2
                            </button>
                            <button
                                type="button"
                                //@ts-ignore
                                style={{ background: data?.carId && '#01BFC4' }}
                                onClick={() => setActiveField(3)}
                            >
                                3
                            </button>
                            <button
                                type="button"
                                //@ts-ignore
                                style={{ background: data?.prepayment ? '#01BFC4' : '' }}
                                onClick={() => setActiveField(4)}
                            >
                                4
                            </button>
                            <button
                                type="button"
                                //@ts-ignore
                                style={{ background: data?.contacts?.find((i) => i.phone && i.firstName) && '#01BFC4' }}
                                onClick={() => setActiveField(5)}
                            >
                                5
                            </button>
                        </div>

                        {formFields.map((f, idx) => (
                            <fieldset name={f.name} data-show={activeField === idx + 1 ? 'true' : 'false'}>
                                <h4 className="text-center text-lg-start mb-4 mb-lg-3 mt-lg-3">{f.title}</h4>
                                {f.content}
                                <MobileButtons
                                    setShowUseTemplate={setShowUseTemplate}
                                    setShowModalSave={setShowModalSave}
                                    setActiveField={setActiveField}
                                    activeField={idx + 1}
                                    onReset={onReset}
                                />
                            </fieldset>
                        ))}

                        <div className="d-none d-lg-block title-font fs-09 fw-5 mt-3">
                            * Поля обязательные к заполнению
                        </div>
                    </div>
                    <div className="col-lg-4 pt-lg-5 position-relative d-none d-lg-block">
                        <RightBlock
                            adRouteId={adRouteId}
                            data={data}
                            findCar={findCar}
                            setShowModalSave={setShowModalSave}
                            setShowUseTemplate={setShowUseTemplate}
                            onReset={onReset}
                        />
                    </div>
                </form>
            </section>
            <RouteTemplateSaveModal
                showModalSave={showModalSave}
                setShowModalSave={setShowModalSave}
                alertForSavePattern={alertForSavePattern}
                isShowAlert={isShowAlert}
                setDataTemplate={setDataTemplate}
                resetFieldVal={resetFieldVal}
                saveTemplate={saveTemplate}
                valid={valid}
            />
            <RouteTemplateSelectModal
                isShow={showUseTemplate}
                setIsShow={setShowUseTemplate}

                templates={templates}
                setData={setData}
                setBtnRadioDate={setBtnRadioDate}
                setBtnRadioCalculate={setBtnRadioCalculate}
                setBtnRadioBargain={setBtnRadioBargain}
                setSelectCar={setSelectCar}
                setSelectPeriodType={setSelectPeriodType}
                setSelectDays={setSelectDays}
                onDeleteTemplate={onDeleteTemplate}
            />
            <AdRequiredFieldsModal
                showModalValidation={showModalValidation}
                setShowModalValidation={setShowModalValidation}
            />
        </main>
    );
};
