import CustomModal from '@shared/ui/CustomModal';

export const AdRequiredFieldsModal = ({ showModalValidation, setShowModalValidation }) => {
    return <CustomModal
        className="modal__routeErrorValid"
        isShow={showModalValidation}
        setIsShow={setShowModalValidation}
        closeButton={true}
        size={'lg'}
    >
        <div>
            <div className="text-center">
                <span className="fs-12">Для размещения объявления заполните все обязательные поля</span>
                <div className="mt-4">
                    <button
                        className="btn btn-2 w-100 h-100 fs-11 text-uppercase px-3"
                        type="button"
                        onClick={() => setShowModalValidation(false)}
                    >
                        ПОНЯТНО
                    </button>
                </div>
            </div>
        </div>
    </CustomModal>;
};
