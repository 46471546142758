import { useAppSelector } from '@core/store/hooks';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import { EEditorRoles } from '@entities/office/dealDocument/dealDocument.types';
import { getChanges } from '../dealDocument.actions';
import { selectDocModel } from '../dealDocument.selectors';

export const useEditor = () => {
    const currentDeal = useAppSelector(selectCurrentDeal);
    const currentUser = useAppSelector(selectCurrentUser);
    const docModel = useAppSelector(selectDocModel);

    const editorRole = currentUser?.id === currentDeal?.contractorId ? EEditorRoles.CONTRACTOR : EEditorRoles.CUSTOMER;

    return Object.assign(
        {
            editorRole,
        },
        getChanges(docModel, editorRole),
    );
};
