
import { Controller } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { IoHelpCircleOutline } from 'react-icons/io5';
import AsyncSelect from 'react-select/async';

import ValidateWrapper from '@shared/ui/ValidateWrapper';

export const CarForm = ({ control, register, loadOptions, carTypes, selectValue, setSelectValue, errors, responseErrors }) => {
    return (
        <div className="box">
            <div className="row align-items-center mb-4">
                <div className="col-sm-5 col-md-3">
                    <div data-label="name" data-warning="false" className="title-font fs-12 fw-5 mb-2 mb-sm-0">
                        Марка машины*
                    </div>
                </div>
                <div className="col-sm-7 col-md-9">
                    <div className="row">
                        <div className="col-md-12">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.name}>
                                <input
                                    placeholder="Укажите марку машины..."
                                    className="weight w-100 fs-12"
                                    {...register('name', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <div data-label="carType" data-warning="false" className="title-font fs-12 fw-5">
                        Тип машины*
                    </div>
                </div>
                <div className="col-md-9">
                    {/* @ts-ignore */}
                    <ValidateWrapper error={errors?.carBodyTypeId}>
                        <Controller
                            control={control}
                            name="carBodyTypeId"
                            render={({ field }) => (
                                <AsyncSelect
                                    className="fs-12 w-100"
                                    classNamePrefix="react-select"
                                    placeholder={'Выберите...'}
                                    loadOptions={loadOptions}
                                    defaultOptions={carTypes}
                                    value={selectValue && carTypes.find((item) => item.value === selectValue.value)}
                                    onChange={(val) => {
                                        setSelectValue({
                                            value: val.value,
                                            label: val.label,
                                        });
                                        field.onChange(val.value);
                                    }}
                                />
                            )}
                            rules={{ required: 'выберите тип машины' }}
                        />
                    </ValidateWrapper>
                    <ValidateWrapper error={errors?.additionalConfiguration} className="mt-3">
                        <div
                            data-label="additionalConfiguration"
                            data-warning="false"
                            className="row row-cols-sm-3 mt-3"
                        >
                            <div className="mb-3 mb-sm-0">
                                <label>
                                    <input
                                        type="radio"
                                        value={0}
                                        {...register('additionalConfiguration', {
                                            required: 'поле обязательно к заполнению',
                                        })}
                                    />
                                    <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Грузовик</span>
                                </label>
                            </div>
                            <div className="mb-3 mb-sm-0">
                                <label>
                                    <input
                                        type="radio"
                                        value={1}
                                        {...register('additionalConfiguration', {
                                            required: 'поле обязательно к заполнению',
                                        })}
                                    />
                                    <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Полуприцеп</span>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        value={2}
                                        {...register('additionalConfiguration', {
                                            required: 'поле обязательно к заполнению',
                                        })}
                                    />
                                    <span className="title-font fs-12 fw-5 ms-2 ms-xl-3">Сцепка</span>
                                </label>
                            </div>
                        </div>
                    </ValidateWrapper>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-5 col-md-3">
                    <div data-label="carrying" data-warning="false" className="title-font fs-12 fw-5 mb-2 mb-sm-0">
                        Грузоподъемность*
                    </div>
                </div>
                <div className="col-sm-7 col-md-9">
                    <div className="row">
                        <div className="col-md-4">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.carrying || responseErrors?.carrying}>
                                <input
                                    type="number"
                                    min="1"
                                    placeholder="0"
                                    className="weight w-100 fs-12"
                                    {...register('carrying', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-5 col-md-3">
                    <div data-label="capacity" data-warning="false" className="title-font fs-12 fw-5 mb-2 mb-sm-0">
                        Объем*
                    </div>
                </div>
                <div className="col-sm-7 col-md-9">
                    <div className="row">
                        <div className="col-md-4">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.capacity}>
                                <input
                                    type="number"
                                    min="1"
                                    placeholder="0"
                                    className="size w-100 fs-12"
                                    {...register('capacity', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-md-3 mb-3 mb-md-0">
                    <div className="title-font fs-12 fw-5">Габариты*</div>
                </div>
                <div className="col-md-9">
                    <div
                        className={`row row-cols-sm-3 gx-3 gx-xxl-4 fs-12 ${
                            errors?.length || errors?.width || errors?.height ? 'dimensions-validation' : ''
                        }`}
                    >
                        <div className="mb-2 mb-sm-0">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.length || responseErrors?.length}>
                                <div className="row gx-2 align-items-center">
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-2'
                                                : 'col-3 col-sm-5'
                                        }
                                    >
                                        <label data-label="length" data-warning="false">
                                            Длина:
                                        </label>
                                    </div>
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-10'
                                                : 'col-9 col-sm-7'
                                        }
                                    >
                                        <input
                                            type="number"
                                            min="1"
                                            step="0.1"
                                            placeholder="0"
                                            className="length"
                                            {...register('length', {
                                                required: 'поле обязательно к заполнению',
                                            })}
                                        />
                                    </div>
                                </div>
                            </ValidateWrapper>
                        </div>
                        <div className="mb-2 mb-sm-0">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.width}>
                                <div className="row gx-2 align-items-center">
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-2'
                                                : 'col-3 col-sm-5'
                                        }
                                    >
                                        <label data-label="width" data-warning="false">
                                            Ширина:
                                        </label>
                                    </div>
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-10'
                                                : 'col-9 col-sm-7'
                                        }
                                    >
                                        <input
                                            type="number"
                                            min="1"
                                            step="0.1"
                                            placeholder="0"
                                            className="length"
                                            {...register('width', {
                                                required: 'поле обязательно к заполнению',
                                            })}
                                        />
                                    </div>
                                </div>
                            </ValidateWrapper>
                        </div>
                        <div className="mb-2 mb-sm-0">
                            {/* @ts-ignore */}
                            <ValidateWrapper error={errors?.height}>
                                <div className="row gx-2 align-items-center">
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-2'
                                                : 'col-3 col-sm-5'
                                        }
                                    >
                                        <label data-label="height" data-warning="false">
                                            Высота:
                                        </label>
                                    </div>
                                    <div
                                        className={
                                            errors?.length || errors?.width || errors?.height
                                                ? 'col-10'
                                                : 'col-9 col-sm-7'
                                        }
                                    >
                                        <input
                                            type="number"
                                            min="1"
                                            step="0.1"
                                            placeholder="0"
                                            className="length"
                                            {...register('height', {
                                                required: 'поле обязательно к заполнению',
                                            })}
                                        />
                                    </div>
                                </div>
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div className="title-font fs-12 fw-5 d-flex align-items-center">
                        <span data-label="sts" data-warning="false">
                            СТС
                        </span>
                        <button
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="внесенные сведения ТС не подлежат разглашению третьим лицам"
                        >
                            <IconContext.Provider value={{ className: 'ms-2 blue icon-15' }}>
                                <IoHelpCircleOutline />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-md-6">
                            {/* @ts-ignore */}
                            <ValidateWrapper
                                error={
                                    errors?.sts ||
                                    (responseErrors?.sts ? { message: 'значение должно быть уникальным' } : null)
                                }
                            >
                                <input
                                    type="text"
                                    placeholder="sts"
                                    className="w-100 fs-12"
                                    {...register('sts', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center mb-4">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div className="title-font fs-12 fw-5 d-flex align-items-center">
                        <span data-label="vin" data-warning="false">
                            VIN код
                        </span>
                        <button
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="внесенные сведения ТС не подлежат разглашению третьим лицам"
                        >
                            <IconContext.Provider value={{ className: 'ms-2 blue icon-15' }}>
                                <IoHelpCircleOutline />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-md-6">
                            {/* @ts-ignore */}
                            <ValidateWrapper
                                error={
                                    errors?.vin ||
                                    (responseErrors?.vin ? { message: 'значение должно быть уникальным' } : null)
                                }
                            >
                                <input
                                    type="text"
                                    placeholder="VIN код"
                                    className="w-100 fs-12"
                                    {...register('vin', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-sm-3 mb-2 mb-sm-0">
                    <div className="title-font fs-12 fw-5 d-flex align-items-center">
                        <span data-label="pts" data-warning="false">
                            ПТС
                        </span>
                        <button
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="внесенные сведения ТС не подлежат разглашению третьим лицам"
                        >
                            <IconContext.Provider value={{ className: 'ms-2 blue icon-15' }}>
                                <IoHelpCircleOutline />
                            </IconContext.Provider>
                        </button>
                    </div>
                </div>
                <div className="col-sm-9">
                    <div className="row">
                        <div className="col-md-6">
                            {/* @ts-ignore */}
                            <ValidateWrapper
                                error={
                                    errors?.pts ||
                                    (responseErrors?.pts ? { message: 'значение должно быть уникальным' } : null)
                                }
                            >
                                <input
                                    type="text"
                                    placeholder="ПТС"
                                    className="w-100 fs-12"
                                    {...register('pts', {
                                        required: 'поле обязательно к заполнению',
                                    })}
                                />
                            </ValidateWrapper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
