export const isExistAndNotEmpty = (obj) => {
    if (obj) {
        if (Array.isArray(obj)) {
            return obj.length === 0 ? false : true;
        } else if (typeof obj === 'object') {
            return Reflect.ownKeys(obj).length ? true : false;
        } else return true;
    } else return false;
};

export const getUniqueObjectsArray = (initialArray) => {
    const newArray = [];
    const uniqueLabels = [];

    initialArray.forEach((item) => {
        if (!uniqueLabels.includes(item.label)) {
            uniqueLabels.push(item.label);
            newArray.push(item);
        }
    });

    return newArray;
};

export const getUniqueArrayElements = (array) => Array.from(new Set(array));

export const deepCopy = <T>(object: T): T => JSON.parse(JSON.stringify(object) || null);

const genArrayNumbers = (range: number): number[] => {
    const startZeroArray = [...Array(range).keys()];
    startZeroArray.push(range);
    return startZeroArray.slice(1, range + 1);
};

export const numberRange = (range: number | [number, number]): number[] =>
    typeof range === 'number' ? genArrayNumbers(range) : genArrayNumbers(range[1]).slice(range[0] - 1, range[1]);
export const justRange = (range: number) => [...Array(range)];

//AS LODASH
export const range = (start, end) => [...Array(end || 1).keys()].slice(start || 0, end || 1);
