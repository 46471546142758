import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import ArticleCard from '@features/news/News.Article.Card';
import Loader from '@shared/ui/Loader';

SwiperCore.use([Navigation, Pagination]);

export default function NewsArticleCarousel({swiperNews}){
    return swiperNews.isLoading ? (
        swiperNews?.items?.length ? (
            <Swiper
                className="swiper-4"
                spaceBetween={4}
                slidesPerView={2}
                freeMode={true}
                breakpoints={{
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 10,
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 8,
                    },
                    992: {
                        slidesPerView: 4,
                        spaceBetween: 8,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 16,
                    },
                    1400: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                    },
                }}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                }}
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                {swiperNews.items.map((item) => (
                    <SwiperSlide key={item.id}>
                        <ArticleCard
                            key={item.id}
                            url={`/news/${item.slug}`}
                            title={item.title}
                            img={`${item.image}`}
                            text={item.description}
                            date={item.createdAt}
                        />
                    </SwiperSlide>
                ))}
                <div className="swiper-button-prev">
                    <IoChevronBackSharp />
                </div>
                <div className="swiper-button-next">
                    <IoChevronForwardSharp />
                </div>
                <div className="swiper-pagination" />
            </Swiper>
        ) : null
    ) : (
        <div className="d-flex justify-content-center">
            <Loader color="#545454" />
        </div>
    );
};
