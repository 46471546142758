import { useState } from 'react';

function usePagination(initialPageLimit) {
    const [pageLimit, setPageLimit] = useState(initialPageLimit);
    const [currentPage, setCurrentPage] = useState(1);
    const [startingPage, setStartingPage] = useState(1);

    return { pageLimit, setPageLimit, currentPage, setCurrentPage, startingPage, setStartingPage };
}
export default usePagination;
