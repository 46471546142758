
import ArticleCard from '@features/news/News.Article.Card';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';

export default function NewsArticleTiles({ news, newsPagination }) {
    return (
        <>
            {news.isLoaded ? (
                news?.items?.length ? (
                    <div className="row row-cols-2 row-cols-lg-3 gx-2 gx-xl-3 gy-4 gy-lg-5">
                        {news.items.map((item) => (
                            <div key={item.id}>
                                <ArticleCard
                                    url={`/news/${item.slug}`}
                                    title={item.title}
                                    img={`${item.image}`}
                                    text={item.description}
                                    date={item.createdAt}
                                />
                            </div>
                        ))}
                    </div>
                ) : null
            ) : (
                <div className="d-flex justify-content-center">
                    <Loader color="#545454" />
                </div>
            )}
            <div className="mt-4">
                {news?.items?.length > 0 && (
                    <Pagination
                        pageLimit={newsPagination.pageLimit}
                        currentPage={newsPagination.currentPage}
                        setCurrentPage={newsPagination.setCurrentPage}
                        pagesDisplayedLimit={3}
                        itemsAmount={news?.meta?.total || 0}
                        startingPage={newsPagination.startingPage}
                        setStartingPage={newsPagination.setStartingPage}
                    />
                )}
            </div>
        </>
    );
}
