
import { useParams } from 'react-router-dom';
import { AdRoutePageContent } from '@features/market/route/Route.FormPageContent';

const RouteEditPage = () => {
    const { id } = useParams();

    return <AdRoutePageContent adRouteId={id} />;
};
export default RouteEditPage;
