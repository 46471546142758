export const fields = {
    isInValidFromRoute: false,
    isInValidToRoute: false,
    isInValidDateType: false,
    isInValidCar: false,
    isInValidPrepayment: false,
    isInValidPhone: false,
    isInValidFirstName: false,
    isInValidNameTemplate: false,
    isInValidForSave: false,
};
