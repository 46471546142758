import { apiRoutes, getAxiosPrivate } from '@core/config/api';
import { useActions } from '@core/store/hooks';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

const useRefreshToken = () => {
    const { setCurrentUser } = useActions();

    const refreshToken = async () => {
        try {
            const response = await (await getAxiosPrivate()).get(getURL(apiRoutes.APP_API_URL, apiRoutes.REFRESH_TOKEN));
            const { token, user } = response.data.body;
            const payload = { token, user };

            setCurrentUser(payload);
            return token;
        } catch (error) {
            Logger.error(error);
            return;
        }
    };

    return refreshToken;
};
export default useRefreshToken;
