import axios from 'axios';

import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

//ROUTE
export const createRoute = async (payload, axiosPrivate) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_ACTIONS), payload);
        return response;
    } catch (error) {
        Logger.error(error);
    }
};

export const getRoutePage = async (id, axiosPrivate) => {
    try {
        const response = axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_ACTIONS, id));
        return response;
    } catch (error) {}
};

export const updateRoute = async (id, data, axiosPrivate) => {
    try {
        const response = axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_ACTIONS, id), data);
        return response;
    } catch (error) {
        Logger.error(error);
    }
};

export const deleteRoute = async (id, axiosPrivate) => {
    try {
        return axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_ACTIONS, id));
    } catch (error) {
        Logger.error(error);
    }
};

export const reportRoute = async (axiosPrivate, payloads) => {
    try {
        return axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.REPORT_ROUTE), payloads);
    } catch (error) {
        Logger.error(error);
    }
};

//ROUTES
export const paginateRoutes = async (city, page, limit) => {
    try {
        const response = axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_PAGINATE, city), { page, limit });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
export const unArchivedRoutes = async (axiosPrivate, routeAdId) => {
    try {
        return axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_UNARCHIVE, routeAdId));
    } catch (error) {
        Logger.error(error);
    }
};
export const getArchiveRoutes = async (limit, page, userId, axiosPrivate) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_ARCHIVE, userId), {
            page,
            limit,
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
export const searchRoute = async (page, limit, payloads = {}) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_SEARCH), {
            page,
            limit,
            ...payloads,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};
export const getUserRoutes = async (axiosPrivate, userId, page, limit) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_NOT_ARCHIVE, userId), {
            limit,
            page,
            orderBy: 'desc',
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
export const getCountRoutes = async () => {
    try {
        const response = axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.ROUTE_COUNT));
        return response;
    } catch (error) {
        Logger.error(error);
    }
};

//TAMPLATES
export const updateTemplateRouteName = async (axiosPrivate, idTemplate, userId, data) => {
    try {
        const response = axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.TEMPLATE_UPDATE_NAME, idTemplate), {
            userId,
            ...data,
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
export const saveRouteTemplate = async (axiosPrivate, data, dataTemplate ) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TEMPLATE_ROUTE_SAVE), {
            ...data,
            ...dataTemplate,
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
export const getRouteTemplates = async (axiosPrivate, userId, page, limit=5) => {
    try {
        const response = axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TEMPLATES_ROUTE_GET, userId), {
            page,
            limit,
            orderBy: 'desc',
        });
        return response;
    } catch (error) {
        Logger.error(error);
    }
};
