import {
    EDataTypes,
    IDocRenderElementData,
    IDocRenderElementProps,
} from '@entities/office/dealDocument/dealDocument.types';

import { useActions, useAppSelector } from '@core/store/hooks';
import { getCell, inputFunctions, setCellEditedState } from '@entities/office/dealDocument/dealDocument.actions';
import { useEditor } from '@entities/office/dealDocument/hooks/document.useEditor';
import {
    selectDocEditedState,
    selectDocIsClosed,
    selectEditedCell,
    selectStartedCell,
} from '@entities/office/dealDocument/dealDocument.selectors';
import Logger from '@shared/lib/logger';
import { useEffect } from 'react';
import { isExistAndNotEmpty } from '@shared/lib/objects';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import store from '@core/store';

// const inputEventsTypes = {
//     CHANGE: 'Inputs:change',
// };

export const DealDocumentInputElement = ({ node }: IDocRenderElementProps) => {
    const { editorRole, lastChangesPartner } = useEditor();
    const docIsClosed = useAppSelector(selectDocIsClosed);

    const data: IDocRenderElementData = {};
    data.valueCell = node.cell || node.order;
    data.dataType = node.config?.dataType;
    data.options = node.config?.options;
    data.isClosed =
        docIsClosed || node?.isClosed || (Number.isFinite(Number(node.privacyRole)) && node.privacyRole !== editorRole);

    //ЗНАЧЕНИЯ
    const startedValue = useAppSelector(selectStartedCell(data.valueCell));
    const editedValue = useAppSelector(selectEditedCell(data.valueCell));

    useEffect(() => {
        if (node.defaultValue && !editedValue) {
            setCellEditedState(data.valueCell, node.defaultValue);
        }
    }, []);

    //ПРОВЕРКА ПРИ ОТКРЫТИИ ДОКУМЕНТА НА ПОЛНОЕ ЗАПОЛНЕНИЕ
    const { reduceDocIsFullFilled } = useActions();
    useEffect(() => {
        reduceDocIsFullFilled(Boolean(editedValue));
    }, []);

    Logger.log(`INPUT`, {
        order: node.order,
        valueCell: data.valueCell,
        dataType: data.dataType,
        isClosed: data.isClosed,
        privacyRole: node.privacyRole,
        startedValue,
        editedValue,
        node,
    });

    if (data.dataType === EDataTypes.SELECT) {
        return (
            <span
                style={Object.assign(
                    { display: 'inline-block', width: 200 },
                    node.config?.fontSize && { fontSize: node.config?.fontSize + 'rem' },
                )}
            >
                {node.config.creatable ? (
                    <CreatableSelect
                        classNamePrefix="react-select"
                        placeholder={'Выберите или напишите...'}
                        value={
                            editedValue
                                ? data.options.find((i) => i.value === editedValue) || {
                                      label: editedValue,
                                      value: editedValue,
                                  }
                                : ''
                        }
                        onChange={(e) => {
                            Logger.log({ e });
                            setCellEditedState(data.valueCell, e?.value || '');
                        }}
                        options={data.options}
                        isSearchable={true}
                        isClearable={true}
                        isDisabled={data.isClosed}
                    />
                ) : (
                    <Select
                        classNamePrefix="react-select"
                        placeholder={'Выберите...'}
                        value={
                            editedValue
                                ? data.options.find((i) => i.value === editedValue) || {
                                      label: editedValue,
                                      value: editedValue,
                                  }
                                : ''
                        }
                        onChange={(e) => {
                            Logger.log({ e });
                            setCellEditedState(data.valueCell, e?.value || '');
                        }}
                        options={data.options}
                        isSearchable={true}
                        isClearable={true}
                        isDisabled={data.isClosed}
                    />
                )}
            </span>
        );
    } else if ([EDataTypes.TEXT, EDataTypes.DATE].includes(data.dataType)) {
        return (
            <span
                style={Object.assign(
                    { display: 'inline-block' },
                    node.config?.fontSize && { fontSize: node.config?.fontSize + 'rem' },
                )}
            >
                <input
                    type={data.dataType}
                    style={node.config?.width && { width: node.config?.width + 'px' }}
                    className={
                        (data.isClosed ? 'close ' : '') +
                        (editedValue
                            ? editedValue === startedValue
                                ? lastChangesPartner.includes(data.valueCell)
                                    ? 'filled-new'
                                    : 'filled'
                                : 'filled-current'
                            : '') +
                        ` form-control input-block`
                    }
                    value={(() => {
                        console.log({ editedValue });
                        let value = editedValue || '';
                        if (data.dataType === EDataTypes.DATE && editedValue) {
                            const valueSplit = editedValue.split('.');
                            value = [valueSplit[2], valueSplit[1], valueSplit[0]].join('-');
                            // value = new Date([valueSplit[1], valueSplit[0], valueSplit[2]].join('.'))
                            //     .toISOString()
                            //     .split('T')[0];
                        }
                        console.log({ value });
                        return value;
                    })()}
                    onChange={(e) => {
                        let value = e.target.value;

                        if (data.dataType === EDataTypes.DATE) {
                            console.log({ sourceValue: e.target.value });

                            value = e.target.value.split('-').reverse().join('.');

                            // let date = new Date(e.target.value);
                            // let formattedDate = date.toLocaleDateString('ru-RU', {
                            //     day: '2-digit',
                            //     month: '2-digit',
                            //     year: 'numeric',
                            // });
                            // value = formattedDate;

                            console.log(value);
                        }

                        setCellEditedState(data.valueCell, value);
                    }}
                    disabled={data.isClosed}
                />
            </span>
        );
    }
};

export const DealDocumentOutputElement = ({ node }: IDocRenderElementProps) => {
    const { editorRole, lastChangesPartner } = useEditor();
    const docIsClosed = useAppSelector(selectDocIsClosed);

    const isCompute = isExistAndNotEmpty(node?.compute);

    const data: IDocRenderElementData = {};
    data.valueCell = node.cell || node.order;
    data.dataType = node.config?.dataType;
    data.isClosed =
        docIsClosed ||
        node?.isClosed ||
        (Number.isFinite(Number(node.privacyRole)) && node.privacyRole !== editorRole) ||
        isCompute;

    //ЗНАЧЕНИЯ
    const startedValue = useAppSelector(selectStartedCell(data.valueCell));
    const editedValue = useAppSelector(selectEditedCell(data.valueCell));

    const editedState = useAppSelector(selectDocEditedState);

    useEffect(() => {
        Logger.log('OUTPUT EFFECT');
        const computeValue = () => {
            const values = node?.compute.args.map((arg) => {
                if (typeof arg === 'string') {
                    return getCell(editedState, arg);
                } else if (typeof arg === 'number') {
                    const vectorArr = node.order.split('.');
                    vectorArr[vectorArr.length - 1] = vectorArr
                        .at(-1)
                        .replace(/(\d+)/g, String(Number(vectorArr.at(-1).match(/(\d+)/g)) + arg));
                    return getCell(editedState, vectorArr.join('.'));
                }
                return null;
            });
            return String(inputFunctions[node?.compute?.function](...values));
        };

        isCompute && setCellEditedState(data.valueCell, computeValue());

        // const computeValue = async (event) => {
        //     inputFunctions[node?.compute?.function](
        //         ...node?.compute.args.map((arg) => {
        //             if (typeof arg === 'string') {
        //                 return getCell(store.getState().document.edit.editedState, arg);
        //             } else if (typeof arg === 'number') {
        //                 const vectorArr = node.order.split('.');
        //                 vectorArr[vectorArr.length - 1] = vectorArr
        //                     .at(-1)
        //                     .replace(/[0-9]/g, String(Number(vectorArr.at(-1).match(/(\d+)/g)) + arg));
        //                 return getCell(store.getState().document.edit.editedState, node.order.split('.'));
        //             }
        //         }),
        //         -9,
        //     );
        // };
        // if (isCompute) {
        //     document.addEventListener(inputEventsTypes.CHANGE, computeValue);
        // }
        // return () => {
        //     if (isCompute) {
        //         document.removeEventListener(inputEventsTypes.CHANGE, computeValue);
        //     }
        // };
    }, [editedState]);

    //ПРОВЕРКА ПРИ ОТКРЫТИИ ДОКУМЕНТА НА ПОЛНОЕ ЗАПОЛНЕНИЕ
    const { reduceDocIsFullFilled } = useActions();
    useEffect(() => {
        reduceDocIsFullFilled(Boolean(editedValue));
    }, []);

    Logger.log(`OUTPUT`, {
        order: node.order,
        valueCell: data.valueCell,
        dataType: data.dataType,
        isClosed: data.isClosed,
        privacyRole: node.privacyRole,
        startedValue,
        editedValue,
    });

    return (
        <span
            style={Object.assign(
                { display: 'inline-block' },
                node.config?.fontSize && { fontSize: node.config?.fontSize + 'rem' },
            )}
        >
            <input
                type={data.dataType}
                style={node.config?.width && { width: node.config?.width + 'px' }}
                className={
                    (data.isClosed ? 'close ' : '') +
                    (editedValue
                        ? editedValue === startedValue
                            ? lastChangesPartner.includes(data.valueCell)
                                ? 'filled-new'
                                : 'filled'
                            : 'filled-current'
                        : '') +
                    ` form-control input-block`
                }
                value={editedValue || ''}
                onChange={(e) => {
                    setCellEditedState(data.valueCell, e.target.value);
                }}
                disabled={data.isClosed}
            />
        </span>
    );
};
