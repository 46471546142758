import { apiRoutes } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

//Спиcor чатов
export const getChats = async (axiosPrivate, userId, page, limit, byDealOnly) => {
    try {
        const response = await axiosPrivate.post(
            getURL(apiRoutes.APP_API_URL, apiRoutes.APP_EVENT_FEED_CHATS, userId),
            {
                page,
                limit,
                orderBy: 'desc',
                byDealOnly,
            },
        );
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

export const getChat = async (axiosPrivate, feedId) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.APP_EVENT_FEED_CHATS, feedId));
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

//Спиcor событий
export const getAppEvents = async (axiosPrivate, feedId, page, limit) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.APP_EVENTS, feedId), {
            page,
            limit,
            orderBy: 'desc',
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

//Создать событие
export const postAppEvent = async (axiosPrivate, type, content, feedId, founderId) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.APP_EVENTS), {
            type,
            content,
            feedId,
            founderId,
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

export const getFeedIdFromDealId = async (axiosPrivate, dealId) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.APP_EVENT_FEEDS, dealId));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};
