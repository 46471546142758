export const getURL = (...listRoutes) =>
    listRoutes.reduce(
        (path, route, idx) =>
            path +
            (listRoutes?.[0] === ''
                ? [0, 1].includes(idx)
                    ? ''
                    : '/'
                : listRoutes?.[0]?.split(':').length > 1 && listRoutes?.[0]?.split(':')?.[1]?.slice(0, 2) === '//'
                ? idx === 0
                    ? ''
                    : '/'
                : '/') +
            route,
        '',
    ) || '/';
