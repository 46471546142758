import Joi from 'joi';
import { useState } from 'react';
import { useAppDispatch} from '@core/store/hooks';
import { Link, useNavigate } from 'react-router-dom';

import Assets from '@core/assets';
import {apiRoutes} from '@core/config/api';
import {appRoutes} from '@core/navigation';
import { handleRemeberMe } from '@entities/.app/account/account.actions';
import { apiResponseMessages } from '@entities/.app/account/account.data';
import { setCurrentUser } from '@entities/.app/account/account.slice';
import { getAxiosPrivate } from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import FormErrorMessage from '@shared/ui/FormErrorMessage';
import InputPassword from '@shared/ui/InputPassword';
import AccountInfoBlock from '@features/.app/App.InfoBlock';

const formValueDefault = {
    email: '',
    password: '',
};

const formErrorDefault = {
    email: '',
    password: '',
};

const logibFormSchema = Joi.object({
    email: Joi.string()
        .email({ tlds: { allow: false } })
        .min(4)
        .required()
        .messages({
            'string.empty': 'Email адрес не может быть пустым',
            'string.min': `Email адрес не может быть короче 4 символов`,
            'string.max': `Email адрес не может быть длиннее 20 символов`,
            'string.email': `Введите Email адрес корректного формата`,
        }),
    password: Joi.string()
        .pattern(/.*[A-Z].*/)
        .pattern(/.*[0-9].*/)
        .min(8)
        .required()
        .messages({
            'string.empty': 'Пароль не может быть пустым',
            'string.pattern.base': 'Пароль должен содержать одну заглавную букву и одну цифру',
            'string.min': `Пароль не может быть короче 8 символов`,
            'string.max': `Пароль не может быть длиннее 20 символов`,
        }),
});

const LoginForm = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [formValue, setFormValue] = useState(formValueDefault);
    const [formErrors, setFormErrors] = useState(formErrorDefault);
    const [rememberMe, setRememberMe] = useState(false);

    const handleFormErrors = (errors) => {
        errors.forEach((formField) => {
            setFormErrors((prev) => {
                return { ...prev, [formField.path[0]]: formField.message };
            });
        });
    };

    const handleFormChange = (e) => {
        setFormValue((prev) => {
            return {
                ...prev,
                [e.target.name]: e.target.value,
            };
        });
        setFormErrors((prev) => {
            return { ...prev, [e.target.name]: '' };
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;

        const result = logibFormSchema.validate(formValue, { abortEarly: false });
        if (result.error) {
            handleFormErrors(result?.error?.details);
            hasError = true;
        }

        if (hasError) return;

        try {
            console.log({axiosPrivate: getAxiosPrivate});
            const response = await (await getAxiosPrivate()).post(`${apiRoutes.LOGIN}`, formValue);
            const token = response?.data?.body?.token;
            const user = response?.data?.body?.user;

            const payload = {
                token,
                user,
            };

            handleRemeberMe(rememberMe);

            dispatch(setCurrentUser(payload));
            navigate(getURL());
            return;
        } catch (error) {
            if (!error?.response?.data) return;

            if (error?.response?.data?.status === 400) {
                setFormErrors({
                    email: apiResponseMessages.USER_NOT_FOUND,
                    password: apiResponseMessages.USER_NOT_FOUND,
                });
                return;
            }
        }

        setFormErrors(formErrorDefault);
        setFormValue(formValueDefault);
    };

    return (
        <>
            <h1 className="text-center dark-blue mt-3 mt-sm-4 mt-lg-5">Вход</h1>
            <form className="fs-12">
                <label>Email</label>
                <input
                    type="email"
                    placeholder="Email"
                    className="mt-2"
                    name="email"
                    value={formValue.email}
                    onChange={handleFormChange}
                />
                <FormErrorMessage>{formErrors.email}</FormErrorMessage>
                <label className="mt-3">Пароль</label>
                <InputPassword
                    name="password"
                    className="mt-2"
                    value={formValue.password}
                    onChange={handleFormChange}
                />
                <FormErrorMessage>{formErrors.password}</FormErrorMessage>
                <div className="d-flex justify-content-between align-items-center mt-3">
                    <label>
                        <input
                            type="checkbox"
                            name="remember"
                            className="me-2"
                            checked={rememberMe}
                            onChange={() => setRememberMe((prev) => !prev)}
                        />
                        <span className="blue">Запомнить меня</span>
                    </label>
                    <Link to={getURL(appRoutes.RESET_PWD)} className="blue">
                        Забыли пароль?
                    </Link>
                </div>
                {/* <button type='submit' className='btn btn-2 fs-12 text-uppercase w-100 mt-3'>Войти</button> */}
                <button className="btn btn-2 fs-12 text-uppercase w-100 mt-3" onClick={handleFormSubmit}>
                    Войти
                </button>

                <div className="text-center mt-3">
                    У Вас еще нет аккаунта?{' '}
                    <Link to={getURL(appRoutes.REG)} className="blue">
                        Зарегистрироваться
                    </Link>
                </div>
            </form>
        </>
    );
};

export default function AppLoginPage() {
    return (
        <main className="bg-white position-relative">
            <img src={Assets.BG_ERITRANCE} className="entrance-bg" alt="грузоперевозки" />
            <section id="sec-10" className="container py-3 py-sm-4 py-lg-5">
                <Link to={getURL()} className="fs-12">
                    <span className="green fs-15 me-2">⟵</span> Назад
                </Link>
                <div className="row gx-md-5 justify-content-between">
                    <div className="col-md-6 col-lg-5 offset-lg-1">
                        <LoginForm />
                    </div>
                    <div className="d-none d-md-block col-6 col-lg-5 col-xxl-4 white">
                        <AccountInfoBlock />
                    </div>
                </div>
            </section>
        </main>
    );
}
