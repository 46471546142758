import Logger from "./logger";

export const onInputHandler = (e, setFunction, isDigit = false) => {
    const text = e.target.value;
    const name = e.target.name;

    setFunction((prevValues) => ({ ...prevValues, [name]: isDigit ? +text : text }));
};

export const onCheckboxHandler = (e, setFunction) => {
    const name = e.target.name;

    setFunction((prevValues) => ({ ...prevValues, [name]: !prevValues[name] }));
};

export const onRadioHandler = (e, setFunction, isDigit = false) => {
    const value = e.target.value;
    const name = e.target.name;

    setFunction((prevValues) => ({ ...prevValues, [name]: isDigit ? +value : value }));
};

export const onSelectHandler = (e, setFunction, isDigit = false) => {
    const select = e.target.value;
    const name = e.target.name;

    setFunction((prevValues) => ({ ...prevValues, [name]: isDigit ? +select : select }));
};

export const handleRSelect = (e, name, func, list, i) => {
    Logger.log(`handleRSelect`, { e, name, func, list, i });
    if (i !== undefined) {
        func(
            list.map((arr, index) => {
                if (index === i) {
                    return arr.map((obj) => {
                        if (obj.name === name) {
                            return { ...obj, value: e?.value };
                        } else {
                            return obj;
                        }
                    });
                } else {
                    return arr;
                }
            }),
        );
    } else {
        func(
            list.map((obj) => {
                if (obj.name === name) {
                    return { ...obj, value: e?.value };
                } else {
                    return obj;
                }
            }),
        );
    }
};

export const fillData = (e, func, list) => {
    const inputName = e.target.name;
    const inputVal = e.target.name === 'remark' ? e.target?.value : e.target?.value.trim();
    const clearState = e.target.dataset.clear;

    if (e.target.type === 'checkbox') {
        if (e.target.checked === true) {
            if (clearState !== null && clearState !== undefined && clearState !== '') {
                const clearStateArr = clearState.split(' ');

                clearStateArr.forEach((item) => clearInput(item));

                func(
                    list.map((obj) => {
                        if (obj.name === inputName) {
                            return { ...obj, value: true }; //write the value of the checkbox to the State
                        } else if (clearStateArr.includes(obj.name)) {
                            return { ...obj, value: '' }; //delete values of inputs from State
                        } else {
                            return obj; //skip the rest
                        }
                    }),
                );
            } else {
                func(
                    list.map((obj) => {
                        if (obj.name === inputName) {
                            return { ...obj, value: true };
                        } else {
                            return obj;
                        }
                    }),
                );
            }
        } else {
            func(
                list.map((obj) => {
                    if (obj.name === inputName) {
                        return { ...obj, value: false };
                    } else {
                        return obj;
                    }
                }),
            );
        }
    } else {
        func(
            list.map((obj) => {
                if (obj.name === inputName) {
                    return { ...obj, value: inputVal };
                } else {
                    return obj;
                }
            }),
        );
    }
};
export const fillDataArr = (e, func, list, i) => {
    const inputName = e.target.name.split(' ')[0];
    const inputVal = e.target?.value.trim();
    const clearState = e.target.dataset.clear;

    if (e.target.type === 'checkbox') {
        if (e.target.checked === true) {
            if (clearState !== null && clearState !== undefined && clearState !== '') {
                const clearStateArr = clearState.split(' ');
                clearStateArr.forEach((item) => clearInput(item));
                func(
                    list.map((arr, index) => {
                        if (index === i) {
                            return arr.map((obj) => {
                                if (obj.name === inputName) {
                                    return { ...obj, value: true }; //write the value of the checkbox to the State
                                } else if (clearStateArr.includes(obj.name)) {
                                    return { ...obj, value: '' }; //delete values of inputs from State
                                } else {
                                    return obj; //skip the rest
                                }
                            });
                        } else {
                            return arr;
                        }
                    }),
                );
            } else {
                func(
                    list.map((arr, index) => {
                        if (index === i) {
                            return arr.map((obj) => {
                                if (obj.name === inputName) {
                                    return { ...obj, value: true };
                                } else {
                                    return obj;
                                }
                            });
                        } else {
                            return arr;
                        }
                    }),
                );
            }
        } else {
            func(
                list.map((arr, index) => {
                    if (index === i) {
                        return arr.map((obj) => {
                            if (obj.name === inputName) {
                                return { ...obj, value: false };
                            } else {
                                return obj;
                            }
                        });
                    } else {
                        return arr;
                    }
                }),
            );
        }
    } else {
        func(
            list.map((arr, index) => {
                if (index === i) {
                    return arr.map((obj) => {
                        if (obj.name === inputName) {
                            return { ...obj, value: inputVal };
                        } else {
                            return obj;
                        }
                    });
                } else {
                    return arr;
                }
            }),
        );
    }
};
//очищение значений инпутов
export const clearInput = (item) => {
    const input: HTMLInputElement = document.querySelector('[name="' + item + '"]'); //clear input's value by name
    if (input.type === 'radio' || input.type === 'checkbox') {
        input.checked = false;
    } else {
        input && (input.value = '');
    }
};
//переключение обязательных для заполнения полей через radiobutton
export const toggleParams = (e, func, list, i) => {
    //нужно прикрутить очистку инпутов и селекта
    const inputVal = e.target?.value;
    const inputName = e.target.name.split(' ')[0];
    const addParams = e.target.dataset.add.split(' ');
    const delParams = e.target.dataset.del.split(' ');

    func(
        list.map((arr, index) => {
            if (index === i) {
                return arr.map((obj) => {
                    if (obj.name === inputName) {
                        return { ...obj, value: inputVal };
                    } else if (addParams.includes(obj.name)) {
                        //TODO. Think about it. Is it required or not, the field below this comment
                        return { ...obj, required: true };
                    } else if (delParams.includes(obj.name)) {
                        delParams.map((item) => clearInput(item));
                        return { ...obj, required: false, value: '' };
                    } else {
                        return obj;
                    }
                });
            } else {
                return arr;
            }
        }),
    );
};

//добавление fieldset
export const addState = (state, callback, stateName) => {
    const newState = [...state, ...stateName];
    callback(newState);
};
//удаление fieldset
export const delState = (state, func, index) => {
    const newArray = [...state];
    const arr = newArray.splice(index, 1);
    func(newArray.filter((obj) => obj !== arr));
};

//поиск значения полей в массиве
export const getObj = (opt, state, param, i) => {
    Logger.log('getObj', { opt, state, param, i });
    if (i !== undefined) {
        // console.log('state[i]', state[i].find((obj) => obj.name === param)?.value);
        if (opt.find((obj) => obj?.value === state[i].find((obj) => obj.name === param)?.value)) {
            return opt.find((obj) => obj?.value === state[i].find((obj) => obj.name === param)?.value);
        } else {
            return '';
        }
    } else {
        const object = state.find((obj) => obj.name === param);
        if (object?.value) {
            const option = opt.find((i) => i?.value === object?.value);
            return option;
        }
        return '';
    }
};
export const getObjLabel = (opt, state, param) => {
    if (opt.find((obj) => obj?.value === state.find((obj) => obj.name === param)?.value)) {
        return opt.find((obj) => obj?.value === state.find((obj) => obj.name === param)?.value).label;
    } else {
        return '';
    }
};
export const getVal = (state, param) => {
    const val = state.find((obj) => obj?.name === param)?.value;
    if (val !== null && val !== undefined && val !== '') {
        return val;
    } else {
        return '';
    }
};
export const getValArr = (state, i, param) => {
    const val = state[i].find((obj) => obj.name === param)?.value;
    if (val !== null && val !== undefined && val !== '') {
        return val;
    } else {
        return '';
    }
};
