const Loader = ({ color = '#FFF' }) => {
    const styles = {
        backgroundColor: color,
    };

    return (
        <div className="lds-ellipsis">
            <div style={styles} />
            <div style={styles} />
            <div style={styles} />
            <div style={styles} />
        </div>
    );
};
export default Loader;
