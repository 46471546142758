import { useAppSelector } from '@core/store/hooks';
import { delCellEditedState, setCellEditedState } from '@entities/office/dealDocument/dealDocument.actions';
import { selectEditedCell } from '@entities/office/dealDocument/dealDocument.selectors';
import {
    ETypeDocNode,
    IDocRenderElementData,
    IDocRenderElementProps,
} from '@entities/office/dealDocument/dealDocument.types';
import { useEditor } from '@entities/office/dealDocument/hooks/document.useEditor';
import DealDocumentRender from '@features/office/dealDocument/DealDocument.Render';
import Logger from '@shared/lib/logger';
import { deepCopy } from '@shared/lib/objects';
import { memo, useEffect, useMemo } from 'react';
import { IconContext } from 'react-icons';
import { IoAddCircle } from 'react-icons/io5';

const FormFields = memo(({ orderPrefix, fields }: any) =>
    fields.map((field, fIDX) => {
        return (
            <div className="row align-items-center mb-1 mt-1" key={`${orderPrefix}.${fIDX}`}>
                <div className="col-md-6  mb-1 mt-1 form-field-style">
                    {/* <div className="col-md-3 mb-3 mb-md-0"> */}
                    <label data-label="loading" data-warning="false" className="title-font fs-12 fw-5">
                        {field.label}
                    </label>
                </div>
                <div className="col-md-6  mb-1 mt-1 form-field-style">
                    {/* <div className="col-md-9"> */}
                    <div className="row">
                        <div className="col-xl-6">
                            <DealDocumentRender
                                nodes={[
                                    {
                                        type: field.type,
                                        config: {
                                            dataType: field.dataType,
                                            options: field.options,
                                            creatable: field.creatable,
                                        },
                                        privacyRole: field.privacy,
                                        order: `${orderPrefix}.INPUT${fIDX}`,
                                        ...field,
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }),
);

const DealDocumentFormElement = ({ node }: IDocRenderElementProps) => {
    const data: IDocRenderElementData = {};

    const { editorRole } = useEditor();

    data.valueCell = useMemo(() => node.cell || node.order, []);

    //DATA
    // data.fields = useMemo(() => node.config?.fields, []);
    data.title = useMemo(() => {
        //DATA_PICKER PATCHING
        let title = node.form?.title;
        if (Array.isArray(title)) {
            const groupOrder = `${node.order}.LIST.FORM0.GROUP0`;
            console.log({node});
            const patchedTitle = deepCopy(title).map((subNode, idx) => ({ ...subNode, order: `${node.order}.TITLE.${idx+1}` }));
            const insertIDX = patchedTitle.map((node) => node.type).indexOf(ETypeDocNode.DATA_PICKER);
            Logger.log({ patchedTitle, insertIDX });
            const pickerFields = {};
            node.form?.groups?.[0]?.fields?.forEach((field, fIDX) => {
                pickerFields[`${groupOrder}.INPUT${fIDX}`] = field.insertType;
            });
            if (patchedTitle[insertIDX]) patchedTitle[insertIDX].fields = pickerFields;
            title = patchedTitle;
            console.log({
                title,
            });
        }

        return (
            <DealDocumentRender
                nodes={[
                    {
                        type: ETypeDocNode.TITLE,
                        order: `${node.order}.TITLE`,
                        content: title,
                    },
                ]}
            />
        );
    }, []);

    //LIST
    const vectorCellList = useMemo(() => `${data.valueCell}.LIST`, []);
    // const [dlist, setDList] = useState(manualSelectEditedCell(vectorCellList));
    // data.list = dlist;
    data.list = useAppSelector(selectEditedCell(vectorCellList));
    data.isList = useMemo(() => node.form?.isList, []);
    useEffect(() => {
        if (!data.list) {
            setCellEditedState(`${vectorCellList}`, { [`${vectorCellList}.FORM0`]: {} });
            // setDList(manualSelectEditedCell(vectorCellList));
        }
    }, []);
    const list = useMemo(() => Object.keys(data.list || {}), [data.list]);

    //SUM
    // data.sumFieldIDX = useMemo(() => node.form?.sumFieldIDX, []);
    // data.sum = useMemo(
    //     () =>
    //         list.reduce(
    //             (acc, el, eIDX) => acc + (Number(getCell(data.list, `${el}.GROUP0.INPUT${data.sumFieldIDX}`)) || 0),
    //             0,
    //         ),
    //     [data.list],
    // );

    const groups = node.form?.groups;

    const LIST = useMemo(
        () =>
            list.map((listOrder, elIDX) => {
                return (
                    <div className="box" key={listOrder}>
                        {list.length > 1 && (
                            <div className="d-flex justify-content-between align-items-center mb-4">
                                <div className="black fw-5">№ {elIDX + 1}</div>
                                <button
                                    type="button"
                                    onClick={() => {
                                        delCellEditedState(`${listOrder}`);
                                        // setDList(manualSelectEditedCell(vectorCellList));
                                    }}
                                    className="red fw-5"
                                >
                                    Удалить
                                </button>
                            </div>
                        )}
                        <div className="d-lg-flex fields-groups-container">
                            {groups.map((g, gIDX) => {
                                const groupOrder = `${listOrder}.GROUP${gIDX}`;

                                //DATA_PICKER PATCHING
                                let title = g.title;
                                if (Array.isArray(title)) {
                                    const patchedTitle = deepCopy(title);
                                    const insertIDX = patchedTitle
                                        .map((node) => node.type)
                                        .indexOf(ETypeDocNode.DATA_PICKER);
                                    const pickerFields = {};
                                    g.fields.forEach((field, fIDX) => {
                                        pickerFields[`${groupOrder}.INPUT${fIDX}`] = field.insertType;
                                    });
                                    patchedTitle[insertIDX].fields = pickerFields;
                                    title = patchedTitle;
                                }

                                return (
                                    <div key={groupOrder}>
                                        {title && (
                                            <p style={{ marginBottom: '1em' }}>
                                                <DealDocumentRender
                                                    nodes={[
                                                        {
                                                            type: ETypeDocNode.TITLE,
                                                            order: `${groupOrder}.TITLE`,
                                                            content: title,
                                                        },
                                                    ]}
                                                />
                                            </p>
                                        )}
                                        <FormFields orderPrefix={groupOrder} fields={g.fields} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                );
            }),
        [list.length],
    );

    Logger.log(`FORM`, {
        order: node.order,
        valueCell: data.valueCell,
        privacyRole: node.privacyRole,
        editorRole,
    });

    return (
        <div>
            <p style={{ padding: '1em' }}>
                {data.title}
                {data.sumFieldIDX && `Итого: ${data.sum}`}
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1em' }}>{LIST}</div>
            {data.isList && (typeof node.privacyRole === typeof editorRole ? node.privacyRole === editorRole : true) ? (
                <button
                    style={{ padding: '1em' }}
                    type="button"
                    onClick={() => {
                        setCellEditedState(`${vectorCellList}.FORM#${Date.now()}`, {});
                        // setDList(manualSelectEditedCell(vectorCellList));
                    }}
                    className="green fs-11 fw-5 mx-auto d-flex align-items-center"
                >
                    <IconContext.Provider value={{ className: 'green icon-15' }}>
                        <IoAddCircle />
                    </IconContext.Provider>
                    <span className="ms-2">Добавить</span>
                </button>
            ) : null}
        </div>
    );
};
export default memo(DealDocumentFormElement);
