import axios from 'axios';
import Logger from '@shared/lib/logger';

export const defineIP = async () => {
    try {
        const response = await axios.get('https://api.ipify.org');
        return response?.data;
    } catch (error) {
        Logger.error(error);
    }
};
