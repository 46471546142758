
import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru.json';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { BsFillExclamationTriangleFill, BsFillInfoSquareFill } from 'react-icons/bs';
import { MdChatBubble, MdFormatQuote, MdThumbDown, MdThumbUp } from 'react-icons/md';
import { useAppDispatch} from '@core/store/hooks';
import { Link, useParams } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import {appRoutes} from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { setAlert } from '@entities/.app/alert/alert.actions';
import { redirectInProfile } from '@entities/.app/account/account.actions';
import { selectUserId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import {
    createTopicMessage,
    getTopic,
    likeTopic,
    likeTopicMessage,
    paginateTopicMessages,
    reportTopic,
    reportTopicMessage,
    resetLikeTopic,
    resetLikeTopicMessage,
} from '@entities/forum/forum.api';
import usePagination from '@shared/hooks/usePagination';
import { getDateUI, getTimeUI } from '@shared/lib/dateTIme';
import { getPhotoPath } from '@entities/.app/account/account.actions';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import CustomSelect from '@shared/ui/CustomSelect';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';
import PublicationRules from '@features/forum/Forum.PublicationRules.Block';
import ValidateWrapper from '@shared/ui/ValidateWrapper';

const Header = ({ topic }) => {
    return (
        <>
            <nav aria-label="breadcrumb" className="mb-3">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                        <Link to={getURL(appRoutes.FORUM)}>Разделы форума</Link>
                    </li>
                    <li className="breadcrumb-item active">{topic?.item?.title || 'Без темы'}</li>
                </ol>
            </nav>
            <div className="d-md-flex justify-content-between align-items-center mb-4">
                <h1 className="text-start dark-blue mb-md-0">{topic?.item?.title || 'Без темы'}</h1>
                <div className="title-font fs-12 text-md-end">
                    {topic?.item?.messagesCount || 0} ответов.&nbsp;
                    {topic?.item?.lastMessage?.createdAt && (
                        <>
                            Последний&nbsp;
                            <ReactTimeAgo
                                className="time-ago__counter"
                                date={Date.parse(topic?.item?.lastMessage?.createdAt)}
                                locale="ru-RU"
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

const Topic = ({
    topic,
    onReplyTopic,
    onReportTopic,
    replyHandleSubmit,
    onSubmitReply,
    setIsShowReplyForm,
    isShowReplyForm,
    replyRegister,
    replyErrors,
}) => {
    const curentUserId = useAppSelector(selectUserId);

    return (
        <div className="fixed-comment">
            {topic.isLoading ? (
                topic?.item ? (
                    <TopicMessage
                        id={topic?.item?.id}
                        idName="topicId"
                        userId={curentUserId}
                        author={{
                            name: topic?.item?.user?.firstName || 'Имя не указано',
                            imgURL: getPhotoPath(topic?.item?.user?.avatar),
                            pageURL: redirectInProfile(curentUserId, topic?.item?.user?.id),
                            post: topic?.item?.user?.roleForUser,
                        }}
                        date={getDateUI(topic?.item?.createdAt)}
                        time={getTimeUI(topic?.item?.createdAt, true)}
                        comment={topic?.item?.description}
                        likeStatus={topic?.item?.likeStatus}
                        likes={topic?.item?.likesCount}
                        dislikes={topic?.item?.dislikesCount}
                        onReply={onReplyTopic}
                        onReport={onReportTopic}
                    />
                ) : (
                    <h6 className="text-center w-100 p-5">Тема не найдена</h6>
                )
            ) : (
                <div className="w-100 d-flex justify-content-center">
                    <Loader color="#545454" />
                </div>
            )}
            <div className="answer-to-comment">
                {curentUserId ? (
                    isShowReplyForm ? (
                        <form
                            onSubmit={replyHandleSubmit((data) => {
                                onSubmitReply(data);
                                setIsShowReplyForm(false);
                            })}
                            noValidate
                        >
                            <label htmlFor="answer-1" className="title-font fs-12 fw-5 mb-2">
                                Ваш ответ
                            </label>
                            {/* @ts-ignore */}
                            <ValidateWrapper error={replyErrors?.answer}>
                                <textarea
                                    rows={6}
                                    placeholder="Текст"
                                    id="answer-1"
                                    {...replyRegister('answer', {
                                        required: 'Невозможно отправить пустой ответ',
                                    })}
                                />
                            </ValidateWrapper>
                            <div className="d-sm-flex align-items-center justify-content-end mt-2 mt-sm-3">
                                <div className="text-end fs-09 me-sm-4 mb-2 mb-sm-0">
                                    Нажимая на кнопку “Ответить”, вы
                                    <br /> соглашаетесь с <span className="blue">правилами публикации</span>
                                </div>
                                <button type="submit" className="btn btn-2 fs-12 ms-auto ms-sm-0">
                                    Ответить
                                </button>
                            </div>
                        </form>
                    ) : (
                        <button
                            type="button"
                            onClick={() => setIsShowReplyForm(true)}
                            className="btn btn-2 fs-12 ms-auto"
                        >
                            Ответить на публикацию
                        </button>
                    )
                ) : null}
            </div>
        </div>
    );
};

const TopicReaction = (props) => {
    const currentUserId = useAppSelector(selectUserId);

    const axiosPrivate = useAxiosPrivate();
    const [likes, setLikes] = useState(+props.likes);
    const [dislikes, setDislikes] = useState(+props.dislikes);
    const [likeStatus, setLikeStatus] = useState(props.likeStatus);
    //@ts-ignore

    const getPayloads = (isLike) => ({
        isLike,
        userId: props.userId,
        [props.idName]: props.id,
    });

    const sendLikeRequests = (payloads) => {
        if (props.userId) {
            if (props.idName === 'topicId') likeTopic(axiosPrivate, payloads);
            if (props.idName === 'topicMessageId') likeTopicMessage(axiosPrivate, payloads);
        }
    };

    const sendResetLikeRequests = (payloads) => {
        if (props.userId) {
            if (props.idName === 'topicId') resetLikeTopic(axiosPrivate, payloads);
            if (props.idName === 'topicMessageId') resetLikeTopicMessage(axiosPrivate, payloads);
        }
    };

    const likeHandler = () => {
        if (likeStatus) {
            setLikes((prev) => (prev > 0 ? prev - 1 : prev));
            setLikeStatus(null);

            sendResetLikeRequests(getPayloads(true));
        } else if (likeStatus === false) {
            setLikes((prev) => prev + 1);
            setDislikes((prev) => (prev > 0 ? prev - 1 : prev));
            setLikeStatus(true);

            sendLikeRequests(getPayloads(true));
        } else if (likeStatus === null) {
            setLikes((prev) => prev + 1);
            setLikeStatus(true);

            sendLikeRequests(getPayloads(true));
        }
    };

    const dislikeHandler = () => {
        if (likeStatus) {
            setLikes((prev) => (prev > 0 ? prev - 1 : prev));
            setDislikes((prev) => prev + 1);
            setLikeStatus(false);

            sendLikeRequests(getPayloads(false));
        } else if (likeStatus === false) {
            setDislikes((prev) => (prev > 0 ? prev - 1 : prev));
            setLikeStatus(null);

            sendResetLikeRequests(getPayloads(false));
        } else if (likeStatus === null) {
            setDislikes((prev) => prev + 1);
            setLikeStatus(false);

            sendLikeRequests(getPayloads(false));
        }
    };

    return (
        currentUserId && (
            <div className="btns">
                <button type="button" onClick={() => likeHandler()} className="d-flex align-items-center mb-lg-3">
                    {likeStatus === true ? (
                        <IconContext.Provider
                            value={{
                                className: 'icon-15 green', //@ts-ignore
                                title: 'Нравится',
                            }}
                        >
                            <MdThumbUp />
                        </IconContext.Provider>
                    ) : (
                        <IconContext.Provider
                            value={{
                                className: 'icon-15 gray-4', //@ts-ignore
                                title: 'Нравится',
                            }}
                        >
                            <MdThumbUp />
                        </IconContext.Provider>
                    )}
                    {likes > 0 && <span className="ms-1 ms-sm-2">{likes}</span>}
                </button>
                <button
                    type="button"
                    onClick={() => dislikeHandler()}
                    className="d-flex align-items-center mb-lg-3 ms-3 ms-sm-4 ms-lg-0"
                >
                    {likeStatus === false ? (
                        <IconContext.Provider
                            value={{
                                className: 'icon-15 red', //@ts-ignore
                                title: 'Не нравится',
                            }}
                        >
                            <MdThumbDown />
                        </IconContext.Provider>
                    ) : (
                        <IconContext.Provider
                            value={{
                                className: 'icon-15 gray-4', //@ts-ignore
                                title: 'Не нравится',
                            }}
                        >
                            <MdThumbDown />
                        </IconContext.Provider>
                    )}
                    {dislikes > 0 && <span className="ms-1 ms-sm-2">{dislikes}</span>}
                </button>
                <button
                    type="button"
                    className="answer d-flex align-items-center mb-lg-3"
                    onClick={() =>
                        props.onReply && props.id && props.onReply(props.id, props?.author?.name, props.comment)
                    }
                >
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 gray-4', //@ts-ignore
                            title: 'Ответить',
                        }}
                    >
                        <MdChatBubble />
                    </IconContext.Provider>
                    <span className="ms-2">Ответить</span>
                </button>
                <button
                    type="button"
                    className="report d-flex align-items-center ms-3 ms-sm-4 ms-lg-0"
                    onClick={() => props.onReport && props.id && props.onReport(props.id)}
                >
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 gray-4', //@ts-ignore
                            title: 'Пожаловаться',
                        }}
                    >
                        <BsFillExclamationTriangleFill />
                    </IconContext.Provider>
                    <span className="d-none d-sm-inline ms-2">Пожаловаться</span>
                </button>
            </div>
        )
    );
};
function TopicMessage(props) {
    return (
        <div className="comment">
            <div className="user">
                <div className="d-flex align-items-center d-md-block">
                    {props?.author?.imgURL && <img src={props.author.imgURL} alt={props.author.name} />}
                    <div>
                        <Link to={props.author.pageURL} className="blue text-decoration-underline d-block mb-2">
                            {props.author.name}
                        </Link>
                        {props.author.post && <div className="fw-5">{props.author.post}</div>}
                    </div>
                </div>
                <div className="gray-3 title-font text-end d-md-none">
                    <div className="mb-2">{props.date}</div>
                    <div>{props.time}</div>
                </div>
            </div>
            <div className="text">
                <div className="gray-3 title-font d-none d-md-flex align-items-center mb-2">
                    <div>{props.time}</div>
                    <div className="ms-4">{props.date}</div>
                </div>
                {props.citation && (
                    <blockquote>
                        <IconContext.Provider
                            value={{
                                className: 'icon-20 green me-2', //@ts-ignore
                                title: 'Кавычка',
                            }}
                        >
                            <MdFormatQuote />
                        </IconContext.Provider>
                        <div className="text">
                            <div className="author blue text-decoration-underline me-2">{props.citation.name}:</div>
                            {props.citation.text}
                        </div>
                    </blockquote>
                )}
                <div>{props.comment}</div>
            </div>
            <TopicReaction
                id={props?.id}
                idName="topicId"
                author={props.author}
                userId={props?.userId}
                comment={props?.comment}
                likeStatus={props?.likeStatus}
                likes={props?.likes}
                dislikes={props?.dislikes}
                onReply={props?.onReply}
                onReport={props?.onReport}
            />
        </div>
    );
}

const Footer = () => {
    return (
        <>
            <hr className="mt-5 mb-3" />
            <div className="d-flex align-items-center fs-11 mb-3">
                <IconContext.Provider
                    value={{
                        className: 'icon-10 blue',
                        // @ts-ignore
                        title: 'Правила публикации',
                    }}
                >
                    <BsFillInfoSquareFill />
                </IconContext.Provider>
                <span className="blue ms-2">Правила публикации</span>
            </div>
            <p className="gray-3">
                Администрация сайта не несет ответственности за информацию, публикуемую в форуме, и ее мнение может не
                совпадать с мнением авторов сообщений. Сообщения о незаконно размещенной информации на форуме присылайте
                на адрес:
                <a href="mailto:mail@gmail.com">mail@gmail.com</a>
            </p>
        </>
    );
};

const ReplyModal = ({
    isShowReplyModal,
    setIsShowReplyModal,
    replyData,
    onSubmitReply,
    replyHandleSubmit,
    replyRegister,
    replyErrors,
}) => {
    const [isShowPublicationRules, setIsShowPublicationRules] = useState(false);

    return (
        <CustomModal isShow={isShowReplyModal} setIsShow={setIsShowReplyModal} size="lg" closeButton>
            <h3>Добавить ответ</h3>
            <form
                className="fs-12"
                onSubmit={replyHandleSubmit((data) => {
                    onSubmitReply(data);
                    setIsShowReplyModal(false);
                })}
                noValidate
            >
                {/* @ts-ignore */}
                {replyData?.name && replyData?.description && (
                    <blockquote className="mb-4">
                        <IconContext.Provider
                            value={{
                                className: 'icon-20 green me-2', //@ts-ignore
                                title: 'Кавычка',
                            }}
                        >
                            <MdFormatQuote />
                        </IconContext.Provider>
                        <div className="text">
                            <div className="author blue text-decoration-underline me-2">
                                {/* @ts-ignore */}
                                {replyData.name}
                            </div>
                            {/* @ts-ignore */}
                            <p>{replyData.description}</p>
                        </div>
                    </blockquote>
                )}

                <label htmlFor="answer-2" className="mb-2">
                    Ваш ответ
                </label>
                {/* @ts-ignore */}
                <ValidateWrapper error={replyErrors?.answer}>
                    <textarea
                        id="answer-2"
                        // @ts-ignore
                        rows="6"
                        placeholder="Текст"
                        {...replyRegister('answer', {
                            required: 'Невозможно отправить пустой ответ',
                        })}
                    />
                </ValidateWrapper>
                <div className="row flex-sm-row-reverse mt-4">
                    <div className="col-sm-5">
                        <button type="submit" className="btn btn-2 w-100">
                            Ответить
                        </button>
                    </div>
                    <div className="col-sm-7 mt-2 mt-sm-0">
                        <div className="fs-09 text-end">
                            Нажимая на кнопку “Ответить”, вы соглашаетесь с{' '}
                            <span
                                className="blue"
                                onClick={() => setIsShowPublicationRules(true)}
                                style={{ cursor: 'pointer' }}
                            >
                                правилами публикации
                            </span>
                        </div>
                    </div>
                </div>
                {isShowPublicationRules && <PublicationRules setIsShowPublicationRules={setIsShowPublicationRules} />}
            </form>
        </CustomModal>
    );
};

const ReportModal = ({
    isShowReportModal,
    setIsShowReportModal,
    reportData,
    setReportData,
    reportReset,
    reportHandleSubmit,
    reportRegister,
    reportErrors,
}) => {
    const curentUserId = useAppSelector(selectUserId);
    const axiosPrivate = useAxiosPrivate();

    const onSubmitReport = (data) => {
        //@ts-ignore
        if (topic?.item && reportData?.topicId && curentUserId) {
            const payloads = {
                fromId: curentUserId,
                //@ts-ignore
                topicId: reportData.topicId,
            };

            if (data.report) {
                //@ts-ignore
                payloads.content = data.report;
            }

            reportTopic(axiosPrivate, payloads)
                .then(() => {
                    reportReset({ report: '' });
                    setReportData({});
                    // @ts-ignore
                    dispatch(setAlert('success', 'Жалоба успешно отправлена'));
                })
                .catch(() =>
                    // @ts-ignore
                    dispatch(setAlert('danger', 'Не удалось отправить жалобу')),
                );
        }

        //@ts-ignore
        if (messages?.items?.length && reportData?.topicMessageId && curentUserId) {
            const payloads = {
                fromId: curentUserId,
                //@ts-ignore
                topicMessageId: reportData.topicMessageId,
            };

            if (data.report) {
                // @ts-ignore
                payloads.content = data.report;
            }

            reportTopicMessage(axiosPrivate, payloads)
                .then(() => {
                    reportReset({ report: '' });
                    setReportData({});
                    //@ts-ignore
                    dispatch(setAlert('success', 'Жалоба успешно отправлена'));
                })
                .catch(() =>
                    //@ts-ignore
                    dispatch(setAlert('danger', 'Не удалось отправить жалобу')),
                );
        }
    };

    return (
        <CustomModal isShow={isShowReportModal} setIsShow={setIsShowReportModal} size="lg" closeButton>
            <h3>Добавить ответ</h3>
            <form
                className="fs-12"
                onSubmit={reportHandleSubmit((data) => {
                    onSubmitReport(data);
                    setIsShowReportModal(false);
                })}
                noValidate
            >
                <label htmlFor="report" className="mb-2">
                    Опишите вашу жалобу
                </label>
                {/* @ts-ignore */}
                <ValidateWrapper error={reportErrors?.report}>
                    <textarea
                        id="report"
                        // @ts-ignore
                        rows="3"
                        placeholder="Текст"
                        {...reportRegister('report')}
                    />
                </ValidateWrapper>
                <button type="submit" className="btn btn-2 w-100 mt-4">
                    Отправить
                </button>
            </form>
        </CustomModal>
    );
};

const initialPageLimit = 10;

export default function ForumTopicChatPage() {
    const axiosPrivate = useAxiosPrivate();
    const { id } = useParams();

    TimeAgo.addLocale(ru);

    const curentUserId = useAppSelector(selectUserId);

    const chatPagination = usePagination(initialPageLimit);
    const dispatch = useAppDispatch();

    const [isShowReplyModal, setIsShowReplyModal] = useState(false);
    const [isShowReplyForm, setIsShowReplyForm] = useState(false);
    const [isShowReportModal, setIsShowReportModal] = useState(false);

    const [topic, setTopic] = useState({
        isLoading: false,
        error: null,
        item: null,
    });
    const [messages, setMessages] = useState({
        isLoading: false,
        error: null,
        meta: null,
        items: [],
    });

    const {
        register: replyRegister,
        formState: { errors: replyErrors },
        handleSubmit: replyHandleSubmit,
        reset: replyReset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const {
        register: reportRegister,
        formState: { errors: reportErrors },
        handleSubmit: reportHandleSubmit,
        reset: reportReset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const [replyData, setReplyData] = useState({});
    const [reportData, setReportData] = useState({});

    const onSubmitReply = (data) => setReplyData((prev) => ({ ...prev, ...data }));

    const getTopicRequest = () => {
        getTopic(id, curentUserId)
            .then((result) => setTopic((prev) => ({ ...prev, isLoading: true, item: result })))
            .catch((error) => setTopic((prev) => ({ ...prev, isLoading: true, error })));
    };

    const paginateTopicMessagesRequest = (page) => {
        topic.item &&
            paginateTopicMessages(topic?.item?.id, curentUserId, page, chatPagination.pageLimit)
                .then((result) =>
                    setMessages((prev) => ({
                        ...prev,
                        isLoading: true,
                        meta: result?.meta,
                        items: result?.data,
                    })),
                )
                .catch((error) => setMessages((prev) => ({ ...prev, isLoading: true, error })));
    };

    const onReplyTopic = (topicId, name, description) => {
        setReplyData((prev) => ({ ...prev, topicId, name, description }));
        setIsShowReplyModal(true);
    };
    const onReplyTopicMessage = (topicMessageId, name, description) => {
        setReplyData((prev) => ({ ...prev, topicMessageId, name, description }));
        setIsShowReplyModal(true);
    };

    const onReportTopic = (topicId) => {
        setReportData((prev) => ({ ...prev, topicId }));
        setIsShowReportModal(true);
    };

    const onReportTopicMessage = (topicMessageId) => {
        setReportData((prev) => ({ ...prev, topicMessageId }));
        setIsShowReportModal(true);
    };

    //Загрузка темы
    useEffect(() => {
        id && getTopicRequest();
    }, [id, curentUserId]);

    useEffect(
        () => paginateTopicMessagesRequest(chatPagination.currentPage),
        [topic, curentUserId, chatPagination.currentPage, chatPagination.pageLimit],
    );

    useEffect(() => {
        // @ts-ignore
        if (topic.item && replyData?.answer && curentUserId) {
            const payloads = {
                // @ts-ignore
                description: replyData?.answer,
                topicId: topic?.item?.id,
                userId: curentUserId,
            };
            // @ts-ignore
            if (replyData.topicId) {
                // @ts-ignore
                payloads.topicId = replyData.topicId;
            }

            // @ts-ignore
            if (replyData.topicMessageId) {
                // @ts-ignore
                payloads.topicMessageId = replyData.topicMessageId;
            }

            createTopicMessage(axiosPrivate, payloads)
                .then(() => {
                    replyReset({ answer: '' });
                    setReplyData({});
                    getTopicRequest();
                    if (chatPagination.currentPage === 1) {
                        paginateTopicMessagesRequest(1);
                    } else {
                        chatPagination.setCurrentPage(1);
                        chatPagination.setStartingPage(1);
                    }
                    //@ts-ignore
                    dispatch(setAlert('success', 'Ответ отправлен'));
                })
                .catch(() =>
                    //@ts-ignore
                    dispatch(setAlert('danger', 'Не удалось отправить ответ')),
                );
        }
    }, [replyData, curentUserId, topic, messages]);

    useEffect(() => {
        if (!isShowReplyModal) {
            setReplyData({});
            replyReset({ answer: '' });
        }
    }, [isShowReplyModal]);

    useEffect(() => {
        if (!isShowReportModal) {
            setReportData({});
            reportReset({ report: '' });
        }
    }, [isShowReportModal]);

    return (
        <>
            <main className="bg-white py-4 py-sm-5 bg-transparent">
                <section
                    className="container"
                    id="sec-11"
                    style={{
                        background: '#FFF',
                        borderRadius: 8,
                        padding: 50,
                    }}
                >
                    <Header topic={topic} />

                    <Topic
                        topic={topic}
                        onReplyTopic={onReplyTopic}
                        onReportTopic={onReportTopic}
                        isShowReplyForm={isShowReplyForm}
                        setIsShowReplyForm={setIsShowReplyForm}
                        replyHandleSubmit={replyHandleSubmit}
                        onSubmitReply={onSubmitReply}
                        replyRegister={replyRegister}
                        replyErrors={replyErrors}
                    />

                    <div className="d-flex justify-content-end my-4">
                        {/* @ts-ignore */}
                        <Pagination
                            pageLimit={chatPagination.pageLimit}
                            currentPage={chatPagination.currentPage}
                            setCurrentPage={chatPagination.setCurrentPage}
                            pagesDisplayedLimit={3}
                            itemsAmount={messages?.meta?.total || 0}
                            startingPage={chatPagination.startingPage}
                            setStartingPage={chatPagination.setStartingPage}
                        />
                    </div>

                    <div className="answers-box">
                        {messages.isLoading ? (
                            messages?.items?.length ? (
                                messages?.items?.map((item) => (
                                    <TopicMessage
                                        id={item?.id}
                                        idName="topicMessageId"
                                        userId={curentUserId}
                                        key={item?.id}
                                        author={{
                                            name: item?.user?.firstName || 'Имя не указано',
                                            imgURL: getPhotoPath(item?.user?.avatar),
                                            pageURL: redirectInProfile(curentUserId, item?.user?.id),
                                            post: item?.user?.roleForUser,
                                        }}
                                        date={getDateUI(item?.createdAt)}
                                        time={getTimeUI(item?.createdAt, true)}
                                        citation={
                                            item?.reply
                                                ? {
                                                      name: item?.reply?.user?.firstName,
                                                      text: item?.reply?.description,
                                                  }
                                                : null
                                        }
                                        comment={item?.description}
                                        likeStatus={item?.likeStatus}
                                        likes={item?.likesCount}
                                        dislikes={item?.dislikesCount}
                                        onReply={onReplyTopicMessage}
                                        onReport={onReportTopicMessage}
                                    />
                                ))
                            ) : null
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-4">
                        {/* @ts-ignore */}
                        <Pagination
                            pageLimit={chatPagination.pageLimit}
                            currentPage={chatPagination.currentPage}
                            setCurrentPage={chatPagination.setCurrentPage}
                            pagesDisplayedLimit={3}
                            itemsAmount={messages?.meta?.total || 0}
                            startingPage={chatPagination.startingPage}
                            setStartingPage={chatPagination.setStartingPage}
                        />

                        <div className="d-flex align-items-center">
                            <span className="d-none d-sm-block me-2">показать</span>
                            <CustomSelect
                                className="inp"
                                name="items-count"
                                options={['10', '15', '20']}
                                checkedOptions={[chatPagination.pageLimit]}
                                callback={({ title }) => chatPagination.setPageLimit(+title)}
                                align="right"
                            />
                            <span className="ms-2 d-none d-md-block">тем на странице</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-9">
                            <Footer />
                        </div>
                    </div>
                </section>
            </main>
            <ReplyModal
                isShowReplyModal={isShowReplyModal}
                setIsShowReplyModal={setIsShowReplyModal}
                replyData={replyData}
                onSubmitReply={onSubmitReply}
                replyHandleSubmit={replyHandleSubmit}
                replyRegister={replyRegister}
                replyErrors={replyErrors}
            />
            <ReportModal
                isShowReportModal={isShowReportModal}
                setIsShowReportModal={setIsShowReportModal}
                reportData={reportData}
                setReportData={setReportData}
                reportReset={reportReset}
                reportHandleSubmit={reportHandleSubmit}
                reportRegister={reportRegister}
                reportErrors={reportErrors}
            />
        </>
    );
}
