import Loader from '@shared/ui/Loader';

export default function NewsArticleContent({ newsItem }) {
    return newsItem.isLoading ? (
        newsItem.item ? (
            <article className="full">
                <h1 className="dark-blue text-start text-uppercase">{newsItem?.item?.title}</h1>
                {/* pass value to dateTime attribute if needed */}
                <time className="d-block fs-11 gray-3 mb-2" dateTime="2021-12-13">
                    {newsItem?.item?.createdAt && new Date(newsItem?.item?.createdAt).toLocaleDateString()}
                </time>
                <figure>
                    <img src={newsItem?.item?.image} alt="Название новости" />
                    <figcaption>
                        <p>{newsItem?.item?.description}</p>
                    </figcaption>
                </figure>
            </article>
        ) : (
            <h6 className="m-auto p-5 text-center">Новость не найдена</h6>
        )
    ) : (
        <div className="d-flex justify-content-center">
            <Loader color="#545454" />
        </div>
    );
}
