
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { IoChevronDownSharp, IoSwapHorizontalSharp } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';

import { useAppSelector } from '@core/store/hooks';
import { selectCities } from '@entities/.app/cities/cities.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { getCarTypes } from '@entities/market/car/car.api';
import { getNotesType, getRoute } from '@entities/market/cargo/cargo.actions';
import { getItemTypes, searchCargo } from '@entities/market/cargo/cargo.api';
import { searchRoute } from '@entities/market/route/route.api';
import CargoCard from '@features/market/cargo/Cargo.Card';
import RouteCard from '@features/market/route/Route.Card';
import usePagination from '@shared/hooks/usePagination';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';
import SearchInput from '@shared/ui/SearchInput';
import { getDate } from '@shared/lib/dateTIme';
import { cargoStateIcons } from '@entities/market/cargo/cargo.data';
import { useWidth } from '@shared/hooks/useWidth';

const SearchForm = ({ type, submitHandler, fromMainPage, searchType }) => {
    const axiosPrivate = useAxiosPrivate();

    const cities = useAppSelector(selectCities);

    const isNarrowScreen = useWidth(767);

    const [isExpandedForm, setIsExpandedForm] = useState(undefined);
    useEffect(() => {
        setIsExpandedForm(!isNarrowScreen);
    }, [isNarrowScreen]);

    const { register, handleSubmit, control, reset, getValues, setValue } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const [cargoTypes, setCargoTypes] = useState([]);
    const [carTypes, setCarTypes] = useState([]);
    useEffect(() => {
        getItemTypes(axiosPrivate)
            .then((res) => res?.length && setCargoTypes(res.map((item) => ({ value: item.id, label: item.name }))))
            .catch(() => setCargoTypes([]));
        getCarTypes(axiosPrivate)
            .then((res) => res?.length && setCarTypes(res.map((item) => ({ value: item.id, label: item.name }))))
            .catch(() => setCarTypes([]));
    }, []);

    const [formData, setFormData] = useState(null);
    useEffect(() => {
        submitHandler && submitHandler(formData);
    }, [formData]);

    const onSumbit = (data) => {
        const tempObject: any = {};
        for (const key in data) {
            if (data[key] !== '' && data[key] !== undefined) {
                tempObject[key] = data[key];
            }
        }

        if (data?.date) {
            tempObject.date = getDate(data.date);
        }
        setFormData(tempObject);
    };

    const [selectType, setSelectType] = useState(null);
    const [selectNote, setSelectNote] = useState(null);

    const resetForm = () => {
        reset({
            fromRoute: '',
            toRoute: '',
            date: '',
            width: '',
            length: '',
            height: '',
            minVolume: '',
            maxVolume: '',
            minWeight: '',
            maxWeight: '',
        });
        setSelectType(null);
        setSelectNote(null);
        setFormData(null);
    };
    useEffect(() => resetForm(), [type]);

    useEffect(() => {
        if (fromMainPage?.fromRoute) setValue('fromRoute', fromMainPage?.fromRoute);
        if (fromMainPage?.toRoute) setValue('toRoute', fromMainPage?.toRoute);
        if (fromMainPage?.date) setValue('date', fromMainPage?.dateN);
    }, [fromMainPage?.fromRoute, fromMainPage?.toRoute, fromMainPage?.date]);

    return (
        <form noValidate onSubmit={handleSubmit(onSumbit)}>
            <div className="row g-3 g-sm-4 g-xl-5">
                <div className="col-lg-8 d-sm-flex align-items-end">
                    <div className="flex-1 mb-3 mb-sm-0">
                        <label className="title-font mb-2 mb-xl-3">Откуда</label>
                        <Controller
                            control={control}
                            name="fromRoute"
                            render={({ field }) => (
                                <SearchInput
                                    //@ts-ignore
                                    data={cities}
                                    placeHolder={'Город отправления'}
                                    value={fromMainPage?.fromRoute}
                                    callback={(value) => field.onChange(value)}
                                />
                            )}
                        />
                    </div>
                    <IconContext.Provider
                        value={{
                            className: 'green icon d-none d-sm-block mx-2 mx-md-3 mb-1 mb-md-2',
                        }}
                    >
                        <IoSwapHorizontalSharp />
                    </IconContext.Provider>
                    <div className="flex-1">
                        <label className="title-font mb-2 mb-xl-3">Куда</label>
                        <Controller
                            control={control}
                            name="toRoute"
                            render={({ field }) => (
                                <SearchInput
                                    //@ts-ignore
                                    data={cities}
                                    placeHolder={'Город назначения'}
                                    value={fromMainPage?.toRoute}
                                    callback={(value) => field.onChange(value)}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className="col-md-5 col-lg-4">
                    <label className="title-font mb-2 mb-xl-3">Дата</label>
                    <Controller
                        control={control}
                        name="date"
                        render={({ field }) => (
                            <input
                                type="date"
                                placeholder="С сегодняшнего дня"
                                value={getValues('date') || ''}
                                onChange={(e) => field.onChange(e.target.value)}
                            />
                        )}
                    />
                </div>
                {isExpandedForm && (
                    <>
                        <div className="col-md-7 col-lg-5 col-xxl-4">
                            <div className="row row-cols-sm-2">
                                <div className="mb-3 mb-sm-0">
                                    <label className="title-font mb-2 mb-xl-3">Объем, м3</label>
                                    <div className="d-flex align-items-center">
                                        <input type="number" placeholder="От" {...register('minVolume')} />
                                        <span className="fs-15 mx-1 mx-xl-2">—</span>
                                        <input type="number" placeholder="До" {...register('maxVolume')} />
                                    </div>
                                </div>
                                <div>
                                    <label className="title-font mb-2 mb-xl-3">Вес, т</label>
                                    <div className="d-flex align-items-center">
                                        <input type="number" placeholder="От" {...register('minWeight')} />
                                        <span className="fs-15 mx-1 mx-xl-2">—</span>
                                        <input type="number" placeholder="До" {...register('maxWeight')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-8">
                            <label className="title-font mb-2 mb-xl-3">Максимальное значение габаритов груза, м</label>
                            <div className="d-sm-flex align-items-center">
                                <div className="d-flex align-items-center mb-3 mb-sm-0 me-sm-4 me-xl-5">
                                    <label className="me-2">Длина:</label>
                                    <input type="number" placeholder="0" {...register('length')} />
                                </div>
                                <div className="d-flex align-items-center mb-3 mb-sm-0 me-sm-4 me-xl-5">
                                    <label className="me-2">Ширина:</label>
                                    <input type="number" placeholder="0" {...register('width')} />
                                </div>
                                <div className="d-flex align-items-center">
                                    <label className="me-2">Высота:</label>
                                    <input type="number" placeholder="0" {...register('height')} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 type-select">
                            <div className="type-select__inner">
                                <label className="title-font mb-2 mb-xl-3">
                                    {type === 'cargo' ? 'Тип груза' : 'Тип машины'}
                                </label>
                                <Controller
                                    control={control}
                                    name={type === 'cargo' ? 'cargoBodyTypeId' : 'carBodyTypeId'}
                                    render={({ field }) =>
                                        (type === 'cargo' ? cargoTypes.length !== 0 : carTypes.length !== 0) && (
                                            <Select
                                                className="fs-12 w-100"
                                                classNamePrefix="react-select"
                                                placeholder="Выберите..."
                                                options={type === 'cargo' ? cargoTypes : carTypes}
                                                value={selectType}
                                                onChange={(val) => {
                                                    setSelectType({ value: val.value, label: val.label });
                                                    field.onChange(val.value);
                                                }}
                                            />
                                        )
                                    }
                                />
                            </div>
                            {type === 'car' && (
                                <div className="type-select__checkbox">
                                    <label className="title-font mb-2 mb-xl-3">
                                        <input type="checkbox" {...register('onlyVerified')} />
                                        Проверенный автомобиль
                                    </label>
                                </div>
                            )}
                        </div>
                        {type === 'cargo' && (
                            <div className="col-sm-6 col-md-4">
                                <label className="title-font mb-2 mb-xl-3">Особые пометки</label>
                                <Controller
                                    control={control}
                                    name="noteType"
                                    render={({ field }) => (
                                        <Select
                                            className="fs-12 w-100"
                                            classNamePrefix="react-select"
                                            placeholder={'Выберите...'}
                                            options={cargoStateIcons.map((item) => ({
                                                value: item.id,
                                                label: item.text,
                                            }))}
                                            value={selectNote}
                                            onChange={(val) => {
                                                setSelectNote({ value: val.value, label: val.label });
                                                field.onChange(val.value);
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </>
                )}
                <div className="col-md-4 d-md-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center d-md-none mx-auto mb-4">
                        {isExpandedForm ? (
                            <button type="button" onClick={() => setIsExpandedForm(false)}>
                                <span className="blue me-2">Свернуть поиск</span>
                                <IconContext.Provider value={{ className: 'blue rotate-180', size: '1.25em' }}>
                                    <IoChevronDownSharp />
                                </IconContext.Provider>
                            </button>
                        ) : (
                            <button type="button" onClick={() => setIsExpandedForm(true)}>
                                <span className="blue me-2">Расширенный поиск</span>
                                <IconContext.Provider value={{ className: 'blue', size: '1.25em' }}>
                                    <IoChevronDownSharp />
                                </IconContext.Provider>
                            </button>
                        )}
                    </div>
                    <button type="submit" className="btn btn-2 fs-15 w-100 px-3">
                        {searchType === 'car' ? 'Найти машину' : 'Найти грузы'}
                    </button>
                </div>
            </div>
        </form>
    );
};

const initialSorting = [
    { value: 'desc', label: 'По убыванию' },
    { value: 'asc', label: 'По возрастанию' },
];

const SearchReults = ({ searchType, cargo, cars, cargoPagination, carsPagination, filters, setFilters }) => {
    return (
        <>
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center mb-4">
                <div className="fs-15 fw-5 mb-2 mb-md-0">
                    {searchType === 'cargo'
                        ? `Найдено ${cargo?.meta?.total || 0} грузов`
                        : `Найдено ${cars?.meta?.total || 0} машин`}
                </div>
                <div className="fs-12 ms-md-5 d-flex align-items-center">
                    <span className="me-2">Сортировать:</span>
                    <Select
                        name="sort"
                        className="w-100"
                        classNamePrefix="react-select"
                        options={initialSorting}
                        value={initialSorting.length && initialSorting.filter((item) => item.value === filters.orderBy)}
                        onChange={(val) => setFilters((prev) => ({ ...prev, orderBy: val.value }))}
                    />
                </div>
            </div>
            {searchType === 'cargo' && (
                <>
                    <div className="row row-cols-2 row-cols-md-3 row-cols-xxl-4 g-1 g-sm-2 g-lg-3">
                        {cargo.isLoading ? (
                            cargo?.data?.length ? (
                                cargo?.data?.length &&
                                cargo.data.map((item) => {
                                    // const notesType = item?.items?.map((i) => i.noteType);
                                    const generalCapacity = item?.items?.reduce(
                                        (acc, currentValue) => acc + currentValue?.capacity,
                                        0,
                                    );
                                    const generalWeight = item?.items?.reduce(
                                        (acc, currentValue) => acc + currentValue?.weight,
                                        0,
                                    );

                                    return (
                                        <CargoCard
                                            key={item.id}
                                            id={item.id}
                                            title={item?.type?.name}
                                            route={getRoute(item, true)}
                                            notesType={getNotesType(item.items)}
                                            capacity={generalCapacity}
                                            weight={generalWeight}
                                            cargo={item}
                                        />
                                    );
                                })
                            ) : (
                                <h6 className="text-center w-100 p-5">Не найдено ни одного груза</h6>
                            )
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>
                    {cargo?.data?.length > 0 && (
                        <div className="mt-4">
                            <Pagination
                                pageLimit={cargoPagination.pageLimit}
                                currentPage={cargoPagination.currentPage}
                                setCurrentPage={cargoPagination.setCurrentPage}
                                pagesDisplayedLimit={3}
                                itemsAmount={cargo?.meta?.total || 0}
                                startingPage={cargoPagination.startingPage}
                                setStartingPage={cargoPagination.setStartingPage}
                            />
                        </div>
                    )}
                </>
            )}
            {searchType === 'car' && (
                <>
                    <div className="row row-cols-2 row-cols-md-3 row-cols-xxl-4 g-1 g-sm-2 g-lg-3">
                        {cars.isLoading ? (
                            cars?.data?.length ? (
                                cars?.data?.length &&
                                cars.data.map((item) => (
                                    <div key={item.id}>
                                        <RouteCard
                                            id={item.id}
                                            route={`${item.fromRoute} - ${item.toRoute}`}
                                            isVerified={item?.car?.isVerified}
                                            size={item.car?.capacity}
                                            carrying={item.car?.carrying}
                                            carType={item.carBodyType?.name}
                                            dimensions={`${item.car?.length}/${item.car?.width}/${item.car?.height}`}
                                            date={item.dateType ? 'единожды' : 'постоянно'}
                                        />
                                    </div>
                                ))
                            ) : (
                                <h6 className="text-center w-100 p-5">Не найдено ни одной машины</h6>
                            )
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>
                    {cars?.data?.length > 0 && (
                        <div className="mt-4">
                            <Pagination
                                pageLimit={carsPagination.pageLimit}
                                currentPage={carsPagination.currentPage}
                                setCurrentPage={carsPagination.setCurrentPage}
                                pagesDisplayedLimit={3}
                                itemsAmount={cars?.meta?.total || 0}
                                startingPage={carsPagination.startingPage}
                                setStartingPage={carsPagination.setStartingPage}
                            />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

const initialPageLimit = 12;

export default function AdSearchPage() {
    const location = useLocation();
    const initialFilters = {
        orderBy: 'desc',
        ...location.state,
    };

    const [searchType, setSearchType] = useState('cargo'); // cargo || car
    useEffect(() => {
        if (location?.state?.searchType) {
            setSearchType(location?.state?.searchType);
        }
    }, [location?.state?.searchType]);

    const [filters, setFilters] = useState(initialFilters);
    useEffect(() => setFilters(initialFilters), [searchType]);

    const submitHandler = (formData) =>
        formData ? setFilters({ ...initialFilters, ...formData }) : setFilters(initialFilters);

    const [cargo, setCargo] = useState({
        isLoading: false,
        error: null,
        meta: null,
        data: [],
    });
    const [cars, setCars] = useState({
        isLoading: false,
        error: null,
        meta: null,
        data: [],
    });

    const cargoPagination = usePagination(initialPageLimit);
    const carsPagination = usePagination(initialPageLimit);
    useEffect(() => {
        searchType === 'cargo' &&
            searchCargo(cargoPagination.currentPage, cargoPagination.pageLimit, filters)
                .then((res) =>
                    setCargo((prev) => ({
                        ...prev,
                        isLoading: true,
                        data: res?.data,
                        meta: res?.meta,
                    })),
                )
                .catch((error) => setCargo((prev) => ({ ...prev, isLoading: true, error })));
    }, [cargoPagination.currentPage, cargoPagination.pageLimit, filters]);
    useEffect(() => {
        searchType === 'car' &&
            searchRoute(carsPagination.currentPage, carsPagination.pageLimit, {
                ...filters,
                onlyVerified: false,
            })
                .then((res) =>
                    setCars((prev) => ({
                        ...prev,
                        isLoading: true,
                        data: res?.data,
                        meta: res?.meta,
                    })),
                )
                .catch((error) => setCars((prev) => ({ ...prev, isLoading: true, error })));
    }, [carsPagination.currentPage, carsPagination.pageLimit, filters]);

    return (
        <main>
            <section id="sec-7" className="py-4 py-sm-5 container">
                <div
                    className="shadow"
                    style={{
                        borderRadius: 15,
                    }}
                >
                    <div className="switch">
                        <button
                            type="button"
                            onClick={() => setSearchType('cargo')}
                            className={searchType === 'cargo' ? 'active' : ''}
                        >
                            <img src="/img/bg/cargo.png" alt="грузы" />
                            <span>Найти груз</span>
                        </button>
                        <button
                            type="button"
                            onClick={() => setSearchType('car')}
                            className={searchType === 'car' ? 'active' : ''}
                        >
                            <img src="/img/bg/car.png" alt="машина" />
                            <span>Найти машину</span>
                        </button>
                    </div>
                    <SearchForm
                        searchType={searchType}
                        fromMainPage={location.state}
                        type={searchType}
                        submitHandler={submitHandler}
                    />
                </div>
            </section>

            <section className="container pb-4 pb-sm-5 my-sm-4">
                <SearchReults
                    searchType={searchType}
                    cargo={cargo}
                    cars={cars}
                    cargoPagination={cargoPagination}
                    carsPagination={carsPagination}
                    filters={filters}
                    setFilters={setFilters}
                />
            </section>
        </main>
    );
}
