import store from '@core/store';
import { getDocFile, saveDocModel } from '@entities/office/dealDocument/dealDocument.api';
import { documentActions } from '@entities/office/dealDocument/dealDocument.slice';
import {
    EDocChangesCodes,
    EDocModelTypesLabels,
    EEditorRoles,
    IDocExportFormat,
    inputFunctionsTypes,
} from '@entities/office/dealDocument/dealDocument.types';
import { deepCopy, isExistAndNotEmpty } from '@shared/lib/objects';

export const setCellEditedState = (vectorString, value, isCurrentUserChanges = true) => {
    console.log('_______setCellEditedState______');
    store.dispatch(documentActions.setDocEditedState([vectorString, value, isCurrentUserChanges]));
};

export const getLVL = (state, vector) => {
    return Object.keys(state).find((e) => e === vector);
};

const getRootCell = (state, vectorString) => {
    if (typeof vectorString === 'string' && vectorString.length && isExistAndNotEmpty(state)) {
        const vectorArray = vectorString.split('.').filter((e) => e);

        if (vectorArray?.length > 1) {
            for (let idx = vectorArray.length; idx > 0; idx--) {
                const cellOrder = vectorArray.slice(0, idx).join('.');
                if (getLVL(state, cellOrder)) {
                    if (idx === vectorArray.length) return state;
                    else {
                        state = state[getLVL(state, cellOrder)];
                        idx = vectorArray.length + 1;
                    }
                }
            }
            return null;
        } else if (vectorArray?.length === 1) {
            return state;
        }
    } else return undefined;
};

export const getCell = (state, vectorString) => {
    if (typeof vectorString === 'string' && vectorString.length && isExistAndNotEmpty(state)) {
        const vectorArray = vectorString.split('.').filter((e) => e);
        const placeCell = getRootCell(state, vectorString);
        if (placeCell) {
            if (vectorArray?.length > 1) {
                return placeCell[vectorString];
            } else if (vectorArray?.length === 1) {
                return placeCell[vectorArray[0]];
            }
        } else return null;
    } else return undefined;
};

export const delCell = (state, vectorString) => {
    if (typeof vectorString === 'string' && vectorString.length && isExistAndNotEmpty(state)) {
        const vectorArray = vectorString.split('.').filter((e) => e);
        const placeCell = getRootCell(state, vectorString);
        if (placeCell) {
            if (vectorArray?.length > 1) {
                delete placeCell[vectorString];
                return true;
            } else if (vectorArray?.length === 1) {
                delete placeCell[vectorArray[0]];
                return true;
            }
        } else return null;
    } else return undefined;
};

export const delCellEditedState = (vectorString) => {
    const editedState = deepCopy(store.getState().document.edit.editedState);
    delCell(editedState, vectorString);
    store.dispatch(documentActions.setDocEditedState(editedState));

    const deltaState = deepCopy(store.getState().document.edit.deltaState);
    deltaState[`${vectorString}`] = 'DELETE';
    store.dispatch(documentActions.setDocDeltaState(deltaState));

    store.dispatch(documentActions.setIsModifyed(true));
};

export const downloadDocFile = async (axiosPrivate, dealId, docType, format: IDocExportFormat) => {
    const response = await getDocFile(axiosPrivate, dealId, docType, format);
    const href = URL.createObjectURL(response);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${EDocModelTypesLabels[docType]}.${format}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

export const getChanges = (docModel, editorRole) => {
    const lastChangesContractor: string[] = JSON.parse(docModel?.lastChangesContractor || '[]');
    const lastChangesCustomer: string[] = JSON.parse(docModel?.lastChangesCustomer || '[]');

    const lastChangesPartner = editorRole === EEditorRoles.CUSTOMER ? lastChangesContractor : lastChangesCustomer;
    const lastChangesEditor = editorRole === EEditorRoles.CUSTOMER ? lastChangesCustomer : lastChangesContractor;

    const docIsDeclareClosed = [lastChangesContractor[0], lastChangesCustomer[0]].includes(
        EDocChangesCodes.DECLARE_CLOSED,
    );
    const isLastDeclorator = lastChangesEditor[0] === EDocChangesCodes.DECLARE_CLOSED;

    return {
        lastChangesContractor,
        lastChangesCustomer,
        lastChangesPartner,
        lastChangesEditor,
        docIsDeclareClosed,
        isLastDeclorator,
    };
};

export const actionSaveDocModel = async ({
    axiosPrivate,
    currentDeal,
    currentUser,
    docModel,
    docIsFullFilled,
    docIsDeclareClosed,
    docDeltaState,
    isModifyed = false,
    setIsShowModalToChat,
    setIsModifyed = null,
}) => {
    const res = await saveDocModel(
        axiosPrivate,
        currentDeal?.id,
        currentUser?.id,
        docModel?.type,
        docIsFullFilled && !isModifyed
            ? docIsDeclareClosed
                ? EDocChangesCodes.CLOSE
                : EDocChangesCodes.DECLARE_CLOSED
            : docDeltaState,
    );
    if (res?.status === 200) {
        setIsShowModalToChat(true, res.body);
        setIsModifyed && setIsModifyed(false);
    }
};

export const inputFunctions = {
    [inputFunctionsTypes.MUL]: (...args) => {
        return args.reduce((a, b) => a * b, 1);
    },
};
