import { Outlet } from 'react-router-dom';

import OfficeMenuWidget from '@features/office/Office.Menu.Widget';
import { useWidth } from '@shared/hooks/useWidth';

const OfficeLayout = () => {
    const isMobile = useWidth(991);
    return (
        <>
            {isMobile ? (
                <Outlet />
            ) : (
                <div className="row gx-4 gx-xl-5">
                    <div className="col-md-4 col-lg-3">
                        <OfficeMenuWidget />
                    </div>
                    <div className="col-md-8 col-lg-9">
                        <Outlet />
                    </div>
                </div>
            )}
        </>
    );
};
export default OfficeLayout;
