
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { IconContext } from 'react-icons';
import { IoCaretDown, IoEllipsisVertical, IoTrash } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { getRouteTemplates, updateTemplateRouteName } from '@entities/market/route/route.api';
import { getCargoTemplates } from '@entities/market/cargo/cargo.api';
import usePagination from '@shared/hooks/usePagination';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import FormErrorMessage from '@shared/ui/FormErrorMessage';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';
import { deleteTemplate } from '@entities/market/market.api';

const TemplateHeader = ({ isDark, title, car, callbackForRename, note, id, type, callbackForDelete }) => {
    return (
        <>
            <div>
                <h5 className="gray-2 mb-1">
                    <span className="me-2" style={isDark ? { color: 'white' } : {}}>
                        {title}
                    </span>
                    <IconContext.Provider
                        value={{
                            className: 'icon-10' + isDark ? ' white' : '', //@ts-ignore
                            title: 'Раскрыть',
                        }}
                    >
                        <IoCaretDown />
                    </IconContext.Provider>
                </h5>
                {car ? <div className="fs-11">{car?.bodyType.name}</div> : <div className="fs-11">{note}</div>}
            </div>
            {/*mobile*/}
            <div className="d-block d-md-none dropdown dropstart">
                <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <IconContext.Provider
                        value={{
                            className: 'icon-20 gray-4', //@ts-ignore
                            title: 'Пожаловаться на пользователя',
                        }}
                    >
                        <IoEllipsisVertical />
                    </IconContext.Provider>
                </button>
                <ul className="dropdown-menu py-2">
                    <li>
                        <button type="button" onClick={() => callbackForRename && callbackForRename(id, type)}>
                            Переименовать
                        </button>
                    </li>
                    <li>
                        <button type="button" onClick={() => callbackForDelete && callbackForDelete(id)}>
                            Удалить
                        </button>
                    </li>
                </ul>
            </div>
            {/*des*/}
            <div className="d-none d-md-flex">
                <button
                    type="button"
                    className="btn btn-1 fs-09 ms-2 ms-xxl-3"
                    onClick={() => callbackForRename && callbackForRename(id, type)}
                >
                    Переименовать
                </button>
                <button
                    type="button"
                    onClick={() => callbackForDelete && callbackForDelete(id)}
                    className="ms-3 ms-xxl-4"
                >
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 gray-4', //@ts-ignore
                            title: 'Удалить',
                        }}
                    >
                        <IoTrash />
                    </IconContext.Provider>
                </button>
            </div>
        </>
    );
};
const TemplateDescription = ({
    fromRoute,
    toRoute,
    date,
    type,
    car,
    cargoInfo,
    bargainType,
    calculateType,
    notVatPrice,
    vatPrice,
    contacts,
    prepayment,
}) => {
    return (
        <table>
            <tbody>
                <tr>
                    <th>Маршрут:</th>
                    <td>
                        {fromRoute} - {toRoute}
                    </td>
                </tr>
                <tr>
                    <th>Дата:</th>
                    <td>{date ? 'Единожды' : 'Постоянно'}</td>
                </tr>
                {type === 'route' && (
                    <tr>
                        <th>О&nbsp;машине:</th>
                        {/* @ts-ignore */}
                        <td>
                            {car &&
                                `${car?.bodyType?.name}, ${car?.name}, ${car?.carrying}Т,${car?.capacity}м3, ${car?.length}/${car?.width}/${car?.height}`}
                        </td>
                    </tr>
                )}
                {type === 'cargo' && (
                    <tr>
                        <th>О&nbsp;грузе:</th>
                        <td>{cargoInfo || 'Данных о грузе не найдено ...'}</td>
                    </tr>
                )}
                <tr>
                    <th>Оплата:</th>
                    <td>
                        {bargainType ? 'возможен торг' : 'без торга'},&nbsp;
                        {calculateType ? 'наличный расчет' : 'перевод по карте'},&nbsp;
                        {notVatPrice && `цена без НДС:${notVatPrice} ₽`},&nbsp;
                        {vatPrice && `цена с НДС:${vatPrice} ₽`},&nbsp; предоплата {prepayment}%
                    </td>
                </tr>
                <tr>
                    <th>Контакты:</th>
                    <td>{contacts && `${contacts.map((i) => i.phone)} ${contacts.map((i) => i.firstName)}`}</td>
                </tr>
            </tbody>
        </table>
    );
};

const AdTemplate = (props) => {
    return (
        <details className={props.className} style={props?.isDark ? { background: '#545454', color: 'white' } : {}}>
            <summary className="d-flex align-items-center justify-content-between">
                <TemplateHeader {...props} />
            </summary>
            <div className="mt-3">
                <TemplateDescription {...props} />
            </div>
        </details>
    );
};

const RenameModal = ({
    isShowModalRenameRoute,
    setIsShowModalRenameRoute,
    renameRouteTemp,
    setRenameRouteTemp,
    formError,
    updateTemplateName,
}) => {
    return (
        <CustomModal
            isShow={isShowModalRenameRoute}
            setIsShow={setIsShowModalRenameRoute}
            closeButton={true}
            title={true}
            titleHead={'Переименовать шаблон'}
            className="modal__rename-template"
        >
            <form className="fs-12">
                <label className="mb-2">Название шаблона</label>
                <input
                    type="text"
                    className="mb-3"
                    placeholder="Название"
                    value={renameRouteTemp.name}
                    onChange={(e) =>
                        setRenameRouteTemp((prevState) => ({
                            ...prevState,
                            name: e.target.value,
                        }))
                    }
                />
                <label className="mb-2">Примечание</label>
                <input
                    type="text"
                    className="mb-3"
                    placeholder="Примечание"
                    value={renameRouteTemp.note}
                    onChange={(e) =>
                        setRenameRouteTemp((prevState) => ({
                            ...prevState,
                            note: e.target.value,
                        }))
                    }
                />
                {formError && <FormErrorMessage>{formError}</FormErrorMessage>}
                <div className="row row-cols-sm-2 mt-4">
                    <div className="mb-3 mb-sm-0">
                        <button
                            type="button"
                            onClick={() => setIsShowModalRenameRoute(false)}
                            className="btn btn-1 w-100"
                        >
                            Отмена
                        </button>
                    </div>
                    <div>
                        <button type="button" className="btn btn-2 w-100" onClick={updateTemplateName}>
                            Сохранить
                        </button>
                    </div>
                </div>
            </form>
        </CustomModal>
    );
};

const DeleteModal = ({ isShowModalDeleteTemplateRoute, setIsShowModalDeleteTemplateRoute, deleteRouteTemplate }) => {
    return (
        <CustomModal
            isShow={isShowModalDeleteTemplateRoute}
            setIsShow={setIsShowModalDeleteTemplateRoute}
            closeButton={true}
            title={true}
            titleHead={'Вы действительно хотите удалить шаблон?'}
            className="modal__delete-template"
        >
            <div className="row row-cols-sm-2 fs-12">
                <div className="mb-3 mb-sm-0">
                    <button
                        type="button"
                        onClick={() => setIsShowModalDeleteTemplateRoute(false)}
                        className="btn btn-1 w-100"
                    >
                        Отмена
                    </button>
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-2 w-100"
                        onClick={() => {
                            deleteRouteTemplate();
                            setIsShowModalDeleteTemplateRoute(false);
                        }}
                    >
                        Удалить
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

const initialPageLimit = 4;

export default function OfficeAdTemplatesPage() {
    const currentUser = useAppSelector(selectCurrentUser);
    const axiosPrivate = useAxiosPrivate();

    //TILES
    const [tab, setTab] = useState('routes');

    const routeTemplatesPag = usePagination(initialPageLimit);
    const cargoTemplatesPag = usePagination(initialPageLimit);
    const [routesTemplates, setRoutesTemplates] = useState({
        error: null,
        data: [],
        meta: [],
        isLoading: false,
    });
    const [cargoTemplates, setCargoTemplates] = useState({
        error: null,
        data: [],
        meta: [],
        isLoading: false,
    });

    useEffect(() => {
        (currentUser?.roleId === 3 || currentUser?.roleId === 4 || currentUser?.roleId === 1) &&
            getRouteTemplates(axiosPrivate, currentUser?.id, routeTemplatesPag.currentPage, routeTemplatesPag.pageLimit)
                .then((r) =>
                    setRoutesTemplates((prevState) => ({
                        ...prevState,
                        data: r?.data?.body?.data,
                        meta: r?.data?.body?.meta,
                        isLoading: true,
                    })),
                )
                .catch((error) => setRoutesTemplates((prev) => ({ ...prev, isLoading: true, error })));
    }, [currentUser, routeTemplatesPag.pageLimit, routeTemplatesPag.currentPage]);

    useEffect(() => {
        (currentUser?.roleId === 2 || currentUser?.roleId === 4 || currentUser?.roleId === 1) &&
            getCargoTemplates(axiosPrivate, currentUser?.id, cargoTemplatesPag.currentPage, cargoTemplatesPag.pageLimit)
                .then((r) =>
                    setCargoTemplates((prevState) => ({
                        ...prevState,
                        data: r?.data?.body?.data,
                        meta: r?.data?.body?.meta,
                        isLoading: true,
                    })),
                )
                .catch((error) => setCargoTemplates((prev) => ({ ...prev, isLoading: true, error })));
    }, [currentUser, cargoTemplatesPag.pageLimit, cargoTemplatesPag.currentPage]);

    useEffect(() => {
        if (routesTemplates?.data?.length === 0) {
            routeTemplatesPag.setCurrentPage(1);
            routeTemplatesPag.setStartingPage(1);
        }

        if (cargoTemplates?.data?.length === 0) {
            cargoTemplatesPag.setCurrentPage(1);
            cargoTemplatesPag.setStartingPage(1);
        }
    }, [routesTemplates?.data?.length, cargoTemplates?.data?.length]);

    useEffect(() => {
        if (currentUser?.roleId !== 2) {
            setTab('routes');
        } else {
            setTab('cargo');
        }
    }, [currentUser?.roleId]);

    //INCARDS
    const getFirstRouteForCargo = (adTemplateId) => {
        if (cargoTemplates?.data?.length <= 0) return '';

        const template = cargoTemplates?.data.find((item) => item.id === adTemplateId);
        if (!template) return;

        const town = template?.cargo.loadings[0]?.town;
        return town;
    };
    const getLastRouteForCargo = (adTemplateId) => {
        if (cargoTemplates?.data?.length <= 0) return '';

        const template = cargoTemplates?.data.find((item) => item.id === adTemplateId);
        if (!template) return;

        const templateUnloadings = template?.cargo.unloadings;
        const lastUnloadingIndex = templateUnloadings.length - 1;
        const town = templateUnloadings[lastUnloadingIndex]?.town;
        return town;
    };
    const getCargoInfo = (adTemplateId) => {
        if (cargoTemplates?.data?.length <= 0) return '';

        const template = cargoTemplates?.data.find((item) => item.id === adTemplateId);
        if (!template) return;
        const cargoCount = template?.cargo.items.length;
        const cargoWeight = template?.cargo.items.reduce((prevValue, currentItem) => {
            return prevValue + currentItem.weight;
        }, 0);
        return `Кол-во грузов: ${cargoCount}, Общий вес: ${cargoWeight}`;
    };

    //ALERT
    const [isShowAlert, setIsShowAlert] = useState(false);
    useEffect(() => {
        if (isShowAlert) {
            setTimeout(() => setIsShowAlert(false), 1500);
        }
    }, [isShowAlert]);

    //MODALS
    const [idTempRoute, setIdTempRoute] = useState(null);
    const [renameRouteTemp, setRenameRouteTemp] = useState({
        name: '',
        note: '',
    });

    const [complete, setComplete] = useState(null);

    const [formError, setFormError] = useState('');
    const [isShowModalRenameRoute, setIsShowModalRenameRoute] = useState(false);
    useEffect(() => {
        if (!isShowModalRenameRoute) {
            setFormError('');
        }
    }, [isShowModalRenameRoute]);

    const handleRenameTemplate = (id, type) => {
        let template;
        if (type === 'cargo') {
            template = cargoTemplates.data.find((item) => item.id === id);
        }
        if (type === 'route') {
            template = routesTemplates.data.find((item) => item.id === id);
        }
        if (template) {
            const templateData = { name: template.name, note: template.note || '' };
            setRenameRouteTemp(templateData);
        }
        setIsShowModalRenameRoute(true);
        setIdTempRoute(id);
    };
    const updateTemplateName = () => {
        if (!renameRouteTemp.name.trim()) {
            setFormError('Название шаблона не может быть пустым');
            return;
        }
        if (renameRouteTemp.name.trim().length < 2) {
            setFormError('Название шаблона не может быть короче 2 символов');
            return;
        }
        if (renameRouteTemp.note !== '' && renameRouteTemp.note.length < 2) {
            setFormError('Примечание может быть пустым, либо не короче 2 символов');
            return;
        }

        updateTemplateRouteName(axiosPrivate, idTempRoute, currentUser?.id, renameRouteTemp)
            .then(() => {
                if (tab === 'routes') {
                    getRouteTemplates(
                        axiosPrivate,
                        currentUser?.id,
                        routeTemplatesPag.currentPage,
                        routeTemplatesPag.pageLimit,
                    ).then((r) => {
                        setRoutesTemplates((prevState) => ({
                            ...prevState,
                            data: r?.data?.body?.data,
                            meta: r?.data?.body?.meta,
                            isLoading: true,
                        }));
                    });
                } else {
                    //Check if that works
                    getCargoTemplates(
                        axiosPrivate,
                        currentUser?.id,
                        cargoTemplatesPag.currentPage,
                        cargoTemplatesPag.pageLimit,
                    ).then((r) => {
                        setCargoTemplates((prevState) => ({
                            ...prevState,
                            data: r?.data?.body?.data,
                            meta: r?.data?.body?.meta,
                            isLoading: true,
                        }));
                    });
                }
                setIsShowAlert(true);
                setComplete(true);
                setRenameRouteTemp({ name: '', note: '' });
            })
            .catch(() => {
                setIsShowAlert(true);
                setComplete(false);
            })
            .finally(() => {
                setIsShowModalRenameRoute(false);
                setFormError('');
            });
    };

    const [isShowModalDeleteTemplateRoute, setIsShowModalDeleteTemplateRoute] = useState(false);
    const deleteRouteTemplate = () => {
        deleteTemplate(idTempRoute, axiosPrivate)
            .then(() => {
                if (tab === 'routes') {
                    getRouteTemplates(
                        axiosPrivate,
                        currentUser?.id,
                        routeTemplatesPag.currentPage,
                        routeTemplatesPag.pageLimit,
                    ).then((r) => {
                        setRoutesTemplates((prevState) => ({
                            ...prevState,
                            data: r?.data?.body?.data,
                            meta: r?.data?.body?.meta,
                            isLoading: true,
                        }));
                    });
                }
                //Check if that works
                if (tab === 'cargo') {
                    getCargoTemplates(
                        axiosPrivate,
                        currentUser?.id,
                        cargoTemplatesPag.currentPage,
                        cargoTemplatesPag.pageLimit,
                    ).then((r) => {
                        setCargoTemplates((prevState) => ({
                            ...prevState,
                            data: r?.data?.body?.data,
                            meta: r?.data?.body?.meta,
                            isLoading: true,
                        }));
                    });
                }
                setIsShowAlert(true);
                setComplete(true);
            })
            .catch(() => {
                setIsShowAlert(true);
                setComplete(false);
            });
    };

    return (
        <div className="box px-0 p-lg-4 p-xl-5">
            <Link to={getURL(appRoutes.OFFICE)} className="fs-12 fw-5 d-block d-lg-none mb-3 mb-sm-5">
                <span className="green fs-15 me-2">⟵</span> Назад
            </Link>
            <h1 className="dark-blue text-center d-lg-none">Шаблоны</h1>
            {/* TABS */}
            <div className="d-flex align-items-center fs-12 fw-5 title-font mb-4 mb-xl-5 position-relative">
                <button
                    type="button"
                    className={tab === 'routes' ? 'active tab-btn' : 'tab-btn'}
                    onClick={() => setTab('routes')}
                >
                    {(currentUser?.roleId === 3 || currentUser?.roleId === 4 || currentUser?.roleId === 1) && //@ts-ignore
                        `Маршруты (${routesTemplates?.meta?.total || 0})`}
                </button>
                <button
                    type="button"
                    className={tab === 'cargo' ? 'active tab-btn ms-3 ms-sm-4 ms-xl-5' : 'tab-btn ms-3 ms-sm-4 ms-xl-5'}
                    onClick={() => setTab('cargo')}
                >
                    {(currentUser?.roleId === 2 || currentUser?.roleId === 4 || currentUser?.roleId === 1) && //@ts-ignore
                        `Грузы (${cargoTemplates?.meta?.total || 0})`}
                </button>
                {complete && (
                    <Alert show={isShowAlert} className="position-absolute end-0 m-0 p-2" variant="success">
                        <span>Изменения применены</span>
                    </Alert>
                )}
                {complete === false && (
                    <Alert show={isShowAlert} className="position-absolute end-0 m-0 p-2" variant="danger">
                        <span>Ошибка</span>
                    </Alert>
                )}
            </div>
            {/* TILES */}
            {tab === 'routes' &&
                ((currentUser?.roleId === 3 || currentUser?.roleId === 4 || currentUser?.roleId === 1) &&
                routesTemplates?.isLoading ? (
                    routesTemplates?.data?.length ? (
                        routesTemplates?.data?.map((i, index) => (
                            <div key={index}>
                                <AdTemplate
                                    isDark={true}
                                    id={i?.id}
                                    type="route"
                                    className="mb-3 mb-sm-4"
                                    title={i?.name}
                                    note={i?.note}
                                    toRoute={i?.route.toRoute}
                                    date={i?.route.date}
                                    fromRoute={i?.route.fromRoute}
                                    car={i?.route.car}
                                    bargainType={i?.route.bargainType}
                                    calculateType={i?.route.calculateType}
                                    vatPrice={i?.route.vatPrice}
                                    notVatPrice={i?.route.noVatPrice}
                                    prepayment={i?.route.prepayment}
                                    contacts={i?.route.contacts}
                                    callbackForRename={handleRenameTemplate}
                                    callbackForDelete={(id) => {
                                        setIsShowModalDeleteTemplateRoute(true);
                                        setIdTempRoute(id);
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <h6 className="text-center w-100 p-5">У вас пока нет маршрутов</h6>
                    )
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader color="#545454" />
                    </div>
                ))}
            {tab === 'cargo' &&
                ((currentUser?.roleId === 2 || currentUser?.roleId === 4 || currentUser?.roleId === 1) &&
                cargoTemplates?.isLoading ? (
                    cargoTemplates?.data?.length ? (
                        cargoTemplates?.data?.map((i, index) => (
                            <div key={index}>
                                <AdTemplate
                                    isDark={true}
                                    id={i?.id}
                                    type="cargo"
                                    className="mb-3 mb-sm-4"
                                    title={i?.name}
                                    note={i?.note}
                                    fromRoute={getFirstRouteForCargo(i?.id)}
                                    date={i?.cargo?.loadings[0]?.date}
                                    toRoute={getLastRouteForCargo(i?.id)}
                                    cargoInfo={getCargoInfo(i?.id)}
                                    bargainType={i?.cargo?.bargainType}
                                    calculateType={i?.cargo?.calculateType}
                                    vatPrice={i?.cargo?.vatPrice}
                                    notVatPrice={i?.cargo?.noVatPrice}
                                    prepayment={i?.cargo?.prepayment}
                                    contacts={i?.cargo?.contacts}
                                    url={`/cargo/add`}
                                    callbackForRename={handleRenameTemplate}
                                    callbackForDelete={(id) => {
                                        setIsShowModalDeleteTemplateRoute(true);
                                        setIdTempRoute(id);
                                    }}
                                />
                            </div>
                        ))
                    ) : (
                        <h6 className="text-center w-100 p-5">У вас пока нет грузов</h6>
                    )
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader color="#545454" />
                    </div>
                ))}
            {tab === 'routes' && routesTemplates?.data?.length > 0 && (
                //@ts-ignore
                <Pagination
                    pageLimit={routeTemplatesPag.pageLimit}
                    currentPage={routeTemplatesPag.currentPage}
                    setCurrentPage={routeTemplatesPag.setCurrentPage}
                    pagesDisplayedLimit={3}
                    //@ts-ignore
                    itemsAmount={routesTemplates?.meta?.total || 0}
                    startingPage={routeTemplatesPag.startingPage}
                    setStartingPage={routeTemplatesPag.setStartingPage}
                />
            )}
            {tab === 'cargo' && cargoTemplates?.data?.length > 0 && (
                //@ts-ignore
                <Pagination
                    pageLimit={cargoTemplatesPag.pageLimit}
                    currentPage={cargoTemplatesPag.currentPage}
                    setCurrentPage={cargoTemplatesPag.setCurrentPage}
                    pagesDisplayedLimit={3}
                    //@ts-ignore
                    itemsAmount={cargoTemplates?.meta?.total || 0}
                    startingPage={cargoTemplatesPag.startingPage}
                    setStartingPage={cargoTemplatesPag.setStartingPage}
                />
            )}
            <RenameModal
                isShowModalRenameRoute={isShowModalRenameRoute}
                setIsShowModalRenameRoute={setIsShowModalRenameRoute}
                renameRouteTemp={renameRouteTemp}
                setRenameRouteTemp={setRenameRouteTemp}
                formError={formError}
                updateTemplateName={updateTemplateName}
            />
            <DeleteModal
                isShowModalDeleteTemplateRoute={isShowModalDeleteTemplateRoute}
                setIsShowModalDeleteTemplateRoute={setIsShowModalDeleteTemplateRoute}
                deleteRouteTemplate={deleteRouteTemplate}
            />
        </div>
    );
}
