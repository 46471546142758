import { apiRoutes } from "@core/config/api";
import { getURL } from "@shared/lib/getURL";
import Logger from "@shared/lib/logger";

export const deleteTemplate = async (adTemplateId, axiosPrivate) => {
    try {
        return axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.TEMPLATE_DELETE, adTemplateId));
    } catch (error) {
        Logger.error(error);
    }
};
