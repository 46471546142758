import store from '@core/store';
import { accountSlice } from '@entities/.app/account/account.slice';
import fingerprint from '@fingerprintjs/fingerprintjs';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';
import axios, { AxiosInstance } from 'axios';

export const apiRoutes = {
    //APP
    APP_API_URL: process.env.REACT_APP_API_URL,
    APP_BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
    APP_HOME: 'home',

    //SUPPORT::REPORTS
    REPORT_ROUTE: 'report/route',
    REPORT_CARGO: 'report/cargo',
    REPORT_USER: 'report/user',
    REPORT_TOPIC: 'report/topic',
    REPORT_TOPIC_MESSAGE: 'report/topicMessage',

    //SUPPORT::QUESTION
    QUESTION_ASK: 'question',

    //CIAM::AUTH
    EMAIL_VERIFY: 'auth/register/emailVerify',
    REGISTER: 'auth/register',
    LOGIN: 'auth/login',
    FORGOT_PASSWORD_VERIFY_EMAIL: 'auth/forgotPassword/emailVerify',
    FORGOT_PASSWORD_CODE_VERIFY: 'auth/forgotPassword/codeVerify',
    FORGOT_PASSWORD: 'auth/forgotPassword',
    REFRESH_TOKEN: 'auth/refreshToken',
    LOGOUT: 'auth/logout',

    //CIAM::USER
    ACCOUNT_TYPES: 'user/accountTypes',
    ACTIONS_USER: 'user',
    DELETE_AVATAR: 'user/deleteAvatar',

    //CIAM::TARIFFS
    TARIFFS_GET: 'tariffs',

    //CIAM::PAYMENTS
    PAYMENTS_BUY_TARIFF: 'payments/buyTariff',

    //NEWS
    NEWS: 'news',
    RANDOM_NEWS: 'news/random',

    //FORUM::TOPIC
    TOPIC_ACTIONS: 'topic',
    TOPIC_PAGINATE: 'topic/paginate',
    TOPIC_SEARCH: 'topic/search',
    TOPIC_STATISTICS: 'topic/statistics',
    TOPIC_USER_PAGINATE: 'topic/user',
    TOPIC_LIKE: 'topic/like',
    TOPIC_MESSAGE_ACTIONS: 'topic/message',
    TOPIC_MESSAGE_CREATE: 'topic/message/create',
    TOPIC_MESSAGE_LIKE: 'topic/message/like',
    TOPIC_MESSAGE_PAGINATE: 'topic/message/paginateLastMessages',

    //APP_EVENT
    APP_EVENT_FEED_CHATS: 'appEventFeedChats',
    APP_EVENT_FEEDS: 'appEventFeeds',
    APP_EVENTS: 'appEvents',

    //MARKET::CAR
    CAR: 'car',
    CAR_TYPES: 'car/bodyTypes',
    CARS: 'car/user',

    //MARKET::CAR
    CARGO_TEMPLATE: 'template/cargo',
    CARGO_TEMPLATE_ACTIONS: 'template',

    //MARKET::CARGO
    CARGO_ITEM_TYPES: 'cargo/itemTypes',
    CARGO_PACKAGE_TYPES: 'cargo/packageTypes',
    CARGO_COUNT: 'cargo/count',
    CARGO_SEARCH: 'cargo/search',
    CARGO_PAGINATE: 'cargo/paginate',
    CARGO_NOT_ARCHIVE: 'cargo/notArchive',
    CARGO_ARCHIVE: 'cargo/archive',
    CARGO_UNARCHIVE: 'cargo/unArchive',
    CARGO_ACTIONS: 'cargo',

    //MARKET::ROUTE
    ROUTE_COUNT: 'route/count',
    ROUTE_SEARCH: 'route/search',
    ROUTE_PAGINATE: 'route/paginate',
    ROUTE_NOT_ARCHIVE: 'route/notArchive',
    ROUTE_ARCHIVE: 'route/archive',
    ROUTE_UNARCHIVE: 'route/unArchive',
    ROUTE_ACTIONS: 'route',

    //MARKET::TEMPLATE
    TEMPLATE_ROUTE_SAVE: 'template/route',
    TEMPLATES_ROUTE_GET: 'template/route',
    TEMPLATE_DELETE: 'template',
    TEMPLATE_UPDATE_NAME: 'template',

    //DEAL
    DEAL: 'deal',
    DEALS_ROUTE: 'deal/route',
    DEALS_CARGO: 'deal/cargo',
    DEAL_DOC: 'dealDocument',
    DEAL_DOC_EXIST_OPEN: 'dealDocument/exist/open',
    DEAL_DOC_EXIST_CLOSE: 'dealDocument/exist/close',
    DEAL_DOC_DATASET: 'dealDocumentDataSet',
};

export const loadFingerPrint = async () => {
    const fp = await fingerprint.load();
    const result = await fp.get();
    localStorage.setItem('fingerprint', result.visitorId);
};

export const getAxiosPrivate = () => {
    // await loadFingerPrint();

    const httpClient: AxiosInstance & { countError400?: number } = axios.create({
        baseURL: apiRoutes.APP_API_URL,
        headers: {
            'Content-Type': 'multipart/form-data',
            'User-Fingerprint': localStorage.getItem('fingerprint'),
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true,
    });
    httpClient.countError400 = 0;

    const currentUserToken = () => store.getState().account.token;
    const refreshToken = async () => {
        const response = await httpClient.get(getURL(apiRoutes.APP_API_URL, apiRoutes.REFRESH_TOKEN));
        const { token, user } = response.data.body;
        const payload = { token, user };

        store.dispatch(accountSlice.actions.setCurrentUser(payload));

        return token;
    };

    httpClient?.interceptors?.request?.use(
        (request) => {
            return loadFingerPrint().then(() => {
                if (!request.headers['Authorization']) {
                    request.headers['Authorization'] = `Bearer ${currentUserToken()}`;
                    request.headers['User-Fingerprint'] = localStorage.getItem('fingerprint');
                }
                return request;
            });
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    httpClient?.interceptors?.response?.use(
        (response) => response,
        async (error) => {
            const prevRequest = error?.config;
            if (error?.response?.status === 403 && !prevRequest.isSent) {
                Logger.log('interceptor is working');
                prevRequest.isSent = true;
                const newAccessToken = await refreshToken();
                prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return httpClient(prevRequest);
            }
            if (error?.response?.status === 400) {
                if (store.getState().account.token && httpClient.countError400 < 10) {
                    httpClient.countError400++;
                    return httpClient(prevRequest);
                } else {
                    httpClient.countError400 = 0;
                    return store.dispatch(accountSlice.actions.resetCurrentUser(null));
                }
            }
            return Promise.reject(error);
        },
    );

    return httpClient;
};
