import { useEffect, useState } from 'react';
import { IoChevronBackSharp, IoChevronForwardSharp } from 'react-icons/io5';
import { Link, NavLink } from 'react-router-dom';
import SwiperCore from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import {appRoutes} from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { getDataForHomePage } from '@entities/.app/app.api';
import { selectCities, selectSelectedCity } from '@entities/.app/cities/cities.selectors';
import { selectCurrentUser } from '@entities/.app/account/account.selectors';
import { getGeneralCapacity, getGeneralWeight, getNotesType, getRoute } from '@entities/market/cargo/cargo.actions';
import CargoCard from '@features/market/cargo/Cargo.Card';
import RouteCard from '@features/market/route/Route.Card';
import NewsArticleCard from '@features/news/News.Article.Card';
import { getDate } from '@shared/lib/dateTIme';
import { getURL } from '@shared/lib/getURL';
import Loader from '@shared/ui/Loader';
import SearchInput from '@shared/ui/SearchInput';

SwiperCore.use([Navigation, Pagination]);

const paginatePage = 1;
const paginateOrderBy = 'desc';
const adPaginateLimit = 6;
const newsPaginateLimit = 5;

const InfoCarousel = () => {
    return (
        <div id="slider-1" className="carousel slide shadow" data-bs-ride="carousel" style={{borderRadius: '1em'}}>
            <div className="carousel-indicators">
                {[...Array(3)].map((_, idx) => (
                    <button
                        key={idx}
                        type="button"
                        data-bs-target="#slider-1"
                        data-bs-slide-to={idx}
                        className={!idx ? 'active' : ''}
                        // aria-current={!idx ? "true" : "false"}
                        aria-label={`Slide ${idx + 1}`}
                    />
                ))}
            </div>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src="/img/img1.jpg" className="img" alt="слайд 1" />
                    <div className="carousel-item__text">
                        <h2 className="title">Грузоперевозки по России</h2>
                        <p className="description">
                            Биржа грузоперевозок и экосистема сервисов для транспортной логистики в России. Помогаем
                            находить грузы, проверенных перевозчиков и экономить за счёт автоматизации процессов
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="/img/img1.jpg" className="img" alt="слайд 2" />
                    <div className="carousel-item__text">
                        <h2 className="title">Грузоперевозки по России</h2>
                        <p className="description">
                            Получите бесплатную консультацию о пользовании системой по телефону +7-927-927-80-23
                        </p>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src="/img/img1.jpg" className="img" alt="слайд 3" />
                    <div className="carousel-item__text">
                        <h2 className="title">Грузоперевозки по России</h2>
                        <p className="description">
                            Посетите раздел «форумы», вступайте в сообщество и находите ответы на все вопросы!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AdCounts = ({ cargosCount, routesCount }) => {
    const currentUser = useAppSelector(selectCurrentUser);

    return (
        <div
            className={`col-lg-4 col-xxl-3 d-flex flex-lg-column justify-content-between mt-4 mt-lg-0 cargo-count ${
                !cargosCount ? 'cargo-count_hasnt' : ''
            }`}
        >
            <div className="box text-center">
                {cargosCount && (
                    <>
                        <div className="title-font dark-blue fw-9 fs-25 mb-2">{cargosCount}</div>
                        <div className="fs-12 mb-3">Грузов доступно</div>
                    </>
                )}
                {[2, 4].includes(currentUser.roleId) && (
                    <Link to={appRoutes.MARKET_CARGO_ADD} className="btn btn-1 fs-12 w-100 px-2">
                        Добавить груз
                    </Link>
                )}
            </div>
            <div className="box text-center">
                {routesCount && (
                    <>
                        <div className="title-font dark-blue fw-9 fs-25 mb-2">{routesCount}</div>
                        <div className="fs-12 mb-3">Маршрутов на сайте</div>
                    </>
                )}
                {[3, 4].includes(currentUser.roleId) && (
                    <Link to={appRoutes.MARKET_ROUTE_ADD} className="btn btn-1 fs-12 w-100 px-2">
                        Добавить маршрут
                    </Link>
                )}
            </div>
        </div>
    );
};

const SearchForm = () => {
    const cities = useAppSelector(selectCities);
    const [citysForSearch, setCitysForSearch] = useState({
        fromRoute: null,
        toRoute: null,
        date: null,
    });

    return (
        <form>
            <div className="row g-3 g-sm-4 justify-content-center">
                <div className="col-md-4">
                    <div className="fs-15 fw-5 mb-1 mb-sm-3">Откуда</div>
                    <SearchInput
                        data={cities}
                        placeHolder={'Город отправления'}
                        callback={(value) =>
                            setCitysForSearch((prevState) => ({
                                ...prevState,
                                fromRoute: value,
                            }))
                        }
                    />
                </div>
                <div className="col-md-4">
                    <div className="fs-15 fw-5 mb-1 mb-sm-3">Куда</div>
                    <SearchInput
                        data={cities}
                        callback={(value) =>
                            setCitysForSearch((prevState) => ({
                                ...prevState,
                                toRoute: value,
                            }))
                        }
                        placeHolder={'Город назначения'}
                    />
                </div>
                <div className="col-md-4 col-xl-3 col-xxl-2">
                    <div className="fs-15 fw-5 mb-1 mb-sm-3">Дата</div>
                    <input
                        type="date"
                        className="fs-15"
                        onChange={(e) => {
                            setCitysForSearch((prevState) => ({
                                ...prevState,
                                dateN: e.target.value,
                                date: getDate(e.target.value),
                            }));
                        }}
                    />
                </div>
                <div className="col-12 col-xl-11 col-xxl-10 d-md-flex flex-md-row-reverse justify-content-between fs-12">
                    <div className="d-flex">
                        <NavLink
                            to={appRoutes.SEARCH}
                            className="btn btn-1 px-2 px-md-4 px-lg-5"
                            state={{ searchType: 'car', ...citysForSearch }}
                        >
                            Найти машину
                        </NavLink>
                        <NavLink
                            to={appRoutes.SEARCH}
                            className="btn btn-1 ms-2 ms-sm-4 px-2 px-md-4 px-lg-5"
                            state={{ searchType: 'cargo', ...citysForSearch }}
                        >
                            Найти груз
                        </NavLink>
                    </div>
                </div>
            </div>
        </form>
    );
};

const CargosCarousel = ({ cargoSwiperItems }) => {
    return cargoSwiperItems.isLoaded ? (
        cargoSwiperItems?.items?.length > 0 ? (
            <section className="sec-3 container mb-6">
                <h2>Грузы в вашем городе</h2>
                <div className="position-relative mb-4">
                    <Swiper
                        className="swiper-4"
                        spaceBetween={4}
                        slidesPerView={2}
                        breakpoints={{
                            576: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 8,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 16,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        pagination={{
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {cargoSwiperItems.items.map((item) => (
                            <SwiperSlide key={item.id}>
                                <CargoCard
                                    id={item.id}
                                    title={item?.type?.name}
                                    route={getRoute(item, true)}
                                    notesType={getNotesType(item?.items)}
                                    capacity={getGeneralCapacity(item?.items)}
                                    weight={getGeneralWeight(item?.items)}
                                    cargo={item}
                                />
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev">
                            <IoChevronBackSharp />
                        </div>
                        <div className="swiper-button-next">
                            <IoChevronForwardSharp />
                        </div>
                        <div className="swiper-pagination"></div>
                    </Swiper>
                </div>
                <NavLink to={appRoutes.SEARCH} className="btn btn-2 fs-12 text-uppercase mx-auto">
                    Найти груз
                </NavLink>
            </section>
        ) : null
    ) : (
        <div className="w-100 d-flex justify-content-center p-5">
            <Loader color="#545454" />
        </div>
    );
};

const RoutesCarousel = ({ routes }) => {
    return routes?.isLoading ? (
        routes?.data?.length > 0 ? (
            <section className="sec-3 container mb-6">
                <h2>Маршруты в Вашем городе</h2>
                <div className="position-relative mb-4">
                    <Swiper
                        className="swiper-4"
                        spaceBetween={4}
                        slidesPerView={2}
                        freeMode={true}
                        breakpoints={{
                            576: {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 8,
                            },
                            992: {
                                slidesPerView: 3,
                                spaceBetween: 16,
                            },
                            1400: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                        }}
                        pagination={{
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        }}
                    >
                        {routes?.data.map((route, index) => (
                            <SwiperSlide key={index}>
                                <RouteCard
                                    id={route.id}
                                    title={`${route.fromRoute} - ${route.toRoute}`}
                                    route={`${route.fromRoute} - ${route.toRoute}`}
                                    size={route.car?.capacity}
                                    carrying={route.car?.carrying}
                                    carType={route.carBodyType?.name}
                                    dimensions={`${route.car?.length}/${route.car?.width}/${route.car?.height}`}
                                    date={route.dateType ? 'единожды' : 'постоянно'}
                                    inProfile={false}
                                />
                            </SwiperSlide>
                        ))}
                        <div className="swiper-button-prev">
                            <IoChevronBackSharp />
                        </div>
                        <div className="swiper-button-next">
                            <IoChevronForwardSharp />
                        </div>
                        <div className="swiper-pagination"></div>
                    </Swiper>
                </div>
                <NavLink
                    to={appRoutes.SEARCH}
                    className="btn btn-2 fs-12 text-uppercase mx-auto"
                    state={{ searchType: 'car' }}
                >
                    Найти МАШИНУ
                </NavLink>
            </section>
        ) : null
    ) : (
        <div className="w-100 d-flex justify-content-center p-5">
            <Loader color="#545454" />
        </div>
    );
};

const About = () => {
    return (
        <div className="container h-100 d-flex align-items-center">
            <div className="row flex-md-row-reverse justify-content-end">
                <div className="col-md-6 col-lg-5 col-xl-4 pt-xxl-4">
                    <h2 className="text-md-start">О сервисе</h2>
                    <p>
                        Участники сервиса Эритранс (Eritrans) могут организовать весь процесс перевозки грузов в рамках
                        нашей системы: размещать и искать заказы, проверять контрагентов, находить надежных
                        исполнителей, обмениваться необходимыми документами и находить ответы на все вопросы на форуме.
                        Наша цель: снизить количество посредников в организации перевозок и увеличить загрузки
                        транспорта. И следовательно доходов перевозчиков и транспортных компаний. Как? Эритранс
                        (Eritrans) объединяет в одном месте грузоперевозчиков и заказчиков, предоставляя удобный и
                        надежный сервис для работы. Таким образом, онлайн- сервис ускоряет организацию перевозки и
                        убирает лишние звенья в цепи, увеличивая прозрачность процесса и рентабельность перевозок.
                    </p>
                </div>
                <div className="col-md-6">
                    <img src="/img/img1.png" alt="" className="img-fluid" />
                </div>
            </div>
        </div>
    );
};

const Infographics = () => {
    return (
        <>
            <h2 style={{ color: '#FFF' }}>С нами удобно</h2>
            <div className="d-none d-lg-flex row gx-4 gx-xxl-5" style={{ background: '#FFF' }}>
                <div className="col-1">
                    <div className="ribbon ribbon-left">Грузоотправитель</div>
                </div>
                <div className="col-3 d-flex flex-column justify-content-between py-4">
                    <div>
                        <div className="title title-left">
                            <span>1. Размещает груз на площадке</span>
                        </div>
                        <div className="fs-11">
                            Легко разместите объявление о грузе с необходимыми параметрами, его увидят все остальные
                            участники
                        </div>
                    </div>
                    <div>
                        <div className="title title-left">
                            <span>3. Готовит заявку</span>
                        </div>
                        <div className="fs-11">
                            Используйте готовые шаблоны документов системы Эритранс (Eritrans), договоритесь с
                            исполнителем о работе
                        </div>
                    </div>
                    <div>
                        <div className="title title-left">
                            <span>5. Закрытие сделки</span>
                        </div>
                        <div className="fs-11">
                            Ожидайте выполнение работы исполнителя
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <img src="/img/scheme.png" alt="Схема" className="scheme" style={{ padding: 10 }} />
                </div>
                <div className="col-3 d-flex flex-column justify-content-between py-4">
                    <div>
                        <div className="title title-right">
                            <span>2. Берет груз</span>
                        </div>
                        <div className="fs-11">
                            Легко найдите необходимый груз, сделайте отклик и получите заказ
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                    <div>
                        <div className="title title-right">
                            <span>4. Одобряет заявку</span>
                        </div>
                        <div className="fs-11">Согласуйте условия и подготовьтесь к работе</div>
                    </div>
                    <div className="py-5">
                        <div className="title title-right">
                            <span>Все готово для перевозки</span>
                        </div>
                    </div>
                </div>
                <div className="col-1 d-flex justify-content-end">
                    <div className="ribbon ribbon-right">Перевозчик</div>
                </div>
            </div>
            <div className="d-block d-lg-none box shadow">
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-1.svg" alt="Размещает груз на площадке" />
                    </div>
                    <div className="text">
                        <div className="title">
                            <span>1. Размещает груз на площадке</span>
                        </div>
                        <div>
                            Легко разместите объявление о грузе с необходимыми параметрами, его увидят все остальные
                            участники
                        </div>
                    </div>
                    <div className="ribbon ribbon-left">Грузоотправитель</div>
                </div>
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-2.svg" alt="Берет груз" />
                    </div>
                    <div className="text">
                        <div className="title">
                            <span>2. Берет груз</span>
                        </div>
                        <div>Легко найдите необходимый груз, сделайте отклик и получите заказ</div>
                    </div>
                    <div className="ribbon ribbon-left">ПеРЕВОЗЧИК</div>
                </div>
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-3.svg" alt="Готовит заявку" />
                    </div>
                    <div className="text">
                        <div className="title">
                            <span>3. Готовит заявку</span>
                        </div>
                        <div>
                            Используйте готовые шаблоны документов системы Эритранс (Eritrans), договоритесь с
                            исполнителем о работе
                        </div>
                    </div>
                    <div className="ribbon ribbon-left">Грузоотправитель</div>
                </div>
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-4.svg" alt="Одобряет заявку" />
                    </div>
                    <div className="text">
                        <div className="title">
                            <span>4. Одобряет заявку</span>
                        </div>
                        <div>Согласуйте условия и подготовьтесь к работе</div>
                    </div>
                    <div className="ribbon ribbon-left">ПеРЕВОЗЧИК</div>
                </div>
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-5.svg" alt="Закрытие сделки" />
                    </div>
                    <div className="text">
                        <div className="title">
                            <span>5. Закрытие сделки</span>
                        </div>
                        <div>Ожидайте выполнение работы исполнителя</div>
                    </div>
                    <div className="ribbon ribbon-left">Грузоотправитель</div>
                </div>
                <div className="point">
                    <div className="icon">
                        <img src="/img/icons/icon-6.svg" alt="Все готово для перевозки" />
                    </div>
                    <div className="text">
                        <div className="title mb-0">
                            <span>Все готово для перевозки</span>
                        </div>
                    </div>
                    <div className="ribbon ribbon-left">ПЕРЕВОЗЧИК</div>
                </div>
            </div>
        </>
    );
};

const NewsBlock = ({ news }) => {
    return (
        news?.items?.length >= 5 && (
            <section id="sec-6" className="container mb-5">
                <h2>Новости ПОРТАЛА</h2>
                {news.isLoaded ? (
                    news?.items?.length >= 5 ? (
                        <div className="news-grid">
                            {news.items.map((item) => (
                                <NewsArticleCard
                                    key={item.id}
                                    url={getURL('', appRoutes.NEWS_ALL, item.slug)}
                                    title={item.title}
                                    img={item.image}
                                    text={item.description}
                                />
                            ))}
                        </div>
                    ) : null
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader color="#545454" />
                    </div>
                )}
                <Link to={appRoutes.NEWS_ALL} className="btn btn-2 mx-auto mt-5 fs-12 text-uppercase">
                    К другим новостям
                </Link>
            </section>
        )
    );
};

export default function AppHomePage() {
    const selectedCity = useAppSelector(selectSelectedCity);

    const [cargosCount, setCargosCount] = useState(null);
    const [routesCount, setRoutesCount] = useState(null);

    const [cargoSwiperItems, setCargoSwiperItems] = useState({
        isLoaded: false,
        error: null,
        items: [],
    });
    const [routes, setRoutes] = useState({
        isLoading: false,
        data: [],
        meta: [],
    });
    const [news, setNews] = useState({
        isLoaded: false,
        error: null,
        meta: null,
        items: [],
    });
    useEffect(() => {
        const load = async () => {
            const result = await getDataForHomePage({
                paginatePage,
                newsPaginateLimit,
                adPaginateLimit,
                paginateOrderBy,
                selectedCity,
            });
            setRoutesCount(result.routesCount);
            setCargosCount(result.cargosCount);
            setNews((prev) => ({
                ...prev,
                isLoaded: true,
                meta: result?.news?.meta,
                items: result?.news?.data,
            }));
            setCargoSwiperItems((prev) => ({
                ...prev,
                isLoaded: true,
                items: result?.cargos?.data,
            }));
            setRoutes({
                isLoading: true,
                meta: result?.routes?.meta,
                data: result?.routes?.data,
            });
        };
        load();
    }, [selectedCity]);

    return (
        <main>
            <section id="sec-1" className="py-4 py-sm-5">
                <div className="container">
                    <div className="row justify-content-center gx-3">
                        <div className="col-lg-8 col-xxl-7">
                            <InfoCarousel />
                        </div>
                        <AdCounts cargosCount={cargosCount} routesCount={routesCount} />
                    </div>
                </div>
            </section>

            <section id="sec-2" className="mb-5">
                <div className="container py-4 py-sm-5">
                    <SearchForm />
                </div>
            </section>

            <CargosCarousel cargoSwiperItems={cargoSwiperItems} />
            <RoutesCarousel routes={routes} />

            <section id="sec-4" className="mb-6">
                <About />
            </section>
            <section id="sec-5" className="container mb-6">
                <Infographics />
            </section>
            <NewsBlock news={news} />
        </main>
    );
}
