
import { useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { IoAddCircleSharp } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { selectUserId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { getGeneralCapacity, getGeneralWeight, getNotesType, getRoute } from '@entities/market/cargo/cargo.actions';
import { deleteCargo, getArchivedCargo, getNotArchivedCargo, unArchiveCargo } from '@entities/market/cargo/cargo.api';
import CargoCard from '@features/market/cargo/Cargo.Card';
import usePagination from '@shared/hooks/usePagination';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import Loader from '@shared/ui/Loader';
import Pagination from '@shared/ui/Pagination';

const DeleteModal = ({ isShowCardModal, setIsShowCardModal, onDelete, cargoAction, onRecovery }) => {
    return (
        <CustomModal
            isShow={isShowCardModal}
            setIsShow={setIsShowCardModal}
            closeButton={true}
            centered={true}
            size={'lg'}
        >
            <div className="dark-blue fs-12 fw-7 title-font text-center">
                Вы действительно хотите {cargoAction?.type === 'delete' ? 'удалить' : 'восстановить'} объявление?
            </div>
            <div className="row row-cols-sm-2 gx-2 gx-lg-4 mt-4 fs-12">
                <div>
                    {cargoAction?.type === 'delete' ? (
                        <button
                            type="button"
                            className="btn btn-1 w-100 px-4 mb-3 mb-sm-0"
                            onClick={() => {
                                onDelete();
                                setIsShowCardModal(false);
                            }}
                        >
                            Удалить
                        </button>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-1 w-100 px-4 mb-3 mb-sm-0"
                            onClick={() => {
                                onRecovery();
                                setIsShowCardModal(false);
                            }}
                        >
                            Восстановить
                        </button>
                    )}
                </div>
                <div>
                    <button
                        type="button"
                        className="btn btn-2 w-100 px-4 point"
                        onClick={() => setIsShowCardModal(false)}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </CustomModal>
    );
};

const initialPageLimit = 9;

export default function OfficeCargosPage() {
    const axiosPrivate = useAxiosPrivate();

    const currentUserId = useAppSelector(selectUserId);

    const [cargoAction, setCargoAction] = useState(null);
    const [tab, setTab] = useState('active');

    const cargoPagination = usePagination(initialPageLimit);
    const archivedCargoPagination = usePagination(initialPageLimit);

    const [cargo, setCargo] = useState({
        isLoading: false,
        error: null,
        meta: null,
        data: [],
    });
    const [archivedCargo, setArchivedCargo] = useState({
        isLoading: false,
        error: null,
        meta: null,
        data: [],
    });

    const sendCargoReqest = (page, limit) => {
        getNotArchivedCargo(axiosPrivate, currentUserId, page, limit)
            .then((result) =>
                setCargo((prev) => ({ ...prev, isLoading: true, meta: result?.meta, data: result?.data })),
            )
            .catch((error) => setCargo((prev) => ({ ...prev, isLoading: true, error })));
    };

    const sendArchivedCargoRequest = (page, limit) => {
        getArchivedCargo(axiosPrivate, currentUserId, page, limit)
            .then((result) =>
                setArchivedCargo((prev) => ({
                    ...prev,
                    isLoading: true,
                    meta: result?.meta,
                    data: result?.data,
                })),
            )
            .catch((error) => setArchivedCargo((prev) => ({ ...prev, isLoading: true, error })));
    };

    useEffect(() => {
        sendCargoReqest(cargoPagination.currentPage, cargoPagination.pageLimit);
    }, [currentUserId, cargoPagination.currentPage, cargoPagination.pageLimit]);

    useEffect(() => {
        sendArchivedCargoRequest(archivedCargoPagination.currentPage, archivedCargoPagination.pageLimit);
    }, [currentUserId, archivedCargoPagination.currentPage, archivedCargoPagination.pageLimit]);

    //FOR MODAL
    const [isShowCardModal, setIsShowCardModal] = useState(false);
    useEffect(() => {
        !isShowCardModal && setCargoAction(null);
    }, [isShowCardModal]);

    const onDelete = async () => {
        await deleteCargo(axiosPrivate, cargoAction?.id);

        if (tab === 'active') {
            sendCargoReqest(1, initialPageLimit);
            cargoPagination.setCurrentPage(1);
        } else {
            sendArchivedCargoRequest(1, initialPageLimit);
            archivedCargoPagination.setCurrentPage(1);
        }

        setCargoAction(null);
    };

    const onRecovery = async () => {
        await unArchiveCargo(axiosPrivate, cargoAction?.id);

        sendCargoReqest(1, initialPageLimit);
        cargoPagination.setCurrentPage(1);
        sendArchivedCargoRequest(1, initialPageLimit);
        archivedCargoPagination.setCurrentPage(1);

        setCargoAction(null);
    };

    return (
        <div className="box px-0 p-sm-4 p-xl-5">
            <Link to={getURL(appRoutes.OFFICE)} className="fs-12 fw-5 d-block d-lg-none mb-3 mb-sm-5">
                <span className="green fs-15 me-2">⟵</span> Назад
            </Link>
            <h1 className="dark-blue text-center d-lg-none">Мои грузы</h1>
            <div className="d-md-flex flex-row-reverse justify-content-between align-items-center mb-4 mb-xl-5">
                <Link to={getURL(appRoutes.MARKET_CARGO_ADD)} className="btn btn-2 fs-12 px-4 mb-4 mb-md-0">
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 white',
                        }}
                    >
                        <IoAddCircleSharp />
                    </IconContext.Provider>
                    <span className="ms-2">Добавить груз</span>
                </Link>
                <div className="d-flex align-items-center fs-12 fw-5 title-font">
                    <button
                        type="button"
                        className={tab === 'active' ? 'active tab-btn' : 'tab-btn'}
                        onClick={() => setTab('active')}
                    >
                        {`Активные объявления (${cargo?.meta?.total ? cargo.meta.total : '0'})`}
                    </button>
                    <button
                        type="button"
                        className={
                            tab === 'archive' ? 'active tab-btn ms-3 ms-sm-4 ms-xl-5' : 'tab-btn ms-3 ms-sm-4 ms-xl-5'
                        }
                        onClick={() => setTab('archive')}
                    >
                        {`Архив (${archivedCargo?.meta?.total ? archivedCargo.meta.total : '0'})`}
                    </button>
                </div>
            </div>
            {tab === 'active' ? (
                <>
                    <div className="row row-cols-2 row-cols-xxl-3 g-1 g-sm-3 g-md-4">
                        {cargo.isLoading ? (
                            cargo?.data?.length ? (
                                cargo?.data?.length &&
                                cargo.data.map((item) => {
                                    return (
                                        <CargoCard
                                            isDark={true}
                                            key={item.id}
                                            id={item.id}
                                            title={item?.type?.name}
                                            route={getRoute(item, true)}
                                            notesType={getNotesType(item?.items)}
                                            capacity={getGeneralCapacity(item?.items)}
                                            weight={getGeneralWeight(item?.items)}
                                            callback={({ id, type }) => {
                                                setCargoAction({ id, type });
                                                setIsShowCardModal(true);
                                            }}
                                            hasActions
                                            cargo={item}
                                        />
                                    );
                                })
                            ) : (
                                <h6 className="text-center w-100 p-5">У вас пока нет грузов</h6>
                            )
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>
                    {cargo?.data?.length > 0 && (
                        <div className="mt-4">
                            <Pagination
                                pageLimit={cargoPagination.pageLimit}
                                currentPage={cargoPagination.currentPage}
                                setCurrentPage={cargoPagination.setCurrentPage}
                                pagesDisplayedLimit={3}
                                itemsAmount={cargo?.meta?.total || 0}
                                startingPage={cargoPagination.startingPage}
                                setStartingPage={cargoPagination.setStartingPage}
                            />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="row row-cols-2 row-cols-xxl-3 g-1 g-sm-3 g-md-4">
                        {archivedCargo.isLoading ? (
                            archivedCargo?.data?.length ? (
                                archivedCargo?.data?.length &&
                                archivedCargo.data.map((item) => {
                                    const notesType = item?.items?.map((i) => i.noteType);
                                    const generalCapacity = item?.items?.reduce(
                                        (acc, currentValue) => acc + currentValue?.capacity,
                                        0,
                                    );
                                    const generalWeight = item?.items?.reduce(
                                        (acc, currentValue) => acc + currentValue?.weight,
                                        0,
                                    );

                                    return (
                                        <CargoCard
                                            key={item.id}
                                            id={item.id}
                                            title={item?.type?.name}
                                            route={getRoute(item, true)}
                                            notesType={notesType}
                                            capacity={generalCapacity}
                                            weight={generalWeight}
                                            callback={({ id, type }) => {
                                                setCargoAction({ id, type });
                                                setIsShowCardModal(true);
                                            }}
                                            archived
                                            hasActions
                                            cargo={item}
                                        />
                                    );
                                })
                            ) : (
                                <h6 className="text-center w-100 p-5">У вас пока нет грузов</h6>
                            )
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>
                    {archivedCargo?.data?.length > 0 && (
                        <div className="mt-4">
                            <Pagination
                                pageLimit={archivedCargoPagination.pageLimit}
                                currentPage={archivedCargoPagination.currentPage}
                                setCurrentPage={archivedCargoPagination.setCurrentPage}
                                pagesDisplayedLimit={3}
                                itemsAmount={archivedCargo?.meta?.total || 0}
                                startingPage={archivedCargoPagination.startingPage}
                                setStartingPage={archivedCargoPagination.setStartingPage}
                            />
                        </div>
                    )}
                </>
            )}
            <DeleteModal
                isShowCardModal={isShowCardModal}
                setIsShowCardModal={setIsShowCardModal}
                onDelete={onDelete}
                cargoAction={cargoAction}
                onRecovery={onRecovery}
            />
        </div>
    );
}
