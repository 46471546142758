import { ComputeRange } from '@core/types';

//STRUCTURE
export enum ETypeDocNode {
    //PRIMITIVE
    NEWLINE,
    TEXT,

    //INPUTS
    INPUT,
    OUTPUT,
    INPUT_TEXT,
    INPUT_DATE,

    //CONTAINERS
    BLOCK,
    TITLE,
    UL_LIST,

    //DOMAIN
    DATA_PICKER,
    FORM,
    TABLE_UPLOAD,
    TABLE_UNLOAD,
    TABLE_CARGO,
    CAR,
    DRIVER,
    TABLE_SERVICES,
    PAYMENT,
    MEMBERS,
    MEMBERS_FULL,
    REQUESITES,
}

export enum ETypeDocNodeValue {
    ORG_NAME,
    INN,
    ADRESS,
    ADRESS2,
    OGRN,
    RS,
    BANK,
    BIK,
    KS,
    PHONE,
    EMAIL,
    DRIVER_NAME,
    DRIVER_PASSPORT,
}

export const EInsertTypes = {
    REQ_NAME: 'REQ_NAME',
    REQ_ADDRESS: 'REQ_ADDRESS',
    REQ_ADDRESS2: 'REQ_ADDRESS2',
    REQ_INN: 'REQ_INN',
    REQ_OGRN: 'REQ_OGRN',
    REQ_BANK_NAME: 'REQ_BANK_NAME',
    REQ_BANK_INN: 'REQ_BANK_INN',
    REQ_BANK_KPP: 'REQ_BANK_KPP',
    REQ_BANK_RS: 'REQ_BANK_RS',
    REQ_BANK_BIK: 'REQ_BANK_BIK',
    REQ_BANK_KS: 'REQ_BANK_KS',
    REQ_CONTACT_NAME: 'REQ_CONTACT_NAME',
    REQ_CONTACT_PHONE: 'REQ_PHONE',
    REQ_CONTACT_EMAIL: 'REQ_EMAIL',
    DRIVER_NAME: 'DRIVER_NAME',
    DRIVER_PASPORT_FULL: 'DRIVER_PASPORT_FULL',
    DRIVER_PASPORT_PART: 'DRIVER_PASPORT_PART',
    DRIVER_PASPORT_NUMBER: 'DRIVER_PASPORT_NUMBER',
    DRIVER_PASPORT_CODE: 'DRIVER_PASPORT_CODE',
    DRIVER_PASSPORT_LOC: 'DRIVER_PASSPORT_LOC',
    DRIVER_PASSPORT_DATE: 'DRIVER_PASSPORT_DATE',
    DRIVER_LICENSE_FULL: 'DRIVER_LICENSE_FULL',
    DRIVER_LICENSE_NUMBER: 'DRIVER_LICENSE_NUMBER',
    DRIVER_LICENSE_DATE: 'DRIVER_LICENSE_DATE',
    DRIVER_PHONE_NUMBER: 'DRIVER_PHONE_NUMBER'
} as const;

export const EEditorRoles = {
    CUSTOMER: 0,
    CONTRACTOR: 1,
} as const;

export const EDataTypes = {
    DATE: 'date',
    TEXT: 'text',
    SELECT: 'select'
} as const;

export const EPickTypes = {
    REQ: 0,
    DRIVER: 1,
} as const;

export type ValueOf<T> = T[keyof T];

interface IFormField {
    label: string;
    privacy?: ValueOf<typeof EEditorRoles>;
    type: ETypeDocNode;
    dataType?: ValueOf<typeof EDataTypes>;
    insertType?: ValueOf<typeof EInsertTypes>;
}

interface IFieldsGroup {
    fields: IFormField[];
    title?: IContentPrimitive | IDocNode[];
}

type IContentPrimitive = string | number;

interface IExportData {
    label: string;
    size: number | string;
    isIDX?: boolean;
}

export const inputFunctionsTypes = {
    SUM: 'SUM',
    MUL: 'MUL',
    WITH_NDS: 'WITH_NDS',
} as const;

interface IComputeConfig {
    args: (string | number)[];
    function: ValueOf<typeof inputFunctionsTypes>;
}

interface ICompute {
    fieldIDX: number;
    results: {
        [key: string]: ValueOf<typeof inputFunctionsTypes>;
    };
}

export enum EConditionTypes {
    BOOLEAN,
    DICT
}

interface ICondition {
    output?:string;
    type:EConditionTypes;
    rule:any;
    input:string;
}


export interface IDocNode {
    type?: ETypeDocNode | -1; //-1 - SCHEMA HEAEER

    isVisible?:ICondition | any;
    id?:string;
    conditions?:ICondition[];

    imports?: any;
    nativeStyle?: any;
    fontSize?: number | string;
    font?: string;

    compute?: IComputeConfig;
    order?: string;
    content?: IContentPrimitive | IDocNode[];

    // export?: boolean;
    fields?: any;
    cell?: string;
    valueType?: ETypeDocNodeValue;
    defaultValue?: string | number;

    privacyRole?: null | ValueOf<typeof EEditorRoles>;
    isClosed?: boolean;

    pickType?: null | ValueOf<typeof EPickTypes>;
    config?: {
        width?: number;
        fontSize?: number;
        editable?: boolean;
        style?: string;
        isMain?: boolean;
        isBulleted?: boolean;
        fields?: any[];
        title?: string;
        dataType?: ValueOf<typeof EDataTypes>;
        options?: any[];
        creatable?: boolean;
    };
    form?: {
        groups: IFieldsGroup[];
        title?: IContentPrimitive | IDocNode[];
        isList?: boolean;
        compute?: ICompute[];
        conditions?:ICondition[];
        native?: {
            columns: IExportData[];
            isInner?: boolean;
            InDXA?: boolean;
            isFullWidth?: boolean;
        };
    };
}

//RENDER
export interface IDocRenderElementProps {
    node: IDocNode;
}
export interface IDocRenderElementData {
    valueCell?: any;
    dataType?: ValueOf<typeof EDataTypes>;
    options?:any[];
    isClosed?: boolean;
    isRequired?: boolean;
    requesites?: any[];
    members?: string[];
    fields?: any[];
    title?: any;
    isList?: boolean;
    list?: any;
    sumFieldIDX?: number;
    sum?: any;
}

//MODEL
export const EDocModelTypesLabels = [
    'Договор',
    'Договор-заявка',
    'Доверенность',
    'ТТН',
    'Путевой лист',
    'Счет на оплату',
    'Акт выполненных работ',
    'Счет фактура',
];

export interface IDocModel {
    id: number;
    deal_id: number;
    type: ComputeRange<8>[number];
    isClosed: boolean;
    state: string;
    lastChangesCustomer: string[] & string;
    lastChangesContractor: string[] & string;
    createdAt: string;
    updatedAt: string;
}

//EDIT
export interface IDocState {
    [key: string]: any;
}
interface IDocFillFields {
    [key: string]: number;
}

interface IDocEdit {
    startedState: IDocState;
    editedState: IDocState;
    deltaState: IDocState;
    linkedState: IDocState;
    isFullFilled: boolean;
    isModifyed: boolean;
}

export const EDocChangesCodes = {
    DECLARE_CLOSED: 'DECLARE_CLOSED',
    CLOSE: 'CLOSE',
};

interface IDocDataSets {
    list: any[];
    fillFields: IDocFillFields;
    pickType: null | ValueOf<typeof EPickTypes>;
}

export interface IDocumentEntity {
    model: IDocModel;
    structure: IDocNode[];
    conditions:ICondition[]
    edit: IDocEdit;
    dataSets: IDocDataSets;
}

export type IDocExportFormat = 'docx' | 'pdf';
