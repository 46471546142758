import {apiRoutes} from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

const createCar = async (axiosPrivate, payloads, userId) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CAR), { ...payloads, userId });
        return response.data.body;
    } catch (error) {
        throw error;
    }
};

const getCar = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CAR, id));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

const updateCar = async (axiosPrivate, payloads, userId) => {
    try {
        const response = await axiosPrivate.patch(getURL(apiRoutes.APP_API_URL, apiRoutes.CAR, payloads.id), {
            ...payloads,
            userId,
        });
        return response.data.body;
    } catch (error) {
        throw error;
    }
};

const deleteCar = async (axiosPrivate, id) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.CAR, id));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

const getCars = async (axiosPrivate, userId, page = 1, limit = '', orderBy = 'desc') => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.CARS, userId), {
            page,
            limit,
            orderBy,
        });
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};

const getCarTypes = async (axiosPrivate) => {
    try {
        const response = await axiosPrivate.get(getURL(apiRoutes.APP_API_URL, apiRoutes.CAR_TYPES));
        return response.data.body;
    } catch (error) {
        Logger.error(error);
    }
};





export { createCar, deleteCar, getCar, getCarTypes, getCars, updateCar };
