//Libs
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

//Modules
import App from '@core';
import store from '@core/store';
import logger from '@shared/lib/logger';

//@ts-ignore
window.log = logger;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <App />
    </Provider>,
);

// import reportWebVitals from './reportWebVitals';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
