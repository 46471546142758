import fingerprint from '@fingerprintjs/fingerprintjs';
import { Dropdown } from 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRouter from '@core/navigation/Router.App';
import '@core/styles/fonts.css';
import '@core/styles/style.css';
import useInitialAuth from '@entities/.app/account/hooks/account.useInitialAuth';
import Loader from '@shared/ui/Loader';

const App = () => {
    //iniaitial Auth
    const isLoading = useInitialAuth();

    useEffect(() => {
        //init dropdowns
        Array.from(document.querySelectorAll('.dropdown-toggle')).forEach((dropdownNode) => new Dropdown(dropdownNode));
    });

    //Set fingerprint
    useEffect(() => {
        const setFingerPrint = async () => {
            const fp = await fingerprint.load();
            const result = await fp.get();
            localStorage.setItem('fingerprint', result.visitorId);
        };
        setFingerPrint();
    }, []);

    return isLoading ? (
        <div style={{width:'100%',height:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <Loader />
        </div>
    ) : (
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
    );
};
export default App;
