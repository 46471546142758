import { memo, useEffect, useState } from 'react';
import { IconContext } from 'react-icons';
import { MdBorderColor, MdBusinessCenter, MdCancel, MdCheckCircle, MdFolder, MdSend } from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useActions, useAppSelector } from '@core/store/hooks';
import { getAppEvents, getChats, getFeedIdFromDealId, postAppEvent } from '@entities/.app/appEvents/appEvents.api';
import { selectAppEventsList } from '@entities/.app/appEvents/appEvents.selectors';
import { EAppEventActions } from '@entities/.app/appEvents/appEvents.types';
import { getPhotoPath } from '@entities/.app/account/account.actions';
import { selectUserId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { selectCurrentDeal } from '@entities/office/deal/deal.selectrors';
import { EDocModelTypesLabels } from '@entities/office/dealDocument/dealDocument.types';
import { useWidth } from '@shared/hooks/useWidth';
import { getDateTimeUI } from '@shared/lib/dateTIme';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';
import { isExistAndNotEmpty } from '@shared/lib/objects';
import Loader from '@shared/ui/Loader';
import { IoMdMenu } from 'react-icons/io';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Mutex } from 'async-mutex';
import React from 'react';

const messagerEventsTypes = {
    MSGR_SEND: 'Messenger:send',
};

//SCROLL
const scrollMutex = new Mutex();
const chatBoxScrollTo = (scrollH = null, smooth = false) => {
    Logger.log('scrollTo');
    const msgList = document.getElementById('msg-list');
    if (msgList && scrollH !== null) {
        Logger.log({ scrollHeight: msgList.scrollHeight, scrollH });
        if (smooth) {
            msgList.scrollTo({
                top: msgList.scrollHeight - scrollH,
                left: 0,
                behavior: 'smooth',
            });
        } else {
            msgList.scrollTop = msgList.scrollHeight - scrollH;
        }
    }
};
// export const useScrollToBottom = (ref) => {
//     useEffect(() => {
//         ref?.current?.querySelector(':scope > :last-child')?.scrollIntoView();
//     }, [ref]);
// };

const msgColors = {
    ORANGE: 'rgba(255, 150, 0, 0.5)',
    VIOLET: 'rgba(150, 150, 255, 0.5)',
    BLUE: 'rgba(0, 150, 255, 0.5)',
    RED: 'rgb(245 139 139 / 50%)',
};
const sysActionColors = {
    [EAppEventActions.DEAL_NEW]: msgColors.ORANGE,
    [EAppEventActions.DEAL_OPEN]: msgColors.VIOLET,
    [EAppEventActions.DEAL_DOC_EDIT]: msgColors.BLUE,
    [EAppEventActions.DEAL_DOC_DECLARE_CLOSED]: msgColors.ORANGE,
    [EAppEventActions.DEAL_DOC_CLOSE]: msgColors.VIOLET,
    [EAppEventActions.DEAL_DECLARE_START]: msgColors.ORANGE,
    [EAppEventActions.DEAL_START]: msgColors.VIOLET,
    [EAppEventActions.DEAL_DECLARE_FINISH]: msgColors.ORANGE,
    [EAppEventActions.DEAL_FINISH]: msgColors.VIOLET,
    [EAppEventActions.DEAL_DECLARE_CANCEL]: msgColors.RED,
    [EAppEventActions.DEAL_CANCEL]: msgColors.RED,
    [EAppEventActions.DEAL_ARCHIVE]: msgColors.VIOLET,
};

const MsgIcon = (event) => {
    switch (event?.content?.action) {
        case EAppEventActions.DEAL_NEW:
        case EAppEventActions.DEAL_DOC_DECLARE_CLOSED:
        case EAppEventActions.DEAL_DECLARE_FINISH:
        case EAppEventActions.DEAL_DECLARE_CANCEL:
            return <MdBusinessCenter />;
        case EAppEventActions.DEAL_OPEN:
        case EAppEventActions.DEAL_DOC_CLOSE:
        case EAppEventActions.DEAL_START:
        case EAppEventActions.DEAL_FINISH:
        case EAppEventActions.DEAL_ARCHIVE:
            return <MdCheckCircle />;
        case EAppEventActions.DEAL_DOC_EDIT:
            return <MdBorderColor />;
        case EAppEventActions.DEAL_CANCEL:
            return <MdCancel />;
    }
};

const MsgText = ({ event }) => {
    const currentDeal = useAppSelector(selectCurrentDeal);
    const currentUserId = useAppSelector(selectUserId);

    switch (event?.content?.action) {
        case EAppEventActions.DEAL_NEW:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {'Вы откликнулись на объявление пользователя '}
                        <strong>
                            {` ${
                                currentDeal?.contractor?.id !== currentUserId
                                    ? currentDeal?.contractor?.fullName
                                    : currentDeal?.customer?.fullName
                            } `}
                        </strong>
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'откликнулся на ваше объявление'}
                    </>
                );
            }
        case EAppEventActions.DEAL_OPEN:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {'Вы приняли предложение пользователя '}
                        <strong>
                            {` ${
                                currentDeal?.contractor?.id !== currentUserId
                                    ? currentDeal?.contractor?.fullName
                                    : currentDeal?.customer?.fullName
                            }. `}
                        </strong>
                        {'Сделка перешла на стадию "На согласовании"'}
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'принял ваше предложение. Сделка перешла на стадию "На согласовании".'}
                    </>
                );
            }

        case EAppEventActions.DEAL_DOC_EDIT:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {`Вы внесли правки в документ`}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}.`}</strong>
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {`внес правки в документ `}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}.`}</strong>
                    </>
                );
            }
        case EAppEventActions.DEAL_DOC_DECLARE_CLOSED:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {'Вы объявили документ '}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}`}</strong>
                        {' готовым к закрытию. Ожидайте подверждения либо правки с другой стороны. '}
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'объявил документ '}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}`}</strong>
                        {' готовым к закрытию. Подтвердите либо внесите правки. '}
                    </>
                );
            }
        case EAppEventActions.DEAL_DOC_CLOSE:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {'Вы подтвердили закрытие документа '}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}. `}</strong>
                        {'Теперь он будет доступен только для чтения и экспорта.'}
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'подтвердили закрытие документа '}
                        <strong>{` ${EDocModelTypesLabels[event?.content?.docType]}. `}</strong>
                        {'Теперь он будет доступен только для чтения и экспорта.'}
                    </>
                );
            }

        case EAppEventActions.DEAL_DECLARE_START:
            if (event?.founder?.id === currentUserId) {
                return <>{'Вы объявили сделку готовой к началу работ. Ожидайте подверждения с другой стороны. '}</>;
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'объявил сделку готовой к началу работ. Подтвердите либо внесите правки. '}
                    </>
                );
            }
        case EAppEventActions.DEAL_START:
            return <>{'Сделка перешла на стадию "В работе"'}</>;

        case EAppEventActions.DEAL_DECLARE_FINISH:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {
                            'Вы объявили сделку готовой к закрытию, чтобы заполнить финальные документы. Ожидайте подверждения с другой стороны. '
                        }
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {
                            'объявил сделку готовой к закрытию, чтобы заполнить финальные документы. Подтвердите либо внесите правки. '
                        }
                    </>
                );
            }
        case EAppEventActions.DEAL_FINISH:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {
                            'Вы подтвердили выполнение сделки - теперь она перешла на стадию "Ожидающие закрытия": заполните финальные документы.'
                        }
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {
                            'подтвердил выполнение сделки - теперь она перешла на стадию "Ожидающие закрытия": заполните финальные документы.'
                        }
                    </>
                );
            }

        case EAppEventActions.DEAL_DECLARE_ARCHIVE:
            if (event?.founder?.id === currentUserId) {
                return <>{'Вы объявили о желании отменить сделку. Ожидайте подверждения с другой стороны. '}</>;
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'объявил о желании отменить сделку. Подтвердите либо обсудите это вопрос в чате. '}
                    </>
                );
            }
        case EAppEventActions.DEAL_ARCHIVE:
            return (
                <>
                    {
                        'Сделка перешла в архив - через нее вы всегда можете получить доступ к последующим переговорам в чате или документам в режиме чтения.'
                    }
                </>
            );

        case EAppEventActions.DEAL_DECLARE_CANCEL:
            if (event?.founder?.id === currentUserId) {
                return <>{'Вы объявили о желании отменить сделку. Ожидайте подверждения с другой стороны. '}</>;
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {'объявил о желании отменить сделку. Подтвердите либо обсудите это вопрос в чате. '}
                    </>
                );
            }
        case EAppEventActions.DEAL_CANCEL:
            if (event?.founder?.id === currentUserId) {
                return (
                    <>
                        {
                            'Вы подтвердили отмену сделки. Доступ к документам закрыт, объявление снова доступно для совершения сделок. Чат остается открытым для возможного обсуждения других сделок.'
                        }
                    </>
                );
            } else {
                return (
                    <>
                        {'Пользователь'}
                        <strong>{` ${event?.founder?.fullName} `}</strong>
                        {
                            'подтвердил отмену сделки. Доступ к документам закрыт, объявление снова доступно для совершения сделок. Чат остается открытым для возможного обсуждения других сделок.'
                        }
                    </>
                );
            }

        default:
            return <>{event?.content?.text}</>;
    }
};

const defaultEventsPaginator = {
    isLoaded: false,
    items: [],
    currentPage: 1,
    startPage: 1,
    pageLimit: 10,
    meta: null,
};

const Chat = ({
    chat,
    isActiveChat,
    setActiveChatID,
    isOnlyChatsView,
    setIsOnlyChatsView,
    isFull = true,
    setUploadedEvents = null,
}) => {
    Logger.log('Chat');

    const currentUserId = useAppSelector(selectUserId);
    const { setCurrentDeal, setCurrentDealFeedId } = useActions();

    const chatDeal = chat?.feed?.deal;
    const companionUser = chatDeal?.contractor?.id !== currentUserId ? chatDeal?.contractor : chatDeal?.customer;
    const adName = chatDeal?.cargo
        ? `Груз № ${chatDeal?.cargo?.id}`
        : `Маршрут ${chatDeal?.route.fromRoute} - ${chatDeal?.route.toRoute}`;

    return (
        <div
            className={
                'list-group-item list-group-item-action  rounded-0' +
                (isActiveChat ? 'active text-white' : 'list-group-item-light')
            }
            style={{
                background: isActiveChat ? '#038cb8' : '',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                userSelect: 'none',
            }}
            onClick={async () => {
                if (isFull && !isActiveChat) {
                    Logger.log('ONCLICK Chat');
                    setUploadedEvents(defaultEventsPaginator);
                    setActiveChatID(chat?.id);
                    setCurrentDeal(chatDeal);
                    setCurrentDealFeedId(chat?.feed?.id);
                    isOnlyChatsView && setIsOnlyChatsView(false);
                }
            }}
        >
            <img
                src={getPhotoPath(companionUser?.avatar)}
                alt="user"
                className="rounded-circle"
                style={{ margin: ' 0 20px 0 0', minWidth: 50, width: 50, minHeight: 50, height: 50 }}
            />
            <div className="media" style={{ flex: 'auto' }}>
                <div className="media-body ml-4">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className="mb-0">{adName}</h6>
                        {isFull ? (
                            <small className="small font-weight-bold" style={{ width: 140 }}>
                                {getDateTimeUI(chat?.feed?.lastEvent?.createdAt)}
                            </small>
                        ) : null}
                    </div>
                    {isFull ? (
                        <p className={'font-italic  mb-0 text-small pMsg' + (!isActiveChat ? ' text-muted' : '')}>
                            <MsgText event={chat?.feed?.lastEvent} />
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const ChatList = ({
    uploadedChats,
    activeChatID,
    setActiveChatID,
    isOnlyChatsView,
    setIsOnlyChatsView,
    setUploadedEvents,
}) => {
    Logger.log('ChatsList');

    const currentDeal = useAppSelector(selectCurrentDeal);

    const isMobile = useWidth();

    return !isMobile || isOnlyChatsView ? (
        <div className={`col-${isOnlyChatsView ? 12 : 5} px-0`}>
            <div className="bg-white" style={{ borderRight: '1px solid #EEE' }}>
                <div className="bg-gray px-4 py-2 bg-light">
                    <p className="h5 mb-0 py-1">Ленты событий по сделкам</p>
                </div>

                <div className="messages-box">
                    <div className="list-group rounded-0">
                        {uploadedChats?.isLoaded ? (
                            uploadedChats?.items?.length ? (
                                uploadedChats?.items?.map((chat) => (
                                    <Chat
                                        key={chat.id}
                                        chat={chat}
                                        isActiveChat={chat.id === activeChatID ? true : false}
                                        setActiveChatID={setActiveChatID}
                                        isOnlyChatsView={isOnlyChatsView}
                                        setIsOnlyChatsView={setIsOnlyChatsView}
                                        setUploadedEvents={setUploadedEvents}
                                    />
                                ))
                            ) : (
                                <h6 className="text-center w-100 p-5">Нет сделок</h6>
                            )
                        ) : (
                            <div className="w-100 d-flex justify-content-center">
                                <Loader color="#545454" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <div style={{ padding: 10, background: '#038cb8', display: 'flex', alignItems: 'center' }}>
            <IconContext.Provider
                value={{
                    className: `icon-20 white col-2`,
                    style: {
                        display: currentDeal?.status ? 'block' : 'none',
                        width: 50,
                        height: 50,
                        marginRight: 10,
                        borderRadius: 8,
                    },
                }}
            >
                <IoMdMenu onClick={() => setIsOnlyChatsView(true)} />
            </IconContext.Provider>
            <Chat
                chat={uploadedChats.items?.find((chat) => chat.id === activeChatID)}
                isActiveChat={uploadedChats.items?.find((chat) => chat.id === activeChatID)}
                setActiveChatID={undefined}
                isOnlyChatsView={isOnlyChatsView}
                setIsOnlyChatsView={setIsOnlyChatsView}
                isFull={false}
            />
        </div>
    );
};

const ChatBoxMessage = memo(function ChatBoxMessage({ event, idx }: any) {
    Logger.log(
        'ChatBoxMessage',
        // JSON.stringify(event, null, 2)
    );

    const navigate = useNavigate();
    const currentUserId = useAppSelector(selectUserId);

    const pageIndicatorClass = idx === 4 ? 'pageIndicator' : '';

    switch (event.type) {
        case 0:
            /* <!-- System Message--> */
            return (
                <div className={`media w-100 mb-3 ${pageIndicatorClass}`}>
                    <div className="media-body ml-3">
                        <div
                            className="rounded py-3 px-3"
                            style={{
                                background: sysActionColors[event?.content?.action],
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    padding: '10px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <MsgIcon event={event} />
                            </div>
                            <div
                                style={{
                                    paddingLeft: '10px',
                                    cursor: typeof event?.content?.docType === 'number' ? 'pointer' : 'default',
                                }}
                                onClick={async () => {
                                    if (typeof event?.content?.docType === 'number') {
                                        navigate(getURL(appRoutes.OFFICE_DEAL_DOC_EDITOR), {
                                            state: {
                                                docType: event?.content?.docType,
                                            },
                                        });
                                    }
                                }}
                            >
                                <p className="text-small mb-0 text-muted">
                                    <MsgText event={event} />
                                </p>
                                <p className="small text-muted" style={{ marginTop: 10 }}>
                                    {getDateTimeUI(event?.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 1:
            if (event?.founderId !== currentUserId) {
                /* <!-- Sender Message--> */
                return (
                    <div className={`media w-50 mb-3 ${pageIndicatorClass}`}>
                        <div className="media mb-3 d-flex align-items-center mb-3">
                            <img
                                src={getPhotoPath(event?.founder?.avatar)}
                                alt="user"
                                className="rounded-circle"
                                style={{ minWidth: 50, width: 50, minHeight: 50, height: 50 }}
                            />
                            <div className="fw-5 mt-1 ms-2">{event?.founder?.name}</div>
                        </div>

                        <div className="media-body ml-3">
                            <div className="rounded py-2 px-3 mb-2 msg receive">
                                <p className="text-small mb-0 text-white">{event?.content?.text}</p>
                                <p className="small text-white" style={{ marginTop: 10 }}>
                                    {getDateTimeUI(event?.createdAt)}
                                </p>
                            </div>
                        </div>
                    </div>
                );
            } else {
                /* <!-- Reciever Message--> */
                return (
                    <div className={`media w-50  ms-auto mb-3 ${pageIndicatorClass}`}>
                        <div className="rounded media-body msg send py-2 px-3 mb-2">
                            <p className="text-small mb-0 text-white" style={{ padding: '5px' }}>
                                {event?.content?.text}
                            </p>
                            <p className="small" style={{ padding: '5px' }}>
                                {getDateTimeUI(event?.createdAt)}
                            </p>
                        </div>
                    </div>
                );
            }
    }
});

const TypingArea = () => {
    Logger.log('TypingArea');

    const navigate = useNavigate();
    const currentDeal = useAppSelector(selectCurrentDeal);

    //MessageInput
    const [inputValue, setInputValue] = useState('');
    const sendMsg = async (event) => {
        event.preventDefault();
        if (inputValue.length) {
            Logger.log(`MSG SENDED! "${inputValue}"`);
            setInputValue('');
            const sendMsgEvent = new CustomEvent(messagerEventsTypes.MSGR_SEND, { detail: { inputValue } });
            document.dispatchEvent(sendMsgEvent);
        }
    };

    return (
        <form className="input-group bg-light" onSubmit={sendMsg}>
            <div
                className="d-flex align-items-center"
                style={
                    currentDeal?.status
                        ? {
                              cursor: currentDeal?.status ? 'pointer' : 'default',
                              padding: 10,
                              display: 'flex',
                              flexDirection: 'column',
                              fontWeight: 500,
                              background: '#FFF',
                              border: '1px solid rgb(238, 238, 238)',
                              color: 'rgb(52 47 47)',
                          }
                        : { display: 'none!important' }
                }
                onClick={() => {
                    currentDeal.status > 0 && navigate(appRoutes.ROOT_OFFICE_DEAL_DOCS);
                }}
            >
                {currentDeal?.status ? 'Документы' : ''}
                <IconContext.Provider
                    value={{
                        className: `blue`,
                        style: { display: currentDeal?.status ? 'block' : 'none', width: 36, height: 36 },
                    }}
                >
                    <MdFolder />
                </IconContext.Provider>
            </div>
            <input
                style={{ marginLeft: 0 }}
                type="text"
                placeholder="Введите сообщение"
                aria-describedby="button-addon2"
                className="form-control rounded-0 border-0 py-4 bg-light"
                value={inputValue}
                onChange={(e) => {
                    setInputValue(e?.target?.value);
                }}
            />
            <button
                type="submit"
                id="msgSendButton"
                className="input-group-append"
                style={{
                    display: 'flex',
                    cursor: inputValue.length ? 'pointer' : 'default',
                    alignItems: 'center',
                    padding: 10,
                }}
            >
                <IconContext.Provider
                    value={{ className: `${inputValue.length ? 'blue' : 'gray'} `, style: { width: 36, height: 36 } }}
                >
                    <MdSend />
                </IconContext.Provider>
            </button>
        </form>
    );
};

const ChatBox = ({ uploadedChats, uploadedEvents, setUploadedEvents, activeChatID }) => {
    Logger.log('ChatBox', uploadedEvents);

    const isMobile = useWidth();

    const currentUserId = useAppSelector(selectUserId);

    const { setAppEvents } = useActions();

    const newAppEvents = useAppSelector(selectAppEventsList);
    useEffect(() => {
        if (newAppEvents.length) {
            const currentFeedId = uploadedChats.items?.find((chat) => chat.id === activeChatID)?.feedId;
            const currentEvents = newAppEvents.filter(
                (event) => event.feedId === currentFeedId && event.founderId !== currentUserId,
            );
            Logger.log({ newAppEvents, currentFeedId, currentEvents });
            setUploadedEvents((prev) => ({
                ...prev,
                items: [...prev.items, ...currentEvents],
            }));
            setAppEvents([]);
        }
    }, [newAppEvents]);

    return (
        <div
            className={`col-${isMobile ? 12 : 7} px-0`}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
        >
            <div
                id="msg-list"
                className="px-4 py-5 chat-box bg-white"
                style={{ overflowX: 'hidden', position: 'relative' }}
                // ref={scrollContainer}
            >
                <TransitionGroup>
                    {uploadedEvents?.items?.map((event, idx) => {
                        return (
                            <CSSTransition timeout={500} classNames={'event'} key={event.id}>
                                <ChatBoxMessage className="event" event={event} idx={idx} />
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
                {!uploadedEvents?.isLoaded && (
                    <div
                        className="w-100 d-flex justify-content-center"
                        style={{ position: 'absolute', top: 0, width: '100%', height: '100%' }}
                    >
                        <Loader color="#545454" />
                    </div>
                )}
            </div>
            <TypingArea />
        </div>
    );
};

export default function OfficeMessagerPage() {
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();

    const { setCurrentDeal, setCurrentDealFeedId } = useActions();
    const currentUserId = useAppSelector(selectUserId);

    //Pagination chats
    const [uploadedChats, setUploadedChats] = useState<any>({
        isLoaded: false,
        items: [],
        currentPage: 1,
        startPage: 1,
        pageLimit: 100,
        meta: null,
    });
    const loadChats = async (page = uploadedChats.currentPage) => {
        Logger.log(`loadChats func`);
        setUploadedChats((prev) => ({
            ...prev,
            isLoaded: false,
        }));

        const res = await getChats(
            axiosPrivate,
            currentUserId,
            page,
            uploadedChats.pageLimit,
            true, //byDealOnly
        );
        Logger.log(`getChats`, res);

        //DESERIALIZE
        const chats = res?.body?.data.map((chat) => ({
            ...chat,
            feed: {
                ...chat?.feed,
                lastEvent: { ...chat?.feed.lastEvent, content: JSON.parse(chat?.feed?.lastEvent?.content) },
            },
        }));

        setUploadedChats((prev) => ({
            ...prev,
            isLoaded: true,
            meta: res?.body?.meta,
            items: chats,
        }));

        return chats;
    };
    const [activeChatID, setActiveChatID] = useState(null);
    //INIT CHATS
    useEffect(() => {
        const init = async () => {
            const loadedChats = await loadChats();
            if (isExistAndNotEmpty(loadedChats)) {
                const selectedChatId = loadedChats?.find(
                    (chat) => chat?.feed?.deal?.id === location?.state?.dealId,
                )?.id;
                if (selectedChatId) {
                    Logger.log(`selectedChatId`, { selectedChatId });
                    setActiveChatID(selectedChatId);
                    setCurrentDeal(loadedChats?.find((chat) => chat.id === selectedChatId)?.feed?.deal);
                    setCurrentDealFeedId(loadedChats?.find((chat) => chat.id === selectedChatId)?.feed.id);
                }
            }
        };
        init();
    }, []);

    //Pagination events activeChat
    const [uploadedEvents, setUploadedEvents] = useState<any>(defaultEventsPaginator);

    const loadEvents = async (feedId, page = uploadedEvents.currentPage) => {
        Logger.log(`loadEvents func`);

        const msgList = document.getElementById('msg-list');
        const height = msgList?.scrollHeight;
        Logger.log({ height });
        setScrollH(height);

        setUploadedEvents((prev) => ({ ...prev, isLoaded: false }));

        const res = await getAppEvents(axiosPrivate, feedId, page, uploadedEvents.pageLimit);
        //DESERIALIZE
        const events = res?.body?.data?.map((event) => ({ ...event, content: JSON.parse(event?.content || null) }));

        Logger.log(`loadEvents`, { feedId, res, events });

        setUploadedEvents((prev) => ({
            ...prev,
            items: [...events?.reverse(), ...prev.items],
            isLoaded: true,
            meta: res?.body?.meta,
            currentPage: page,
        }));
    };

    // const scrollContainer = useRef();
    // useScrollToBottom(scrollContainer);

    useEffect(() => {
        const msgList = document.getElementById('msg-list');
        const scrollTopHandler = async (e) => {
            const indicatorY =
                document.getElementsByClassName('pageIndicator')[0]?.getBoundingClientRect().top -
                msgList.getBoundingClientRect().top;
            const indicatorYPercent = indicatorY / msgList.getBoundingClientRect().height;
            // console.log('indicatorYPercent', indicatorY, indicatorYPercent);
            if (indicatorYPercent >= 0.2) {
                if (uploadedEvents.meta?.currentPage !== uploadedEvents.meta?.lastPage) {
                    // await scrollMutex.waitForUnlock();
                    if (!scrollMutex.isLocked()) {
                        const release = await scrollMutex.acquire();
                        Logger.log('scrollTopHandler');
                        await loadEvents(
                            uploadedChats.items.find((chat) => chat.id === activeChatID)?.feed?.id,
                            uploadedEvents.meta?.currentPage + 1,
                        );
                        if (msgList.scrollTop < 5 && uploadedEvents.meta?.currentPage !== uploadedEvents.meta?.lastPage)
                            chatBoxScrollTo(scrollH);
                        setTimeout(() => release(), 0);
                    }
                }
            }
        };
        msgList?.addEventListener('scroll', scrollTopHandler);
        return () => {
            msgList?.removeEventListener('scroll', scrollTopHandler);
        };
    }, [activeChatID, uploadedEvents.meta]);

    //SCROLL TO BOTTOM NEW ACTIVE CHAT
    const [scrollH, setScrollH] = useState(0);
    useEffect(() => {
        Logger.log(`useEffect uploadedEvents.isLoaded`, uploadedEvents.isLoaded);
        if (uploadedEvents.isLoaded) {
            setTimeout(() => chatBoxScrollTo(uploadedEvents.currentPage === 1 ? 0 : null), 0);
        }
    }, [uploadedEvents]);

    //UPDATE EVENTS AND LOADER
    useEffect(() => {
        Logger.log(`useEffect uploadedChats`);
        if (uploadedChats.isLoaded && typeof activeChatID === 'number') {
            loadEvents(uploadedChats.items?.find((chat) => chat.id === activeChatID)?.feedId);
        } else {
            setUploadedEvents((prev) => ({ ...prev, items: [], isLoaded: true }));
        }
    }, [activeChatID, uploadedChats]);

    const [isOnlyChatsView, setIsOnlyChatsView] = useState(false);
    useEffect(() => {
        !activeChatID ? !isOnlyChatsView && setIsOnlyChatsView(true) : isOnlyChatsView && setIsOnlyChatsView(false);
    }, [activeChatID]);

    const isMobile = useWidth();

    //SEND MSG
    useEffect(() => {
        const sendMsg = async (event) => {
            setUploadedEvents((prev) => ({
                ...prev,
                // items: [...prev.items, { ...msg, content: JSON.parse(msg.content) }],
                items: [
                    ...prev.items,
                    {
                        id: Date.now(),
                        type: 1,
                        founderId: currentUserId,
                        content: { text: event.detail.inputValue },
                        createdAt: Date.now(),
                    },
                ],
            }));
            setTimeout(() => chatBoxScrollTo(0, true), 0);
            await postAppEvent(
                axiosPrivate,
                1,
                JSON.stringify({ text: event.detail.inputValue }),
                uploadedChats.items?.find((chat) => chat?.id === activeChatID)?.feedId,
                currentUserId,
            );
        };
        document.addEventListener(messagerEventsTypes.MSGR_SEND, sendMsg);
        return () => {
            document.removeEventListener(messagerEventsTypes.MSGR_SEND, sendMsg);
        };
    }, [activeChatID]);

    Logger.log('AccountDealFeedsPage', { activeChatID, uploadedEvents });

    return (
        <div className="d-flex overflow-hidden shadow box p-0" style={{ flexDirection: isMobile ? 'column' : 'row' }}>
            <ChatList
                isOnlyChatsView={isOnlyChatsView}
                uploadedChats={uploadedChats}
                activeChatID={activeChatID}
                setActiveChatID={setActiveChatID}
                setIsOnlyChatsView={setIsOnlyChatsView}
                setUploadedEvents={setUploadedEvents}
            />
            {!isOnlyChatsView ? (
                <ChatBox
                    uploadedChats={uploadedChats}
                    uploadedEvents={uploadedEvents}
                    setUploadedEvents={setUploadedEvents}
                    activeChatID={activeChatID}
                />
            ) : null}
        </div>
    );
}
