import axios from 'axios';

import {apiRoutes} from '@core/config/api';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

const paginateTopics = async (page, limit) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_PAGINATE), { page, limit });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const searchTopics = async (page, limit, query, orderBy = 'desc') => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_SEARCH), {
            page,
            limit,
            query,
            orderBy,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const getStatistics = async () => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_STATISTICS));
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const paginateUserTopics = async (axiosPrivate, userId, page, limit) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_USER_PAGINATE, userId), {
            page,
            limit,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const likeTopic = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_LIKE), payloads);
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const resetLikeTopic = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_LIKE), {
            data: payloads,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const createTopic = async (axiosPrivate, userId, payloads = {}) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_ACTIONS), {
            ...payloads,
            userId,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const getTopic = async (id, userId) => {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_ACTIONS, id, userId ?? ''));
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const createTopicMessage = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(
            getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_MESSAGE_CREATE),
            payloads,
        );
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const paginateTopicMessages = async (topicId, userId, page, limit) => {
    try {
        const response = await axios.post(
            getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_MESSAGE_ACTIONS, topicId, userId),
            {
                page,
                limit,
            },
        );
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const paginateLastMessages = async (page, limit, orderBy) => {
    try {
        const response = await axios.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_MESSAGE_PAGINATE), {
            page,
            limit,
            orderBy,
        });
        return response?.data?.body;
    } catch (error) {
        Logger.error(error);
    }
};

const likeTopicMessage = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_MESSAGE_LIKE), payloads);
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const resetLikeTopicMessage = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.delete(getURL(apiRoutes.APP_API_URL, apiRoutes.TOPIC_MESSAGE_LIKE), {
            data: payloads,
        });
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const reportTopic = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(getURL(apiRoutes.APP_API_URL, apiRoutes.REPORT_TOPIC), payloads);
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

const reportTopicMessage = async (axiosPrivate, payloads) => {
    try {
        const response = await axiosPrivate.post(
            getURL(apiRoutes.APP_API_URL, apiRoutes.REPORT_TOPIC_MESSAGE),
            payloads,
        );
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
};

export {
    createTopic, createTopicMessage, getStatistics, getTopic, likeTopic, likeTopicMessage, paginateLastMessages, paginateTopicMessages, paginateTopics, paginateUserTopics, reportTopic,
    reportTopicMessage, resetLikeTopic, resetLikeTopicMessage, searchTopics
};
