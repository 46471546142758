import { useState } from 'react';
import { IconContext } from 'react-icons';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export default function InputPassword(props) {
    const [visible, setVisibility] = useState(props.visible);
    const handleClick = () => {
        visible ? setVisibility(false) : setVisibility(true);
    };

    return (
        <div className={'password ' + props.className}>
            <input
                type={visible ? 'text' : 'password'}
                name={props.name}
                autoComplete="current-password"
                minLength={8}
                maxLength={20}
                size={8}
                required
                value={props.value}
                onChange={props.onChange}
            />
            <button type="button" onClick={() => handleClick()}>
                {visible ? (
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 gray-4',
                        }}
                    >
                        <BsEye />
                    </IconContext.Provider>
                ) : (
                    <IconContext.Provider
                        value={{
                            className: 'icon-15 gray-4',
                        }}
                    >
                        <BsEyeSlash />
                    </IconContext.Provider>
                )}
            </button>
        </div>
    );
}
