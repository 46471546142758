
import { IoSnow, IoWine } from 'react-icons/io5';
import { MdLocalShipping } from 'react-icons/md';

export const cargoStateIcons = [
    {
        id: 0,
        text: 'Холод',
        element: <IoSnow />,
    },
    {
        id: 1,
        text: 'Хрупкое',
        element: <IoWine />,
    },
    {
        id: 2,
        text: 'Негабаритные',
        element: <MdLocalShipping />,
    },
];


export const initialLoading = [
    [
        {
            name: 'frequency',
            value: '',
            required: true,
        },
        {
            name: 'loadingDate',
            value: '',
            required: false,
        },
        {
            name: 'loadingDays',
            value: '',
            required: false,
        },
        {
            name: 'loadingPeriodType',
            value: '',
            required: false,
        },
        {
            name: 'loadingTimeFrom',
            value: '',
            required: false,
        },
        {
            name: 'loadingTimeTo',
            value: '',
            required: false,
        },
        {
            name: 'isLoadingAllDay',
            value: '',
            required: false,
        },
        {
            name: 'loadingTown',
            value: '',
            required: true,
        },
        {
            name: 'loadingAddress',
            value: '',
            required: true,
        },
        {
            name: 'transportationType',
            value: '',
            required: false,
        },
        {
            name: 'loadingType',
            value: '',
            required: false,
        },
    ],
];
export const initialUnloading = [
    [
        {
            name: 'unloadingDateFrom',
            value: '',
            required: false,
        },
        {
            name: 'unloadingDateTo',
            value: '',
            required: false,
        },
        {
            name: 'unloadingTimeFrom',
            value: '',
            required: false,
        },
        {
            name: 'unloadingTimeTo',
            value: '',
            required: false,
        },
        {
            name: 'isUnloadingAllDay',
            value: '',
            required: false,
        },
        {
            name: 'unloadingTown',
            value: '',
            required: true,
        },
        {
            name: 'unloadingAddress',
            value: '',
            required: true,
        },
        {
            name: 'unloadingType',
            value: '',
            required: false,
        },
    ],
];
export const initialCargo = [
    [
        {
            name: 'cargoType',
            value: '',
            required: false,
        },
        {
            name: 'weight',
            value: '',
            required: true,
        },
        {
            name: 'capacity',
            value: '',
            required: true,
        },
        {
            name: 'length',
            value: '',
            required: false,
        },
        {
            name: 'width',
            value: '',
            required: false,
        },
        {
            name: 'height',
            value: '',
            required: false,
        },
        {
            name: 'cargoPackageType',
            value: '',
            required: false,
        },
        {
            name: 'packageCount',
            value: '',
            required: false,
        },
        {
            name: 'notes',
            value: '',
            required: false,
        },
        {
            name: 'adr1',
            value: false,
            required: false,
        },
        {
            name: 'adr2',
            value: false,
            required: false,
        },
        {
            name: 'adr3',
            value: false,
            required: false,
        },
        {
            name: 'adr4',
            value: false,
            required: false,
        },
        {
            name: 'adr5',
            value: false,
            required: false,
        },
        {
            name: 'adr6',
            value: false,
            required: false,
        },
        {
            name: 'adr7',
            value: false,
            required: false,
        },
        {
            name: 'adr8',
            value: false,
            required: false,
        },
        {
            name: 'adr9',
            value: false,
            required: false,
        },
        {
            name: 'tir',
            value: false,
            required: false,
        },
        {
            name: 'ekmt',
            value: false,
            required: false,
        },
    ],
];

export const initialRequirements = [
    {
        name: 'carType',
        value: '',
        required: true,
    },
    {
        name: 'tempFrom',
        value: '',
        required: false,
    },
    {
        name: 'tempTo',
        value: '',
        required: false,
    },
];

export const initialPayment = [
    {
        name: 'bargain',
        value: '',
        required: false,
    },
    {
        name: 'paymentType',
        value: '',
        required: false,
    },
    {
        name: 'cash',
        value: '',
        required: false,
    },
    {
        name: 'priceVat',
        value: '',
        required: false,
    },
    {
        name: 'priceNovat',
        value: '',
        required: false,
    },
    {
        name: 'prepay',
        value: '',
        required: true,
    },
];

export const initialContacts = [
    {
        index: 0,
        phone: '',
        name: '',
        required: true,
    },
];

export const initialContactsField = [
    {
        name: 'contactsData',
        value: initialContacts,
    },
    {
        name: 'remark',
        value: '',
        required: false,
    },
];




export const optionsLoading = [
    { value: '0', label: 'верхняя' },
    { value: '1', label: 'боковая' },
    { value: '2', label: 'задняя' },
    { value: '3', label: 'с полной растентовкой' },
    { value: '4', label: 'со снятием поперечных перекладин' },
    { value: '5', label: 'со снятием стоек' },
    { value: '6', label: 'без ворот' },
    { value: '7', label: 'гидроборт' },
    { value: '8', label: 'аппарели' },
    { value: '9', label: 'с обрешеткой' },
    { value: '10', label: 'с бортами' },
    { value: '11', label: 'боковая с 2-х сторон' },
];
export const optionsPackageType = [
    { value: '1', label: 'не указано' },
    { value: '2', label: 'навалом' },
    { value: '3', label: 'коробки' },
    { value: '4', label: 'россыпью' },
    { value: '5', label: 'паллеты' },
    { value: '6', label: 'пачки' },
    { value: '7', label: 'мешки' },
    { value: '8', label: 'биг-бэги' },
    { value: '9', label: 'ящики' },
    { value: '10', label: 'листы' },
    { value: '11', label: 'пирамида' },
    { value: '12', label: 'рулоны' },
    { value: '13', label: 'еврокубы' },
    { value: '14', label: 'катушки' },
    { value: '15', label: 'барабаны' },
    { value: '16', label: 'бочки' },
    { value: '17', label: 'канистры' },
];
export const optionsCargoType = [
    { value: '1', label: 'Автомобиль' },
    { value: '2', label: 'автошины' },
    { value: '3', label: 'алкогольные напитки' },
    { value: '4', label: 'арматура' },
    { value: '5', label: 'балки надрессорные' },
    { value: '6', label: 'безалкогольные напитки' },
    { value: '7', label: 'боковая рама' },
    { value: '8', label: 'бумага' },
    { value: '9', label: 'бытовая техника' },
    { value: '10', label: 'бытовая химия' },
    { value: '11', label: 'вагонка' },
    { value: '12', label: 'газосиликатные блоки' },
    { value: '13', label: 'гипс' },
    { value: '14', label: 'гофрокартон' },
    { value: '15', label: 'грибы' },
    { value: '16', label: 'двери' },
    { value: '17', label: 'ДВП' },
    { value: '18', label: 'домашний переезд' },
    { value: '19', label: 'доски древесина' },
    { value: '20', label: 'древесный уголь' },
    { value: '21', label: 'ДСП' },
    { value: '22', label: 'ж/д запчасти (прочие)' },
    { value: '23', label: 'ЖБИ' },
    { value: '24', label: 'животные' },
    { value: '25', label: 'зерно и семена (в упаковке)' },
    { value: '26', label: 'игрушки' },
    { value: '27', label: 'изделия из кожи' },
    { value: '28', label: 'изделия из металла' },
    { value: '29', label: 'изделия из резины' },
    { value: '30', label: 'инструмент' },
    { value: '31', label: 'кабель' },
    { value: '32', label: 'канцтовары' },
    { value: '33', label: 'кирпич' },
    { value: '34', label: 'ковры' },
    { value: '35', label: 'колесная пара' },
    { value: '36', label: 'компьютеры' },
    { value: '37', label: 'кондитерские изделия' },
    { value: '38', label: 'консервы' },
    { value: '39', label: 'кормовые/пищевые добавки' },
    { value: '40', label: 'крупа' },
    { value: '41', label: 'ЛДСП' },
    { value: '42', label: 'люди' },
    { value: '43', label: 'макулатура' },
    { value: '44', label: 'мебель' },
    { value: '45', label: 'медикаменты' },
    { value: '46', label: 'мел' },
    { value: '47', label: 'металл' },
    { value: '48', label: 'металлолом' },
    { value: '49', label: 'минвата' },
    { value: '50', label: 'молоко сухое' },
    { value: '51', label: 'мороженое' },
    { value: '52', label: 'мука' },
    { value: '53', label: 'мясо' },
    { value: '54', label: 'нефтепродукты' },
    { value: '55', label: 'оборудование и запчасти' },
    { value: '56', label: 'оборудование медицинское' },
    { value: '57', label: 'обувь' },
    { value: '58', label: 'овощи' },
    { value: '59', label: 'огнеупорная продукция' },
    { value: '60', label: 'одежда' },
    { value: '61', label: 'парфюмерия и косметика' },
    { value: '62', label: 'пенопласт' },
    { value: '63', label: 'песок' },
    { value: '64', label: 'пиво' },
    { value: '65', label: 'пиломатериалы' },
    { value: '66', label: 'пластик' },
    { value: '67', label: 'поглощающий препарат' },
    { value: '68', label: 'поддоны' },
    { value: '69', label: 'продукты питания' },
    { value: '70', label: 'профлист' },
    { value: '71', label: 'птица' },
    { value: '72', label: 'рыба (неживая)' },
    { value: '73', label: 'сантехника' },
    { value: '74', label: 'сахар' },
    { value: '75', label: 'сборный груз' },
    { value: '76', label: 'соки' },
    { value: '77', label: 'соль' },
    { value: '78', label: 'СОНК (КП)' },
    { value: '79', label: 'стекло и фарфор' },
    { value: '80', label: 'стеклотара (бутылки и др.)' },
    { value: '81', label: 'стройматериалы' },
    { value: '82', label: 'сэндвич панели' },
    { value: '83', label: 'табачные изделия' },
    { value: '84', label: 'тара и упаковка' },
    { value: '85', label: 'текстиль' },
    { value: '86', label: 'ТНП' },
    { value: '87', label: 'торф' },
    { value: '88', label: 'транспортные средства' },
    { value: '89', label: 'трубы' },
    { value: '90', label: 'удобрения' },
    { value: '91', label: 'утеплитель' },
    { value: '92', label: 'фанера' },
    { value: '93', label: 'ферросплавы' },
    { value: '94', label: 'фрукты' },
    { value: '95', label: 'химпродукты (неопасные)' },
    { value: '96', label: 'химпродукты (опасные)' },
    { value: '97', label: 'хозтовары' },
    { value: '98', label: 'холодильное оборудование' },
    { value: '99', label: 'цветы' },
    { value: '100', label: 'цемент' },
    { value: '101', label: 'чипсы' },
    { value: '102', label: 'шкуры мокросоленые' },
    { value: '103', label: 'шпалы' },
    { value: '104', label: 'щебень' },
    { value: '105', label: 'электроника' },
    { value: '106', label: 'ягоды' },
    { value: '107', label: '20 контейнер' },
    { value: '108', label: '20 контейнер НС' },
    { value: '109', label: '20 реф контейнер' },
    { value: '110', label: '20 танк контейнер' },
    { value: '111', label: '40 контейнер' },
    { value: '112', label: '40 контейнер НС' },
    { value: '113', label: '40 реф контейнер' },
    { value: '114', label: '40 танк контейнер' },
    { value: '115', label: '45 контейнер (нов)' },
    { value: '116', label: '45 контейнер (стар)' },
    { value: '117', label: '45 реф контейнер' },
    { value: '118', label: 'другой' },
];
export const optionsCarType = [
    { value: '0', label: 'тентованный' },
    { value: '1', label: 'контейнер' },
    { value: '2', label: 'фургон' },
    { value: '3', label: 'цельнометалл.' },
    { value: '4', label: 'изотермический' },
    { value: '5', label: 'рефрижератор' },
    { value: '6', label: 'реф мультирежимный' },
    { value: '7', label: 'реф с перегородкой' },
    { value: '8', label: 'реф тушевоз' },
    { value: '9', label: 'бортовой' },
    { value: '10', label: 'открытый конт.' },
    { value: '11', label: 'площадка без бортов' },
    { value: '12', label: 'самосвал' },
    { value: '13', label: 'шаланда' },
    { value: '14', label: 'низкорамный' },
    { value: '15', label: 'низкорам. платф.' },
    { value: '16', label: 'телескопический' },
    { value: '17', label: 'трал' },
    { value: '18', label: 'балковоз (негабарит)' },
    { value: '19', label: 'автобус' },
    { value: '20', label: 'автовоз' },
    { value: '21', label: 'автовышка' },
    { value: '22', label: 'автотранспортер' },
    { value: '23', label: 'бетоновоз' },
    { value: '24', label: 'битумовоз' },
    { value: '25', label: 'бензовоз' },
    { value: '26', label: 'вездеход' },
    { value: '27', label: 'газовоз' },
    { value: '28', label: 'зерновоз' },
    { value: '29', label: 'коневоз' },
    { value: '30', label: 'контейнеровоз' },
    { value: '31', label: 'кормовоз' },
    { value: '32', label: 'кран' },
    { value: '33', label: 'лесовоз' },
    { value: '34', label: 'ломовоз' },
    { value: '35', label: 'манипулятор' },
    { value: '36', label: 'микроавтобус' },
    { value: '37', label: 'муковоз' },
    { value: '38', label: 'панелевоз' },
    { value: '39', label: 'пухтовоз' },
    { value: '40', label: 'пирамида' },
    { value: '41', label: 'рулоновоз' },
    { value: '42', label: 'седельный тягач' },
    { value: '43', label: 'скотовоз' },
    { value: '44', label: 'стекловоз' },
    { value: '45', label: 'трубовоз' },
    { value: '46', label: 'цементовоз' },
    { value: '47', label: 'цистерна' },
    { value: '48', label: 'щеповоз' },
    { value: '49', label: 'эвакуатор' },
    { value: '50', label: 'грузопассажирский' },
    { value: '51', label: 'клюшковоз' },
    { value: '52', label: 'мусоровоз' },
    { value: '53', label: 'jumbo' },
    { value: '54', label: '20 танк-контейнер' },
    { value: '55', label: '40 танк-контейнер' },
    { value: '56', label: 'мега фура' },
    { value: '57', label: 'допельшток' },
];
export const optionsDays = [
    { value: '0', label: '0 дн.' },
    { value: '1', label: '1 дн.' },
    { value: '2', label: '2 дн.' },
    { value: '3', label: '3 дн.' },
    { value: '4', label: '4 дн.' },
    { value: '5', label: '5 дн.' },
    { value: '6', label: '6 дн.' },
    { value: '7', label: '7 дн.' },
    { value: '8', label: '8 дн.' },
    { value: '9', label: '9 дн.' },
    { value: '10', label: '10 дн.' },
];
export const optionsNotes = [
    { value: null, label: 'Нет' },
    { value: '0', label: 'Режим' },
    { value: '1', label: 'Хрупкое' },
    { value: '2', label: 'Негабаритные' },
];
