import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { MdMenu } from 'react-icons/md';
import { RiUserLine } from 'react-icons/ri';
import { Link, NavLink } from 'react-router-dom';

import Assets from '@core/assets';
import {appRoutes} from '@core/navigation';
import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { setAlert } from '@entities/.app/alert/alert.actions';
import { askQuestion } from '@entities/.app/app.api';
import CityContainer from '@features/.app/App.CityContainer.Widget';
import OfficeFeedsButton from '@features/office/Office.FeedsButton.Widget';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import CustomOffcanvas from '@shared/ui/CustomOffcanvas';
import ValidateWrapper from '@shared/ui/ValidateWrapper';
import { selectToken } from '@entities/.app/account/account.selectors';
import Logger from '@shared/lib/logger';

const links = [
    { to: appRoutes.ROOT, title: 'Главная' },
    { to: appRoutes.SEARCH, title: 'Поиск' },
    { to: appRoutes.FORUM, title: 'Форум' },
    { to: appRoutes.INFOPAY, title: 'Оплата' },
    { to: appRoutes.CONTACTS, title: 'Контакты' },
];

const CanvasOffMenu = ({ isShowOffcanvas, setIsShowOffcanvas, setIsShowModal }) => {
    return (
        <CustomOffcanvas isShow={isShowOffcanvas} setIsShow={setIsShowOffcanvas} closeButton={true} placement="end">
            <nav>
                <ul>
                    {links.map((link, idx) => (
                        <li key={idx}>
                            <NavLink to={link.to} onClick={() => setIsShowOffcanvas(false)}>
                                {link.title}
                            </NavLink>
                        </li>
                    ))}
                    <li>
                        <NavLink
                            to=""
                            onClick={() => {
                                setIsShowOffcanvas(false);
                                setIsShowModal(true);
                            }}
                        >
                            Задать вопрос
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </CustomOffcanvas>
    );
};

const QuestionModal = ({ isShowModal, setIsShowModal }) => {
    const dispatch = useAppDispatch();

    const {
        register,
        formState: { isSubmitSuccessful, errors },
        handleSubmit,
        reset,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    useEffect(() => {
        reset({
            name: '',
            email: '',
            description: '',
        });
        setIsShowModal(false);
    }, [isSubmitSuccessful]);

    const handleFormSubmit = async (payload) => {
        try {
            await askQuestion(payload);
            dispatch(setAlert('success', 'Вопрос был успешно отправлен'));
        } catch (error) {
            dispatch(setAlert('danger', 'Не удалось отправить вопрос. Повторите попытку позже'));
            Logger.error(error);
        }
    };

    return (
        <CustomModal
            className="modal__header"
            centered={false}
            isShow={isShowModal}
            setIsShow={setIsShowModal}
            closeButton={true}
            titleHead={'Задать вопрос'}
            titleBody={'Задайте интересующий вопрос и мы ответим Вам в течение часа.'}
        >
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <label>Ваше имя</label>
                <ValidateWrapper error={errors?.name}>
                    <input
                        type="text"
                        {...register('name', {
                            required: 'Поле обязательно к заполнению',
                            minLength: {
                                value: 2,
                                message: 'Минимум 2 символа',
                            },
                            maxLength: {
                                value: 50,
                                message: 'Максимум 50 символов',
                            },
                        })}
                    />
                </ValidateWrapper>

                <label>Ваш Email</label>
                <ValidateWrapper error={errors?.email}>
                    <input
                        type="text"
                        {...register('email', {
                            required: 'Поле обязательно к заполнению',
                            pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                        })}
                    />
                </ValidateWrapper>
                <label>Ваш вопрос</label>

                <ValidateWrapper error={errors.description}>
                    <textarea
                        {...register('description', {
                            required: 'Поле обязательно для заполнения',
                            minLength: {
                                value: 5,
                                message: 'Минимум 5 символов',
                            },
                            maxLength: {
                                value: 100,
                                message: 'Максимум 100 символов',
                            },
                        })}
                    />
                </ValidateWrapper>

                <div className="d-flex justify-content-center">
                    <button className="btn btn-2 fs-12 text-uppercase mt-3">Задать вопрос</button>
                </div>
            </form>
        </CustomModal>
    );
};

const Logo = () => {
    return (
        <NavLink to={getURL()} className="logo me-lg-auto">
            <img src={Assets.LOGO} alt="Название сайта" className="logo me-lg-auto" />
        </NavLink>
    );
};

const NavMenu = ({ setIsShowModal }) => {
    return (
        <nav className="d-none d-lg-flex">
            {links.map((link, idx) => (
                <NavLink key={idx} to={link.to}>
                    {link.title}
                </NavLink>
            ))}
            <button className="header__button" type="button" onClick={() => setIsShowModal(true)}>
                Задать вопрос
            </button>
        </nav>
    );
};

const Widgets = ({ setIsShowOffcanvas }) => {
    const isAuthenticated = useAppSelector(selectToken);

    return (
        <div className="d-flex align-items-center ms-5">
            <Link
                to={getURL(isAuthenticated ? appRoutes.OFFICE : appRoutes.LOGIN)}
                className="d-flex align-items-center order-2 order-lg-1 ms-4 ms-lg-0"
            >
                <span className="d-none d-xxl-inline fw-5 fs-12 me-2">Личный кабинет</span>
                <IconContext.Provider
                    value={{
                        className: 'icon',
                        //аккаунт
                    }}
                >
                    <RiUserLine />
                </IconContext.Provider>
            </Link>
            <div style={{ marginRight: '20px' }}>
                <OfficeFeedsButton />
            </div>

            <CityContainer />

            <button type="button" className="order-3 d-block d-lg-none ms-4" onClick={() => setIsShowOffcanvas(true)}>
                <IconContext.Provider
                    value={{
                        className: 'icon',
                        //меню
                    }}
                >
                    <MdMenu />
                </IconContext.Provider>
            </button>
        </div>
    );
};

const AppHeader = () => {
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowOffcanvas, setIsShowOffcanvas] = useState(false);

    return (
        <>
            <header>
                <div className="container">
                    <Logo />
                    <NavMenu setIsShowModal={setIsShowModal} />
                    <Widgets setIsShowOffcanvas={setIsShowOffcanvas} />
                </div>
            </header>
            <QuestionModal isShowModal={isShowModal} setIsShowModal={setIsShowModal} />
            <CanvasOffMenu
                isShowOffcanvas={isShowOffcanvas}
                setIsShowOffcanvas={setIsShowOffcanvas}
                setIsShowModal={setIsShowModal}
            />
        </>
    );
};
export default memo(AppHeader);
