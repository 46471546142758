
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { IconContext } from 'react-icons';
import { BsFillChatRightTextFill, BsFillInfoSquareFill } from 'react-icons/bs';
import { IoAddCircleSharp, IoSearch } from 'react-icons/io5';
import { Link, NavLink, Outlet, useLocation } from 'react-router-dom';

import {appRoutes} from '@core/navigation';
import { useAppDispatch, useAppSelector } from '@core/store/hooks';
import { setAlert, showNoAuthAlert } from '@entities/.app/alert/alert.actions';
import { selectRoleId } from '@entities/.app/account/account.selectors';
import useAxiosPrivate from '@entities/.app/account/hooks/account.useAxiosPrivate';
import { createTopic, getStatistics, paginateUserTopics, searchTopics } from '@entities/forum/forum.api';
import ForumLastMsgFeedWidget from '@features/forum/Forum.LastMsgFeed.Widget';
import useDebounce from '@shared/hooks/useDebounce';
import usePagination from '@shared/hooks/usePagination';
import { getURL } from '@shared/lib/getURL';
import CustomModal from '@shared/ui/CustomModal';
import Loader from '@shared/ui/Loader';
import PublicationRules from '@features/forum/Forum.PublicationRules.Block';
import ValidateWrapper from '@shared/ui/ValidateWrapper';

const initialPageLimit = 10;

const Crumbs = ({ pathname }) => {
    return (
        <nav aria-label="breadcrumb" className="mb-3">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">
                    <Link to={getURL(appRoutes.FORUM)}>Разделы форума</Link>
                </li>
                {pathname === '/forum/my-topics' && <li className="breadcrumb-item active">Мои темы</li>}
            </ol>
        </nav>
    );
};

const MyTopicsButton = ({ userTopics }) => {
    return (
        <NavLink to={appRoutes.FORUM_MY_TOPICS} className="d-flex justify-content-end align-items-center mb-3 fs-12">
            <IconContext.Provider
                value={{
                    className: 'icon-10 blue', // @ts-ignore
                    title: 'Мои темы',
                }}
            >
                <BsFillChatRightTextFill />
            </IconContext.Provider>
            <span className="ms-2 blue">Мои темы ({userTopics?.items?.length || 0})</span>
        </NavLink>
    );
};

const CreateTopicButton = ({ currentUserId, setIsShowCreateTopic }) => {
    return (
        <button
            type="button"
            className="btn btn-2 w-100 mb-3 fs-12 px-3 py-2 d-flex"
            onClick={() => {
                currentUserId
                    ? setIsShowCreateTopic((prevState) => !prevState)
                    : // @ts-ignore
                      dispatch(showNoAuthAlert());
            }}
        >
            <IconContext.Provider
                value={{
                    className: 'icon-15 white', // @ts-ignore
                    title: 'Создать тему',
                }}
            >
                <IoAddCircleSharp />
            </IconContext.Provider>
            <span className="flex-1">Создать тему</span>
        </button>
    );
};

const SearchForm = ({ search, setSearch }) => {
    return (
        <form className="form-search mb-4">
            <input
                type="search"
                placeholder="Поиск по форуму"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <button>
                <IconContext.Provider
                    value={{
                        className: 'icon-15 green', //@ts-ignore
                        title: 'Поиск',
                    }}
                >
                    <IoSearch />
                </IconContext.Provider>
            </button>
        </form>
    );
};

const Statistics = () => {
    const [statistic, setStatistic] = useState({
        isLoading: false,
        error: null,
        item: null,
    });

    useEffect(() => {
        getStatistics()
            .then((result) => setStatistic((prev) => ({ ...prev, isLoading: true, item: result })))
            .catch((error) => setStatistic((prev) => ({ ...prev, isLoading: true, error })));
    }, []);

    return statistic.isLoading ? (
        statistic.item ? (
            <>
                <h5 className="d-none d-lg-block mb-1">Статистика портала</h5>
                <div className="stat d-none d-lg-block title-font p-3 mb-4">
                    {statistic?.item?.topicCount && (
                        <div className="d-flex justify-content-between mb-2 mb-xl-3">
                            <div className="gray-3 fw-4 me-4">Темы:</div>
                            <div className="fw-5">{statistic.item.topicCount}</div>
                        </div>
                    )}
                    {statistic?.item?.messagesCount && (
                        <div className="d-flex justify-content-between mb-2 mb-xl-3">
                            <div className="gray-3 me-4">Сообщения:</div>
                            <div className="fw-5">{statistic.item.messagesCount}</div>
                        </div>
                    )}
                    {statistic?.item?.usersWithTopics && (
                        <div className="d-flex justify-content-between mb-2 mb-xl-3">
                            <div className="gray-3 me-4">Пользователи:</div>
                            <div className="fw-5">{statistic.item.usersWithTopics}</div>
                        </div>
                    )}
                    {statistic?.item?.lastTopicTitle && (
                        <div className="d-flex justify-content-between">
                            <div className="gray-3 me-4">Новая тема:</div>
                            <div className="fw-5 flex-1 text-truncate blue">{statistic.item.lastTopicTitle}</div>
                        </div>
                    )}
                </div>
            </>
        ) : (
            <div className="p-5"></div>
        )
    ) : (
        <div className="w-100 d-flex justify-content-center p-5">
            <Loader color="#545454" />
        </div>
    );
};

const Footer = () => {
    return (
        <>
            <hr className="mt-5 mb-3" />
            <div className="d-flex align-items-center fs-11 mb-3">
                <IconContext.Provider
                    value={{
                        className: 'icon-10 blue', //@ts-ignore
                        title: 'Правила публикации',
                    }}
                >
                    <BsFillInfoSquareFill />
                </IconContext.Provider>
                <span className="blue ms-2">Правила публикации</span>
            </div>
            <p className="gray-3">
                Администрация сайта не несет ответственности за информацию, публикуемую в форуме, и ее мнение может не
                совпадать с мнением авторов сообщений. Сообщения о незаконно размещенной информации на форуме присылайте
                на адрес:
                <a href="mailto:mail@gmail.com">mail@gmail.com</a>
            </p>
        </>
    );
};

const CreateNewTopicModal = ({ isShowCreateTopic, setIsShowCreateTopic, setCreateTopicPayloads }) => {
    const submitHandler = (data) => {
        setCreateTopicPayloads((prev) => ({ ...prev, ...data }));
        setIsShowCreateTopic(false);
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
    });

    const [isShowPublicationRules, setIsShowPublicationRules] = useState(false);

    return (
        <CustomModal
            isShow={isShowCreateTopic}
            setIsShow={setIsShowCreateTopic}
            closeButton={true}
            centered={true}
            size={'lg'}
        >
            <h3>Новая тема</h3>
            <form className="fs-12" onSubmit={handleSubmit(submitHandler)}>
                <label className="mb-2">Название темы</label>
                <ValidateWrapper error={errors?.title} className="mb-4">
                    <input
                        type="text"
                        className={!errors?.title ? 'mb-4' : ''}
                        placeholder="Придумайте название темы"
                        {...register('title', {
                            required: 'поле обязательно к заполнению',
                        })}
                    />
                </ValidateWrapper>
                <label className="mb-2">Текст темы</label>
                {/* @ts-ignore */}
                <ValidateWrapper error={errors?.description}>
                    <textarea
                        //@ts-ignore
                        rows="5"
                        placeholder="Ваша история или вопрос"
                        {...register('description', {
                            required: 'поле обязательно к заполнению',
                        })}
                    />
                </ValidateWrapper>
                <div className="row flex-sm-row-reverse mt-4">
                    <div className="col-sm-5">
                        <button type="submit" className="btn btn-2 w-100">
                            Сохранить
                        </button>
                    </div>
                    <div className="col-sm-7 mt-2 mt-sm-0">
                        <div className="fs-09 text-center">
                            Нажимая на кнопку “Создать тему”, вы соглашаетесь с{' '}
                            <span
                                className="blue"
                                onClick={() => setIsShowPublicationRules(true)}
                                style={{ cursor: 'pointer' }}
                            >
                                правилами публикации
                            </span>
                        </div>
                    </div>
                </div>
                {isShowPublicationRules && <PublicationRules setIsShowPublicationRules={setIsShowPublicationRules} />}
            </form>
        </CustomModal>
    );
};

export default function ForumMainPage() {
    const dispatch = useAppDispatch();
    const axiosPrivate = useAxiosPrivate();

    const { pathname } = useLocation();

    const currentUserId = useAppSelector(selectRoleId);

    const [isAuth, setIsAuth] = useState(false);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 300);

    const topicsPagination = usePagination(initialPageLimit);
    const userTopicsPagination = usePagination(initialPageLimit);
    const [shownPagination, setShownPagination] = useState({});

    const [topics, setTopics] = useState({
        isLoading: false,
        error: null,
        meta: null,
        items: [],
    });
    const [userTopics, setUserTopics] = useState({
        isLoading: false,
        error: null,
        meta: null,
        items: [],
    });

    const [shownItems, setShownItems] = useState([]);
    const [isShowCreateTopic, setIsShowCreateTopic] = useState(false);
    const [createTopicPayloads, setCreateTopicPayloads] = useState({});

    const searchTopicsRequest = (page, limit) => {
        searchTopics(page, limit, debouncedSearch)
            .then((result) =>
                setTopics((prev) => ({ ...prev, isLoading: true, meta: result?.meta, items: result?.data })),
            )
            .catch((error) => setTopics((prev) => ({ ...prev, isLoading: true, error })));
    };

    const paginateUserTopicsRequest = (page, limit) => {
        paginateUserTopics(axiosPrivate, currentUserId, page, limit)
            .then((result) =>
                setUserTopics((prev) => ({
                    ...prev,
                    isLoading: true,
                    meta: result?.meta,
                    items: result?.data,
                })),
            )
            .catch((error) => setUserTopics((prev) => ({ ...prev, isLoading: true, error })));
    };

    useEffect(() => {
        const value = debouncedSearch && debouncedSearch.trim();

        if (pathname === '/forum') {
            setIsAuth(false);
            // @ts-ignore
            setShownItems(topics);
            setShownPagination(topicsPagination);
        }
        if (value && pathname === '/forum/my-topics') {
            !currentUserId && setIsAuth(true);
            // @ts-ignore
            setShownItems(topics);
            setShownPagination(topicsPagination);
        }
        if (!value && pathname === '/forum/my-topics') {
            !currentUserId && setIsAuth(true);
            // @ts-ignore
            setShownItems(userTopics);
            setShownPagination(userTopicsPagination);
        }
    }, [pathname, topics, userTopics, debouncedSearch, currentUserId]);

    useEffect(() => {
        topicsPagination.setCurrentPage(1);
        userTopicsPagination.setCurrentPage(1);
    }, [pathname, debouncedSearch]);

    useEffect(() => {
        //@ts-ignore
        searchTopicsRequest(topicsPagination.currentPage, topicsPagination.pageLimit, debouncedSearch);
    }, [topicsPagination.currentPage, topicsPagination.pageLimit, debouncedSearch]);

    useEffect(() => {
        currentUserId && paginateUserTopicsRequest(topicsPagination.currentPage, topicsPagination.pageLimit);
    }, [userTopicsPagination.currentPage, userTopicsPagination.pageLimit, currentUserId]);

    useEffect(() => {
        Object.keys(createTopicPayloads)?.length &&
            currentUserId &&
            createTopic(axiosPrivate, currentUserId, createTopicPayloads)
                .then(() => {
                    //@ts-ignore
                    searchTopicsRequest(topicsPagination.currentPage, topicsPagination.pageLimit, debouncedSearch);
                    paginateUserTopicsRequest(topicsPagination.currentPage, topicsPagination.pageLimit);
                    // @ts-ignore
                    dispatch(setAlert('success', 'Тема успешно создана'));
                })
                // @ts-ignore
                .catch(() => dispatch(setAlert('danger', 'Не удалось создать тему')));
    }, [createTopicPayloads, currentUserId]);

    return (
        <main className="bg-white py-4 py-sm-5 bg-transparent">
            <section
                className="container shadow"
                id="sec-11"
                style={{
                    background: '#FFF',
                    borderRadius: 8,
                    padding: 50,
                }}
            >
                <Crumbs pathname={pathname} />

                <h1 className="dark-blue text-uppercase">Форум ПОРТАЛА</h1>

                <div className="row flex-lg-row-reverse">
                    <div className="col-lg-3">
                        <MyTopicsButton userTopics={userTopics} />

                        <CreateTopicButton currentUserId={currentUserId} setIsShowCreateTopic={setIsShowCreateTopic} />

                        <SearchForm search={search} setSearch={setSearch} />

                        <Statistics />

                        <ForumLastMsgFeedWidget className="d-none d-lg-block" />
                    </div>
                    {/* ForumTopics */}
                    <Outlet context={[shownItems, shownPagination, isAuth]} />
                </div>
                <div className="row">
                    <div className="col-lg-9">
                        <Footer />
                    </div>
                </div>
            </section>
            <CreateNewTopicModal
                isShowCreateTopic={isShowCreateTopic}
                setIsShowCreateTopic={setIsShowCreateTopic}
                setCreateTopicPayloads={setCreateTopicPayloads}
            />
        </main>
    );
}
