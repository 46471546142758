import { memo } from 'react';
import { IconContext } from 'react-icons';
import { MdChat, MdFolder } from 'react-icons/md';
import { NavLink, useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { getPhotoPath } from '@entities/.app/account/account.actions';
import { getURL } from '@shared/lib/getURL';

const DealCard = (props) => {
    const navigate = useNavigate();

    const partner =
        props?.item?.contractor?.id !== props?.currentUser?.id ? props?.item?.contractor : props?.item?.customer;

    const isOwner = [props.item?.cargo?.userId, props.item?.route?.userId].includes(props?.currentUser?.id);

    return (
        <div className={'response'}>
            <div className="d-flex justify-content-between  mb-3">
                <NavLink className="d-flex align-items-center" to={getURL(appRoutes.MARKET_PROFILE, partner?.id)}>
                    <img src={getPhotoPath(partner?.avatar)} alt={partner?.name} className="img" />
                    <div className="flex-1 ms-2 fs-11" style={{ maxWidth: 150 }}>
                        {partner?.subject ? (
                            <div className="fw-6" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                {partner?.companyName}
                            </div>
                        ) : null}
                        <div className="fw-5 mt-1" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {partner?.fullName}
                        </div>
                    </div>
                </NavLink>

                <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                >
                    <div
                        className="d-flex align-items-center"
                        style={{ cursor: 'pointer' }}
                        onClick={() => props.onToMsgr()}
                    >
                        <IconContext.Provider value={{ className: 'icon-20 white' }}>
                            <MdChat />
                        </IconContext.Provider>
                    </div>
                    {props?.item?.status > 0 ? (
                        <div
                            className="d-flex align-items-center"
                            style={{ cursor: 'pointer' }}
                            onClick={() => props.onToDocs()}
                        >
                            <IconContext.Provider value={{ className: 'icon-20 white' }}>
                                <MdFolder />
                            </IconContext.Provider>
                        </div>
                    ) : null}
                </div>
            </div>
            {props.item?.cargo ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <NavLink to={getURL(appRoutes.MARKET_CARGO, props.item?.cargo?.id)} className="link-to-product">
                        Груз №{props.item?.cargo.id} {props.item?.cargo?.loadings?.at(-1)?.town} -{' '}
                        {props.item?.cargo?.unloadings?.at(-1)?.town}
                    </NavLink>
                </div>
            ) : null}
            {props.item?.route ? (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        justifyItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <NavLink to={getURL(appRoutes.MARKET_ROUTE, props.item?.route?.id)} className="link-to-product">
                        Маршрут №{props.item?.route.id} {props.item?.route.fromRoute} - {props.item?.route.toRoute}
                    </NavLink>
                </div>
            ) : null}
            <div
                className="px-4"
                style={{
                    minHeight: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <button
                    type="button"
                    className={`btn btn-${
                        [1, 3].includes(props.item?.status) || props.item?.lastToNextUserId ? '6' : '1'
                    } w-100 mt-3`}
                    disabled={
                        props.item?.lastToNextUserId === props?.currentUser?.id ||
                        (!isOwner && props.item?.status === 0)
                    }
                    style={{ color: '#FFF' }}
                    onClick={() => {
                        if (props.item?.status === 4) {
                            props.onToDocs();
                        } else {
                            props.onNext();
                        }
                    }}
                >
                    {(() => {
                        if (props.item?.status === 4) {
                            return 'Посмотреть документы';
                        }
                        if (props.item?.status === 0) {
                            if (!isOwner) return 'Ожидайте принятия';
                            else return 'Принять';
                        }
                        if (props.item?.status === 1) {
                            if (!props.item?.lastToNextUserId) {
                                return 'Объявить начало работы';
                            } else if (props.item?.lastToNextUserId === props?.currentUser?.id) {
                                return 'Ожидайте подтверждения';
                            } else {
                                return 'Подтвердить начало работы';
                            }
                        }
                        if (props.item?.status === 2) {
                            if (!props.item?.lastToNextUserId) {
                                return 'Объявить выполненным';
                            } else if (props.item?.lastToNextUserId === props?.currentUser?.id) {
                                return 'Ожидайте подтверждения';
                            } else {
                                return 'Подтвердить выполнение';
                            }
                        }
                        if (props.item?.status === 3) {
                            if (!props.item?.lastToNextUserId) {
                                return 'Объявить закрытым';
                            } else if (props.item?.lastToNextUserId === props?.currentUser?.id) {
                                return 'Ожидайте подтверждения';
                            } else {
                                return 'Подтвердить закрытие';
                            }
                        }
                    })()}
                </button>
                {[0, 1, 2].includes(props.item?.status) ? (
                    <button
                        type="button"
                        className={`btn btn-${props.item?.lastToCancelUserId ? '7' : '2'} w-100 mt-2`}
                        onClick={() => props.onCancel()}
                        disabled={props.item?.lastToCancelUserId === props?.currentUser?.id}
                    >
                        {isOwner && props.item?.status === 0
                            ? 'Отклонить'
                            : !props.item?.lastToCancelUserId
                            ? 'Отменить'
                            : props.item?.lastToCancelUserId === props?.currentUser?.id
                            ? 'Ожидайте подтверждения'
                            : 'Подтвердить отмену'}
                    </button>
                ) : null}
            </div>
        </div>
    );
};
export default memo(DealCard);
