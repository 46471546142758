import { FaBell } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { MdBorderColor, MdBusinessCenter, MdCheckCircle } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

import {appRoutes} from '@core/navigation';
import useCustomSelect from '@shared/hooks/useCustomSelect';
import { getURL } from '@shared/lib/getURL';

import { MdChat, MdGridView, MdLocalShipping } from 'react-icons/md';

export default function OfficeFeedsButton() {
    const navigate = useNavigate();
    const { isShowDropdown, toggleDropdown, closeDropdown, ref } = useCustomSelect(false);
    const nofiticationsMock = [
        {
            id: 1,
            type: 1,
            from: 'ADMIN',
            subject: 'Договор',
            date: Date.now(),
        },
        {
            id: 2,
            type: 2,
            from: 'ADMIN',
            subject: 'Договор',
            date: Date.now() - 100000,
        },
        {
            id: 3,
            type: 2,
            from: 'ADMIN',
            subject: 'Договор',
            date: Date.now() - 100000,
        },
        {
            id: 4,
            type: 2,
            from: 'ADMIN',
            subject: 'Договор',
            date: Date.now() - 100000,
        },
        {
            id: 6,
            type: 3,
            from: 'ADMIN',
            subject: 'Груз № 51',
            date: Date.now() - 1000000,
        },
    ];
    return (
        <div className={`custom-select ${isShowDropdown ? 'show' : ''}`} onClick={() => navigate(getURL(appRoutes.OFFICE_MESSAGER))}>
            <IconContext.Provider
                value={{
                    className: 'icon-20 green',
                    //Уведомления
                }}
            >
                {/* <FaBell onClick={() => toggleDropdown()} style={{cursor:'pointer'}}/> */}
                <MdChat onClick={() => toggleDropdown()} style={{cursor:'pointer'}}/>
            </IconContext.Provider>

            {/* <div
                className={`dropdown-list dropdown-list_inner options`}
                data-alignment="right"
                ref={ref}
                style={{ minWidth: '350px', overflowY: 'scroll', padding: '10px', right: 'calc(-800%)' }}
                // style={{
                //     display: isShowDropdown ? 'flex' : 'none',
                //     flexDirection: 'column',
                //     overflowX: 'hidden!important',
                //     background:'red'
                // }}
            >
                {nofiticationsMock.map((notif) => (
                    <div
                        key={notif.id}
                        style={{
                            // height: '50px',
                            display: 'flex',
                            padding: '10px',
                            width: '100%',
                            background:
                                notif.type === 1
                                    ? 'rgba(0,150,255,.5)'
                                    : notif.type === 2
                                    ? 'rgba(150,150,255,.5)'
                                    : 'rgba(255,150,0,.5)',
                            marginBottom: '10px',
                        }}
                    >
                        <div
                            style={{
                                padding: '10px',
                                paddingLeft: '5px',
                            }}
                        >
                            {notif.type === 1 ? (
                                <MdCheckCircle />
                            ) : notif.type === 2 ? (
                                <MdBorderColor />
                            ) : (
                                <MdBusinessCenter />
                            )}
                        </div>

                        <span style={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                            {notif.type === 1 ? (
                                <>
                                    <div>
                                        {`Пользователь`} <strong>{notif.from}</strong> {`согласовал ваши правки в документе`}{' '}
                                        <strong>{notif.subject}</strong>
                                    </div>{' '}
                                    <div>{`${new Date(notif.date).toLocaleDateString()}`}</div>
                                </>
                            ) : notif.type === 2 ? (
                                <>
                                    <div>
                                        {`Пользователь`} <strong>{notif.from}</strong> {`отправил свои правки по документу`}{' '}
                                        <strong>{notif.subject}</strong>
                                    </div>
                                    <div>{`${new Date(notif.date).toLocaleDateString()}`}</div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        {`Пользователь`} <strong>{notif.from}</strong> {`откликнулся на ваше объявление`}{' '}
                                        <strong>{notif.subject}</strong>{' '}
                                    </div>{' '}
                                    <div> {`${new Date(notif.date).toLocaleDateString()}`}</div>
                                </>
                            )}
                        </span>
                    </div>
                ))}
            </div> */}
        </div>
    );
}
