import { IDocRenderElementData, IDocRenderElementProps } from '@entities/office/dealDocument/dealDocument.types';
import { IconContext } from 'react-icons';
import { MdBorderColor } from 'react-icons/md';

import { useActions, useAppSelector } from '@core/store/hooks';
import { selectDocIsClosed } from '@entities/office/dealDocument/dealDocument.selectors';
import { useEditor } from '@entities/office/dealDocument/hooks/document.useEditor';
import Logger from '@shared/lib/logger';

export default function DealDocumentDataSetPickerElement({ node }: IDocRenderElementProps) {
    const { setFillFields, setPickType } = useActions();

    const { editorRole } = useEditor();
    const docIsClosed = useAppSelector(selectDocIsClosed);

    const data: IDocRenderElementData = {};
    data.valueCell = node.cell || [node.order];
    data.isClosed =
        docIsClosed || node?.isClosed || (Number.isFinite(Number(node.privacyRole)) && node.privacyRole !== editorRole);

    Logger.log(`DATA_PICKER`, {
        order: node.order,
        valueCell: data.valueCell,
        isClosed: data.isClosed,
        privacyRole: node.privacyRole,
    });

    return !data.isClosed ? (
        <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#req-picker"
            className="d-flex align-items-center ms-2"
        >
            <div
                onClick={() => {
                    setFillFields(node?.fields);
                    setPickType(node.pickType)
                }}
            >
                <IconContext.Provider value={{ className: 'icon-10 green' }}>
                    <MdBorderColor />
                </IconContext.Provider>
            </div>
        </button>
    ) : null;
}
