import { MdCopyright } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Assets from '@core/assets';
import {appRoutes} from '@core/navigation';
import { getURL } from '@shared/lib/getURL';


const payImgs = [
    { src: '/img/payment/visa.png', alt: 'visa' },
    { src: '/img/payment/mastercard.png', alt: 'mastercard' },
    { src: '/img/payment/tinkoff.png', alt: 'tinkoff' },
    { src: '/img/payment/mir.png', alt: 'mir' },
    { src: '/img/payment/sbp.png', alt: 'sbp' },
];

const links = [
    { to: appRoutes.ROOT, title: 'ГЛАВНАЯ' },
    { to: appRoutes.INFOPAY, title: 'ОПЛАТА' },
    { to: appRoutes.SEARCH, title: 'ПОИСК' },
    { to: appRoutes.CONTACTS, title: 'КОНТАКТЫ' },
    { to: appRoutes.FORUM, title: 'ФОРУМ' },
    { to: appRoutes.OFFICE, title: 'ЛИЧНЫЙ КАБИНЕТ' },
];

const socialIcons = [
    'vk',
    'telegram',
    'whatsapp'
]


export const AppFooter = () => {
    return (
        <footer>
            <div className="container pt-4 pt-sm-5">
                <div className="row mb-4 mb-md-5">
                    <div className="d-none d-sm-block col-sm-3 col-md-4">
                        <img src={Assets.LOGO} alt="Грузоперевозки" className="logo" />
                    </div>
                    <div className="col-sm-9 col-md-8">
                        <div className="fs-12 fw-7 mb-4 mb-md-5 text-center">КАРТА САЙТА</div>
                        <hr />
                        <ul className="list-unstyled row row-cols-sm-3 g-3 g-md-4 mt-1 mt-sm-3 text-center">
                            {links.map((link, idx) => (
                                <li key={idx}>
                                    <Link to={link.to}>{link.title}</Link>
                                </li>
                            ))}
                            <li style={{
                                display:'flex',
                                justifyContent:'space-evenly',
                                width:'100%'
                            }}>
                            {socialIcons.map((socialLink, idx) => (
                                    <Link to={appRoutes.ROOT} className="socials-link" key={idx}>
                                        <img src={getURL('img','icons',`${socialLink}.svg`)} alt="Размещает груз на площадке" className='socials-icon' />
                                    </Link>
                            ))}
                            </li>
                        </ul>
                    </div>
                </div>
                <hr />
                <div className="payment-methods mt-3 mt-md-4">
                    {payImgs.map((img, idx) => (
                        <img key={idx} src={img.src} alt={img.alt} />
                    ))}
                </div>
                <div className="d-flex justify-content-center align-items-center mt-3 fs-09 fw-7">
                    <MdCopyright /> <span className="ms-2">ВСЕ ПРАВА ЗАЩИЩЕНЫ · ГРУЗОПЕРЕВОЗКИ&nbsp;·&nbsp;2022</span>
                </div>
            </div>
        </footer>
    );
};
