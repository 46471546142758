import { useEffect, useState } from 'react';

import { getAllNews } from '@entities/news/news.api';
import ForumLastMsgFeedWidget from '@features/forum/Forum.LastMsgFeed.Widget';
import NewsArticleTiles from '@features/news/News.Article.Tiles';
import usePagination from '@shared/hooks/usePagination';

const pageLimit = 12;

export default function NewsMainPage() {
    const newsPagination = usePagination(pageLimit);

    const [news, setNews] = useState({
        isLoaded: false,
        error: null,
        meta: null,
        items: [],
    });
    useEffect(() => {
        getAllNews(newsPagination.currentPage, pageLimit).then((allNewsRes) =>
            setNews((prev) => ({ ...prev, isLoaded: true, meta: allNewsRes.meta, items: allNewsRes.data })),
        );
    }, [newsPagination.currentPage]);

    return (
        <main className="bg-white bg-transparent">
            <section className="container py-4 py-lg-5">
                <h1 className="dark-blue text-center text-uppercase">Новости ПОРТАЛА</h1>
                <div className="row">
                    <div className="col-md-8 col-lg-9">
                        <NewsArticleTiles news={news} newsPagination={newsPagination} />
                    </div>
                    <div className="d-none d-md-block col-4 col-lg-3">
                        <ForumLastMsgFeedWidget />
                    </div>
                </div>
            </section>
        </main>
    );
}
