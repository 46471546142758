import { useEffect, useState } from 'react';

import { useAppDispatch } from '@core/store/hooks';
import { defineCity, setSelectedCity } from '@entities/.app/cities/cities.actions';
import { citiesLSKeys } from '@entities/.app/cities/cities.data';

const useSelectedCity = () => {
    const dispatch = useAppDispatch();
    const [activeCity, setActiveCity] = useState<string | null>(null);
    const [isDefinedCity, setIsDefinedCity] = useState(false);
    const localStorageUserCity = localStorage.getItem(citiesLSKeys.USER_CITY);

    useEffect(() => {
        if (localStorageUserCity) {
            setActiveCity(localStorageUserCity);
        } else {
            defineCity().then((city) => {
                setActiveCity(city);
                setIsDefinedCity(true);
            });
        }
    }, []);

    useEffect(() => {
        if (activeCity) {
            dispatch(setSelectedCity(activeCity));
        }
    }, [activeCity]);

    return { city: activeCity, setCity: setActiveCity, isDefinedCity };
};
export default useSelectedCity;
