export const defaultTownsOptions = [
    { value: '0', label: 'Москва' },
    { value: '1', label: 'Санкт-Петербург' },
    { value: '2', label: 'Казань' },
    { value: '4', label: 'Новосибирск' },
    { value: '3', label: 'Екатеринбург' },
    { value: '5', label: 'Нижний Новгород' },
    { value: '6', label: 'Самара' },
    { value: '7', label: 'Челябинск' },
    { value: '8', label: 'Омск' },
    { value: '9', label: 'Ростов-на-Дону' },
    { value: '10', label: 'Уфа' },
];

export const citiesLSKeys = {
    USER_CITY: 'userCity'
}
