
import { useEffect, useState } from 'react';

import { getCargoCount } from '@entities/market/cargo/cargo.api';
import { getCountRoutes } from '@entities/market/route/route.api';

export default function AccountInfoBlock(){
    const [countRoute, setCountRoute] = useState(null);
    const [cargoCount, setCargoCount] = useState(null);
    useEffect(() => {
        getCountRoutes().then((res) => setCountRoute(res?.data?.body));
        getCargoCount().then((res) => res && setCargoCount(res));
    }, []);

    return (
        <>
            <h4 className="mb-5">С личным кабинетом вы сможете:</h4>
            <ul className="marked-list fs-12">
                <li>Видеть контакты машин и грузов</li>
                <li>Добавлять грузы и машины</li>
                <li>Использовать электронные документы</li>
                <li>Общаться на Форуме</li>
            </ul>
            <h4 className="mt-5">Сейчас на сайте</h4>
            <div className="d-flex justify-content-between">
                <div className="text-center">
                    <div className="title-font fw-9 fs-25 mb-2">{cargoCount}</div>
                    <div className="fs-12">Грузов</div>
                </div>
                <div className="text-center">
                    <div className="title-font fw-9 fs-25 mb-2">{countRoute}</div>
                    <div className="fs-12">Машин</div>
                </div>
            </div>
        </>
    );
};
