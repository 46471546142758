import { IconContext } from 'react-icons';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { IoAddCircleSharp, IoBriefcase, IoCloseOutline, IoCube, IoExitSharp, IoNewspaperSharp } from 'react-icons/io5';
import {
    // MdChat, MdGridView,
    MdLocalShipping } from 'react-icons/md';
import { RiMoneyDollarBoxFill, RiUserFill } from 'react-icons/ri';
import { useAppDispatch} from '@core/store/hooks';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';

import { appRoutes } from '@core/navigation';
import { useAppSelector } from '@core/store/hooks';
import { logout } from '@entities/.app/account/account.actions';
import { selectRoleId } from '@entities/.app/account/account.selectors';
import { getURL } from '@shared/lib/getURL';

const ExitModal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <div
            className="modal fade"
            id="account-exit"
            tabIndex={-1}
            aria-hidden="true"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <button type="button" className="btn-close" data-bs-dismiss="modal">
                            <IoCloseOutline />
                        </button>
                        <h3>Выход</h3>
                        <div className="fs-12 title-font text-center mb-4 mb-sm-5">
                            Вы действительно хотите выйти из личного кабинета?
                        </div>
                        <div className="row row-cols-sm-2 fs-12">
                            <div className="mb-3 mb-sm-0">
                                <button type="button" data-bs-dismiss="modal" className="btn btn-1 w-100">
                                    Отмена
                                </button>
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-2 w-100"
                                    data-bs-dismiss="modal"
                                    onClick={() => {
                                        logout(dispatch);
                                        navigate(getURL());
                                    }}
                                >
                                    Выйти
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default function OfficeMenuWidget() {
    const currentRoleId = useAppSelector(selectRoleId);
    // const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <div className="shadow rounded" style={{ background: '#FFF', padding: '0 10px' }}>
                <nav className="d-block d-lg-none mt-3 pt-3 p-2 mb-sm-5" aria-label="breadcrumb">
                    <Link to={getURL()} className="gray-3">
                        &#10094; На главную
                    </Link>
                </nav>
                <nav className="menu">
                    <ul>
                        <li>
                            <NavLink to={appRoutes.PROFILE}>
                                <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                    <RiUserFill />
                                </IconContext.Provider>
                                <span className="ms-2 ms-xl-3">Профиль</span>
                            </NavLink>
                        </li>
                        {(currentRoleId === 1 || currentRoleId === 3 || currentRoleId === 4) && (
                            <li>
                                <NavLink to={appRoutes.CARS}>
                                    <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                        <MdLocalShipping />
                                    </IconContext.Provider>
                                    <span className="ms-2 ms-xl-3">Мои машины</span>
                                </NavLink>
                                <Link to={getURL(appRoutes.MARKET_CAR_ADD)}>
                                    <IconContext.Provider value={{ className: 'icon-15 blue' }}>
                                        <IoAddCircleSharp />
                                    </IconContext.Provider>
                                </Link>
                            </li>
                        )}
                        {(currentRoleId === 1 || currentRoleId === 2 || currentRoleId === 4) && (
                            <li>
                                <NavLink to={appRoutes.CARGOS}>
                                    <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                        <IoCube />
                                    </IconContext.Provider>
                                    <span className="ms-2 ms-xl-3">Мои грузы</span>
                                </NavLink>
                                <Link to={getURL(appRoutes.MARKET_CARGO_ADD)}>
                                    <IconContext.Provider value={{ className: 'icon-15 blue' }}>
                                        <IoAddCircleSharp />
                                    </IconContext.Provider>
                                </Link>
                            </li>
                        )}
                        {(currentRoleId === 1 || currentRoleId === 3 || currentRoleId === 4) && (
                            <li>
                                <NavLink to={appRoutes.ROUTES}>
                                    <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                        <FaMapMarkerAlt />
                                    </IconContext.Provider>
                                    <span className="ms-2 ms-xl-3">Мои маршруты</span>
                                </NavLink>
                                <Link to={getURL(appRoutes.MARKET_ROUTE_ADD)}>
                                    <IconContext.Provider value={{ className: 'icon-15 blue' }}>
                                        <IoAddCircleSharp />
                                    </IconContext.Provider>
                                </Link>
                            </li>
                        )}
                        <li>
                            <NavLink
                                to={getURL(appRoutes.OFFICE, appRoutes.DEALS)}
                                className={location?.pathname?.split('/').includes('deals') ? 'active' : ''}
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'space-between',
                                    cursor: 'default',
                                }}
                            >
                                <div style={{ cursor: 'pointer' }}>
                                    <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                        <IoBriefcase />
                                    </IconContext.Provider>
                                    <span className="ms-2 ms-xl-3">Мои сделки</span>
                                </div>
                            </NavLink>

                            {/* <div style={{ display: 'flex' }}>
                                <div
                                    className="d-flex align-items-center"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => navigate(getURL(appRoutes.ACCOUNT, appRoutes.DEALS))}
                                >
                                    <IconContext.Provider value={{ className: 'icon-20 blue' }}>
                                        <MdGridView />
                                    </IconContext.Provider>
                                </div>
                                {typeof currentRoleId === 'number' && (
                                    <div
                                        className="d-flex align-items-center"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => navigate(getURL(appRoutes.ACCOUNT_DEAL_FEED))}
                                    >
                                        <IconContext.Provider value={{ className: 'icon-20 blue' }}>
                                            <MdChat />
                                        </IconContext.Provider>
                                    </div>
                                )}
                            </div> */}
                        </li>

                        <li>
                            <NavLink to={appRoutes.TEMPLATES}>
                                <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                    <IoNewspaperSharp />
                                </IconContext.Provider>
                                <span className="ms-2 ms-xl-3">Мои шаблоны</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={appRoutes.TARIFFS}>
                                <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                    <RiMoneyDollarBoxFill />
                                </IconContext.Provider>
                                <span className="ms-2 ms-xl-3">Тарифы</span>
                            </NavLink>
                        </li>
                        <li>
                            <button type="button" data-bs-toggle="modal" data-bs-target="#account-exit">
                                <IconContext.Provider value={{ className: 'icon-15 green' }}>
                                    <IoExitSharp />
                                </IconContext.Provider>
                                <span className="ms-2 ms-xl-3">Выход</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <ExitModal />
        </>
    );
}
