import axios from 'axios';

import { apiRoutes } from '@core/config/api';
import tokens from '@core/config/tokens';
import { getURL } from '@shared/lib/getURL';
import Logger from '@shared/lib/logger';

export async function getCities() {
    try {
        const response = await axios.get(getURL(apiRoutes.APP_API_URL, 'cities'));
        return response.data;
    } catch (error) {
        Logger.error(error);
    }
}

export const getCityFromIP = async (ipAddress) => {
    try {
        const response = await axios.post(
            `https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address?ip=${ipAddress}`,
            {},
            {
                headers: {
                    Authorization: `Token ${tokens.DADATA}`,
                },
            },
        );
        return response?.data?.location?.data?.city;
    } catch (error) {
        Logger.error(error);
        return 'Москва';
    }
};
