import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getRandomNews, getOneNews } from '@entities/news/news.api';
import ForumLastMsgFeedWidget from '@features/forum/Forum.LastMsgFeed.Widget';
import NewsArticleCarousel from '@features/news/News.Article.Carousel';
import NewsArticleContent from '@features/news/News.Article.Content';

export default function NewsArticlePage() {
    const { slug } = useParams();

    const [newsItem, setNewsItem] = useState({
        isLoading: false,
        error: null,
        item: null,
    });
    useEffect(() => {
        getOneNews(slug)
            .then((item) => setNewsItem((prev) => ({ ...prev, isLoading: true, item })))
            .catch((error) => setNewsItem((prev) => ({ ...prev, isLoading: true, error })));
    }, [slug]);

    const [swiperNews, setSwiperNews] = useState({
        isLoading: false,
        error: null,
        items: [],
    });
    useEffect(() => {
        getRandomNews(8)
            .then((items) => setSwiperNews((prev) => ({ ...prev, isLoading: true, items })))
            .catch((error) => setSwiperNews((prev) => ({ ...prev, isLoading: true, error })));
    }, []);

    return (
        <main className="bg-white py-4 py-lg-5 bg-transparent">
            <div className="container">
                <section className="row">
                    <div className="col-md-8 col-lg-9 box shadow">
                        <NewsArticleContent newsItem={newsItem} />
                    </div>
                    <div className="d-none d-md-block col-4 col-lg-3">
                        <ForumLastMsgFeedWidget />
                    </div>
                </section>
                <section className="position-relative mb-3 mb-sm-4 mb-lg-5">
                    <NewsArticleCarousel swiperNews={swiperNews} />
                </section>
            </div>
        </main>
    );
}
