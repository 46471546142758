const envMode = process.env.NODE_ENV;
export default class Logger {
    static log(...data) {
        if (envMode === 'development') {
            console.log(...data);
        }
    }
    static error(...data) {
        if (envMode === 'development') {
            console.error(...data);
        }
    }
    static info(...data) {
        if (envMode === 'development') {
            console.info(...data);
        }
    }
    static warn(...data) {
        if (envMode === 'development') {
            console.warn(...data);
        }
    }
}
